import html2canvas from 'html2canvas'

const useHtmlToImage = () => {
  const captureImage = async (id: string) => {
    const element = document.getElementById(id)
    if (element) {
      const canvas = await html2canvas(element, {
        scale: 2,
        removeContainer: true
      })
      const dataUrl = canvas.toDataURL('image/png')
      return dataUrl
    }
  }

  return { captureImage }
}

export default useHtmlToImage
