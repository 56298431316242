import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getTurbine } from '@/store/slices/turbines'
import { UnknownAction } from '@reduxjs/toolkit'

const useTurbineItem = () => {
  const dispatch = useAppDispatch()
  const { turbineItem } = useAppSelector(s => s.turbines)
  const get = (id: string) =>
    dispatch(getTurbine(`${id}`) as unknown as UnknownAction)

  return {
    turbineItem,
    get
  }
}
export default useTurbineItem
