import { View } from '@react-pdf/renderer'
import React from 'react'
import Table from './Table'
import { helper } from '@/utils'

interface Props {
  turbineName?: string
  turbineDetails?: any
}

const { convertOptionsToTableRows } = helper

const ReportTurbineDetailsPage: React.FC<Props> = ({
  turbineName,
  turbineDetails
}) => (
  <View>
    <Table
      name="Turbine details"
      description={turbineName}
      rows={convertOptionsToTableRows(turbineDetails)}
    />
  </View>
)

export default ReportTurbineDetailsPage
