import { helper } from '@/utils'

import {
  DecommissionOffshoreBottomFixedScopeViewModel,
  DecommissionOffshoreFloatingScopeViewModel,
  DecommissionOnshoreScopeViewModel,
  ProjectItemViewModel,
  ProjectType,
  ScenarioItemViewModel
} from '@/interfaces'

const { getCheckedText } = helper

const useDecommissioningScope = (
  project?: ProjectItemViewModel,
  scenario?: ScenarioItemViewModel | null
) => {
  const onshoreScope =
    scenario?.decommissionScope as DecommissionOnshoreScopeViewModel
  const onshore = [
    {
      text: 'Access road to be removed?',
      value: getCheckedText(!!onshoreScope?.accessRoadsToBeRemoved)
    },
    {
      text: 'Are there geotextiles beneath the road to be removed?',
      value: getCheckedText(
        !!onshoreScope?.geotextilesBeneathTheRoadToBeRemoved
      )
    },
    {
      text: 'Do the foundations need to be removed?',
      value: getCheckedText(!!onshoreScope?.foundationsNeedToBeRemoved)
    },
    {
      text: 'Are the level crane pads at each turbine base to be removed?',
      value: getCheckedText(
        !!onshoreScope?.levelCranePadsAtEachTurbineBaseToBeRemoved
      )
    },
    {
      text: 'Are there geotextiles beneath the crane pad to be removed?',
      value: getCheckedText(
        !!onshoreScope?.geotextilesBeneathTheCranePadToBeRemoved
      )
    },
    {
      text: 'Do the cables need to be removed?',
      value: getCheckedText(!!onshoreScope?.cablesNeedToBeRemoved)
    },
    {
      text: 'Is there a top-soil storage area on site possible?',
      value: getCheckedText(!!onshoreScope?.topSoilStorageAreaOnSitePossible)
    },
    {
      text: 'Does the HV Substation need to be removed?',
      value: getCheckedText(!!onshoreScope?.hVsubstationNeedToBeRemoved)
    },
    {
      text: 'Is the met mast to be removed?',
      value: getCheckedText(!!onshoreScope?.metMastToBeRemoved)
    },
    {
      text: 'Any other building or constructions on site to be removed?',
      value: getCheckedText(!!onshoreScope?.buildingToBeRemoved)
    }
  ]

  const offshoreFloatingScope =
    scenario?.decommissionScope as DecommissionOffshoreFloatingScopeViewModel
  const offshoreFloating = [
    {
      text: 'Floater to be removed',
      value: getCheckedText(!!offshoreFloatingScope?.floaterToBeRemoved)
    },
    {
      text: 'Mooring lines to be removed',
      value: getCheckedText(!!offshoreFloatingScope?.mooringLinesToBeRemoved)
    },
    {
      text: 'Sub sea array cables to be removed',
      value: getCheckedText(
        !!offshoreFloatingScope?.subSeaArrayCablesToBeRemoved
      )
    },
    {
      text: 'Export cables to be removed',
      value: getCheckedText(!!offshoreFloatingScope?.exportCablesToBeRemoved)
    },
    {
      text: 'Cable crossing protection to be removed',
      value: getCheckedText(
        !!offshoreFloatingScope?.cableCrossingProtectionToBeRemoved
      )
    },
    {
      text: 'Offshore substation to be removed',
      value: getCheckedText(
        !!offshoreFloatingScope?.offshoreSubstationToBeRemoved
      )
    }
  ]
  const offshoreBottomFixedScope =
    scenario?.decommissionScope as DecommissionOffshoreBottomFixedScopeViewModel
  const offshoreBottomFixed = [
    {
      text: 'Foundations to be removed',
      value: getCheckedText(!!offshoreBottomFixedScope?.foundationsToBeRemoved)
    },
    {
      text: 'Piles below sea bed to be removed',
      value: getCheckedText(
        !!offshoreBottomFixedScope?.pilesBelowSeaBedToBeRemoved
      )
    },
    {
      text: 'Scour protection to be removed',
      value: getCheckedText(
        !!offshoreBottomFixedScope?.scourProtectionToBeRemoved
      )
    },
    {
      text: 'Sub sea array cables to be removed',
      value: getCheckedText(
        !!offshoreBottomFixedScope?.subSeaArrayCablesToBeRemoved
      )
    },
    {
      text: 'Export cables to be removed',
      value: getCheckedText(!!offshoreBottomFixedScope?.exportCablesToBeRemoved)
    },
    {
      text: 'Cable crossing protection to be removed',
      value: getCheckedText(
        !!offshoreBottomFixedScope?.cableCrossingProtectionToBeRemoved
      )
    },
    {
      text: 'Offshore substation to be removed',
      value: getCheckedText(
        !!offshoreBottomFixedScope?.offshoreSubstationToBeRemoved
      )
    }
  ]

  let output: any[] = []

  if (project?.projectType === ProjectType.Onshore) output = onshore
  if (project?.projectType === ProjectType.OffshoreBottomFixed)
    output = offshoreBottomFixed
  if (project?.projectType === ProjectType.OffshoreFloating)
    output = offshoreFloating

  return [output]
}

export default useDecommissioningScope
