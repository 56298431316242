import React from 'react'
import { OBadge } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  isDefault?: boolean
  className?: string
}

const CostModelBadge: React.FC<Props> = ({ isDefault, className }) => (
  <OBadge className={className} outline variant="secondary">
    {isDefault ? 'Default' : 'Custom'}
  </OBadge>
)

export default React.memo(CostModelBadge)
