import React, { useState, useEffect } from 'react'
import { Marker, Popup } from 'react-map-gl'

const MapBoxPin = ({
  draggable = false,
  latitude,
  longitude,
  color = '0f204b',
  strokeColor = 'fff',
  iconClass = 'fas fa-wind-turbine',
  iconColor = 'fff',
  onDragEnd,
  onClick,
  displayPopup,
  allowPopups,
  isPopupPersistent = false,
  iconFontSize = '12px',
  cursor = 'pointer',
  children
}: any) => {
  const [isPopupVisible, setIsPopupVisible] = useState<any>(true)
  const [innerLatitude, setInnerLatitude] = useState<any>(latitude)
  const [innerLongitude, setInnerLongitude] = useState<any>(longitude)

  useEffect(() => {
    if (draggable) setTimeout(showPopup, 1000)
    return () => hidePopup()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (allowPopups || isPopupPersistent) setIsPopupVisible(displayPopup)
  }, [displayPopup]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInnerLatitude(latitude)
  }, [latitude]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setInnerLongitude(longitude)
  }, [longitude]) //eslint-disable-line react-hooks/exhaustive-deps

  const showPopup = (e: any) => {
    onClick?.(e)
    if (allowPopups && !isPopupVisible) {
      setIsPopupVisible(true)
      setTimeout(() => document.addEventListener('click', hidePopup), 100)
    }
  }

  const hidePopup = () => {
    if (isPopupPersistent) return
    setIsPopupVisible(false)
    document.removeEventListener('click', hidePopup)
  }

  const handleOnDragEnd = (e: any) => {
    if (draggable && e?.lngLat?.length) {
      setInnerLongitude(e.lngLat?.[0])
      setInnerLatitude(e.lngLat[1])
    }
    onDragEnd?.(e)
  }

  return innerLatitude && innerLongitude ? (
    <>
      <Marker
        draggable={draggable}
        latitude={innerLatitude}
        longitude={innerLongitude}
        onDragEnd={handleOnDragEnd}
        onDragStart={hidePopup}
        onClick={isPopupVisible ? hidePopup : showPopup}>
        <div
          className="marker"
          style={{ backgroundColor: { color }, cursor } as any}>
          <div className="marker-pin">
            <svg
              viewBox="0 0 250 410"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <path
                clipRule="evenodd"
                fill={`#${color}`}
                fillRule="evenodd"
                stroke={`#${strokeColor}`}
                strokeWidth="4"
                strokeMiterlimit="4"
                d="M 125,348 C 116,311 102,279 84,250 70,228 54,208 40,186 35,180 30,172 26,165 17,150 9,133 10,112 10,90 17,72 27,58 43,35 70,17 106,12 c 30,-4 58,3 77,13 16,8 28.71,20.0813 38.23305,33.61566 9.9408,14.126239 16.78636,30.815013 17.3601,52 0,11 -2,21 -4,30 -2,8 -7,16 -11,24 -8,15 -18,28 -28,42 -30,40 -58,84 -70,140 z"
              />
            </svg>
          </div>

          <div className="marker-icon">
            <i
              className={iconClass}
              style={{ color: `#${iconColor}`, fontSize: iconFontSize }}
            />
          </div>
        </div>
      </Marker>
      {!!children && isPopupVisible && !!latitude && !!longitude && (
        <Popup
          latitude={latitude}
          longitude={longitude}
          closeButton={false}
          closeOnClick={false}
          anchor="top">
          {children}
        </Popup>
      )}
    </>
  ) : null
}

export default React.memo(MapBoxPin)
