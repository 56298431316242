import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getScenarioConfiguration } from '@/store/slices/scenarios'
import { UnknownAction } from '@reduxjs/toolkit'

const useScenarioCostCalcationConfiguration = () => {
  const { scenarioCostCalcationConfiguration } = useAppSelector(
    s => s.scenarios
  )
  const dispatch = useAppDispatch()

  const getScenarioCostCalcationConfiguration = (scenarioId: string) =>
    dispatch(getScenarioConfiguration(scenarioId) as unknown as UnknownAction)

  return {
    scenarioCostCalcationConfiguration,
    getScenarioCostCalcationConfiguration
  }
}

export default useScenarioCostCalcationConfiguration
