import React from 'react'
import {
  PlantWeightsViewModel,
  ProjectItemViewModel,
  ProjectType,
  SubstationWeightsViewModel
} from '@/interfaces'

import { config, helper } from '@/utils'
import BoPSubstation from '@/components/helpers/BoPSubstation'

const { projects, showNewSubstations } = config
const { formatPercentage, formatTonnes, getAnchorMaterialName } = helper

interface Props {
  data?: PlantWeightsViewModel | null
  project?: ProjectItemViewModel
}

const cellClass = 'border py-1 px-2'

const BalanceOfPlant: React.FC<Props> = ({ data: d, project: p }) =>
  p === undefined ? null : (
    <table className="w-100">
      <tbody>
        {/* OffshoreFloating */}
        {p?.projectType === ProjectType.OffshoreFloating && (
          <>
            <tr>
              <td rowSpan={4} className="p-3 border">
                Floater
              </td>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.floaterSteelWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(d?.floatingWeights?.floaterSteelPercentage)}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Concrete</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.floaterConcreteWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.floatingWeights?.floaterConcretePercentage
                )}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.floaterOtherWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(d?.floatingWeights?.floaterOtherPercentage)}
              </td>
            </tr>
            <tr className="font-weight-bold bg-light">
              <td className={cellClass}>Total</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.floaterTotalWeightT)}
              </td>
              <td className={cellClass}>100%</td>
            </tr>
            <tr>
              <td rowSpan={3} className="p-3 border">
                Mooring lines
              </td>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.mooringLinesSteelWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.floatingWeights?.mooringLinesSteelPercentage
                )}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.mooringLinesOtherWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.floatingWeights?.mooringLinesOtherPercentage
                )}
              </td>
            </tr>
            <tr className="font-weight-bold bg-light">
              <td className={cellClass}>Total</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.mooringLinesTotalWeightT)}
              </td>
              <td className={cellClass}>100%</td>
            </tr>
            <tr>
              <td rowSpan={2} className="p-3 border">
                Anchors
              </td>
              <td className={cellClass}>
                {getAnchorMaterialName(p?.anchor?.material)}
              </td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.anchorsMetalWeightT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(d?.floatingWeights?.anchorsMetalPercentage)}
              </td>
            </tr>
            <tr className="font-weight-bold bg-light">
              <td className={cellClass}>Total</td>
              <td className={cellClass}>
                {formatTonnes(d?.floatingWeights?.anchorsTotalWeightT)}
              </td>
              <td className={cellClass}>100%</td>
            </tr>
          </>
        )}

        {/* OffshoreBottomFixed and Onshore */}
        {(p?.projectType === ProjectType.OffshoreBottomFixed ||
          p?.projectType === ProjectType.Onshore) && (
          <>
            <tr>
              <td rowSpan={4} className="p-3 border">
                Foundations
              </td>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(
                  d?.onshoreOffshoreWeights?.foundationsSteelWeightT
                )}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.onshoreOffshoreWeights?.foundationsSteelPercentage
                )}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Concrete</td>
              <td className={cellClass}>
                {formatTonnes(
                  d?.onshoreOffshoreWeights?.foundationsConcreteWeightT
                )}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.onshoreOffshoreWeights?.foundationsConcretePercentage
                )}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(
                  d?.onshoreOffshoreWeights?.foundationsOtherWeightT
                )}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  d?.onshoreOffshoreWeights?.foundationsOtherPercentage
                )}
              </td>
            </tr>
            <tr className="font-weight-bold bg-light">
              <td className={cellClass}>Total</td>
              <td className={cellClass}>
                {formatTonnes(
                  d?.onshoreOffshoreWeights?.foundationsTotalWeightT
                )}
              </td>
              <td className={cellClass}>100%</td>
            </tr>
          </>
        )}

        {/* Common sections */}
        <tr>
          <td rowSpan={3} className="p-3 border">
            Array cables
          </td>
          <td className={cellClass}>
            {
              projects.selectOptions.cableMaterial.find(
                i => i.value === p?.arrayCableMaterial
              )?.text
            }
          </td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.arrayCablesMetalWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(d?.cablesWeights?.arrayCablesMetalPercentage)}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Other</td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.arrayCablesOtherWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(d?.cablesWeights?.arrayCablesOtherPercentage)}
          </td>
        </tr>
        <tr className="font-weight-bold bg-light">
          <td className={cellClass}>Total</td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.arrayCablesTotalWeightT)}
          </td>
          <td className={cellClass}>100%</td>
        </tr>

        <tr>
          <td rowSpan={3} className="p-3 border">
            Export cables
          </td>
          <td className={cellClass}>
            {
              projects.selectOptions.cableMaterial.find(
                i => i.value === p?.exportCableMaterial
              )?.text
            }
          </td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.exportCablesMetalWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(d?.cablesWeights?.exportCablesMetalPercentage)}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Other</td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.exportCablesOtherWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(d?.cablesWeights?.exportCablesOtherPercentage)}
          </td>
        </tr>
        <tr className="font-weight-bold bg-light">
          <td className={cellClass}>Total</td>
          <td className={cellClass}>
            {formatTonnes(d?.cablesWeights?.exportCablesTotalWeightT)}
          </td>
          <td className={cellClass}>100%</td>
        </tr>

        {showNewSubstations && p?.hasOnshoreSubstation && (
          <BoPSubstation
            data={d?.substations?.onshore ?? null}
            substationType="Onshore"
          />
        )}
        {showNewSubstations &&
          p?.hasOffshoreSubstation &&
          p.projectType !== ProjectType.Onshore && (
            <BoPSubstation
              data={
                (d?.substations
                  ?.offshore as unknown as SubstationWeightsViewModel) ?? null
              }
              substationType="Offshore"
            />
          )}
      </tbody>
    </table>
  )

export default React.memo(BalanceOfPlant)
