import React from 'react'
import { ContentSection } from '../layout'

import { helper, config } from '../../utils'
import { useTexts } from '@/hooks'

interface Props {
  className?: string
}

const ContactSupportSnippet = ({ className }: Props) => {
  const texts = useTexts()
  return (
    <ContentSection title={texts?.contact.title} className={className}>
      <p>
        <span>{texts?.contact.message}</span>&nbsp;
        <a href={helper.getFeedbackLink()}>{config.support.to}</a>.
      </p>
    </ContentSection>
  )
}

export default React.memo(ContactSupportSnippet)
