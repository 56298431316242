import { ProjectType } from '@/interfaces'
import React from 'react'

import { useTexts } from '@/hooks'

interface Props {
  projectType?: ProjectType
}

const ReportBalanceOfPlant = ({ projectType: t }: Props) => {
  const texts = useTexts()
  const text = texts?.report?.balanceOfPlant
  return (
    <div>
      <h2>Balance of plant</h2>
      {!!text && (
        <p>
          {t === ProjectType.Onshore && text.Onshore}
          {t === ProjectType.OffshoreBottomFixed && text.OffshoreBottomFixed}
          {t === ProjectType.OffshoreFloating && text.OffshoreFloating}
        </p>
      )}
    </div>
  )
}

export default React.memo(ReportBalanceOfPlant)
