import { useAppSelector } from '@/store/hooks'
import { getTurbineMaterialBreakdown } from '@/store/slices/turbines'
import { useDispatch } from 'react-redux'

const useMaterialBreakdown = () => {
  const dispatch = useDispatch()

  const { turbineMaterialBreakdown } = useAppSelector(s => s.turbines)
  const get = (id: string) => dispatch(getTurbineMaterialBreakdown(`${id}`))

  return {
    turbineMaterialBreakdown,
    get
  }
}
export default useMaterialBreakdown
