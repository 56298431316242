import React, { useState, useEffect, ReactNode } from 'react'
import {
  ORow,
  OCol,
  OTable,
  OTabs,
  OTab,
  TableField,
  OField,
  FieldType
} from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '@/components/layout'
import { InputGroup } from '@/components/helpers'
import { TurbineItemViewModel } from '@/interfaces'
import { useLocalStorage } from '@/hooks'
import { config } from '@/utils'

interface TabItem {
  title: string
  filter: Function
}

interface Props {
  id: string
  className?: string
  isLoading: boolean
  items?: any[]
  itemName?: string
  tabs?: TabItem[]
  fields?: TableField[]
  filterKeys?: string[]
  searchAllowed?: boolean
  rowsPerPageOptions?: number[]
  children?: ReactNode
}

const { rowsPerPageOptions } = config

const defaults = {
  rowsPerPage: 20,
  rowsPerPageOptions
}

const tableProps = {
  bordered: true,
  small: true,
  showFilter: false,
  showOptionsButton: false,
  showActionIcons: false,
  showColumnsButton: false,
  showFilterButton: false
}

const ItemsCollection = ({
  id,
  className,
  isLoading,
  items = [],
  itemName = 'item',
  tabs = [],
  fields = [],
  filterKeys = [],
  searchAllowed = true,
  rowsPerPageOptions = defaults.rowsPerPageOptions,
  children
}: Props) => {
  const [query, setQuery] = useState<string>('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const onSearchChange = (value: string) => setQuery(value)
  const [selectedTab, setSelectedTab] = useLocalStorage(id, 0)
  const [rowsPerPage, setRowsPerPage] = useLocalStorage(
    `${id}-rowsPerPage`,
    defaults.rowsPerPage
  )

  const filterItems = (
    filterValue: string,
    items: TurbineItemViewModel[] = []
  ) => {
    if (!filterValue) return items
    const query = filterValue.toLocaleLowerCase()
    return items.filter(f => {
      let found = false
      filterKeys.forEach((i: any) => {
        if (i?.includes('.')) {
          const keys = i?.split('.')
          for (const key of keys) {
            if (i.hasOwnProperty(key)) {
              if (
                `${(f as any)?.[i]?.[key]}`
                  ?.toLocaleLowerCase()
                  ?.includes(query)
              )
                found = true
              i = i[key]
            }
          }
        } else if (`${(f as any)?.[i]}`?.toLocaleLowerCase()?.includes(query))
          found = true
      })
      return found
    })
  }

  useEffect(() => {
    if (!query && items?.length) setFilteredItems(items || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(
    () => {
      const _items = filterItems(query, items)
      setFilteredItems(_items)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query]
  )

  if (!items.length && isLoading) return null

  return (
    <ContentSection className={className}>
      {!!children && (
        <ORow>
          <OCol md="12">{children}</OCol>
        </ORow>
      )}
      <ORow>
        {!items?.length && !isLoading && (
          <OCol md="12" className="text-center m-4">
            <p>The list is empty.</p>
          </OCol>
        )}
        {searchAllowed && items?.length > 1 && (
          <OCol md="3">
            <InputGroup className="mb-1" onChange={onSearchChange} />
          </OCol>
        )}
        {items?.length > rowsPerPageOptions[0] && (
          <OCol
            md="3"
            offsetMd={searchAllowed ? '6' : '9'}
            className="text-right">
            <OField
              className="no-print border-0"
              value={{ rowsPerPage }}
              onChange={e =>
                setRowsPerPage(parseInt(`${e.value}`) || defaults.rowsPerPage)
              }
              field={{
                name: 'rowsPerPage',
                type: FieldType.Select,
                options: rowsPerPageOptions.map(i => ({
                  value: i,
                  text: `${i} ${itemName === 'country' ? 'countrie' : itemName}s / page`
                }))
              }}
            />
          </OCol>
        )}
        <OCol md="12">
          {items.length ? (
            tabs?.length ? (
              <OTabs
                selectedTab={selectedTab}
                onTabSelect={id => setSelectedTab?.(id)}>
                {tabs.map(({ title, filter }) => (
                  <OTab title={title} key={title}>
                    <OTable
                      className={['position-relative', `table-${id}`].join(' ')}
                      rowsPerPage={rowsPerPage}
                      fields={fields}
                      allItems={filteredItems?.filter?.(filter as any)}
                      {...tableProps}
                    />
                  </OTab>
                ))}
              </OTabs>
            ) : (
              <OTable
                className={['position-relative', `table-${id}`].join(' ')}
                rowsPerPage={rowsPerPage}
                fields={fields}
                allItems={filteredItems}
                {...tableProps}
              />
            )
          ) : null}
        </OCol>
      </ORow>
    </ContentSection>
  )
}
export default React.memo(ItemsCollection)
