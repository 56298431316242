import React from 'react'
import { MapBoxLine, MapBoxPin } from '@/components/maps'
import ProviderPinSelected from './ProviderPinSelected'

interface Props {
  selectedProject: any
  metalProcessingProvider: any
  bladeProcessingProvider: any
  concreteProcessingProvider: any
  onClick?: (providerId: string) => void
}

const ProvidersProjectLines = ({
  selectedProject,
  metalProcessingProvider,
  bladeProcessingProvider,
  concreteProcessingProvider,
  onClick
}: Props) => {
  const features = []

  if (selectedProject?.location && metalProcessingProvider?.location)
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [
            selectedProject?.location?.longitude,
            selectedProject?.location?.latitude
          ],
          [
            metalProcessingProvider?.location?.longitude,
            metalProcessingProvider?.location?.latitude
          ]
        ]
      }
    })

  if (selectedProject?.location && bladeProcessingProvider?.location)
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [
            selectedProject?.location?.longitude,
            selectedProject?.location?.latitude
          ],
          [
            bladeProcessingProvider?.location?.longitude,
            bladeProcessingProvider?.location?.latitude
          ]
        ]
      }
    })

  if (selectedProject?.location && concreteProcessingProvider?.location)
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: [
          [
            selectedProject?.location?.longitude,
            selectedProject?.location?.latitude
          ],
          [
            concreteProcessingProvider?.location?.longitude,
            concreteProcessingProvider?.location?.latitude
          ]
        ]
      }
    })

  return (
    <>
      <MapBoxPin
        draggable={false}
        latitude={selectedProject?.location?.latitude}
        longitude={selectedProject?.location?.longitude}
      />
      {!!metalProcessingProvider?.id && (
        <ProviderPinSelected
          latitude={metalProcessingProvider?.location?.latitude}
          longitude={metalProcessingProvider?.location?.longitude}
          type="metal"
          isPopupPersistent
          displayPopup
          onClick={() => onClick?.(metalProcessingProvider?.id)}>
          <>
            <div className="text-center">
              <small>Metal processing</small>
            </div>
            <div>
              <small>{metalProcessingProvider?.name}</small>
            </div>
          </>
        </ProviderPinSelected>
      )}
      {!!bladeProcessingProvider?.id && (
        <ProviderPinSelected
          latitude={bladeProcessingProvider?.location?.latitude}
          longitude={bladeProcessingProvider?.location?.longitude}
          type="blade"
          isPopupPersistent
          displayPopup
          onClick={() => onClick?.(bladeProcessingProvider?.id)}>
          <>
            <div className="text-center">
              <small>Blade processing</small>
            </div>
            <div>
              <small>{bladeProcessingProvider?.name}</small>
            </div>
          </>
        </ProviderPinSelected>
      )}
      {!!bladeProcessingProvider?.id && (
        <ProviderPinSelected
          latitude={concreteProcessingProvider?.location?.latitude}
          longitude={concreteProcessingProvider?.location?.longitude}
          type="other"
          isPopupPersistent
          displayPopup
          onClick={() => onClick?.(concreteProcessingProvider?.id)}>
          <>
            <div className="text-center">
              <small>Concrete processing</small>
            </div>
            <div>
              <small>{concreteProcessingProvider?.name}</small>
            </div>
          </>
        </ProviderPinSelected>
      )}
      <MapBoxLine id="lines" features={features} />
    </>
  )
}

export default React.memo(ProvidersProjectLines)
