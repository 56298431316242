import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getCountries } from '@/store/slices/general'
import { config, helper } from '@/utils'
import useLocalStorage from './useLocalStorage'

const { isValidGuid } = helper

const useCountries = () => {
  const dispatch = useAppDispatch()
  const { countries, countryOptions } = useAppSelector(s => s.general)
  const get = () => dispatch(getCountries() as any)

  const [defaultCountry, setDefaultCountry] = useLocalStorage<string | 0>(
    'defaultCountry',
    0
  )

  useEffect(() => {
    if (defaultCountry === 0) {
      const country = countries?.find?.(i => i?.name === config.defaultCountry)
      if (isValidGuid(country?.id)) setDefaultCountry(`${country?.id}`)
    }
  }, [countries, defaultCountry, setDefaultCountry])

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { countries, countryOptions, defaultCountry, setDefaultCountry }
}

export default useCountries
