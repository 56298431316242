import React from 'react'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const options: ScrollToOptions = {
  top: 0,
  left: 0,
  behavior: 'smooth'
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(options)
  }, [pathname])

  return null
}

export default React.memo(ScrollToTop)
