import React from 'react'
import { config } from '@/utils'
import ProviderPin from './ProviderPin'
import { SelectedProviderType } from '@/interfaces'

interface Props {
  latitude?: number
  longitude?: number
  type?: SelectedProviderType
  displayPopup?: boolean
  isPopupPersistent?: boolean
  children?: JSX.Element
  onClick?: () => void
}

const { icons } = config

const getIconClass = (type?: SelectedProviderType) => {
  const output = ['text-white']
  if (type === 'metal') output.push(icons.metalProcessingLight)
  if (type === 'blade') output.push(icons.bladeProcessingLight)
  if (type === 'other') output.push(icons.concreteProcessingLight)
  return output.join(' ')
}

const ProviderPinSelected = ({
  latitude,
  longitude,
  type,
  displayPopup,
  isPopupPersistent,
  children,
  onClick
}: Props) => (
  <ProviderPin
    latitude={latitude}
    longitude={longitude}
    color="0F204B"
    displayPopup={displayPopup}
    isPopupPersistent={isPopupPersistent}
    iconClass={getIconClass(type)}
    onClick={onClick}>
    {children}
  </ProviderPin>
)

export default React.memo(ProviderPinSelected)
