import React, { ReactNode } from 'react'

interface Props {
  id?: string
  className?: string
  icon?: string
  titleLeft?: string
  titleRight?: string
  children?: ReactNode
}

const ReportSection = ({
  id,
  className,
  icon,
  titleLeft,
  titleRight,
  children
}: Props) => (
  <div className={className} id={id}>
    <div className="d-flex bg-dark fg-white">
      <div className="d-flex w-50 p-2 font-weight-bold">
        {!!icon && <i className={`${icon} mr-3 mt-1`} />}
        {titleLeft}
      </div>
      <div className="d-flex w-50 p-2 justify-content-end">{titleRight}</div>
    </div>
    <div className="border">{children}</div>
  </div>
)

export default React.memo(ReportSection)
