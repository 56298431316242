import React from 'react'
import { Page } from '@/components/layout'
import { ContactSupportSnippet } from '@/components/helpers'

const ContactSupportPage = () => (
  <Page title="Contact support">
    <ContactSupportSnippet />
  </Page>
)

export default React.memo(ContactSupportPage)
