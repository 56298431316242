import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '@/utils'
import {
  OffshoreFixedCostCalculationViewModel,
  OffshoreFloatingCostCalculationViewModel,
  OnshoreCostCalculationViewModel,
  ProjectType
} from '@/interfaces'

export interface CalculationsState {
  isLoading: boolean
  costCalculationForScenario: OnshoreCostCalculationViewModel | null
  onshoreCalculation: OnshoreCostCalculationViewModel | null
  offshoreFixedCalculation: OffshoreFixedCostCalculationViewModel | null
  offshoreFloatingCalculation: OffshoreFloatingCostCalculationViewModel | null
}

const initialState: CalculationsState = {
  isLoading: false,
  costCalculationForScenario: null,
  onshoreCalculation: null,
  offshoreFixedCalculation: null,
  offshoreFloatingCalculation: null
}

export const calculationsctsSlice = createSlice({
  name: 'calculations',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setCostCalculationForScenario: (
      state,
      action: PayloadAction<OnshoreCostCalculationViewModel | null>
    ) => {
      state.costCalculationForScenario = action.payload
    },
    setOnshoreCalculation: (
      state,
      action: PayloadAction<OnshoreCostCalculationViewModel | null>
    ) => {
      state.onshoreCalculation = action.payload
    },
    setOffshoreFixedCalculation: (
      state,
      action: PayloadAction<OffshoreFixedCostCalculationViewModel | null>
    ) => {
      state.offshoreFixedCalculation = action.payload
    },
    setOffshoreFloatingCalculation: (
      state,
      action: PayloadAction<OffshoreFloatingCostCalculationViewModel | null>
    ) => {
      state.offshoreFloatingCalculation = action.payload
    }
  }
})

export const { actions } = calculationsctsSlice

export const getCostCalculationForScenario =
  (scenarioId: string, projectType: ProjectType) =>
  (dispatch: any, getState: any) => {
    const state = getState()
    if (state.calculations.isLoading) return

    dispatch(actions.setIsLoading(true))
    dispatch(actions.setCostCalculationForScenario(null))
    return api.costCalculations
      .read(scenarioId, projectType)
      .then(
        (
          data:
            | OnshoreCostCalculationViewModel
            | OffshoreFixedCostCalculationViewModel
            | OffshoreFloatingCostCalculationViewModel
        ) => {
          if (data) {
            if (projectType === ProjectType.Onshore)
              dispatch(
                actions.setOnshoreCalculation(
                  data as OnshoreCostCalculationViewModel
                )
              )

            if (projectType === ProjectType.OffshoreBottomFixed)
              dispatch(
                actions.setOffshoreFixedCalculation(
                  data as OffshoreFixedCostCalculationViewModel
                )
              )

            if (projectType === ProjectType.OffshoreFloating)
              dispatch(
                actions.setOffshoreFloatingCalculation(
                  data as OffshoreFloatingCostCalculationViewModel
                )
              )
          }
        }
      )
      .finally(() => dispatch(actions.setIsLoading(false)))
  }
