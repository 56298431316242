import { useParams } from 'react-router-dom'
import useLocalStorage from './useLocalStorage'

import { config } from '@/utils'
import {
  useScenarioCostCalculation,
  useScenarioState,
  useScenarioUpdate
} from '@/hooks'
import { useEffect } from 'react'
import { ProjectType } from '@/interfaces'

const { calculations } = config

const useDecommCostCalculator = (
  projectType?: ProjectType,
  scenarioId?: string
) => {
  const { id: projectId } = useParams()
  const { onPropertyUpdate } = useScenarioUpdate()
  const { scenario, get: getScenario } = useScenarioState()
  const { costCalculationForScenario, get: getScenarioCostCalculation } =
    useScenarioCostCalculation()

  const isProjectTypeSupported = calculations.supportedProjectTypes.includes(
    projectType as ProjectType
  )

  const [activeTab, setActiveTab] = useLocalStorage(
    `useSelectProviders-activeTab-${projectId}`,
    0
  )

  const getDefaultScenario = (id?: string | null) => getScenario(`${id}`)
  const getDefaultScenarioCostCalculation = (
    id: string | null,
    projectType: ProjectType
  ) => getScenarioCostCalculation(`${id}`, projectType)

  const toggleScopeValue = (name: string) => {
    if (scenario === null && (scenario as any)[name] === undefined) return
    onPropertyUpdate(name, !(scenario as any)[name], `${scenario?.id}`)
  }

  useEffect(() => {
    if (isProjectTypeSupported && projectType !== undefined) {
      getDefaultScenario(scenarioId ? scenarioId : null)
      getDefaultScenarioCostCalculation(
        scenarioId ? scenarioId : null,
        projectType
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenarioId])

  return {
    isProjectTypeSupported,
    defaultScenario: scenario as any,
    projectId,
    costCalculationForScenario,
    activeTab,
    setActiveTab,
    toggleScopeValue
  }
}

export default useDecommCostCalculator
