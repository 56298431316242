import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

interface Props {
  isSelected?: boolean
  text: string
  onClick?: () => void
}

const FilterButton = ({ isSelected, text, onClick }: Props) => (
  <OButton
    variant="flat"
    className="d-flex w-100 align-items-center white-space-nowrap"
    iconClass={`${isSelected ? 'fas fa-check-square' : 'far fa-square'}`}
    onClick={onClick}>
    {text}
  </OButton>
)

export default React.memo(FilterButton)
