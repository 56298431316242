import React, { FC } from 'react'
import { config, helper } from '@/utils'
import ItemsCollection from './ItemsCollection'

import { ProjectItemViewModel, ProjectType } from '@/interfaces'
import { OActionBtn } from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate } from 'react-router-dom'

interface Props {
  projects?: ProjectItemViewModel[]
}

const { buildItemLink, isNotDraft, getProjectTypeName, addPluralS } = helper
const { urls, missingValueCharacter, rowsPerPageOptions } = config

const ConfigurationProjects: FC<Props> = ({ projects = [] }) => {
  const getProject = (name?: string) =>
    projects?.find?.((item: ProjectItemViewModel) => item?.name === name)

  const navigate = useNavigate()
  return (
    <div>
      {!!projects.length ? (
        <ItemsCollection
          id="ConfigurationProjects"
          className="card-dnvgl-overflow"
          isLoading={false}
          items={projects}
          itemName="item"
          fields={[
            {
              name: 'name',
              heading: 'Projects',
              sortable: true,
              formatter: (name: string) =>
                buildItemLink(
                  [urls.settings, 'projects', getProject(name)?.id].join('/'),
                  name
                )
            },
            {
              name: 'name',
              heading: 'Scenarios',
              formatter: (name: string) => {
                const s = getProject(name)?.scenarios
                return s?.length ? (
                  <OActionBtn
                    actions={
                      s?.map(scenario => {
                        return {
                          name: scenario?.name,
                          onClick: () =>
                            navigate(
                              [urls.settingsScenarios, scenario?.id].join('/')
                            )
                        }
                      }) as any
                    }
                    variant="flat">
                    {s?.length} scenario
                    {addPluralS(s?.length || 0)}
                  </OActionBtn>
                ) : (
                  <div className="text-center">{missingValueCharacter}</div>
                )
              }
            },
            {
              name: 'projectType',
              heading: 'Type',
              sortable: true,
              formatter: (type: ProjectType) => getProjectTypeName(type)
            },
            {
              name: 'countryName',
              heading: 'Location',
              sortable: true
            },
            {
              name: 'companyName',
              heading: 'Company',
              sortable: true
            }
          ]}
          tabs={[
            { title: 'Saved projects', filter: isNotDraft },
            { title: 'Drafts', filter: (i: any) => i?.isDraft }
          ]}
          filterKeys={[
            'name',
            'projectTypeName',
            'countryName',
            'turbineType',
            'companyName'
          ]}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      ) : null}
    </div>
  )
}

export default React.memo(ConfigurationProjects)
