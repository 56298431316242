import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useTurbineOptions } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getTurbines } from '@/store/slices/turbines'
import { api, config, helper } from '@/utils'
import {
  PaginatedListOfTurbineItemWithProjectsViewModel,
  ProjectSimpleItemViewModel,
  TurbineSimpleItemViewModel
} from '@/interfaces'
import {
  OBadge,
  OTooltip,
  showError
} from '@dnvgl-onefoundation/onedesign-react'

const { urls } = config

const {
  genericErrorHandler,
  isValidGuid,
  deepClone,
  handleZeroStrings,
  truncateString
} = helper

const buildProjectLinks = (projects: ProjectSimpleItemViewModel[]) =>
  projects?.map(p => (
    <Link to={`${urls.projects}/${p.id}`} className="mr-1 d-inline-block">
      <OTooltip content={p?.name}>
        <OBadge outline variant="secondary" className="mb-1">
          {truncateString(p?.name, 18)}
        </OBadge>
      </OTooltip>
    </Link>
  ))

const useTurbines = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { defaulValues, manufacturers } = useTurbineOptions()
  const { isLoading, turbines } = useAppSelector(s => s.turbines)

  const get = () => dispatch(getTurbines() as any)

  const create = (payload: any, isDraft: boolean) => {
    if (!payload?.name?.length)
      return showError('Name is required', 'Please provide a value.')
    if (!payload?.modelNumber?.length)
      return showError('Model is required', 'Please provide a value.')
    if (payload?.generatorType === false)
      return showError('Generator type is required', 'Please provide a value.')

    return api.turbines
      .create({ ...defaulValues, ...handleZeroStrings(payload), isDraft })
      .then(
        newTurbineId =>
          isValidGuid(newTurbineId) &&
          navigate(`${urls.turbines}/${newTurbineId}`)
      )
      .catch(genericErrorHandler)
  }

  const updateTurbines = (
    turbines: PaginatedListOfTurbineItemWithProjectsViewModel | null
  ) => {
    if (!turbines?.items?.length) return []
    const items = deepClone(turbines.items)
    return items?.map?.((i: any) => {
      i.manufacturer =
        manufacturers?.find?.(m => m.value === i?.manufacturer)?.text ||
        i.manufacturer
      i.projectLinks = buildProjectLinks(i.projects || [])
      return i
    })
  }

  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const add = (payload: any) => create(payload, false)
  const saveDraft = (payload: any) => create(payload, true)

  const items = updateTurbines(turbines)
  const alreadyAvailableNames = items?.map(
    (i: TurbineSimpleItemViewModel) => i.name
  )

  return {
    isLoading,
    items,
    alreadyAvailableNames,
    add,
    saveDraft
  }
}

export default useTurbines
