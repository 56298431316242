import React from 'react'
import {
  OTabs,
  OTab,
  ORow,
  OCol,
  OButton,
  OBadge
} from '@dnvgl-onefoundation/onedesign-react'
import { helper } from '@/utils'
import ConfigurationParameters from './ConfigurationParameters'
import { useConfigurationUpdate } from '@/hooks'
import { SpinIndicator } from '../layout'

import {
  ConfigurationType,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel
} from '@/interfaces'

interface Props {
  data?:
    | CostCalculationOnshoreConfigurationRegisterViewModel
    | CostCalculationOffshoreFixedConfigurationRegisterViewModel
    | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
    | null
  id: string | 'default'
  type: ConfigurationType | null
  name: string | 'Default'
}

const { getProjectTypeName, toLocaleDateTime } = helper

const SingleConfiguration: React.FC<Props> = ({ data, id, type, name }) => {
  const { isUpdating, revertConfiguration } = useConfigurationUpdate()
  const isSingleProjectConfiguration = data?.scenarioIsLocked === null
  const isConfigurationLocked =
    (isSingleProjectConfiguration && data?.projectIsLocked) ||
    (!isSingleProjectConfiguration && data?.scenarioIsLocked)

  return (
    <div>
      <ORow>
        <OCol md="8">
          <h2>{data?.name}</h2>
        </OCol>
        <OCol md="4" className="text-right mt-2">
          {!!data?.lastEdit && (
            <div className="pr-3">
              {isConfigurationLocked && (
                <OBadge className="mr-2" variant="secondary">
                  Locked
                </OBadge>
              )}
              Last edit: {toLocaleDateTime(data?.lastEdit?.toString())}
            </div>
          )}
          {data?.reversible && !data?.isDefault && !isConfigurationLocked && (
            <div>
              <OButton
                disabled={isUpdating}
                size="small"
                className="mr-4"
                onClick={() =>
                  type !== null && revertConfiguration(id, name, type)
                }>
                Revert to default configuration
              </OButton>
            </div>
          )}
        </OCol>
      </ORow>
      {isUpdating ? (
        <SpinIndicator />
      ) : (
        <OTabs>
          <OTab
            title={getProjectTypeName(data?.projectType) ?? ''}
            disabled={data?.projectType === 2}>
            <ConfigurationParameters
              isLocked={isConfigurationLocked}
              data={data?.costCalculationConfigurationSet}
              projectType={data?.projectType ?? 0}
              id={id}
              type={type}
            />
          </OTab>
          <OTab title={''} />
        </OTabs>
      )}
    </div>
  )
}
export default React.memo(SingleConfiguration)
