import { ReactNode } from 'react'
import SpinIndicator from './SpinIndicator'

interface Props {
  title?: ReactNode
  isLoading?: boolean
  icon?: string
  disabled?: boolean
  className?: string
  contentClassName?: string
  titleClassName?: string
  noMarginTop?: boolean
  showHoverShadow?: boolean
  padding?: '0' | '1' | '2' | '3' | '4'
  underlineTitle?: boolean
  actions?: ReactNode
  onClick?: () => void
  children?: ReactNode
}

const ContentSection = ({
  title,
  isLoading,
  icon,
  disabled,
  className = '',
  contentClassName = 'p-2',
  titleClassName = 'p-2',
  noMarginTop,
  showHoverShadow = false,
  underlineTitle = false,
  actions,
  onClick: onClickProp,
  children
}: Props) => {
  const pointerEvents: 'none' | 'auto' = disabled ? 'none' : 'auto'
  const opacity: number = disabled ? 0.3 : 1
  const classes = ['color-dark-blue']
  if (title) classes.push(titleClassName)
  if (underlineTitle) classes.push('border-bottom')
  return (
    <div
      className={[
        'card',
        'card-dnvgl',
        'content-section',
        `mt-${noMarginTop ? 0 : 3}`,
        className,
        showHoverShadow ? undefined : 'no-shadow'
      ].join(' ')}
      style={{ pointerEvents, opacity }}
      onClick={() => onClickProp?.()}>
      <div className={classes.join(' ')}>
        {isLoading && (
          <div className="position-absolute w-100" style={{ top: 0 }}>
            <SpinIndicator />
          </div>
        )}
        {!!icon && <i className={`${icon} mr-2`} />}
        {!!title && <span className="font-weight-bold">{title}</span>}
        {!!actions && <span className="float-right">{actions}</span>}
      </div>
      <div className={`mt-0 ${contentClassName}`}>{children}</div>
    </div>
  )
}

export default ContentSection
