import React from 'react'
import { Link } from 'react-router-dom'
import {
  showError,
  showInfo,
  Option,
  OTooltip
} from '@dnvgl-onefoundation/onedesign-react'
import config from './config'
import {
  AnchorMaterial,
  AppUser,
  CurrencyInformationViewModel,
  GroupType,
  MooringLineMaterial,
  ProjectType,
  UserRoleType
} from '@/interfaces'
import doc from './doc'
import { DEFAULT_CURRENCY_CODE } from '@/components/helpers/Constants'

const isDnvUser = (user: AppUser | null) =>
  !!user?.groups?.find(g => g.isInternal === true)

const hasRole = (user: AppUser | null, Role: UserRoleType) =>
  user?.roles?.map(role => role.id).includes(Role) ?? false

const isAuthenticated = (user: AppUser | null) => !!user?.id

const isCustomer = (user: AppUser | null) =>
  hasRole(user, UserRoleType.Customer)

const isInternal = (user: AppUser | null) =>
  hasRole(user, UserRoleType.Internal)

const isSuperAdministrator = (user: AppUser | null) =>
  hasRole(user, UserRoleType.SuperAdministrator)

const getUserCompanyIds = (user: AppUser | null) =>
  user?.groups?.filter(g => g.type === GroupType.Company)?.map(g => g.id)

const getFeedbackLink = (subject = `${config.appFullName} Feedback`) => {
  const url = window.location.href
  return `mailto:${config.support.to}?subject=${subject}&cc=${config.support.cc}&body=%0D%0A%0D%0ALink: ${url}`
}

const deepClone = (obj: unknown) => JSON.parse(JSON.stringify(obj))
const callAsync = (callback: Function, delay: number = 0) =>
  window.setTimeout(callback, delay)
const addPluralS = (count: number | null) => (count === 1 ? '' : 's')
const getPercentage = (num: number | undefined) =>
  num ? parseFloat(`${num * 100}`).toPrecision(4) : 0
const calculatePercentage = (num: number, total: number) =>
  total ? (num / total) * 100 : 0

const truncateString = (
  originalString: string,
  stringLength: number = 10,
  separator: string = '...'
) => {
  if (originalString.length <= stringLength) return originalString

  separator = separator || '...'

  const sepLen = separator.length,
    charsToShow = stringLength - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    originalString.substr(0, frontChars) +
    separator +
    originalString.substr(originalString.length - backChars)
  )
}
const getOptionText = (
  collection: readonly Option[],
  value?: number | string | undefined
) => collection?.find?.(i => `${i?.value}` === `${value}`)?.text || value

const handleErrorMessage = (error?: any) => {
  console.error('Error >>>')
  console.error(error?.response ? error.response : error)
  const maxMessageLength = 250
  let message = 'An Error Occured!'
  if (error?.response?.status === 401)
    message = 'Session expired. Please refresh and log in.'
  if (error?.response?.status === 403)
    message =
      'Access Denied. You do not have permission to access, or update the data.'
  const data = error?.response?.data
  if (data?.message) message = data.message
  // Handle server errors e.g. request body limit.
  if (data?.errors?.[''][0]) message = data.errors[''][0]

  if (typeof error?.response?.data === 'string') {
    const parsed = JSON.parse(error?.response?.data)
    if (typeof parsed?.Message === 'string') message = parsed.Message
  }

  if (message?.length > maxMessageLength)
    message = `Error: ${truncateString(
      message,
      maxMessageLength
    )}… More details at the developer console.`

  showError('Error', message)
}

const genericErrorHandler = () =>
  showError('An Error Occurred!', 'Please check the developer console.')

const nullHandler = (value: null | string | number | boolean) =>
  value === null ? config.missingValueCharacter : value

const isNotNull = (value: any) => value !== null

const isValidEmail = (emailCandidate?: string) => {
  const regexExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regexExp.test(String(emailCandidate).toLowerCase())
}

const isValidGuid = (candidateGuid?: string | void) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(`${candidateGuid}`) // true
}

const notifyUnderConstruction = () =>
  showInfo(
    <span>
      <i className="fal fa-space-station-moon-alt" />
      &nbsp;Under construction…
    </span>,
    'The feature is under construction.'
  )

const toLocaleDate = (dateTimeOffset?: string) => {
  if (!dateTimeOffset) return config.missingValueCharacter
  const date = new Date(dateTimeOffset)
  return date?.toString() !== 'Invalid Date'
    ? `${date?.toLocaleDateString('en-GB')}`
    : config.missingValueCharacter
}

const toLocaleDateTime = (dateTimeOffset?: string) => {
  if (!dateTimeOffset) return config.missingValueCharacter
  const date = new Date(dateTimeOffset)
  return date?.toString() !== 'Invalid Date'
    ? `${date?.toLocaleDateString()}, ${date?.toLocaleTimeString()}`
    : config.missingValueCharacter
}

const toYear = (dateTimeOffset?: string) => {
  if (!dateTimeOffset) return config.missingValueCharacter
  const date = new Date(dateTimeOffset)
  return date?.toString() !== 'Invalid Date'
    ? date.getFullYear()
    : config.missingValueCharacter
}

const toHtmlDate = (input: string | Date, locale: string = 'fr-CA') => {
  const date = new Date(input)
  return date?.toString() !== 'Invalid Date'
    ? date?.toLocaleDateString?.(locale).split('T')[0] // fr-CA is YYYY-MM-DD format
    : config.missingValueCharacter
}

const isMissingValue = (value?: any) => [null, undefined, '']?.includes(value)

export const getProjectTypeName = (t?: ProjectType) => {
  if (t === ProjectType.Onshore) return 'Onshore'
  if (t === ProjectType.OffshoreBottomFixed) return 'Offshore (bottom fixed)'
  if (t === ProjectType.OffshoreFloating) return 'Offshore (floating)'
}

const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1)

const sort = (arr: any[], key: string, order: 'asc' | 'desc' = 'asc') =>
  arr?.sort((a, b) => {
    let aValue = a?.[key]
    let bValue = b?.[key]

    if (typeof aValue === 'string') aValue = aValue.toLowerCase()
    if (typeof bValue === 'string') bValue = bValue.toLowerCase()

    if (aValue < bValue) return order === 'asc' ? -1 : 1
    if (aValue > bValue) return order === 'desc' ? -1 : 1

    return 0
  })

const buildTruncatedName = (name: string, stringLength = 50) => (
  <OTooltip content={name} placement="bottom">
    {truncateString(name, stringLength)}
  </OTooltip>
)

const buildItemLink = (to: string, text: string, stringLength = 50) => (
  <OTooltip content={text} placement="bottom">
    <Link to={to} className="underlined">
      {truncateString(text, stringLength)}
    </Link>
  </OTooltip>
)

const generateYearOptions = (start: number, end = new Date().getFullYear()) => {
  const options = []
  for (let year = start; year < end + 1; year++)
    options.push({ text: `${year}`, value: `${year}-01-01` })
  return options
}

const isNotDraft = (i: any) => !i?.isDraft
const isDraft = (i: any) => i?.isDraft

const getCheckedText = (value: boolean) => (value ? 'Yes' : 'No')

const currentDate = toLocaleDate(new Date().toString())
const currentYear = new Date().getFullYear()

const formatCoordinates = (location: any) =>
  location?.latitude !== undefined && location?.longitude !== undefined
    ? `${location?.latitude}°N, ${location?.longitude}°E`
    : config.missingValueCharacter

const formatLink = (url?: string) =>
  url ? (
    <a
      href={url.startsWith('http') ? url : `https://${url}`}
      target="_blank"
      rel="noopener noreferrer">
      {url}
    </a>
  ) : (
    config.missingValueCharacter
  )

const formatMailto = (email?: string) =>
  isValidEmail(email) ? (
    <a href={`mailto:${email}`}>{email}</a>
  ) : (
    config.missingValueCharacter
  )

const formatCurrency = (
  value?: number,
  currencyCode: string = DEFAULT_CURRENCY_CODE
) =>
  typeof value === 'number'
    ? new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol'
      })
        .format(value)
        .replace(/,/, ';') // as there is no locale format that does the exact format we need
        .replace(/\./g, ',') // with currency symbol separated from value and on the last place
        .replace(/;/, '.')
    : config.missingValueCharacter

const invertValue = (value?: number) =>
  typeof value === 'number' ? -value : value

const getArraySum = (values: any[]) =>
  values?.reduce((a, b) => {
    const _a = parseFloat(a)
    const _b = parseFloat(b)
    if (typeof _a !== 'number' || typeof _b !== 'number') return 0
    return _a + _b
  }, 0)

const getTimeDifference = (timestamp: Date): number => {
  const currentTimestamp = Date.now()
  const difference = currentTimestamp - timestamp.getTime()
  return difference
}

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'
  const units: string[] = [
    'Bytes',
    'KB',
    'MB',
    'GB',
    'TB',
    'PB',
    'EB',
    'ZB',
    'YB'
  ]
  const base: number = 1024
  const exponent: number = Math.floor(Math.log(bytes) / Math.log(base))
  const size: number = parseFloat((bytes / Math.pow(base, exponent)).toFixed(2))
  return `${size} ${units[exponent]}`
}

const isNamePresent = (name: string, collection: any[]) =>
  collection
    ?.map(i => i?.name?.toLowerCase?.()?.trim())
    ?.includes?.(name?.toLocaleLowerCase?.()?.trim())

const formatTonnes = (value?: number | null, precision = 1) =>
  value === undefined
    ? config.missingValueCharacter
    : value === 0
    ? '0 tonnes'
    : `${value?.toFixed?.(precision)} tonne${addPluralS(
        typeof value === 'number'
          ? parseInt(value?.toFixed?.(precision))
          : value
      )}`

const formatPercentage = (value?: number | null) =>
  typeof value !== 'number'
    ? config.missingValueCharacter
    : value === 0
    ? '0%'
    : `${value?.toFixed?.(2)}%`

const safeFormatPercentage = (value?: number | null): string => {
  if (value === undefined || value === null) {
    return '0%'
  }

  return formatPercentage(value * 100)
}

const getFileExtention = (name?: string) => name?.match(/\.(.*)$/)?.[1]

const waitaSecond = (callback: Function) => callAsync(callback, 1000)

const handleZeroStrings = (obj: { [key: string]: string | number }) => {
  for (let key in obj)
    if (obj?.hasOwnProperty?.(key) && obj[key] === '0') obj[key] = 0
  return obj || {}
}

const setActiveNavItem = (linkPath: string) => {
  doc.removeClass('.o-header-link', 'active')
  doc.addClass(`.o-header-link[href="${linkPath}"]`, 'active')
}

const verifiedElement = (value: any) => {
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean'
  )
    return value

  return React.isValidElement(value) ? value : null
}

const getGreeting = () => {
  const currentTime = new Date()
  const currentHour = currentTime.getHours()

  if (currentHour >= 5 && currentHour < 12) return 'Good morning'
  else if (currentHour >= 12 && currentHour < 18) return 'Good afternoon'
  else return 'Good evening'
}

const stylizeStatusMessage = (message: string) => {
  if (message.startsWith('Failure'))
    return (
      <span className="text-danger font-weight-bold">
        <i className="fas fa-exclamation-circle mr-2" />
        {message}
      </span>
    )
  if (message.startsWith('Success'))
    return (
      <span className="text-success">
        <i className="fas fa-check-circle mr-2" />
        {message}
      </span>
    )
  return message
}

const isInternalCompany = (name?: string) => name === 'DNV'

const camelCaseToHumanFriendly = (str: string) =>
  str
    .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter
    .replace(/^./, match => match.toUpperCase()) // Ensure the first letter is uppercase
    .trim()

const isEmptyObject = (obj: any) =>
  typeof obj === 'object' ? !Object.keys(obj)?.length : true

const defineConfigurationSection = (projectType: ProjectType) => {
  let result = 'onshore'
  if (projectType === ProjectType.OffshoreBottomFixed) result = 'offshoreFixed'
  if (projectType === ProjectType.OffshoreFloating) result = 'offshoreFloating'
  return result
}

const formatFinancialShortcut = (num?: number) => {
  if (num === undefined) return config.missingValueCharacter
  if (Math.abs(num) < 1000)
    return num?.toFixed(2) // Return the number as it is if it's less than 1000
  else if (Math.abs(num) < 1000000)
    return (num / 1000).toFixed(1) + 'K' // Convert thousands to 'K'
  else if (Math.abs(num) < 1000000000) {
    return (num / 1000000).toFixed(1) + 'M' // Convert millions to 'M'
  } else return (num / 1000000000).toFixed(1) + 'B' // Convert billions to 'B'
}

const formatCurrencyMask = (
  value: number,
  currencyInformation: CurrencyInformationViewModel
) => {
  if (currencyInformation?.formattingMask) {
    return currencyInformation.formattingMask
      .replace(
        /\{DoubleValue}/g,
        `${Math.round(value).toLocaleString('en-US')}`
      )
      .replace(/\{Sign}/g, `${currencyInformation.symbol}`)
      .replace(/\{Unit}/g, `${currencyInformation.unit}`)
  }
  return value
}

const getMooringLineMaterialName = (material?: MooringLineMaterial) =>
  config.projects.selectOptions.mooringLineMaterial?.find(
    i => i.value === material
  )?.text || config.missingValueCharacter

const getAnchorMaterialName = (material?: AnchorMaterial) =>
  config.projects.selectOptions.anchor?.find(i => i.value === material)?.text ||
  config.missingValueCharacter

const convertOptionsToTableRows = (options: Option[]) =>
  options?.map((option: any) => {
    const output = [option?.text]
    if (option?.value !== undefined && option?.value !== 'header') {
      if (Array.isArray(option?.value)) {
        output.push(...option?.value)
      } else {
        output.push(option?.value)
      }
    }
    return output
  })
const convertOptionsToTableRowsWithMultiplier = (
  options: Option[],
  multiplierTurbine: number = 1,
  multiplierMw: number = 1
) =>
  options?.map((option: any) => {
    const output = [option?.text]
    if (option?.value !== undefined && option?.value !== 'header') {
      if (typeof option.value === 'number') {
        output.push(
          !!multiplierTurbine ? multiplierTurbine * option?.value : option.value
        )
        output.push(
          !!multiplierMw ? multiplierMw * option?.value : option.value
        )
      } else {
        output.push(...Array(2).fill(option.value))
      }
    }
    return output
  })
const calculatePerTurbineMultiplier = (
  numberOfTurbines: number
): number | undefined => (numberOfTurbines ? 1 / numberOfTurbines : undefined)

const calculatePerMwMultiplier = (
  perTurbineMultiplier: number | undefined,
  turbineRatedPower: number | undefined
): number | undefined =>
  perTurbineMultiplier && turbineRatedPower
    ? perTurbineMultiplier / turbineRatedPower
    : undefined

const toggle = (i: boolean) => !i

const numberToWords = (number: number) => {
  const list = [
    '',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine'
  ]
  if (number < 10) {
    return list[number]
  }
  return number
}

const helper = {
  isDnvUser,
  addPluralS,
  buildTruncatedName,
  buildItemLink,
  callAsync,
  capitalize,
  calculatePercentage,
  currentDate,
  currentYear,
  deepClone,
  formatCoordinates,
  formatLink,
  formatMailto,
  formatCurrency,
  formatCurrencyMask,
  formatFileSize,
  formatPercentage,
  safeFormatPercentage,
  formatTonnes,
  generateYearOptions,
  getArraySum,
  getTimeDifference,
  getCheckedText,
  getFeedbackLink,
  getOptionText,
  getPercentage,
  getProjectTypeName,
  getUserCompanyIds,
  genericErrorHandler,
  handleErrorMessage,
  hasRole,
  invertValue,
  isAuthenticated,
  isCustomer,
  isDraft,
  isInternal,
  isMissingValue,
  isNamePresent,
  isNotDraft,
  isSuperAdministrator,
  isValidEmail,
  isValidGuid,
  isNotNull,
  isEmptyObject,
  notifyUnderConstruction,
  nullHandler,
  sort,
  toHtmlDate,
  toLocaleDate,
  toLocaleDateTime,
  toYear,
  truncateString,
  getFileExtention,
  waitaSecond,
  handleZeroStrings,
  setActiveNavItem,
  verifiedElement,
  getGreeting,
  stylizeStatusMessage,
  isInternalCompany,
  camelCaseToHumanFriendly,
  defineConfigurationSection,
  formatFinancialShortcut,
  getMooringLineMaterialName,
  getAnchorMaterialName,
  convertOptionsToTableRows,
  convertOptionsToTableRowsWithMultiplier,
  calculatePerTurbineMultiplier,
  calculatePerMwMultiplier,
  toggle,
  numberToText: numberToWords
}

export default helper
