import React, { useEffect, useState } from 'react'
import { LatLng } from '@/interfaces'
import ReInput from '@/components/helpers/ReInput'

const range = {
  lat: {
    min: -90,
    max: 90
  },
  lng: {
    min: -180,
    max: 180
  }
}

const getCorrectValue = (value: number, min: number, max: number) => {
  if (value <= min) value = min
  if (value >= max) value = max
  return value
}

interface Props {
  isEditable?: boolean
  isLocked?: boolean
  lngLat?: LatLng
  onLocationUpdate?: (e: any) => void
}

const CoordinatesValue = ({
  isEditable,
  isLocked,
  lngLat,
  onLocationUpdate
}: Props) => {
  const [lng, setLng] = useState(lngLat?.lng ?? 0)
  const [lat, setLat] = useState(lngLat?.lat ?? 0)

  useEffect(() => {
    if (lngLat?.lng) setLng(lngLat?.lng)
    if (lngLat?.lat) setLat(lngLat?.lat)
  }, [lngLat])

  const onChange = (n: string, v: number) => {
    if (n === 'lng') setLng(getCorrectValue(v, range.lng.min, range.lng.max))
    if (n === 'lat') setLat(getCorrectValue(v, range.lat.min, range.lat.max))
  }

  const onBlur = () => {
    if (!lng || !lat) return
    if (lngLat?.lng !== lng || lngLat?.lat !== lat)
      onLocationUpdate?.({ lngLat: { lng, lat } })
  }

  return (
    <>
      <ReInput
        propertyName="lat"
        gridSize={3}
        label="Latitude, °N"
        disabled={isLocked || !isEditable}
        defaultValue={lat}
        onChange={(n, v) => onChange?.(n, +v)}
        onBlur={() => onBlur()}
        type="number"
      />
      <ReInput
        propertyName="lng"
        gridSize={3}
        label="Longitude, °E"
        defaultValue={lng}
        disabled={isLocked || !isEditable}
        onChange={(n, v) => onChange?.(n, +v)}
        onBlur={() => onBlur()}
        type="number"
      />
    </>
  )
}

export default CoordinatesValue
