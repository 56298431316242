import React from 'react'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '@/utils'
import {
  ProjectItemViewModel,
  SubstationSubType,
  SubstationViewModel
} from '@/interfaces'
import ProjectPageSubstationWeights from '@/components/projects/ProjectPageSubstationWeights'
import { Grid2 } from '@mui/material'
import { FormControl, FormLabel, Option, Select } from '@mui/joy'
import ReRadioToggle from '@/components/helpers/ReRadioToggle'

interface Props {
  project?: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstations: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
}

const { projects } = config

const ProjectPageOffshoreSubstation = ({
  project,
  onUpdate,
  onUpdateSubstations,
  projectSubstations
}: Props) => {
  return (
    <>
      <Grid2 size={6}>
        <ReRadioToggle
          label="Offshore substation"
          selectedValue={project?.hasOffshoreSubstation}
          handleChange={() => {
            onUpdate('hasOffshoreSubstation', !project?.hasOffshoreSubstation)
          }}
        />
        {project?.hasOffshoreSubstation && (
          <ReRadioToggle
            label="Offshore substation type"
            selectedValue={
              projectSubstations?.offshore?.subType ===
                SubstationSubType.Hvac ||
              projectSubstations?.offshore?.subType === undefined
            }
            noLabel={SubstationSubType[SubstationSubType.Hvac].toUpperCase()}
            yesLabel={SubstationSubType[SubstationSubType.Hvdc].toUpperCase()}
            handleChange={() => {
              onUpdateSubstations(
                'subType',
                projectSubstations?.offshore?.subType === SubstationSubType.Hvac
                  ? SubstationSubType.Hvdc
                  : SubstationSubType.Hvac,
                'offshore'
              )
            }}
          />
        )}
      </Grid2>
      {project?.hasOffshoreSubstation && project.projectType ? (
        <>
          <Grid2 size={6}>
            <FormControl>
              <FormLabel>Number of offshore substations</FormLabel>
              <Select
                defaultValue={1}
                value={projectSubstations?.offshore?.count}
                onChange={(_, v) =>
                  onUpdateSubstations('count', v, 'offshore')
                }>
                {projects.selectOptions.numberOfSubstations.map(item => (
                  <Option key={item.text} value={item?.value}>
                    {item.text}
                  </Option>
                ))}
              </Select>
            </FormControl>
            <ProjectPageSubstationWeights
              project={project}
              onUpdateSubstations={onUpdateSubstations}
              offshoreType="foundation"
              weights={projectSubstations?.offshore?.foundation}
            />
            <ProjectPageSubstationWeights
              project={project}
              onUpdateSubstations={onUpdateSubstations}
              offshoreType="topside"
              weights={projectSubstations?.offshore?.topSide}
            />
          </Grid2>
        </>
      ) : (
        <OCol md="6" />
      )}
    </>
  )
}

export default React.memo(ProjectPageOffshoreSubstation)
