import {
  ORow,
  OCol,
  OLogo,
  OTabs,
  OTab,
  OList
} from '@dnvgl-onefoundation/onedesign-react'
import { config } from '@/utils'
import AdminUsersList from './AdminUsersList'
import { AppUser, UserGroupItem } from '@/interfaces'
import AdminTeamsList from './AdminTeamsList'
import { ContentSection } from '@/components/layout'
import { useAdminUsers } from '@/hooks'

interface Props {
  currentUser: AppUser
}

const getMenuIconClass = (group: UserGroupItem) =>
  group === config.groups.unassignedGroup
    ? 'fas fa-users'
    : group?.isInternal
    ? 'fab fa-fort-awesome'
    : 'fas fa-building'

const AdminUsers = (props: Props) => {
  const { currentUser } = props
  const { currentGroup, groups, onSelected } = useAdminUsers(currentUser)

  if (!groups?.length) return null

  return (
    <ContentSection className="card-dnvgl-overflow mb-3">
      <ORow>
        <OCol md="3">
          <OList
            className="pt-2 pl-2 mb-2"
            items={groups.map((i: UserGroupItem) => {
              return {
                id: i?.id,
                content: (
                  <span>
                    <i className={getMenuIconClass(i)} />
                    <span className="ml-3">{i?.name}</span>
                  </span>
                )
              }
            })}
            selected={[`${currentGroup?.id}`]}
            small
            onSelected={(i: any) => onSelected(i?.id)}
          />
        </OCol>
        {!!groups?.length && (
          <OCol md="9">
            <ORow className="pt-3 mt-2 mr-2 border">
              <OCol col="12">
                {groups?.length && currentGroup && currentGroup?.isInternal ? (
                  <OLogo style={{ maxWidth: '112px' }} />
                ) : (
                  <h1 className="mt-0">{currentGroup?.name}</h1>
                )}
              </OCol>
              <OCol col="12" className="mt-3">
                {currentGroup?.id ? (
                  <OTabs className="w-100">
                    <OTab title="Users">
                      <AdminUsersList
                        currentUser={currentUser}
                        currentUserGroup={currentGroup as any}
                      />
                    </OTab>
                    <OTab title="Teams">
                      <AdminTeamsList
                        currentUser={currentUser}
                        currentUserGroup={currentGroup as any}
                      />
                    </OTab>
                  </OTabs>
                ) : (
                  <p className="m-3 mb-5 text-center w-100">
                    <i className="fas fa-hand-point-left mr-2" />
                    Please select a group.
                  </p>
                )}
              </OCol>
            </ORow>
          </OCol>
        )}
      </ORow>
    </ContentSection>
  )
}

export default AdminUsers
