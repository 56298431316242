import { useCallback, useEffect, useState } from 'react'
import { api, config, helper } from '@/utils'
import { useItemId } from '@/hooks'
import { AppUser, UserIdentity } from '@/interfaces'
import { useAppSelector } from '@/store/hooks'
import { useDispatch } from 'react-redux'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'
import { getUsers } from '@/store/slices/userGroups'

const useUser = (currentUser: AppUser) => {
  const isCustomer = helper.isCustomer(currentUser)
  const [email, setEmail] = useState('')
  const routeGroupId = useItemId(config.urls.adminUserGroups)
  const userGroups = useAppSelector(s => s.userGroups.userGroups)
  const [groupId, setGroupId] = useState<string | undefined>()
  const [isVisible, setIsVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isSearchCompleted, setIsSearchCompleted] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [veracityUser, setVeracityUser] = useState<UserIdentity | null>(null)

  useEffect(() => {
    setGroupId(routeGroupId ?? userGroups[0]?.id)
  }, [routeGroupId, userGroups])

  const dispatch = useDispatch()
  const reloadUsers = useCallback(() => {
    if (routeGroupId === groupId) dispatch(getUsers(groupId, true))
  }, [dispatch, groupId, routeGroupId])

  const showModal = () => {
    reset()
    setEmail('')
    setIsVisible(true)
  }

  const reset = () => {
    setIsSubmitting(false)
    setIsSearchCompleted(false)
    setIsLoading(false)
    setVeracityUser(null)
  }
  const hideModal = () => setIsVisible(false)
  const handleOK = () => {
    setIsLoading(true)
    return veracityUser?.id ? addUser() : inviteUser()
  }

  const addUser = () =>
    api.userAdministration
      .addVeracityUser(`${veracityUser?.id}`)
      .then(() => {
        reloadUsers()
        hideModal()
        showSuccess(
          'User added',
          `${veracityUser?.firstName} ${veracityUser?.lastName}`
        )
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsLoading(false))

  const inviteUser = () =>
    (isCustomer
      ? api.groups.inviteUser(groupId!, email)
      : api.userAdministration.inviteUser(email)
    )
      .then(() => {
        reloadUsers()
        hideModal()
        showSuccess('User invited', email)
      })
      .catch(helper.handleErrorMessage)
      .finally(() => setIsLoading(false))

  const getVeracityUser = () => {
    reset()
    setIsLoading(true)
    setIsSearchCompleted(false)
    api.userAdministration
      .findVeracityUser(email)
      .then(data => {
        if (data?.length) setVeracityUser(data[0])
      })
      .finally(() => {
        setIsLoading(false)
        setIsSearchCompleted(true)
      })
  }

  const getOKButtonText = () => {
    if (!isSearchCompleted) return 'Add'
    return veracityUser
      ? `Invite ${veracityUser.firstName} ${veracityUser.lastName}`
      : `Invite ${email}`
  }

  return {
    isLoading,
    isCustomer,
    email,
    groupId,
    userGroups,
    isSearchCompleted,
    veracityUser,
    isVisible,
    isSubmitting,
    handleOK,
    hideModal,
    showModal,
    getVeracityUser,
    setEmail,
    setGroupId,
    setIsSearchCompleted,
    getOKButtonText
  }
}

export default useUser
