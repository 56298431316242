import { useState } from 'react'

import { api, helper } from '@/utils'

import useLocalStorage from './useLocalStorage'

const { genericErrorHandler, toLocaleDateTime } = helper

export default function useTeamsRequest(id: string) {
  const [isRequesting, setIsRequesting] = useState(false)

  const [timestamp, setTimestamp] = useLocalStorage<string>(id)

  const sendRequest = (message: string) => {
    setIsRequesting(true)
    api.teamsNotifications
      .notify(message, window.location.href)
      .then(() => setTimestamp(toLocaleDateTime(new Date().toString())))
      .catch(genericErrorHandler)
      .finally(() => setIsRequesting(false))
  }

  return {
    isRequesting,
    timestamp,
    sendRequest
  }
}
