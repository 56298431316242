import React from 'react'
import { Link } from 'react-router-dom'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '@/components/layout'

interface Item {
  path: string
  icon?: string
  title?: string
}

interface Props {
  items?: Item[]
  cardColumns?: '2' | '3' | '4' | '6'
  className?: string
}

const NavigationCards = ({ items, cardColumns = '3', className }: Props) => (
  <>
    {items?.map?.(({ path, icon, title }) => (
      <OCol
        key={path}
        md={cardColumns}
        sm="12"
        className={[className].join(' ')}>
        <Link to={path}>
          <ContentSection className="text-center" showHoverShadow>
            <i className={[icon, 'fa-2x', 'mt-3'].join(' ')} />
            <h3 className="mt-3">{title}</h3>
          </ContentSection>
        </Link>
      </OCol>
    ))}
  </>
)

export default React.memo(NavigationCards)
