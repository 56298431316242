import { ProcessedMaterial, ScenarioItemViewModel } from '@/interfaces'
import { ActionCreator } from '@/store'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getProvider, resetProvider } from '@/store/slices/scenarioProviders'
import { useEffect } from 'react'

const useScenarioProviders = (scenario: ScenarioItemViewModel | null) => {
  const {
    metalProcessingProvider,
    bladeProcessingProvider,
    concreteProcessingProvider
  } = useAppSelector(s => s.scenarioProviders)
  const dispatch: any = useAppDispatch()
  const reset = (type: ProcessedMaterial) => dispatch(resetProvider(type))

  useEffect(() => {
    if (scenario?.providers?.metal)
      dispatch(getProvider(scenario?.providers?.metal) as ActionCreator)
    else reset(ProcessedMaterial.Metal)

    if (scenario?.providers?.blade)
      dispatch(getProvider(scenario?.providers?.blade) as ActionCreator)
    else reset(ProcessedMaterial.Blade)

    if (scenario?.providers?.concrete)
      dispatch(getProvider(scenario?.providers?.concrete) as ActionCreator)
    else reset(ProcessedMaterial.Concrete)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario])

  return {
    metalProcessingProvider,
    bladeProcessingProvider,
    concreteProcessingProvider,
    reset
  }
}
export default useScenarioProviders
