import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'
import { api, helper } from '@/utils'
import {
  PaginatedListOfProjectItemViewModel,
  ProjectItemViewModel,
  SubstationViewModel,
  ProjectTurbineViewModel,
  UpdatePropertyViewModel,
  TurbineInfoPatch
} from '@/interfaces'
import { getErrorHandler } from './users'
import { ActionCreator } from '@/store'

export interface ProjectsState {
  isLoading: boolean
  isLoadingSubstations: boolean
  isLoadingTurbines: boolean
  isUpdating: boolean
  isNotFound: boolean
  isLoaded: boolean
  projects: PaginatedListOfProjectItemViewModel | null
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  projectTurbines: ProjectTurbineViewModel[] | null
}

const initialState: ProjectsState = {
  isLoading: false,
  isLoadingSubstations: false,
  isLoadingTurbines: false,
  isUpdating: false,
  isNotFound: false,
  isLoaded: false,
  projects: null,
  project: null,
  projectSubstations: null,
  projectTurbines: null
}

const { isValidGuid } = helper

export const projectsSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsLoadingSubstations: (state, action: PayloadAction<boolean>) => {
      state.isLoadingSubstations = action.payload
    },
    setIsLoadingTurbines: (state, action: PayloadAction<boolean>) => {
      state.isLoadingTurbines = action.payload
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload
    },
    setIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.isLoaded = action.payload
    },
    setIsNotFound: (state, action: PayloadAction<boolean>) => {
      state.isNotFound = action.payload
    },
    setProjects: (
      state,
      action: PayloadAction<PaginatedListOfProjectItemViewModel | null>
    ) => {
      state.projects = action.payload
    },
    setProject: (state, action: PayloadAction<ProjectItemViewModel | null>) => {
      state.project = action.payload
    },
    setProjectSubstations: (
      state,
      action: PayloadAction<SubstationViewModel | null>
    ) => {
      state.projectSubstations = action.payload
    },
    setProjectTurbines: (
      state,
      action: PayloadAction<ProjectTurbineViewModel[] | null>
    ) => {
      state.projectTurbines = action.payload
    },
    resetSubstation: (state, action: PayloadAction<string>) => {
      state.projectSubstations = {
        ...state.projectSubstations,
        [action.payload]: null
      }
    }
  }
})

export const { actions } = projectsSlice

export const getProjects: ActionCreator = () => dispatch => {
  dispatch(actions.setProjects(null))
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setProject(null))

  return api.projects
    .readAll()
    .then(data => {
      dispatch(actions.setProjects(data || []))
      dispatch(actions.setIsLoaded(true))
    })
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getProject: ActionCreator = (id: string) => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setIsNotFound(false))
  dispatch(actions.setProject(null))
  dispatch(actions.setProjectTurbines(null))
  return api.projects
    .read(id)
    .then(data => dispatch(actions.setProject(data || null)))
    .catch(e => {
      dispatch(actions.setIsNotFound(true))
      getErrorHandler(dispatch)(e)
    })
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getProjectSubstations: ActionCreator =
  (id: string) => dispatch => {
    dispatch(actions.setIsLoadingSubstations(true))
    dispatch(actions.setProjectSubstations(null))
    return api.projects
      .getSubstations(id)
      .then(data => {
        return dispatch(actions.setProjectSubstations(data || null))
      })
      .catch(e => {
        dispatch(actions.setIsNotFound(true))
        getErrorHandler(dispatch)(e)
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

export const getProjectTurbines: ActionCreator = (id: string) => dispatch => {
  dispatch(actions.setIsLoadingTurbines(true))
  dispatch(actions.setProjectTurbines(null))
  return api.projects
    .getTurbines(id)
    .then(data => {
      return dispatch(actions.setProjectTurbines(data || null))
    })
    .catch(e => {
      dispatch(actions.setIsNotFound(true))
      getErrorHandler(dispatch)(e)
    })
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const createProject: ActionCreator = (payload: any) => dispatch => {
  dispatch(actions.setIsLoading(true))
  return api.projects
    .create(payload)
    .then(id => (isValidGuid(id) ? id : undefined))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const updateProperty =
  (propertyName: string, propertyValue: string | number, projectId: string) =>
  (dispatch: any) => {
    dispatch(actions.setIsUpdating(true))
    if (
      propertyName === 'hasOnshoreSubstation' ||
      propertyName === 'hasOffshoreSubstation'
    )
      dispatch(
        actions.resetSubstation(
          propertyName === 'hasOnshoreSubstation' ? 'onshore' : 'offshore'
        )
      )
    const payload: UpdatePropertyViewModel = { propertyName, propertyValue }
    return api.projects
      .update(projectId, payload)
      .then(data => {
        showSuccess('Project updated.')
        dispatch(actions.setProject(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }

export const updateSubstation =
  (
    propertyName: string,
    propertyValue: string | number,
    projectId: string,
    type: string,
    offshoreType?: string
  ) =>
  (dispatch: any) => {
    dispatch(actions.setIsLoadingSubstations(true))
    const payload: UpdatePropertyViewModel = { propertyName, propertyValue }
    return api.projects
      .updateSubstations(projectId, type, payload, offshoreType)
      .then(data => {
        showSuccess('Project updated.')
        dispatch(actions.setProjectSubstations(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoadingSubstations(false)))
  }

export const updateTurbine =
  (
    projectId: string,
    oldTurbineId: string,
    newTurbineId: string,
    turbineInfo: TurbineInfoPatch
  ) =>
  (dispatch: any) => {
    dispatch(actions.setIsUpdating(true))

    return api.projects
      .removeTurbine(projectId, oldTurbineId)
      .then(() =>
        api.projects.changeTurbine(projectId, newTurbineId, turbineInfo)
      )
      .then(data => {
        showSuccess('Project updated.')
        dispatch(actions.setProjectTurbines([data]))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }

export const assignTurbine =
  (projectId: string, turbineId: string, turbineData: TurbineInfoPatch) =>
  (dispatch: any) => {
    dispatch(actions.setIsUpdating(true))

    return api.projects
      .changeTurbine(projectId, turbineId, turbineData)
      .then(data => {
        showSuccess('Project updated.')
        dispatch(actions.setProjectTurbines([data]))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }
