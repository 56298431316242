import { StyleSheet, Text, View } from '@react-pdf/renderer'
import React from 'react'

type Props = {
  name?: string
  value?: string
}

const styles = StyleSheet.create({
  view: {
    marginBottom: 10
  },
  name: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 2
  },
  value: {
    fontSize: 14
  }
})

const DisplayValue: React.FC<Props> = ({ name, value }) => (
  <View style={styles.view}>
    <Text style={styles.name}>{name}</Text>
    <Text style={styles.value}>{value}</Text>
  </View>
)

export default DisplayValue
