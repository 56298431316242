import React from 'react'
import { Text } from '@react-pdf/renderer'
import { helper } from '@/utils'
import ConditionalRender from './ConditionalRenderer'
import Table from './Table'
import {
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  OnshoreParameterViewModel,
  ProjectType
} from '@/interfaces'

interface Props {
  projectType?: ProjectType
  includeScrap?: boolean
  scenarioCostCalcationConfiguration?:
    | CostCalculationOnshoreConfigurationRegisterViewModel
    | CostCalculationOffshoreFixedConfigurationRegisterViewModel
    | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
    | null
  styles?: any
  texts?: any
}

const { camelCaseToHumanFriendly, formatCurrencyMask, currentYear } = helper

const buildRows = (parameters?: OnshoreParameterViewModel[]) => {
  const rows: any = []
  // eslint-disable-next-line array-callback-return
  Object.keys(!!parameters ? parameters : []).map((i: any) => {
    const header = [camelCaseToHumanFriendly(i)]
    const visibleItems: any[] = []
    ;(parameters as any)?.[i]?.forEach?.((p: any) => {
      if (p.isVisibleForUser)
        visibleItems.push([
          p?.displayName,
          p?.description,
          formatCurrencyMask(p.doubleValue, p.currencyInformation!)
        ])
    })

    if (visibleItems.length > 0) {
      rows.push(header)
      visibleItems?.forEach?.((i: any) => rows.push(i))
    }
  })

  return rows
}

const ReportCostAssumptions: React.FC<Props> = ({
  projectType,
  includeScrap,
  scenarioCostCalcationConfiguration,
  styles,
  texts
}) => (
  <>
    <Text style={styles.pageTitle}>{texts?.report.costAssumptions.Header}</Text>
    {projectType === ProjectType.Onshore
      ? texts?.report.costAssumptions.Onshore.map((i: any) => (
          <ConditionalRender
            data={
              !includeScrap
                ? i
                    .replace(
                      texts?.report.costAssumptions
                        .OnshoreScrapMetalPricesConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions.OnshoreScrapConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions
                        .OnshoreScrapMetalScopeConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions.CurrentYear,
                      currentYear.toString()
                    )
                    .replace(
                      texts?.report.costAssumptions.SelectedCurrency,
                      `${scenarioCostCalcationConfiguration?.currency.name}, ${scenarioCostCalcationConfiguration?.currency.currencyInformation.symbol}`
                    )
                : i
                    .replace(
                      texts?.report.costAssumptions.CurrentYear,
                      currentYear.toString()
                    )
                    .replace(
                      texts?.report.costAssumptions.SelectedCurrency,
                      `${scenarioCostCalcationConfiguration?.currency.name}, ${scenarioCostCalcationConfiguration?.currency.currencyInformation.symbol}`
                    )
            }
            key={i}
          />
        ))
      : null}
    <Table
      breakPage
      name="Cost assumptions"
      rows={buildRows(
        scenarioCostCalcationConfiguration?.costCalculationConfigurationSet
          ?.parameters as any[]
      )}
      valueFormater={formatCurrencyMask}
    />
  </>
)

export default ReportCostAssumptions
