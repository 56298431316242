import { ORow, OCol, OButton } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'
import InputGroup from './InputGroup'
import { providers } from '@/utils'
import FilterButton from './FilterButton'
import ButtonDropdown from './ButtonDropdown'

const {
  providerTypeNamesOptions,
  providerMaterialsEnumNamesOptions,
  providerServiceNamesOptions
} = providers

interface Props {
  selectedTypes?: any[]
  selectedMatrials?: any[]
  selectedServices?: any[]
  onSearchChange?: (query: string) => void
  onFilterToggle?: (
    section: 'type' | 'materials' | 'services',
    value: any
  ) => void
  onClearSelection?: (section: 'type' | 'materials' | 'services') => void
}

const ProvidersFilters = ({
  selectedTypes = [],
  selectedMatrials = [],
  selectedServices = [],
  onSearchChange,
  onFilterToggle,
  onClearSelection
}: Props) => {
  const facilityTypesNumber = providerTypeNamesOptions?.filter(({ value }) =>
    selectedTypes.includes(value)
  )?.length
  const materialsNumber = providerMaterialsEnumNamesOptions?.filter(
    ({ value }) => selectedMatrials.includes(value)
  )?.length

  const servicesNumber = providerServiceNamesOptions?.filter(({ value }) =>
    selectedServices.includes(value)
  )?.length

  return (
    <ORow>
      <OCol md="4">
        <InputGroup
          placeholder="Search facilities by company or location"
          className="mb-1"
          onChange={onSearchChange}
        />
      </OCol>
      <OCol md="2" className="pt-2 text-right font-weight-bold">
        Filter
      </OCol>
      <OCol md="2" className="pr-0 pl-0">
        <ButtonDropdown
          label={
            <>
              Facility type
              {!!facilityTypesNumber && (
                <small className="ml-1 d-inline badge-primary p-1 px-2 rounded-pill">
                  {facilityTypesNumber}
                </small>
              )}
            </>
          }
          className="w-100">
          {providerTypeNamesOptions?.map(({ text, value }: any) => (
            <FilterButton
              key={`${text}-${value}`}
              text={text}
              isSelected={selectedTypes.includes(value)}
              onClick={() => onFilterToggle?.('type', value)}
            />
          ))}
          <div className="p-1 pb-2">
            <OButton
              variant="flat"
              className="border rounded-pill"
              iconClass="fas fa-times"
              onClick={() => onClearSelection?.('type')}>
              Clear selection
            </OButton>
          </div>
        </ButtonDropdown>
      </OCol>
      <OCol md="2" className="pr-0 pl-1">
        <ButtonDropdown
          label={
            <>
              Materials
              {!!materialsNumber && (
                <small className="ml-1 d-inline badge-primary p-1 px-2 rounded-pill">
                  {materialsNumber}
                </small>
              )}
            </>
          }
          className="w-100">
          {providerMaterialsEnumNamesOptions?.map(({ text, value }: any) => (
            <FilterButton
              key={`${text}-${value}`}
              text={text}
              isSelected={selectedMatrials.includes(value)}
              onClick={() => onFilterToggle?.('materials', value)}
            />
          ))}
          <div className="p-1 pb-2">
            <OButton
              variant="flat"
              className="border rounded-pill"
              iconClass="fas fa-times"
              onClick={() => onClearSelection?.('materials')}>
              Clear selection
            </OButton>
          </div>
        </ButtonDropdown>
      </OCol>
      <OCol md="2" className="pr-3 pl-1">
        <ButtonDropdown
          label={
            <>
              Services
              {!!servicesNumber && (
                <small className="ml-1 d-inline badge-primary p-1 px-2 rounded-pill">
                  {servicesNumber}
                </small>
              )}
            </>
          }
          className="w-100">
          {providerServiceNamesOptions?.map(({ text, value }: any) => (
            <FilterButton
              key={`${text}-${value}`}
              text={text}
              isSelected={selectedServices.includes(value)}
              onClick={() => onFilterToggle?.('services', value)}
            />
          ))}
          <div className="p-1 pb-2">
            <OButton
              variant="flat"
              className="border rounded-pill"
              iconClass="fas fa-times"
              onClick={() => onClearSelection?.('services')}>
              Clear selection
            </OButton>
          </div>
        </ButtonDropdown>
      </OCol>
    </ORow>
  )
}

export default React.memo(ProvidersFilters)
