import { Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { ReportSectionCode } from '@/interfaces'

interface Props {
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const ReportSupplyChainMapPage: React.FC<Props> = ({
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.SupplyChainMap]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="supplychainmap" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.SupplyChainMap) + 1}
        . Supply chain map
      </Text>
      <Text style={styles.paragraph}>
        {texts?.report?.supplyChainMap.intro}
      </Text>
    </View>
  )
}
export default ReportSupplyChainMapPage
