import ReactDOM from 'react-dom'
import { App } from './App'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from '@/store'
import ScrollToTop from './components/helpers/ScrollToTop'
import './styles/main.scss'

document.addEventListener('DOMContentLoaded', () => {
  // Make sure the icon font is loaded
  if ('fonts' in document) document.fonts.load('1em "Font Awesome 5 Pro"')

  return ReactDOM.render(
    // @ts-ignore
    <Provider store={store}>
      <Router basename="/">
        <ScrollToTop />
        <App />
      </Router>
    </Provider>,
    document.getElementById('root')
  )
})
