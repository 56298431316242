import React from 'react'
import { OAlert } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '@/utils'
import { Page, ContentSection } from '@/components/layout'
import { ContactSupportSnippet } from '@/components/helpers'
import { useTexts } from '@/hooks'

const { icons } = config

const Title = ({ icon, title }: { icon?: string; title: string }) => (
  <h3>
    {!!icon && <i className={icon} />}
    <span className={!!icon ? 'ml-2' : undefined}>{title}</span>
  </h3>
)

const AboutPage = () => {
  const texts = useTexts()
  return (
    <Page title="About">
      <ContentSection className="p-4">
        <Title title="What is ReWind?" />
        {texts?.about.whatIs.map((i: string) => (
          <p key={i}>{i}</p>
        ))}
        <Title icon={icons.windTurbine} title="Turbines" />
        <p>{texts?.about.turbines.intro}</p>
        <OAlert
          dismissable={false}
          message={texts?.about.turbines.alert.message}
          description={
            <div className="my-2">
              {texts?.about.turbines.alert.description}
            </div>
          }
        />
        <p className="font-italic">Adding a turbine</p>
        <ol>
          {texts?.about.turbines.add.map((i: string) => (
            <li key={i}>{i}</li>
          ))}
        </ol>
        <Title icon={icons.projectsGrid} title="Projects" />
        <p>{texts?.about.projects.intro}</p>
        <p className="font-italic">Setting up a project</p>
        <ol>
          {texts?.about.projects.set.map((i: string) => (
            <li key={i}>{i}</li>
          ))}
        </ol>
        <Title icon={icons.scenario} title="Scenarios" />
        <p>{texts?.about.scenarios.intro}</p>
        <p className="font-italic">Creating a scenario</p>
        <ol>
          {texts?.about.scenarios.create.map((i: string) => (
            <li key={i}>{i}</li>
          ))}
        </ol>
        <p>
          <strong className="mr-2">Tip:</strong>
          {texts?.about.scenarios.tip}
        </p>
        <Title icon={icons.providersMap} title="Providers" />
        <p>{texts?.about.providers.intro}</p>
      </ContentSection>
      <ContactSupportSnippet className="p-4" />
    </Page>
  )
}

export default React.memo(AboutPage)
