import {
  G,
  Path,
  Polygon,
  Rect,
  StyleSheet,
  Svg,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'

const styles = StyleSheet.create({
  view: {
    borderBottom: '1px solid #000',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10
  },
  logo: {
    width: 100
  },
  productName: {
    fontSize: 22,
    fontFamily: 'DNV Display Medium'
  }
})

const ReportHeader: React.FC = () => (
  <View style={styles.view}>
    <Svg viewBox="0 0 612.27 261.49" width="120" style={styles.logo}>
      <G>
        <Rect width="612.27" height="73.1" fill="#98d9f0"></Rect>
        <Rect y="97.45" width="612.27" height="12.19" fill="#3f9c35"></Rect>
        <Rect y="121.79" width="612.27" height="24.35" fill="#003591"></Rect>
        <Path
          d="M370.28,186.91q-9-4-21.49-4H306.23v78.62h42.56q12.48,0,21.49-4a29.77,29.77,0,0,0,13.84-12.15q4.81-8.13,4.82-20.39v-5.46q0-12.25-4.82-20.38A29.79,29.79,0,0,0,370.28,186.91Zm5.77,37.58q0,12.78-6.82,19t-20.33,6.19H319.34V194.72H348.9q13.52,0,20.33,6t6.82,19.13Z"
          fill="#0f204b"></Path>
        <Polygon
          points="487.37 244.31 426.57 182.87 419.34 182.87 413.79 182.87 413.79 261.49 426.57 261.49 426.57 200.13 487.37 261.49 500.27 261.49 500.27 182.87 487.37 182.87 487.37 244.31"
          fill="#0f204b"></Polygon>
        <Polygon
          points="598.28 182.87 568.2 244.31 538.11 182.87 523.33 182.87 562.75 261.49 572.91 261.49 612.22 182.87 598.28 182.87"
          fill="#0f204b"></Polygon>
      </G>
    </Svg>
    <Text fixed style={styles.productName}>
      ReWind
    </Text>
  </View>
)

export default ReportHeader
