import {
  ComponentType,
  MaterialComponentType,
  TurbinePart,
  UpdateMaterialsBreakdownViewModel
} from '@/interfaces'
import { api, helper, turbines } from '@/utils'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'
import { useState } from 'react'
import { actions } from '@/store/slices/turbines'
import { useAppDispatch } from '@/store/hooks'

const { handleErrorMessage } = helper
const { getComponentType } = turbines

const useTurbineMaterialBreakdownUpdate = () => {
  const [isEditableMaterialBreakdown, setIsEditableMaterialBreakdown] =
    useState<boolean>(false)
  const dispatch = useAppDispatch()

  const updateTurbineMaterialBreakdown = (
    turbineId: string,
    part: TurbinePart,
    propertyName: string,
    propertyValue: number
  ) => {
    const componentType = getComponentType(part)

    if (componentType === null) return

    const payload: UpdateMaterialsBreakdownViewModel = {
      componentType,
      turbineId
    }
    payload[propertyName as keyof UpdateMaterialsBreakdownViewModel] =
      propertyValue as never
    payload.turbineId = turbineId
    api.turbines
      .updateMaterialBreakdown(
        turbineId,
        payload as UpdateMaterialsBreakdownViewModel
      )
      .then((mb: any) => {
        showSuccess(
          `Material breakdown for ${part} updated`,
          `${propertyName}=${propertyValue}`
        )

        Object.keys(mb).forEach((p: string) => {
          const data = mb[p]
          if (!data || !(data instanceof Object)) {
            return;
          }
          const type = ComponentType[
            data.componentType
          ].toLowerCase() as MaterialComponentType

          dispatch(
            actions.setTurbineMaterialBreakdownType(
              {
                data: { ...data },
                turbineId: turbineId,
                type: type
              } || null
            )
          )
        })
      })
      .catch(handleErrorMessage)
  }

  return {
    isEditableMaterialBreakdown,
    setIsEditableMaterialBreakdown,
    updateTurbineMaterialBreakdown
  }
}

export default useTurbineMaterialBreakdownUpdate
