import { getScenario, actions } from '@/store/slices/scenarios'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

import { helper } from '@/utils'

const { isValidGuid } = helper

const useScenarioState = () => {
  const { isLoading, isUpdating, isNotFound, scenario } = useAppSelector(
    s => s.scenarios
  )
  const dispatch = useAppDispatch()

  const get = (scenarioId: string) => {
    if (isValidGuid(scenarioId) && scenarioId !== scenario?.id)
      dispatch(getScenario(scenarioId) as any)
  }

  const dispatchNotFound = () => dispatch(actions.setIsNotFound(true))

  return {
    scenario,
    isLoading,
    isUpdating,
    isNotFound,
    dispatchNotFound,
    get
  }
}

export default useScenarioState
