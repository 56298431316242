import { TotalWindFarmWeightsViewModel } from '@/interfaces'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import React, { FC } from 'react'
import { MaterialBreakdownAlert, MaterialBreakdownChart } from '../projects'
import { config } from '@/utils'
import MaterialsTable from './MaterialsTable'

const { materialBreakdown } = config

interface Props {
  data?: TotalWindFarmWeightsViewModel | null
  height?: number
  width?: number
  layout?: 'horizontal' | 'vertical'
  legendPosition?: 'bottom' | 'left' | 'center' | 'right' | 'top' | 'chartArea'
}

const WindFarmTotalMaterialBreakdown: FC<Props> = ({
  data,
  height = 400,
  width = 600,
  layout = 'horizontal',
  legendPosition
}) => {
  const labels: string[] = materialBreakdown.turbineFields
    ?.filter(i => (i as any).heading !== 'Total')
    ?.map(i => (i as any).heading)

  const values: number[] = materialBreakdown.turbineFields
    ?.filter(i => (i as any)?.heading !== 'Total')
    ?.map(i => (data as any)?.[(i as any).name] || 0)

  return (
    <ORow>
      {!!data ? (
        <>
          {layout === 'vertical' && (
            <OCol md="12">
              <MaterialBreakdownChart
                animationDuration={1}
                chartData={{ labels, values }}
                height={height}
                width={width}
                legendPosition={legendPosition}
              />
            </OCol>
          )}
          <OCol md={layout === 'horizontal' ? '6' : '12'}>
            <MaterialsTable
              data={data}
              baseFields={materialBreakdown.turbineFields}
              total={data?.total}
            />
          </OCol>
          {layout === 'horizontal' && (
            <OCol md="6">
              <MaterialBreakdownChart
                animationDuration={1}
                chartData={{ labels, values }}
                height={height}
                width={width}
                legendPosition={legendPosition}
              />
            </OCol>
          )}
        </>
      ) : (
        <OCol md="12">
          <MaterialBreakdownAlert />
        </OCol>
      )}
    </ORow>
  )
}

export default React.memo(WindFarmTotalMaterialBreakdown)
