import React, { ReactNode } from 'react'

import { config } from '@/utils'

interface Props {
  className?: string
  left?: ReactNode
  right?: ReactNode
}

const ContentSectionValue = ({ className, left, right }: Props) => (
  <div className={`d-flex justify-content-between w-100 ${className}`}>
    <div className="d-inline-flex">{left}</div>
    <div className="d-inline-flex justify-content-end font-weight-bold text-right">
      {right || config.missingValueCharacter}
    </div>
  </div>
)

export default React.memo(ContentSectionValue)
