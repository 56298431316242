import React from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '@/utils'
import OnboardingForm from './OnboardingForm'

import { OnboardingStep } from '@/interfaces'
import { useOnboardingForm } from '@/hooks'
import * as Constants from '@/components/helpers/Constants'

interface Props {
  isOnboardingSubmitted?: boolean
  firstGreeting?: string
  step: OnboardingStep
  image?: string | null
  title?: string
  texts?: string[]
  onFormSubmitted?: () => void
  onNext?: () => void
  onPrev?: () => void
  onRemindLater?: () => void
  onSkipTour?: () => void
  onCompleted?: () => void
}

const { staticAssetsUrlBlob } = config

const OnboardingStepCard = ({
  isOnboardingSubmitted,
  firstGreeting,
  step,
  image,
  title,
  texts,
  onFormSubmitted,
  onNext,
  onPrev,
  onRemindLater,
  onSkipTour,
  onCompleted
}: Props) => {
  const { payload, setRole, toggleFlag, setAnotherGoal } = useOnboardingForm(
    step,
    !!isOnboardingSubmitted,
    onFormSubmitted
  )

  return (
    <div>
      {/* Image */}
      <div className="modal-onboarding-image">
        {!!image ? (
          <img
            className="w-100"
            src={`${staticAssetsUrlBlob}/images/onboarding/${image}`}
            alt="Circularity"
            title="Circularity"
          />
        ) : null}
      </div>
      {/* Content */}
      <div className="p-3">
        <h1 className="dnv-display-regular">
          {step === 1 && firstGreeting ? firstGreeting : title}
        </h1>
        {texts?.map(text => <p key={text}>{text}</p>)}
        {step === 1 && (
          <OnboardingForm
            payload={payload}
            setRole={setRole}
            toggleFlag={toggleFlag}
            setAnotherGoal={setAnotherGoal}
          />
        )}
      </div>
      {/* Footer */}
      <div className="d-flex justify-content-between p-3">
        {step === 1 && (
          <>
            <div>
              <OButton onClick={onNext} className="mr-2">
                <span data-testid={Constants.onboardingFormNextBtn1}>Next</span>
              </OButton>
              <OButton onClick={onRemindLater} variant="subtle">
                <span data-testid={Constants.onboardingFormRemindMeLaterBtn1}>
                  Remind me later
                </span>
              </OButton>
            </div>
            <OButton onClick={onSkipTour} variant="flat">
              <span data-testid={Constants.onboardingFormDontShowAgainBtn}>
                Don't show again
              </span>
            </OButton>
          </>
        )}
        {step === 2 && (
          <>
            <OButton onClick={onSkipTour} variant="flat">
              <span data-testid={Constants.onboardingFormSkipTourBtn1}>
                Skip tour
              </span>
            </OButton>
            <div>
              <OButton
                onClick={onRemindLater}
                variant="subtle"
                className="mr-2">
                <span data-testid={Constants.onboardingFormRemindMeLaterBtn2}>
                  Remind me later
                </span>
              </OButton>
              <OButton onClick={onNext} className="mr-2">
                <span data-testid={Constants.onboardingFormShowMeAround}>
                  Show me around
                </span>
              </OButton>
            </div>
          </>
        )}
        {(step === 3 ||
          step === 4 ||
          step === 5 ||
          step === 6 ||
          step === 7) && (
          <>
            <OButton onClick={onSkipTour} variant="flat">
              <span data-testid={Constants.onboardingFormSkipTourBtn2}>
                Skip tour
              </span>
            </OButton>
            <div>
              {step !== 3 && (
                <OButton onClick={onPrev} variant="subtle" className="mr-2">
                  <span data-testid={Constants.onboardingFormPreviousBtn1}>
                    Previous
                  </span>
                </OButton>
              )}
              <OButton onClick={onNext} className="mr-2">
                <span data-testid={Constants.onboardingFormNextBtn2}>Next</span>
              </OButton>
            </div>
          </>
        )}
        {step === 8 && (
          <>
            <div>&nbsp;</div>
            <div>
              <OButton onClick={onPrev} variant="subtle" className="mr-2">
                <span data-testid={Constants.onboardingFormPreviousBtn2}>
                  Previous
                </span>
              </OButton>
              <OButton onClick={onCompleted}>
                <span
                  data-testid={Constants.onboardingFormAddYourFirstTurbineBtn}>
                  Add your first turbine
                </span>
              </OButton>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(OnboardingStepCard)
