import { CountryWithConfigurationsViewModel } from '@/interfaces'
import React, { FC } from 'react'
import ItemsCollection from './ItemsCollection'

import { config, helper } from '@/utils'
import CostModelBadge from './CostModelBadge'

interface Props {
  countries?: CountryWithConfigurationsViewModel[]
}

const { buildItemLink, toLocaleDateTime } = helper
const { urls, missingValueCharacter, rowsPerPageOptions } = config

const ConfigurationCountries: FC<Props> = ({ countries = [] }) => {
  const getCountry = (name?: string) =>
    countries?.find?.(
      (item: CountryWithConfigurationsViewModel) => item?.name === name
    )
  return (
    <div>
      {!!countries.length ? (
        <ItemsCollection
          id="ConfigurationCountries"
          isLoading={false}
          items={countries}
          itemName="country"
          fields={[
            {
              name: 'name',
              heading: 'Name',
              sortable: true,
              formatter: (name: string) =>
                buildItemLink(
                  [urls.settings, 'countries', getCountry(name)?.id].join('/'),
                  name
                )
            },
            {
              name: 'name',
              heading: 'Cost model',
              formatter: (name: string) => (
                <CostModelBadge
                  isDefault={
                    getCountry(name)?.costCalculationConfiguration?.isDefault
                  }
                  className="max-100px"
                />
              )
            },
            {
              name: 'name',
              heading: 'Currency',
              formatter: (name: string) =>
                getCountry(name)?.costCalculationConfiguration?.currency
                  .currencyInformation?.symbol || missingValueCharacter
            },
            {
              name: 'name',
              heading: 'Last edit',
              formatter: (name: string) =>
                toLocaleDateTime(
                  getCountry(
                    name
                  )?.costCalculationConfiguration?.lastEdit?.toString()
                )
            }
          ]}
          filterKeys={['name']}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      ) : null}
    </div>
  )
}

export default React.memo(ConfigurationCountries)
