import { ProviderInfo } from '@/store/slices/scenarioProviders'
import React from 'react'
import ReportSection from './ReportSection'
import { config, providers } from '@/utils'
import ReportTable from './ReportTable'
import { ProcessedMaterial, TableItemOption } from '@/interfaces'

const { buildProviderData } = providers
const { icons } = config

interface Props {
  providerInfo: ProviderInfo | null
  leftColumnWidth: string
}

export const LandfillMessage = () => (
  <p className="p-3">Landfill at nearest available facility</p>
)

const getIcon = (providerInfo: ProviderInfo | null) => {
  switch (providerInfo?.material) {
    case ProcessedMaterial.Metal:
      return icons.metalProcessingLight
    case ProcessedMaterial.Blade:
      return icons.bladeProcessingLight
    case ProcessedMaterial.Concrete:
      return icons.concreteProcessingLight
    default:
      return ''
  }
}

const getTitleLeft = (providerInfo: ProviderInfo | null) => {
  switch (providerInfo?.material) {
    case ProcessedMaterial.Metal:
      return 'Metal processing'
    case ProcessedMaterial.Blade:
      return 'Blade processing'
    case ProcessedMaterial.Concrete:
      return 'Concrete processing'
  }
}

const getTitleRight = (providerInfo: ProviderInfo | null) => {
  if (!providerInfo || providerInfo?.isLandfill) {
    return ''
  }

  if (providerInfo?.provider) {
    return providerInfo.provider.name
  }

  return 'Input from Customer'
}

const ProviderInfoSection = ({ providerInfo, leftColumnWidth }: Props) => (
  <ReportSection
    icon={`${getIcon(providerInfo)} text-white`}
    titleLeft={getTitleLeft(providerInfo)}
    titleRight={getTitleRight(providerInfo)}>
    {providerInfo?.provider && (
      <ReportTable
        leftColumnWidth={leftColumnWidth}
        items={buildProviderData(providerInfo.provider) as TableItemOption[]}
      />
    )}
    {providerInfo?.isLandfill && <LandfillMessage />}

    {(providerInfo?.distance ?? -1) >= 0 && (
      <ReportTable
        leftColumnWidth={leftColumnWidth}
        items={
          [
            {
              text: 'Location and contact details',
              value: 'header'
            },
            {
              text: 'Distance to the facility',
              value: `${providerInfo?.distance} km`
            }
          ] as TableItemOption[]
        }
      />
    )}
  </ReportSection>
)

export default React.memo(ProviderInfoSection)
