import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import { api, config, helper } from '@/utils'
import {
  OButton,
  ODragAndDrop,
  ORow,
  OCol,
  OList
} from '@dnvgl-onefoundation/onedesign-react'
import { useExcelImport, useLocalStorage } from '@/hooks'
import { Page, ContentSection, SpinIndicator } from '@/components/layout'
import { BreadcrumbsTitle, Input, RadioToggle } from '@/components/helpers'
import { getGroups } from '@/store/slices/userGroups'
import { ActionCreator, ApplicationState } from '@/store'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

const { urls, icons, defaultSelectOption, templates } = config
const { formatFileSize } = helper

const TurbinesImportPage = () => {
  const {
    accept,
    selected,
    feedbackRows,
    isUploading,
    onDrop,
    onReset,
    onUpload
  } = useExcelImport(api.turbines.upload)
  const [forAllCompanies, setForAllCompanies] = useLocalStorage<boolean>(
    'TurbinesImportPage-forAllCompanies',
    true
  )
  const [groupId, setGroupId] = useLocalStorage<string | number>(
    'TurbinesImportPage-groupId',
    0
  )

  const { userGroups } = useAppSelector((s: ApplicationState) => s.userGroups)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (forAllCompanies || userGroups?.length) return
    dispatch(getGroups() as ActionCreator)
  }, [dispatch, forAllCompanies, userGroups])

  return (
    <Page
      title={
        <BreadcrumbsTitle
          title="Import turbines"
          backLink={urls.admin}
          backName="Administration"
        />
      }
      isH1Title={false}
      actions={
        <div>
          <a href={templates.turbines} rel="noreferrer" target="_blank">
            <OButton
              iconClass={icons.download}
              variant="flat"
              disabled={isUploading}>
              Download template
            </OButton>
          </a>
          <Link to={urls.turbines}>
            <OButton
              iconClass={icons.windTurbine}
              variant="flat"
              disabled={isUploading}>
              Open turbines list
            </OButton>
          </Link>
        </div>
      }>
      <ContentSection>
        <ORow className="mt-1 px-5">
          <OCol md="6" className="mb-4">
            <label>Visiblility scope</label>
            <RadioToggle
              value={forAllCompanies}
              yesLabel="For all companies"
              noLabel="For a specific company"
              onToggle={() => setForAllCompanies(!forAllCompanies)}
            />
          </OCol>
          <OCol md="6">
            {!forAllCompanies && (
              <Input
                propertyName="company"
                label="Company"
                type="select"
                value={groupId}
                options={[
                  defaultSelectOption,
                  ...userGroups?.map(i => ({ text: i?.name, value: i?.id }))
                ]}
                isValueMissing={!groupId || groupId === '0'}
                onChange={(_key, value) => setGroupId(value)}
              />
            )}
          </OCol>
        </ORow>
        {isUploading && <SpinIndicator />}
        {selected ? (
          <ORow className="p-5">
            <OCol md="12" className="mb-4">
              {selected.file.name}&nbsp;({formatFileSize(selected.file.size)}
              )&nbsp;
              <OButton
                iconClass={icons.cancel}
                variant="flat"
                disabled={isUploading}
                onClick={onReset}
              />
            </OCol>
            <OCol md="2">
              <OButton
                iconClass={icons.upload}
                onClick={() =>
                  onUpload(
                    forAllCompanies || !groupId || groupId === '0'
                      ? ''
                      : (groupId as string)
                  )
                }
                disabled={isUploading}>
                Upload
              </OButton>
            </OCol>
          </ORow>
        ) : (
          <>
            <ODragAndDrop multiple={false} accept={accept} onDrop={onDrop} />
            {!!feedbackRows?.length && (
              <OList
                className="mt-2"
                items={feedbackRows
                  ?.filter(i => typeof i === 'string' && i.length > 1)
                  ?.map(content => ({
                    id: content,
                    content
                  }))}
              />
            )}
          </>
        )}
      </ContentSection>
    </Page>
  )
}

export default React.memo(TurbinesImportPage)
