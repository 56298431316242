import React, { ReactNode } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import { helper } from '@/utils'
import { CurrencyInformationViewModel } from '@/interfaces'
import {
  dayRateLabels,
  valueFormater as dayRateFormater
} from '@/utils/calculation'

const styles = StyleSheet.create({
  section: {
    flexGrow: 1,
    fontSize: 9,
    marginBottom: 10
  },
  name: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    fontWeight: 'bold',
    fontSize: 9,
    padding: 3,
    backgroundColor: '#0f204b',
    color: '#fff'
  },
  table: {
    display: 'flex',
    flexDirection: 'column'
  },
  tableRow: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  tableColHeader: {
    flex: 1,
    padding: 4,
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    borderWidth: 1,
    textAlign: 'center'
  },
  tableCol: {
    flex: 1,
    padding: 4,
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    borderWidth: 1
  },
  singleCellRow: {
    flexDirection: 'row',
    fontSize: 9,
    alignItems: 'center',
    backgroundColor: '#f8f9fa',
    fontWeight: 'bold',
    borderStyle: 'solid',
    borderColor: '#dee2e6',
    borderWidth: 1
  },
  singleCell: {
    flex: 1,
    padding: 2
  }
})

interface Props {
  linkId?: string
  name?: string | ReactNode
  description?: string
  headers?: string[]
  rows?: string[][]
  valueFormater?: Function
  breakPage?: boolean
  currencyInformation?: CurrencyInformationViewModel
}

const { verifiedElement } = helper

const Table: React.FC<Props> = ({
  linkId,
  name,
  description,
  headers,
  rows,
  valueFormater,
  breakPage,
  currencyInformation
}) => {
  const valueHandler = (value?: any, valueFormater?: Function) => {
    if (typeof value === 'string') return value
    return !!valueFormater
      ? valueFormater?.(value, currencyInformation)
      : verifiedElement(value)
  }

  const checkValueHandler = (fieldName: string) => {
    return Object.values(dayRateLabels).includes(fieldName)
      ? dayRateFormater
      : valueFormater
  }

  return (
    <View style={styles.section} wrap={breakPage} id={linkId}>
      <View style={styles.name}>
        <Text>{name}</Text>
        {!!description && <Text>{description}</Text>}
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          {/* Render table headers */}
          {headers?.map?.((header, index) => (
            <Text key={index} style={styles.tableColHeader}>
              {header}
            </Text>
          ))}
        </View>
        {/* Render table rows */}
        {rows?.map?.((row, rowIndex) => (
          <View
            key={rowIndex}
            style={row.length === 1 ? styles.singleCellRow : styles.tableRow}>
            {row?.map?.((cell, colIndex) => (
              <Text
                key={colIndex}
                style={row.length === 1 ? styles.singleCell : styles.tableCol}>
                {valueHandler(cell, checkValueHandler(row[0]))}
              </Text>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}
export default Table
