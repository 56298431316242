import { useState } from 'react'

import { config, helper } from '@/utils'
import { FileDrop, showSuccess } from '@dnvgl-onefoundation/onedesign-react'

const { fileImport } = config
const { handleErrorMessage, getFileExtention } = helper

export default function useExcelImport(apiUploadPromise: Function) {
  const [selected, setSelected] = useState<FileDrop | null>(null)
  const [feedbackRows, setFeedbackRows] = useState<string[]>([])
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const onReset = () => setSelected(null)
  const onDrop = (drops?: FileDrop[]): any => {
    onReset()
    const drop = drops?.[0]
    const fileName = drop?.file?.name
    if (!fileName?.length) return undefined
    const fileExtention = getFileExtention(fileName)

    if (!fileImport.excelExtentions.includes(fileExtention as string))
      return setFeedbackRows([
        `The provided file extension ".${fileExtention}" is not allowed.`,
        `Please select a file with one of the supported extensions: ${fileImport.excelExtentions
          .map((i: string) => `.${i}`)
          .join(', ')}.
`
      ])

    if (drop?.file?.name) setSelected(drop)
  }

  const onUpload = (groupId?: string | null) => {
    if (selected?.file) {
      setIsUploading(true)
      apiUploadPromise?.(selected?.file, { groupId })
        ?.then((data: any) => {
          showSuccess(selected?.file?.name, 'File successfully uploaded')
          onReset()
          setFeedbackRows((data as any) || [])
        })
        ?.catch(handleErrorMessage)
        ?.finally(() => setIsUploading(false))
    }
  }

  return {
    accept: fileImport.excelExtentions.map((i: string) => `.${i}`).join(','),
    selected,
    feedbackRows,
    isUploading,
    onDrop,
    onReset,
    onUpload
  }
}
