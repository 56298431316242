import api from './api'

const convertDegreesToRadians = (degrees: any) => (degrees * Math.PI) / 180

const getDistance = (start: any, destination: any) => {
  if (!start || !destination) return undefined

  const startingLat = convertDegreesToRadians(start.latitude)
  const startingLong = convertDegreesToRadians(start.longitude)
  const destinationLat = convertDegreesToRadians(destination.latitude)
  const destinationLong = convertDegreesToRadians(destination.longitude)

  // Radius of the Earth in kilometers
  const earthRadius = 6371

  // Haversine formula
  const dLat = destinationLat - startingLat
  const dLon = destinationLong - startingLong
  const a =
    Math.sin(dLat / 2) ** 2 +
    Math.cos(startingLat) * Math.cos(destinationLat) * Math.sin(dLon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const distanceInKilometers = earthRadius * c

  return distanceInKilometers
}

const findNearestItemId = (items: any, location: any) => {
  if (!items || !location) return null

  let found = null
  let minimalDistance = Infinity

  items.forEach((item: any) => {
    const distance = getDistance(item.location, location)
    if (distance !== undefined && distance < minimalDistance) {
      found = item
      minimalDistance = distance
    }
  })

  return (found as any)?.id
}

const getCoordinatesByAddress = (address: string, limit = 1) =>
  new Promise((resolve, reject) => {
    if (!address?.length) reject('Please provide address')
    api.mapbox
      .getGeoCoding(address, limit)
      .then(r => r?.json())
      .then(data => {
        if (data?.features?.length) resolve(data.features[0])
        else reject(`Coordinates for "${address}" not found.`)
      })
      .catch(err => {
        console.error(err)
        reject(err)
      })
  })

const projects = {
  convertDegreesToRadians,
  getDistance,
  findNearestItemId,
  getCoordinatesByAddress
}

export default projects
