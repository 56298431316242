import React from 'react'
import { Source, Layer } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

const layerStyle = {
  id: 'route',
  type: 'line',
  source: 'route',
  layout: {
    'line-join': 'round',
    'line-cap': 'round'
  },
  paint: {
    'line-color': '#0F204B',
    'line-width': 1
  }
}

interface Props {
  id: string
  features?: any[]
}

const MapBoxLine = ({ id = 'MapBoxLine', features = [] }: Props) => (
  <Source
    id={id}
    type="geojson"
    data={
      {
        type: 'FeatureCollection',
        features
      } as any
    }>
    <Layer {...(layerStyle as any)} />
  </Source>
)

export default React.memo(MapBoxLine)
