import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { createProject, getProjects } from '@/store/slices/projects'
import { showConfirm } from '@dnvgl-onefoundation/onedesign-react'
import { useCountries, useLocalStorage } from '@/hooks'
import { config, helper } from '@/utils'
import { useNavigate } from 'react-router-dom'
import {
  ProjectItemViewModel,
  ProjectSimpleItemViewModel,
  ProjectType
} from '@/interfaces'
import { UnknownAction } from '@reduxjs/toolkit'

const { deepClone, genericErrorHandler, getProjectTypeName, isValidGuid } =
  helper
const { urls } = config

const updateItems = (items: ProjectItemViewModel[], options: any[] = []) => {
  if (!items?.length || !options?.length) return []
  const updated = deepClone(items)
  updated.forEach((i: any) => {
    i.projectTypeName = getProjectTypeName(i?.projectType)
    i.country = options?.find?.(c => c?.value === i?.country)?.text
    i.turbineType = i.turbineManufacturer
      ? `${i.turbineManufacturer}`
      : config.missingValueCharacter
    i.decommissioningCost = config.missingValueCharacter
  })
  return updated
}

const useProjects = () => {
  const { countryOptions } = useCountries()
  const state = useAppSelector(s => s)

  const [defaultType, setDefaultType] = useLocalStorage(
    'useProjects-defaultType',
    ProjectType.Onshore
  )
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { defaultCountry } = useCountries()

  const create = (payload: any) =>
    dispatch(createProject(payload) as unknown as UnknownAction as any)
      ?.then(
        (id: string) => isValidGuid(id) && navigate(`${urls.projects}/${id}`)
      )
      .catch(genericErrorHandler)

  const { projects, isLoading, isLoaded } = state.projects

  const get = () => dispatch(getProjects() as unknown as UnknownAction)

  const items = updateItems(projects?.items || [], countryOptions)
  const alreadyAvailableNames = items?.map(
    (i: ProjectSimpleItemViewModel) => i.name
  )

  const addProjectDraft = (payload: any) =>
    payload?.name?.length &&
    !alreadyAvailableNames?.includes(payload?.name) &&
    showConfirm(payload.name, 'Save this project to drafts?').then(
      confirmed => confirmed && addProject({ ...payload, isDraft: true })
    )

  const addProject = (payload: any) => {
    payload.countryId = payload.countryId || defaultCountry
    if (payload?.projectType !== undefined) setDefaultType(payload.projectType)
    else payload.projectType = defaultType
    payload.projectType = parseInt(payload.projectType)
    if (payload?.name?.length) create(payload)
  }

  return {
    get,
    defaultCountry,
    defaultType,
    isLoading,
    isLoaded,
    items,
    alreadyAvailableNames,
    projects,
    addProjectDraft,
    addProject
  }
}

export default useProjects
