import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { localStorage } from '@/utils'
import useCallbackRef from './useCallbackRef'

const initialize = <T>(
  key: string,
  initialValue: T | undefined,
  options?: UseLocalStorageOptions<T>
) => {
  const existingValue = localStorage.get<T>(key, options)

  if (existingValue !== undefined) return existingValue

  initialValue && localStorage.set(key, initialValue, options)

  return initialValue
}

type SetValue<T> = Dispatch<SetStateAction<T>>

export type UseLocalStorageOptions<T> = {
  deserializer?: (value: string) => T
  serializer?: (value: T) => string
}

export default function useLocalStorage<T>(
  key: string,
  initialValue?: T,
  options?: UseLocalStorageOptions<T>
): [T | undefined, SetValue<T | undefined>, () => void] {
  const [state, setState] = useState<T | undefined>(() =>
    initialize(key, initialValue, options)
  )

  useEffect(() => {
    setState(initialize(key, initialValue, options))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key])

  const set: SetValue<T | undefined> = useCallbackRef((valOrFunc: any) => {
    const newValue =
      valOrFunc instanceof Function ? valOrFunc(state) : valOrFunc

    localStorage.set(key, newValue, options)

    setState(newValue)
  })

  const remove = useCallbackRef(() => {
    try {
      localStorage.remove(key)
      setState(undefined)
    } catch {
      // If user is in private mode or has storage restriction localStorage can throw.
      // Try-catch is only needed here, because set and get utilities already have it internally.
    }
  })

  return [state, set, remove]
}
