import React from 'react'
import { AddItem, ItemsCollection } from '@/components/helpers'
import { Page } from '@/components/layout'
import { useScenarios } from '@/hooks'
import { config, helper } from '@/utils'
import { ProjectType } from '@/interfaces'

const { urls, missingValueCharacter, rowsPerPageOptions } = config
const {
  isNotDraft,
  isDraft,
  toLocaleDate,
  buildTruncatedName,
  buildItemLink,
  getProjectTypeName
} = helper

const ScenariosPage = () => {
  const { isLoading, items, addScenario } = useScenarios()
  return (
    <Page
      title="Decommissioning scenarios"
      isLoading={isLoading}
      actions={
        <AddItem
          disabled={isLoading}
          type="scenario"
          fields={[
            {
              heading: 'Name *',
              id: 'name',
              isRequired: true
            }
          ]}
          alreadyAvailableNames={items?.map(i => i.name) || []}
          inputGridColumns="12"
          onOK={addScenario}
        />
      }>
      <ItemsCollection
        id="ScenariosPage"
        isLoading={isLoading}
        items={items}
        itemName="scenario"
        fields={[
          {
            name: 'name',
            heading: 'Name',
            sortable: true,
            formatter: (name: string) =>
              buildItemLink(
                [
                  urls.scenarios,
                  items?.find?.((item: any) => item.name === name)?.id
                ].join('/'),
                name
              )
          },
          {
            name: 'projectName',
            heading: 'Project',
            sortable: true,
            formatter: (projectName: string) => {
              return projectName
                ? buildTruncatedName(projectName, 40)
                : missingValueCharacter
            }
          },
          {
            name: 'projectType',
            heading: 'Type',
            sortable: true,
            formatter: (projectType: ProjectType) =>
              getProjectTypeName(projectType) ?? missingValueCharacter
          },
          {
            name: 'companyName',
            heading: 'Company',
            sortable: true
          },
          {
            name: 'createdAt',
            heading: 'Date added',
            sortable: true,
            formatter: (date: string) => toLocaleDate(date)
          }
        ]}
        tabs={[
          { title: 'Saved scenarios', filter: isNotDraft },
          { title: 'Drafts', filter: isDraft }
        ]}
        filterKeys={[
          'name',
          'projectName',
          'companyName',
          'project.projectType'
        ]}
        rowsPerPageOptions={[...rowsPerPageOptions, 500, 1000]}
      />
    </Page>
  )
}

export default React.memo(ScenariosPage)
