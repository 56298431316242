import { useState } from 'react'

import useCallbackRef from './useCallbackRef'

export type ToggleFunction = {
  (): void
  off: () => void
  on: () => void
}

export default function useRequestCalculation() {
  const [value, setValue] = useState(false)

  const on = useCallbackRef(() => setValue(true))
  const off = useCallbackRef(() => setValue(false))
  const toggle = useCallbackRef(() => setValue(x => !x)) as ToggleFunction

  toggle.on = on
  toggle.off = off

  return [value, toggle] as const
}
