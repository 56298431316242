export const DEFAULT_CURRENCY_SYMBOL = '€'
export const DEFAULT_CURRENCY_CODE = 'EUR'

export const signUpBtn = 'sign-up-btn'
export const signInBtn = 'sign-in-btn'

export const userHeaderActions = 'user-header-actions'
export const userDropdownMenuEmail = 'user-dropdown-menu-email'
export const signOutBtn = 'sign-out-btn'

export const onboardingFormNextBtn1 = 'onboarding-form-next-btn1'
export const onboardingFormRemindMeLaterBtn1 =
  'onboarding-form-remind-me-later-btn1'
export const onboardingFormDontShowAgainBtn =
  'onboarding-form-dont-show-again-btn'
export const onboardingFormSkipTourBtn1 = 'onboarding-form-skip-tour-btn1'
export const onboardingFormRemindMeLaterBtn2 =
  'onboarding-form-remind-me-later-btn2'
export const onboardingFormShowMeAround = 'onboarding-form-show-me-around-btn'
export const onboardingFormSkipTourBtn2 = 'onboarding-form-skip-tour-btn2'
export const onboardingFormPreviousBtn1 = 'onboarding-form-previous-btn1'
export const onboardingFormNextBtn2 = 'onboarding-form-next-btn2'
export const onboardingFormPreviousBtn2 = 'onboarding-form-previous-btn2'
export const onboardingFormAddYourFirstTurbineBtn =
  'onboarding-form-add-your-first-turbine-btn'

export const projectNameInput = 'project-name-input'
export const projectType = 'project-type'
