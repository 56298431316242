import { CurrencyInformationViewModel, TableItemOption } from '@/interfaces'
import React from 'react'
import { helper } from '@/utils'

interface Props {
  className?: string
  leftColumnWidth?: string
  isPrintTextSmaller?: boolean
  includesDescriptons?: boolean
  simpleTable?: boolean
  items?: TableItemOption[]
  headers?: string[]
  valueFormater?: Function
  total?: TableItemOption
  scenarioCurrency?: CurrencyInformationViewModel | null
}

const { verifiedElement } = helper
const headerValueName = 'header'
const cellClassNames = ['report-table', 'py-1', 'px-2']

const ReportTable = ({
  className,
  leftColumnWidth,
  isPrintTextSmaller,
  includesDescriptons,
  items = [],
  valueFormater,
  total,
  scenarioCurrency,
  headers,
  simpleTable
}: Props) => {
  const valueHandler = (value?: any, valueFormater?: Function) => {
    if (typeof value === 'string') return value

    return !!valueFormater
      ? valueFormater?.(value, scenarioCurrency)
      : verifiedElement(value)
  }

  const containsRowSpan = items?.some?.((i: TableItemOption) => i?.rowSpan)

  const getColSpan = (i: TableItemOption) => {
    let span = undefined
    if ((containsRowSpan && !i?.isNested) || i.value === headerValueName)
      span = 2
    if (containsRowSpan && i.value === headerValueName) span = 5
    if (includesDescriptons && i.value === headerValueName) span = 3
    return span
  }

  return (
    <table className={['w-100', className].join(' ')}>
      <tbody className={isPrintTextSmaller ? 'print-smaller-text' : undefined}>
        {!!headers && (
          <tr>
            {headers?.map((header, index) => (
              <td
                key={index}
                style={{ width: leftColumnWidth || 'auto' }}
                className={[
                  ...cellClassNames,
                  'border',
                  'font-weight-bold',
                  'bg-light',
                  'font-weight-bold bg-light'
                ].join(' ')}>
                {header}
              </td>
            ))}
          </tr>
        )}
        {items?.map?.((i: TableItemOption, index) => (
          <tr key={`${index}`}>
            {i?.rowSpan && i.rowSpan > 1 && (
              <td className={cellClassNames.join(' ')} rowSpan={i.rowSpan}>
                {i.rowSpanTitle}
              </td>
            )}
            <td
              style={{ width: leftColumnWidth || 'auto' }}
              colSpan={getColSpan(i)}
              className={[
                ...cellClassNames,
                'border',
                i?.value === headerValueName || i.isBold
                  ? 'font-weight-bold'
                  : undefined,
                i?.value === headerValueName ? 'bg-light' : undefined,
                i.isHighlighted ? 'font-weight-bold bg-light' : undefined,
                i.isSmall ? 'text-small' : undefined
              ].join(' ')}>
              {i?.textNode || i?.text}
            </td>
            {!!i?.description && (
              <td
                style={{ width: leftColumnWidth || 'auto' }}
                colSpan={getColSpan(i)}
                className={[
                  ...cellClassNames,
                  'border',
                  i?.value === headerValueName || i.isBold
                    ? 'font-weight-bold'
                    : undefined,
                  i?.value === headerValueName ? 'bg-light' : undefined,
                  i.isHighlighted ? 'font-weight-bold bg-light' : undefined,
                  i.isSmall ? 'text-small' : undefined
                ].join(' ')}>
                {i?.description}
              </td>
            )}
            {i?.value !== headerValueName ? (
              Array.isArray(i?.value) && !simpleTable ? (
                i.value.map(val => (
                  <td
                    className={[
                      ...cellClassNames,
                      'border',
                      i.isHighlighted ? 'font-weight-bold bg-light' : undefined,
                      i.isBold ? 'font-weight-bold' : undefined,
                      i.isSmall ? 'text-small' : undefined
                    ].join(' ')}>
                    {valueHandler(val, i?.valueFormater || valueFormater)}
                  </td>
                ))
              ) : (
                <td
                  className={[
                    ...cellClassNames,
                    'border',
                    i.isHighlighted ? 'font-weight-bold bg-light' : undefined,
                    i.isBold ? 'font-weight-bold' : undefined,
                    i.isSmall ? 'text-small' : undefined
                  ].join(' ')}>
                  {Array.isArray(i?.value)
                    ? valueHandler(
                        i?.value[0],
                        i?.valueFormater || valueFormater
                      )
                    : valueHandler(i?.value, i?.valueFormater || valueFormater)}
                </td>
              )
            ) : null}
          </tr>
        ))}
        {!!total && (
          <tr
            className={['bg-light', 'total-row', 'font-weight-bold'].join(' ')}>
            <td
              style={{ width: leftColumnWidth || 'auto' }}
              className={cellClassNames.join(' ')}>
              {total?.text}
            </td>
            <td className={cellClassNames.join(' ')}>
              {valueHandler(total?.value)}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
export default React.memo(ReportTable)
