import { MaterialsBreakdownsViewModel } from '@/interfaces'
import { config, helper } from '@/utils'
import { View } from '@react-pdf/renderer'
import React from 'react'
import Table from './Table'

const { materialBreakdown, missingValueCharacter } = config
const { calculatePercentage, formatPercentage, formatTonnes } = helper

interface Props {
  turbineMaterialBreakdown?: MaterialsBreakdownsViewModel | null
  turbineName?: string
}

const buildRows = (
  turbineMaterialBreakdown?: MaterialsBreakdownsViewModel | null
) => {
  const rows: any[] = []
  materialBreakdown.turbineFields.forEach(field => {
    const row = []
    const value = (turbineMaterialBreakdown as any)?.total?.[field.name]
    if (field?.name !== undefined && value !== undefined) {
      const total = (turbineMaterialBreakdown as any)?.total?.total
      row.push(field.heading)
      row.push(formatTonnes(value))
      if (total) {
        const percentage = formatPercentage(
          calculatePercentage(value, total) || 0
        )
        row.push(`${percentage || missingValueCharacter}`)
      }
    }

    rows.push(row)
  })

  return rows
}

const ReportMaterialCompositionTablePage: React.FC<Props> = ({
  turbineMaterialBreakdown,
  turbineName
}) => (
  <View>
    <Table
      name="Material composition"
      description={turbineName}
      rows={buildRows(turbineMaterialBreakdown)}
    />
  </View>
)
export default ReportMaterialCompositionTablePage
