import { ApplicationState } from '@/store'
import { config, localStorage } from '@/utils'
import { useSelector } from 'react-redux'

const { protectedContent } = config
const { removeMultiple } = localStorage

const useProtectedContent = () => {
  const { currentUser, isLoadingCurrentUser, isAuthSessionExpired } =
    useSelector((state: ApplicationState) => state.users)

  const removeLocalStorageRecords = () => {
    removeMultiple(protectedContent.localStorageRecordsToRemove)
  }

  return {
    currentUser,
    isLoadingCurrentUser,
    isAuthSessionExpired,
    removeLocalStorageRecords
  }
}

export default useProtectedContent
