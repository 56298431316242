import React from 'react'
import {
  useConfigurations,
  useItemId,
  useProjects,
  useSettingsPage
} from '@/hooks'
import { OTabs, OTab } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection, Page } from '@/components/layout'
import {
  Configuration,
  ConfigurationCountries,
  ConfigurationProjects
} from '@/components/helpers'
import { config } from '@/utils'
import { SettingsTabIndex } from '@/interfaces'

const baseUrl = config.urls.settings

const SettingsPage = () => {
  const basePath = useItemId(baseUrl)
  const { countries, defaultConfiguration, getDefault, getCountries } =
    useConfigurations()

  const { get: getProjects, projects } = useProjects()

  const { selectedTab, onTabSelect } = useSettingsPage(
    basePath,
    baseUrl,
    getDefault,
    getCountries,
    getProjects
  )

  return (
    <Page title="Cost calculator settings">
      <ContentSection className="card-dnvgl-overflow mb-3">
        <OTabs
          className="w-100"
          selectedTab={selectedTab}
          onTabSelect={id => onTabSelect(id as SettingsTabIndex)}>
          <OTab title="Default">
            <div className="p-2 border">
              <Configuration
                data={defaultConfiguration}
                id="default"
                name="Default"
                type={null}
              />
            </div>
          </OTab>
          <OTab title="Countries">
            <ConfigurationCountries countries={countries} />
          </OTab>
          <OTab title="Projects and Scenarios">
            <ConfigurationProjects projects={projects?.items} />
          </OTab>
        </OTabs>
      </ContentSection>
    </Page>
  )
}

export default React.memo(SettingsPage)
