import React from 'react'
import { Page, ContentSection } from '../layout'
import { BreadcrumbsTitle, Configuration } from '../helpers'
import { config } from '@/utils'
import useConfiguration from '@/hooks/useConfiguration'
import { ConfigurationType } from '@/interfaces'

const { urls } = config

interface Props {
  type: ConfigurationType
}

const typeSettings = {
  country: {
    name: 'Country',
    backName: 'Countries',
    backLink: urls.settingsCountries
  },
  project: {
    name: 'Project',
    backName: 'Projects and Scenarios',
    backLink: urls.settingsProjects
  },
  scenario: {
    name: 'Scenario',
    backName: 'Projects and Scenarios',
    backLink: urls.settingsProjects
  }
}

const SettingsItemPage = ({ type }: Props) => {
  const {
    isLoading,
    countryId,
    projectId,
    scenarioId,
    itemConfiguation: i
  } = useConfiguration()

  return (
    <Page
      isLoading={isLoading}
      title={
        <BreadcrumbsTitle
          title={`${typeSettings?.[type]?.name} settings`}
          backLink={typeSettings?.[type]?.backLink}
          backName={typeSettings?.[type]?.backName}
        />
      }>
      <ContentSection contentClassName="p-4">
        <Configuration
          data={i}
          id={countryId || projectId || scenarioId || null}
          name={i?.name || 'Default'}
          type={type}
        />
      </ContentSection>
    </Page>
  )
}

export default React.memo(SettingsItemPage)
