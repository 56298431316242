import { ProviderType } from '@/interfaces'
import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

import { providers } from '@/utils'

interface Props {
  isTableView?: boolean
  type?: ProviderType
  className?: string
}

const fontSize = 12
const color = '#0F204B'
const backgroundColor = '#CCECF8'

const { facilityTypeDisplayNames, iconCodes, pinColors } = providers

const styleCommon = {
  border: `1px solid ${color}`,
  borderRadius: fontSize,
  backgroundColor,
  color,
  display: 'inline',
  fontSize,
  padding: fontSize / 2
}

const styleTableView = {
  border: 0,
  borderRadius: 8,
  padding: 3,
  paddingRight: 4,
  paddingLeft: 4,
  marginBottom: 1,
  marginRight: 2
}

const FacilityTypeIndicator = ({ isTableView, type, className }: Props) =>
  isTableView ? (
    <span
      className="d-inline-block"
      style={{
        ...styleCommon,
        ...styleTableView,
        backgroundColor: type ? `#${pinColors?.[type]}` : undefined
      }}>
      <i
        className={`${type ? iconCodes?.[type] : ''} ${
          className ? className : ''
        } fg-dark-blue`}
      />
      <span className="ml-1 font-weight-bold">
        {type ? facilityTypeDisplayNames?.[type] : ''}
      </span>
    </span>
  ) : (
    <OTooltip
      content={type ? facilityTypeDisplayNames?.[type] : ''}
      placement="right">
      <i
        className={`${type ? iconCodes?.[type] : ''} ${
          className ? className : ''
        }`}
        style={{
          ...styleCommon,
          backgroundColor: type ? `#${pinColors?.[type]}` : undefined
        }}
      />
    </OTooltip>
  )

export default React.memo(FacilityTypeIndicator)
