import React from 'react'
import { MapBoxPin } from '../maps'

interface Props {
  draggable?: boolean
  isDraft?: boolean
  isActive?: boolean
  latitude?: string | number
  longitude?: string | number
  iconClass?: string
  color?: string
  displayPopup?: boolean
  isPopupPersistent?: boolean
  onClick?: (payload: any) => void
  onDragEnd?: (payload: any) => void
  children?: JSX.Element
}

const defaultColor = '99D9F0'
const activeColor = '0F204B'
const whiteColor = 'FFF'

const ProviderPin = ({
  draggable,
  isDraft,
  isActive,
  latitude,
  longitude,
  iconClass,
  color,
  displayPopup,
  isPopupPersistent,
  onClick,
  onDragEnd,
  children
}: Props) => (
  <MapBoxPin
    allowPopups
    draggable={draggable}
    color={
      isActive ? activeColor : isDraft ? whiteColor : color || defaultColor
    }
    strokeColor="0F204B"
    iconClass={iconClass || ''}
    iconColor={isActive ? whiteColor : activeColor}
    latitude={latitude}
    longitude={longitude}
    displayPopup={displayPopup}
    isPopupPersistent={isPopupPersistent}
    onClick={onClick}
    onDragEnd={onDragEnd}>
    {children}
  </MapBoxPin>
)

export default React.memo(ProviderPin)
