import {
  useLocalStorage,
  useScenarioProviders,
  useScenarioUpdate
} from '@/hooks'
import { enumFlags, helper, locations } from '@/utils'
import { useAppSelector } from '@/store/hooks'
import { useState } from 'react'
import {
  ProcessedMaterial,
  ProjectType,
  ProviderMaterial,
  ProviderType
} from '@/interfaces'

const { getEnumFlagValues } = enumFlags
const { isNotDraft } = helper
const { findNearestItemId } = locations

enum TabIds {
  AllProviders = 0,
  MetalProcessing = 1,
  BladeProcessing = 2,
  ConcreteProcessing = 3
}

const tabs = [
  'All providers',
  'Metal processing',
  'Blade processing',
  'Concrete processing'
]

const filterKeys = ['name', 'countryName']

const filterItems = (
  filterValue: string,
  items: any[] = [],
  activeTab?: number
) => {
  // Filter Items according to the selected tab
  if (activeTab === TabIds.MetalProcessing)
    items = items.filter?.((i: any) => i?.materialsList?.includes('Metals'))

  if (activeTab === TabIds.BladeProcessing)
    items = items.filter?.((i: any) => i?.materialsList?.includes('Blades'))

  if (activeTab === TabIds.ConcreteProcessing)
    items = items.filter?.((i: any) => i?.materialsList?.includes('Concrete'))

  if (!filterValue) return items
  const query = filterValue.toLocaleLowerCase()
  return items.filter(isNotDraft).filter(f => {
    let found = false
    filterKeys.forEach((i: any) => {
      if (`${(f as any)?.[i]}`?.toLocaleLowerCase()?.includes(query))
        found = true
    })
    return found
  })
}

export default function useSelectProviders(
  allItems: any[],
  filteredItems: any[],
  selectedProject: any
) {
  const { scenario } = useAppSelector(s => s.scenarios)
  const { reset: resetProvider } = useScenarioProviders(scenario)
  const [isMapView, setIsMapView] = useLocalStorage(
    'useSelectProviders-isMapView',
    true
  )

  const [activeTab, setActiveTab] = useState(TabIds.AllProviders)

  const { onPropertyUpdate, onScenarioUpdate } = useScenarioUpdate()
  const [clickedProvider, setClickedProvider] = useState<any>(null)
  const [query, setQuery] = useState<string>('')

  const setMetalProcessingProviderId = (id: string | null) => {
    onPropertyUpdate('metalProcessingProviderId', id, `${scenario?.id}`)
    if (id === null) resetProvider(ProcessedMaterial.Metal)
  }

  const setBladeProcessingProviderId = (id: string | null) => {
    onPropertyUpdate('bladeProcessingProviderId', id, `${scenario?.id}`)
    if (id === null) resetProvider(ProcessedMaterial.Blade)
  }

  const setConcreteProcessingProviderId = (id: string | null) => {
    onPropertyUpdate('concreteProcessingProviderId', id, `${scenario?.id}`)
    if (id === null) resetProvider(ProcessedMaterial.Concrete)
  }

  const toggleIsMapView = () => setIsMapView(!isMapView)
  const onSelection = (selectedProvider: any) => {
    if (typeof clickedProvider === 'string') {
      if (activeTab === TabIds.AllProviders) {
        const payload: any = {}
        if (selectedProvider?.materialsList?.includes('Metals'))
          payload.metalProcessingProviderId = selectedProvider?.id
        if (selectedProvider?.materialsList?.includes('Blades'))
          payload.bladeProcessingProviderId = selectedProvider?.id
        if (selectedProvider?.materialsList?.includes('Concrete'))
          payload.concreteProcessingProviderId = selectedProvider?.id

        if (Object.keys(payload).length)
          onScenarioUpdate(payload, `${scenario?.id}`)
      }

      if (activeTab === TabIds.MetalProcessing)
        setMetalProcessingProviderId(selectedProvider?.id as string)

      if (activeTab === TabIds.BladeProcessing)
        setBladeProcessingProviderId(selectedProvider?.id as string)

      if (activeTab === TabIds.ConcreteProcessing)
        setConcreteProcessingProviderId(selectedProvider?.id as string)

      setClickedProvider(selectedProvider)
    }
  }

  const onProviderSelection = (id: string | null) => setClickedProvider(id)

  const selectNearestProviders = () => {
    const metals: any[] = []
    const blades: any[] = []
    const concrete: any[] = []

    const itemList =
      selectedProject.projectType === ProjectType.Onshore
        ? allItems.filter(
            item =>
              !getEnumFlagValues(item.providerTypes).includes(ProviderType.Port)
          )
        : allItems

    itemList?.forEach(i => {
      const materials = getEnumFlagValues(i?.materials)
      if (materials?.includes(ProviderMaterial.Metals)) metals.push(i)
      if (materials?.includes(ProviderMaterial.Blades)) blades.push(i)
      if (materials?.includes(ProviderMaterial.Concrete)) concrete.push(i)
    })

    const payload: any = { useDefaultProviders: true }

    const metalProcessingProviderId = findNearestItemId(
      metals,
      selectedProject?.location
    )
    const bladeProcessingProviderId = findNearestItemId(
      blades,
      selectedProject?.location
    )
    const concreteProcessingProviderId = findNearestItemId(
      concrete,
      selectedProject?.location
    )

    if (metalProcessingProviderId)
      payload.metalProcessingProviderId = metalProcessingProviderId
    if (bladeProcessingProviderId)
      payload.bladeProcessingProviderId = bladeProcessingProviderId
    if (concreteProcessingProviderId)
      payload.concreteProcessingProviderId = concreteProcessingProviderId

    onScenarioUpdate(payload, `${scenario?.id}`)
  }

  const toggleUseDefaultProviders = () =>
    scenario?.useDefaultProviders
      ? onPropertyUpdate('useDefaultProviders', false, `${scenario?.id}`)
      : selectNearestProviders()

  return {
    scenario,
    filteredProviders: filterItems(query, filteredItems, activeTab) || [],
    isMapView,
    activeTab,
    tabs,
    clickedProvider,
    toggleUseDefaultProviders,
    toggleIsMapView,
    setActiveTab,
    setClickedProvider,
    setMetalProcessingProviderId,
    setBladeProcessingProviderId,
    setConcreteProcessingProviderId,
    setQuery,
    onSelection,
    onProviderSelection,
    selectNearestProviders
  }
}
