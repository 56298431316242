import React from 'react'
import { ContentSection } from '@/components/layout'
import { Input, InputValue, RadioToggle } from '@/components/helpers'
import { config, helper } from '@/utils'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import { ProjectItemViewModel, SubstationViewModel } from '@/interfaces'
import {
  ProjectPageOffshoreSubstation,
  ProjectPageOnshoreSubstation
} from '@/components/projects/index'

interface Props {
  project: ProjectItemViewModel | null
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstation: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
}

const { icons, defaultSelectOption, projects, showNewSubstations } = config
const { formatTonnes } = helper

const ProjectPageDetailsOffshoreFloating = ({
  project,
  onUpdate,
  projectSubstations,
  onUpdateSubstation
}: Props) => (
  <ContentSection
    title="Site infrastructure"
    padding="0"
    icon={icons.infrastructure}
    contentClassName="p-2"
    underlineTitle>
    <ORow>
      <OCol md="12" className="font-weight-bold">
        General
      </OCol>
      <OCol md="6">
        <Input
          propertyName="distanceToPortKm"
          label="Distance to port"
          helpText="Distance (km) to port from centre of wind farm"
          type="number"
          defaultValue={project?.distanceToPortKm}
          isValueMissing={project?.distanceToPortKm === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="averageWaterDepthM"
          label="Average water depth"
          helpText="m"
          type="number"
          defaultValue={project?.averageWaterDepthM}
          isValueMissing={project?.averageWaterDepthM === null}
          min={0}
          max={10000}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="12" className="border-bottom mb-2" />
      <OCol md="12" className="font-weight-bold">
        Floater
      </OCol>
      <OCol md="6" />
      <OCol md="6">
        <Input
          propertyName="floaterSteelWeightT"
          label="Floater steel weight"
          helpText="tonnes"
          type="number"
          defaultValue={project?.floaterSteelWeightT}
          min={0}
          onBlur={(n, v) => onUpdate(n, parseFloat(v))}
        />
        <Input
          propertyName="floaterConcreteWeightT"
          label="Floater concrete weight"
          helpText="tonnes"
          type="number"
          defaultValue={project?.floaterConcreteWeightT}
          min={0}
          onBlur={(n, v) => onUpdate(n, parseFloat(v))}
        />
        <Input
          propertyName="floaterOtherWeightT"
          label="Floater other weight"
          helpText="tonnes"
          type="number"
          defaultValue={project?.floaterOtherWeightT}
          min={0}
          onBlur={(n, v) => onUpdate(n, parseFloat(v))}
        />
        <InputValue
          label="Floater total weight"
          value={formatTonnes(project?.floaterTotalWeightT)}
        />
      </OCol>
      <OCol md="12" className="border-bottom mb-2" />
      <OCol md="12" className="font-weight-bold">
        Mooring lines
      </OCol>
      <OCol md="6">
        <Input
          propertyName="primaryMooringLineMaterial"
          label="Primary mooring line material"
          type="select"
          value={project?.primaryMooringLine?.material}
          options={projects.selectOptions.mooringLineMaterial}
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
        <Input
          propertyName="primaryMooringLineWeightKgM"
          label="Primary mooring line weight"
          helpText="kg/m"
          type="number"
          defaultValue={project?.primaryMooringLine?.weightKgM}
          isValueMissing={project?.primaryMooringLine?.weightKgM === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
        <Input
          propertyName="primaryMooringLineLengthM"
          label="Primary mooring line length"
          helpText="m/turbine"
          type="number"
          defaultValue={project?.primaryMooringLine?.lengthM}
          isValueMissing={project?.primaryMooringLine?.lengthM === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="6">
        <Input
          label="Secondary mooring line material"
          propertyName="secondaryMooringLineMaterial"
          type="select"
          value={project?.secondaryMooringLine?.material}
          options={projects.selectOptions.mooringLineMaterial}
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
        <Input
          propertyName="secondaryMooringLineWeightKgM"
          label="Secondary mooring line weight"
          helpText="kg/m"
          type="number"
          defaultValue={project?.secondaryMooringLine?.weightKgM}
          isValueMissing={project?.secondaryMooringLine?.weightKgM === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
        <Input
          propertyName="secondaryMooringLineLengthM"
          label="Secondary mooring line length"
          helpText="m/turbine"
          type="number"
          defaultValue={project?.secondaryMooringLine?.lengthM}
          isValueMissing={project?.secondaryMooringLine?.lengthM === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="12" className="border-bottom mb-2" />
      <OCol md="12" className="font-weight-bold">
        Anchor
      </OCol>
      <OCol md="6">
        <Input
          propertyName="anchorMaterial"
          label="Anchor material"
          type="select"
          value={project?.anchor?.material}
          options={projects.selectOptions.anchor}
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="anchorWeightKg"
          label="Anchor weight"
          helpText="(kg/turbine)"
          type="number"
          defaultValue={project?.anchor?.weightKg}
          isValueMissing={project?.anchor?.weightKg === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="12" className="border-bottom mb-2" />
      <OCol md="12" className="font-weight-bold">
        Cables
      </OCol>
      <OCol md="6">
        <Input
          propertyName="arrayCableLengthKm"
          label="Array cable length"
          helpText="km"
          type="number"
          defaultValue={project?.arrayCableLengthKm}
          isValueMissing={project?.arrayCableLengthKm === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="exportCableLengthKm"
          label="Export cable length"
          helpText="km"
          type="number"
          defaultValue={project?.exportCableLengthKm}
          isValueMissing={project?.arrayCableLengthKm === null}
          min={0}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="arrayCableSizeKV"
          label="Array cable size"
          helpText="kV"
          type="select"
          value={project?.arrayCableSizeKV}
          options={projects.selectOptions.cableSize}
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="exportCableSizeKV"
          label="Export cable size"
          helpText="kV"
          type="select"
          value={project?.exportCableSizeKV}
          options={projects.selectOptions.cableSize}
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="arrayCableMaterial"
          label="Array cable core material"
          type="select"
          value={project?.arrayCableMaterial}
          options={
            project?.arrayCableMaterial === null
              ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
              : projects.selectOptions.cableMaterial
          }
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
      </OCol>
      <OCol md="6">
        <Input
          propertyName="exportCableMaterial"
          label="Export cable core material"
          type="select"
          value={project?.exportCableMaterial}
          options={
            project?.exportCableMaterial === null
              ? [defaultSelectOption, ...projects.selectOptions.cableMaterial]
              : projects.selectOptions.cableMaterial
          }
          onChange={(n, v) => onUpdate(n, parseInt(v))}
        />
      </OCol>
      <OCol md="12" className="border-bottom mb-2" />
      <OCol md="12" className="font-weight-bold">
        Substations
      </OCol>
      {showNewSubstations ? (
        <>
          <ProjectPageOffshoreSubstation
            project={project}
            onUpdate={onUpdate}
            onUpdateSubstations={onUpdateSubstation}
            projectSubstations={projectSubstations}
          />
          <ProjectPageOnshoreSubstation
            project={project}
            onUpdate={onUpdate}
            onUpdateSubstations={onUpdateSubstation}
            projectSubstations={projectSubstations}
          />
        </>
      ) : (
        <OCol md="12">
          <RadioToggle
            label="Offshore substation"
            value={project?.hasOffshoreSubstation}
            onToggle={() => {
              onUpdate('hasOffshoreSubstation', !project?.hasOffshoreSubstation)
            }}
          />
        </OCol>
      )}
    </ORow>
  </ContentSection>
)

export default React.memo(ProjectPageDetailsOffshoreFloating)
