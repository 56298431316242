import { OBadge } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

interface Props {
  text:string
  isDraft?: boolean
  disabled?: boolean
}

const DraftIndicator = ({text, isDraft, disabled }: Props) =>
  isDraft ? (
    <OBadge
      outline={isDraft}
      className={`ml-2${disabled ? ' is-disabled' : ''}`}>
      {text}
    </OBadge>
  ) : null

export default React.memo(DraftIndicator)
