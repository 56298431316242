import { useEffect, useState } from 'react'
import useTexts from './useTexts'
import { useNavigate } from 'react-router-dom'
import { config, helper } from '@/utils'
import { OnboardingStep } from '@/interfaces'
import { useAppSelector } from '@/store/hooks'
import useLocalStorage from './useLocalStorage'

const { urls, onboarding } = config
const { getTimeDifference, waitaSecond } = helper
const formStepIndex = 1
const tourStartStepIndex = 2

const useOnboardingTutorial = (isDisplayForced?: boolean) => {
  const texts = useTexts()
  const navigate = useNavigate()
  const { currentUser } = useAppSelector(s => s.users)

  const [image, setImage] = useState<string | null>(onboarding.images[1])
  const [isOnboardingSubmitted, setIsOnboardingSubmitted] =
    useLocalStorage<boolean>('isOnboardingSubmitted', false)

  const [remindLaterTimestamp, setRemindLaterTimestamp] =
    useLocalStorage<string>('useOnboardingTutorial-remindLaterTimestamp', '')

  const [isSkipTour, setIsSkipTour] = useLocalStorage<boolean>(
    'useOnboardingTutorial-isSkipTour',
    false
  )
  const [isCompleted, setIsCompleted] = useLocalStorage<boolean>(
    'useOnboardingTutorial-isCompleted',
    false
  )
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [step, setStep] = useState<OnboardingStep>(
    isOnboardingSubmitted ? tourStartStepIndex : formStepIndex
  )

  useEffect(() => {
    if ((isSkipTour || isCompleted) && !isDisplayForced) return
    let v: boolean = !!isDisplayForced
    if (remindLaterTimestamp) {
      const remindTimestamp = new Date(Date.parse(remindLaterTimestamp))
      const diff = getTimeDifference(remindTimestamp)
      if (diff > onboarding.remindLaterDelay) {
        setRemindLaterTimestamp('')
        v = true
      }
    } else v = true

    waitaSecond(() => setIsVisible(v))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setImage((onboarding.images as any)?.[step])
    if (step === formStepIndex && isOnboardingSubmitted) onNext()
  }, [isOnboardingSubmitted, step])

  const hideModal = () => setIsVisible(false)
  const onNext = () => {
    setImage(null)
    setStep(step => (step + 1) as OnboardingStep)
  }
  const onPrev = () => {
    setImage(null)
    setStep(step => (step - 1) as OnboardingStep)
  }

  const onRemindLater = () => {
    setRemindLaterTimestamp(new Date().toISOString())
    setIsVisible(false)
    if (isDisplayForced) navigate(urls.home)
  }

  const onSkipTour = () => {
    if (isDisplayForced) navigate(urls.home)
    else {
      setIsSkipTour(true)
      setIsVisible(false)
    }
  }

  const onCompleted = () => {
    setIsCompleted(true)
    setIsVisible(false)
    navigate(urls.turbines)
  }

  return {
    step,
    image,
    title: texts?.onboarding?.[`${step}`]?.title,
    texts: texts?.onboarding?.[`${step}`]?.texts,
    isVisible,
    currentUserFirstName: currentUser?.firstName,
    isOnboardingSubmitted,
    hideModal,
    setIsOnboardingSubmitted,
    onNext,
    onPrev,
    onRemindLater,
    onSkipTour,
    onCompleted
  }
}

export default useOnboardingTutorial
