import React from 'react'

import { helper } from '@/utils'
import Indicator from './Indicator'
import { MaterialsBreakdownViewModel } from '@/interfaces'

interface Props {
  data?: MaterialsBreakdownViewModel | any
  baseFields?: any[]
  total?: number
}

const { formatPercentage, calculatePercentage, formatTonnes } = helper

const cellClass = ['px-2', 'py-1', 'border'].join(' ')

const MaterialsTable: React.FC<Props> = ({
  data = {},
  baseFields = [],
  total = 0
}) => (
  <table className="w-100">
    <thead>
      <tr className="font-weight-bold bg-light">
        <th className={cellClass}>Material</th>
        <th className="p-1 border ">Weight</th>
        <th className={cellClass}>%</th>
      </tr>
    </thead>
    <tbody>
      {baseFields.map((i, key) => (
        <tr
          key={`${i?.name}-${key}`}
          className={
            key === baseFields.length - 1 ? 'font-weight-bold bg-light' : ''
          }>
          <td className={cellClass}>
            {key < baseFields.length - 1 && (
              <Indicator colorCode={i?.color} className="mr-2" />
            )}
            {i?.heading}
          </td>
          <td className={cellClass}>{formatTonnes(data?.[i.name])}</td>
          <td className={cellClass}>
            {formatPercentage(calculatePercentage(data?.[i.name], total) || 0)}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)

export default React.memo(MaterialsTable)
