import React, { ReactNode } from 'react'
import { ORow, OCol, OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'
import { ContentSection } from '../layout'
import { helper } from '@/utils'
import {
  DecommissionOffshoreBottomFixedScopeViewModel,
  ProjectItemViewModel,
  ScenarioItemViewModel
} from '@/interfaces'
import { useCostScenarioBreakdown, useScenarioState } from '@/hooks'

interface Props {
  scenario?: ScenarioItemViewModel | null
  children?: ReactNode
  onScopeUpdate?: (name: string, value?: boolean) => void
  project: ProjectItemViewModel | null
}

const { getCheckedText } = helper

const DefineScopeOffshoreFixed = ({
  project,
  scenario,
  children,
  onScopeUpdate
}: Props) => {
  const data =
    scenario?.decommissionScope as DecommissionOffshoreBottomFixedScopeViewModel
  const existingSubstation = project?.hasOffshoreSubstation
  const { isUpdating } = useScenarioState()
  const { isCalculationLoading } = useCostScenarioBreakdown(
    false,
    scenario?.includeScrapValue
  )

  return (
    <ORow>
      <OCol md={children ? '6' : '12'}>
        <ContentSection title="Decommissioning scope">
          <div className="px-2">
            <label>Foundations to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              checked={data?.foundationsToBeRemoved}
              onChange={() =>
                onScopeUpdate?.(
                  'foundationsToBeRemoved',
                  !data?.foundationsToBeRemoved
                )
              }>
              {getCheckedText(!!data?.foundationsToBeRemoved)}
            </OToggleSwitch>
            <label>Piles below sea bed to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.pilesBelowSeaBedToBeRemoved}
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'pilesBelowSeaBedToBeRemoved',
                  !data?.pilesBelowSeaBedToBeRemoved
                )
              }>
              {getCheckedText(!!data?.pilesBelowSeaBedToBeRemoved)}
            </OToggleSwitch>
            <label>Scour protection to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.scourProtectionToBeRemoved}
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'scourProtectionToBeRemoved',
                  !data?.scourProtectionToBeRemoved
                )
              }>
              {getCheckedText(!!data?.scourProtectionToBeRemoved)}
            </OToggleSwitch>
            <div className="border-bottom my-3" />
            <label>Sub sea array cables to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.subSeaArrayCablesToBeRemoved}
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'subSeaArrayCablesToBeRemoved',
                  !data?.subSeaArrayCablesToBeRemoved
                )
              }>
              {getCheckedText(!!data?.subSeaArrayCablesToBeRemoved)}
            </OToggleSwitch>
            <label>Export cables to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.exportCablesToBeRemoved}
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'exportCablesToBeRemoved',
                  !data?.exportCablesToBeRemoved
                )
              }>
              {getCheckedText(!!data?.exportCablesToBeRemoved)}
            </OToggleSwitch>
            <label>Cable crossing protection to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={data?.cableCrossingProtectionToBeRemoved}
              disabled={
                isUpdating || isCalculationLoading || scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'cableCrossingProtectionToBeRemoved',
                  !data?.cableCrossingProtectionToBeRemoved
                )
              }>
              {getCheckedText(!!data?.cableCrossingProtectionToBeRemoved)}
            </OToggleSwitch>
            <label>Offshore substation to be removed?</label>
            <OToggleSwitch
              className="pointer mb-3"
              textLocation="hidden"
              checked={
                existingSubstation ? data?.offshoreSubstationToBeRemoved : false
              }
              disabled={
                isUpdating ||
                !existingSubstation ||
                isCalculationLoading ||
                scenario?.isLocked
              }
              onChange={() =>
                onScopeUpdate?.(
                  'offshoreSubstationToBeRemoved',
                  !data?.offshoreSubstationToBeRemoved
                )
              }>
              {getCheckedText(
                existingSubstation
                  ? !!data?.offshoreSubstationToBeRemoved
                  : false
              )}
            </OToggleSwitch>
          </div>
        </ContentSection>
      </OCol>
      {!!children && <OCol md="6">{children}</OCol>}
    </ORow>
  )
}

export default React.memo(DefineScopeOffshoreFixed)
