import React from 'react'
import { ORow, OCol, OTable } from '@dnvgl-onefoundation/onedesign-react'
import { StatusIndicator } from '../helpers'
import { ProviderStatus } from '@/interfaces'

const fields = [
  {
    name: 'name',
    heading: 'Company',
    sortable: true
  },
  {
    name: 'countryName',
    heading: 'Location',
    sortable: true
  },
  {
    name: 'status',
    heading: 'Status',
    formatter: (s: ProviderStatus) => <StatusIndicator status={s} isTableView />
  },
  {
    name: 'providerTypesIconsTableView',
    heading: 'Facility type'
  }
]

interface Props {
  items?: any[]
  onItemClick?: (link: string) => void
}

const ProvidersCollection = ({ items = [], onItemClick }: Props) => (
  <ORow>
    <OCol md="12">
      <OTable
        className="selectable-table"
        bordered
        small
        fields={fields}
        allItems={items}
        showFilter={false}
        showOptionsButton={false}
        showActionIcons={false}
        showColumnsButton={false}
        showFilterButton={false}
        onFocusRowChange={(e: any) => onItemClick?.(e?.id)}
      />
    </OCol>
  </ORow>
)

export default React.memo(ProvidersCollection)
