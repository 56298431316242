import { SubscriptionTier } from '@/interfaces'
import { useAppSelector } from '@/store/hooks'

import { config, helper } from '@/utils'

const { subscriptionTierOptions } = config
const { isDnvUser, toLocaleDate } = helper

const basicFields = [
  {
    name: 'user',
    heading: 'User'
  },
  {
    name: 'email',
    heading: 'E-Mail'
  },
  {
    name: 'groups',
    heading: 'Company'
  }
]

const adminFields = [
  {
    name: 'roles',
    heading: 'Roles'
  }
]

const useAccount = () => {
  const { currentUser, isLoadingCurrentUser } = useAppSelector(
    state => state.users
  )

  let fields = isDnvUser(currentUser)
    ? [...basicFields, ...adminFields]
    : basicFields

  if (!isDnvUser(currentUser)) {
    fields = [
      ...fields,
      {
        name:
          currentUser?.subscription?.tier === SubscriptionTier.Trial
            ? 'subscriptionTrial'
            : 'subscription',
        heading: 'Subscription'
      }
    ]
  }

  const subscriptionName = subscriptionTierOptions.find(
    i => i.value === currentUser?.subscription?.tier
  )?.text

  return {
    isLoadingCurrentUser,
    fields,
    value: {
      user: `${currentUser?.firstName} ${currentUser?.lastName}`,
      email: currentUser?.email,
      groups: currentUser?.groups
        ?.map(group => group.name)
        ?.filter(i => i !== 'Default')
        .join(', '),
      roles: currentUser?.roles?.map(i => i.name).join(', '),
      subscription: subscriptionName,
      subscriptionTrial: (
        <span>
          {subscriptionName}, expires:{' '}
          <span className="text-danger">
            {toLocaleDate(`${currentUser?.subscription?.expirationDate}`)}
          </span>
        </span>
      )
    }
  }
}

export default useAccount
