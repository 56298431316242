import React from 'react'
import { Text, View, StyleSheet, Svg, Circle } from '@react-pdf/renderer'

interface Props {
  items: string[] | string[][]
  fontSize?: number
  styles?: any
}

const innerStyles = StyleSheet.create({
  listItem: {
    flexDirection: 'row'
  },
  bulletPoint: {
    width: 10, // width of the bullet point
    textAlign: 'right' // align the bullet to the right of the column
  },
  innerBulletPoint: {
    paddingLeft: 12,
    paddingTop: 6,
    width: 20, // width of the bullet point
    textAlign: 'right' // align the bullet to the right of the column
  },
  listItemContent: {
    flex: 1, // take up remaining space
    paddingTop: 4,
    paddingLeft: 5 // space between bullet and text
  },
  innerListItemContent: {
    flex: 1, // take up remaining space
    paddingTop: 4,
    paddingLeft: 6 // space between bullet and text
  },
  list: {
    paddingLeft: 14,
    paddingBottom: 7
  }
})

// Create Document Component
const ReportList: React.FC<Props> = ({ items, fontSize = 11 }) => (
  <View style={innerStyles.list}>
    {items?.map?.((item, index) => (
      <View key={index}>
        {typeof item === 'string' ? (
          <View key={index} style={innerStyles.listItem}>
            <Text
              style={{
                ...innerStyles.bulletPoint,
                paddingTop: fontSize === 10 ? '4px' : undefined
              }}>
              •
            </Text>
            <Text style={{ ...innerStyles.listItemContent, fontSize }}>
              {item}
            </Text>
          </View>
        ) : (
          <View>
            {item.map((i, idx) => {
              return (
                <View key={idx} style={innerStyles.listItem}>
                  <Svg
                    width={18}
                    height={18}
                    style={innerStyles.innerBulletPoint}>
                    <Circle r="3" cx="4" cy="4" fill="white" stroke="black" />
                  </Svg>
                  <Text
                    style={{ ...innerStyles.innerListItemContent, fontSize }}>
                    {i}
                  </Text>
                </View>
              )
            })}
          </View>
        )}
      </View>
    ))}
  </View>
)

export default ReportList
