import React from 'react'
import { Link } from 'react-router-dom'

import { api, config, helper } from '@/utils'
import {
  OButton,
  ODragAndDrop,
  ORow,
  OCol,
  OList
} from '@dnvgl-onefoundation/onedesign-react'
import { useExcelImport } from '@/hooks'
import { Page, ContentSection, SpinIndicator } from '@/components/layout'
import { BreadcrumbsTitle } from '@/components/helpers'

const { urls, icons, templates } = config
const { formatFileSize, stylizeStatusMessage } = helper

const ProvidersImportPage = () => {
  const {
    accept,
    selected,
    feedbackRows,
    isUploading,
    onDrop,
    onReset,
    onUpload
  } = useExcelImport(api.providers.upload)
  return (
    <Page
      title={
        <BreadcrumbsTitle
          title="Import providers"
          backLink={urls.admin}
          backName="Administration"
        />
      }
      isH1Title={false}
      actions={
        <div>
          <a href={templates.providers} rel="noreferrer" target="_blank">
            <OButton
              iconClass={icons.download}
              variant="flat"
              disabled={isUploading}>
              Download template
            </OButton>
          </a>
          <Link to={urls.providers}>
            <OButton
              iconClass={icons.providersMap}
              variant="flat"
              disabled={isUploading}>
              Open providers list
            </OButton>
          </Link>
        </div>
      }>
      <ContentSection>
        {isUploading && <SpinIndicator />}
        {selected ? (
          <ORow className="p-5">
            <OCol md="12" className="mb-4">
              {selected.file.name}&nbsp;({formatFileSize(selected.file.size)}
              )&nbsp;
              <OButton
                iconClass={icons.cancel}
                variant="flat"
                disabled={isUploading}
                onClick={onReset}
              />
            </OCol>
            <OCol md="2">
              <OButton
                iconClass={icons.upload}
                onClick={() => onUpload()}
                disabled={isUploading}>
                Upload
              </OButton>
            </OCol>
          </ORow>
        ) : (
          <>
            <ODragAndDrop multiple={false} accept={accept} onDrop={onDrop} />
            {!!feedbackRows?.length && (
              <OList
                className="mt-2"
                items={feedbackRows
                  ?.filter(i => typeof i === 'string' && i.length > 1)
                  ?.map(message => ({
                    id: message,
                    content: stylizeStatusMessage(message)
                  }))}
              />
            )}
          </>
        )}
      </ContentSection>
    </Page>
  )
}

export default React.memo(ProvidersImportPage)
