import React from 'react'

interface Props {
  unit: string
  power: number
  className?: string
}

const SuperscriptUnit: React.FC<Props> = ({
  unit,
  power,
  className
}: Props) => (
  <span className={className}>
    {unit}
    <sup>{power}</sup>
  </span>
)

export default React.memo(SuperscriptUnit)
