import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Option } from '@dnvgl-onefoundation/onedesign-react'
import { api, helper } from '@/utils'
import { getErrorHandler } from './users'
import { ActionCreator } from '@/store'
import { CountryViewModel, CurrencyViewModel } from '@/interfaces'

export interface GeneralState {
  countries: CountryViewModel[]
  countryOptions: Option[]
  currencies: CurrencyViewModel[]
  licenses: any | null
  texts: any | null
  isLoading: string[]
}

const initialState: GeneralState = {
  countries: [],
  currencies: [],
  countryOptions: [],
  licenses: null,
  texts: null,
  isLoading: []
}

const { sort } = helper

export const generalSlice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<CountryViewModel[]>) => {
      state.countries = action.payload
      const countryOptions = action.payload?.map?.(({ name, id }) => ({
        text: name,
        value: id
      }))
      state.countryOptions = sort(countryOptions, 'text')
    },
    setLicenses: (state, action: PayloadAction<any>) => {
      state.licenses = action.payload
    },
    setTexts: (state, action: PayloadAction<any>) => {
      state.texts = action.payload
    },
    setLoading: (state, action: PayloadAction<any>) => {
      state.isLoading = [...state.isLoading, action.payload]
    },
    setLoaded: (state, action: PayloadAction<any>) => {
      state.isLoading = state.isLoading.filter(i => i !== action.payload)
    },
    setCurrencies: (state, action: PayloadAction<any>) => {
      state.isLoading = state.currencies = action.payload
    }
  }
})

export const { actions } = generalSlice

const requiresLoading = (state: any, name: string) =>
  !state?.general?.[name]?.length &&
  !state?.general?.isLoading?.includes?.(name)

export const getCountries: ActionCreator = () => (dispatch, getState) => {
  const name = 'countries'
  if (requiresLoading(getState(), name)) {
    dispatch(actions.setLoading(name))
    api.countries
      .readAll()
      .then(i => dispatch(actions.setCountries(i || [])))
      .finally(() => dispatch(actions.setLoaded(name)))
      .catch(getErrorHandler(dispatch))
  }
}

export const getJsonData: ActionCreator =
  (name: 'licenses' | 'texts') => (dispatch, getState) => {
    if (requiresLoading(getState(), name)) {
      dispatch(actions.setLoading(name))
      api.files
        .readJson(name)
        .then(i => {
          if (name === 'texts') dispatch(actions.setTexts(i || []))
          if (name === 'licenses') dispatch(actions.setLicenses(i || []))
        })
        .finally(() => dispatch(actions.setLoaded(name)))
        .catch(getErrorHandler(dispatch))
    }
  }

export const getCurrencies: ActionCreator = () => (dispatch, getState) => {
  const name = 'currencies'
  if (requiresLoading(getState(), name)) {
    dispatch(actions.setLoading(name))
    api.currencies
      .getAll()
      .then(i => dispatch(actions.setCurrencies(i || [])))
      .finally(() => dispatch(actions.setLoaded(name)))
      .catch(getErrorHandler(dispatch))
  }
}
