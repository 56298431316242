import React, { ReactNode, useRef, useState } from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { useClickOutside } from '@/hooks'

interface Props {
  className?: string
  label?: ReactNode
  children?: ReactNode
}

const ButtonDropdown = ({ className, label, children }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const handleClickOutside = () => setIsMenuOpen(false)

  useClickOutside(containerRef, handleClickOutside)
  return (
    <div
      className={`position-relative d-flex justify-content-between ${className}`}
      ref={containerRef}>
      <OButton
        variant="subtle"
        className="border-right-0 white-space-nowrap w-100"
        onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {label}
      </OButton>
      <OButton
        iconClass={`far fa-chevron-${isMenuOpen ? 'up' : 'down'}`}
        variant="subtle"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      />
      {isMenuOpen && !!children && (
        <div
          className="position-absolute bg-white border"
          style={{ left: 0, top: 40, zIndex: 10000, minWidth: '100%' }}>
          {children}
        </div>
      )}
    </div>
  )
}

export default React.memo(ButtonDropdown)
