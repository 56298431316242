import React, { useMemo } from 'react'
import ReportTable from './ReportTable'
import CostCalculationChart from './CostCalculationChart'
import { config, helper } from '@/utils'
import { ContentSection } from '../layout'
import { useLocalStorage } from '@/hooks'
import {
  OAlert,
  OBadge,
  OButton,
  OToggleSwitch
} from '@dnvgl-onefoundation/onedesign-react'
import {
  Manufacturer,
  ProjectItemViewModel,
  ProjectTurbineViewModel,
  ProjectType,
  ScenarioItemViewModel
} from '@/interfaces'
import Input from '@/components/helpers/Input'
import useCurrencies from '@/hooks/useCurrencies'
import { TooltipHelpText } from '@/components/helpers'
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_CURRENCY_SYMBOL
} from '@/components/helpers/Constants'
import { tooltips } from '@/utils'
import { Link } from 'react-router-dom'

interface Props {
  scenario?: ScenarioItemViewModel | null
  costScenarioBreakdownTableItems?: any[]
  costScenarioBreakdownChartData?: any[]
  project?: ProjectItemViewModel | null
  projectTurbines?: ProjectTurbineViewModel[] | null
  includeScrapValue?: boolean
  includeContingencyAllocation?: boolean
  isManual?: boolean
  isSuperAdmin?: boolean
  toggleIncludeScrapValue?: () => void
  toggleincludeContingencyAllocation?: () => void
  changeCurrency: (value?: string) => void
}

const { getCheckedText, sort, toHtmlDate, formatCurrencyMask } = helper
const { urls } = config

const CostScenarioBreakdown = ({
  scenario,
  costScenarioBreakdownTableItems,
  costScenarioBreakdownChartData,
  project,
  projectTurbines,
  includeScrapValue,
  includeContingencyAllocation,
  isManual,
  isSuperAdmin,
  toggleIncludeScrapValue,
  toggleincludeContingencyAllocation,
  changeCurrency
}: Props) => {
  const [isTableView, setIsTableView] = useLocalStorage<boolean>(
    `CostScenarioBreakdown-isTableView-${scenario?.id}`,
    false
  )
  const { currencies } = useCurrencies()
  const sortedCurrencies = useMemo(
    () => sort([...currencies], 'name'),
    [currencies]
  )

  if (costScenarioBreakdownChartData === null) {
    const loadedTurbine = projectTurbines?.[0]
    return (
      <ContentSection>
        <OAlert
          dismissable={false}
          variant="info"
          message="Data Unavailable"
          description={`Please verify the material breakdown data for Turbine ${loadedTurbine?.manufacturer ? Manufacturer[loadedTurbine?.manufacturer] : ''} ${loadedTurbine?.modelNumber ? loadedTurbine?.modelNumber : ''} to ensure data integrity.`}
        />
      </ContentSection>
    )
  }

  return (
    <ContentSection>
      {(project?.projectType === ProjectType.Onshore ||
        project?.projectType === ProjectType.OffshoreBottomFixed) && (
        <>
          <label>
            Currency
            {!(
              scenario?.currency?.currencyInformation.symbol ===
                DEFAULT_CURRENCY_CODE ||
              scenario?.currency?.currencyInformation.symbol ===
                DEFAULT_CURRENCY_SYMBOL
            ) && (
              <TooltipHelpText
                message={`
                Exchange rates are updated every seven days. 
                Rates are over 90-day average. 
                Last update ${scenario?.currency?.updatedAt && toHtmlDate(scenario?.currency?.updatedAt, 'en-GB')}
              `}
                placement="bottom"
                className="ml-2"
              />
            )}
          </label>

          <Input
            propertyName="currency"
            type="select"
            options={sortedCurrencies.map(currency => {
              return {
                text: `${currency.currencyInformation.symbol} - ${currency.name}`,
                value: currency.id
              }
            })}
            value={scenario?.currency?.id || ''}
            onChange={(_key, value) => {
              changeCurrency(value)
            }}
            disabled={isManual || scenario?.isLocked}
          />
        </>
      )}
      <div className="d-flex justify-content-between px-2 mb-2">
        <div className="d-flex align-items-center">
          <span className="d-flex font-weight-bold">Cost</span>
          {isSuperAdmin && isManual && (
            <OBadge className="ml-2 text-small" variant="secondary" outline>
              Manual
            </OBadge>
          )}
        </div>
        <div className="d-flex">
          <OButton
            variant={isTableView ? 'subtle' : 'secondary'}
            size="small"
            onClick={() => setIsTableView(false)}>
            Chart
          </OButton>
          <OButton
            variant={isTableView ? 'secondary' : 'subtle'}
            size="small"
            onClick={() => setIsTableView(true)}>
            Table
          </OButton>
        </div>
      </div>
      {isTableView ? (
        <ReportTable
          items={costScenarioBreakdownTableItems}
          className="border"
          scenarioCurrency={scenario?.currency.currencyInformation}
          valueFormater={formatCurrencyMask}
          simpleTable
        />
      ) : (
        <CostCalculationChart
          data={costScenarioBreakdownChartData}
          currencySymbol={scenario?.currency?.currencyInformation.symbol}
          small
        />
      )}
      <div className="mt-2 py-3 border-top">
        <label>
          Include scrap value of wind farm{' '}
          <TooltipHelpText
            message={
              <>
                {tooltips.Scope.ScrapValue.replace('settings.', '')}
                <Link to={[urls.settingsScenarios, scenario?.id].join('/')}>
                  settings.
                </Link>
              </>
            }
          />
        </label>
        <OToggleSwitch
          className="pointer"
          textLocation="hidden"
          disabled={scenario?.isLocked}
          checked={includeScrapValue}
          onChange={toggleIncludeScrapValue}>
          {getCheckedText(!!includeScrapValue)}
        </OToggleSwitch>
        {project?.projectType === ProjectType.Onshore && (
          <>
            <label>
              Include contingency allocation{' '}
              <TooltipHelpText
                message={
                  <>
                    {tooltips.Scope.ContingencyAllocation.replace(
                      'settings.',
                      ''
                    )}
                    <Link to={[urls.settingsScenarios, scenario?.id].join('/')}>
                      settings.
                    </Link>
                  </>
                }
              />
            </label>
            <OToggleSwitch
              className="pointer"
              textLocation="hidden"
              checked={includeContingencyAllocation}
              disabled={scenario?.isLocked}
              onChange={toggleincludeContingencyAllocation}>
              {getCheckedText(!!includeContingencyAllocation)}
            </OToggleSwitch>
          </>
        )}
      </div>
    </ContentSection>
  )
}

export default React.memo(CostScenarioBreakdown)
