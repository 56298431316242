import { O404 } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'

const PageNotFound = () => (
  <div style={{ minHeight: 500 }} className="w-100">
    <O404 hideIcon />
  </div>
)

export default React.memo(PageNotFound)
