import {
  CircularityRateViewModel,
  ProjectItemViewModel,
  ProjectTurbineViewModel,
  ReportSectionCode,
  ScenarioItemViewModel
} from '@/interfaces'
import { Link, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import Table from '@/components/pdf/Table'
import { helper } from '@/utils'

interface Props {
  project?: ProjectItemViewModel
  projectTurbines?: ProjectTurbineViewModel[] | null
  styles?: any
  texts?: any
  setPages?: any
  turbineRatedPower?: number
  scenario?: ScenarioItemViewModel
  scenarioCircularityRate?: CircularityRateViewModel | null
  printSections: number[]
  costScenarioBreakdownSummaryData: any
}
const { formatPercentage, formatTonnes, formatCurrencyMask } = helper

const innerStyles = {
  link: {
    color: '#0f204b'
  },
  tableTitle: {
    fontSize: 10,
    marginTop: 7,
    marginBottom: 2,
    fontFamily: 'Avenir Bold'
  }
}
const ReportExecutiveSummaryPage: React.FC<Props> = ({
  project,
  projectTurbines,
  styles,
  setPages,
  printSections,
  turbineRatedPower,
  scenario,
  scenarioCircularityRate,
  costScenarioBreakdownSummaryData
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.ExecutiveSummary]: page })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  const projectHeaders: any[] = [
    'Name',
    'Total Material Mass',
    'Total Recovered Material',
    'Project Circularity'
  ]
  const projectRows: any[] = [
    [
      project?.name,
      formatTonnes(
        scenarioCircularityRate?.circularityWeights.windfarmTotalMaterialWeightT
      ),
      formatTonnes(
        scenarioCircularityRate?.circularityWeights
          .windfarmRecoveredMaterialWeight
      ),
      formatPercentage(
        scenarioCircularityRate?.circularityWeights.windfarmCircularityRate
      )
    ]
  ]

  const decommissioningHeaders: any[] = [
    'Name',
    'Location',
    'Total',
    'Per MW',
    'Per Turbine'
  ]

  const totalRows: any[] = [
    [
      project?.name,
      project?.countryName,
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.totalCosts,
        scenario?.currency.currencyInformation!
      ),
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.totalPerMW,
        scenario?.currency.currencyInformation!
      ),
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.totalPerTurbine,
        scenario?.currency.currencyInformation!
      )
    ]
  ]

  const netRows: any[] = [
    [
      project?.name,
      project?.countryName,
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.netCosts,
        scenario?.currency.currencyInformation!
      ),
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.netPerMW,
        scenario?.currency.currencyInformation!
      ),
      formatCurrencyMask(
        costScenarioBreakdownSummaryData?.netPerTurbine,
        scenario?.currency.currencyInformation!
      )
    ]
  ]

  const getScenarioScope = () => {
    return scenario?.decommissionScope || {}
  }

  const scopeOptions = () => {
    let includedInScope = []
    for (const [key, value] of Object.entries(getScenarioScope() || {})) {
      value && key !== 'scenarioId' && includedInScope.push(key)
    }
    return includedInScope
  }

  const getScopeText = () => {
    if (
      printSections.includes(ReportSectionCode.DecommissioningCosts) &&
      printSections.includes(ReportSectionCode.Circularity)
    ) {
      return 'has used DNV’s ReWind software to generate a circularity and decommissioning cost study of '
    }
    if (printSections.includes(ReportSectionCode.Circularity)) {
      return 'has used DNV’s ReWind software to generate a circularity of '
    }
    if (printSections.includes(ReportSectionCode.DecommissioningCosts)) {
      return 'has used DNV’s ReWind software to generate a decommissioning cost study of '
    } else {
      return 'has used DNV’s ReWind software to generate a decommissioning study of '
    }
  }

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="executiveSummary" style={styles.pageTitle}>
        1. Executive Summary
      </Text>
      <Text style={styles.paragraph}>
        “{project?.companyName}” (the “Customer”), {getScopeText()}“
        {project?.name}” wind farm (the “Project”).
      </Text>
      <Text style={styles.paragraph}>
        The Project is located in {project?.countryName} and comprises of&nbsp;
        {projectTurbines?.[0]?.numberOfTurbines}&nbsp;({turbineRatedPower}
        &nbsp;MW of capacity) turbine
        {projectTurbines?.[0]?.numberOfTurbines &&
        projectTurbines?.[0]?.numberOfTurbines > 1
          ? 's '
          : ' '}
        with a total capacity of&nbsp;
        {projectTurbines?.[0]?.numberOfTurbines &&
          turbineRatedPower &&
          Math.round(
            projectTurbines?.[0]?.numberOfTurbines * turbineRatedPower
          )}
        &nbsp;MW.
      </Text>
      <Text style={styles.paragraph}>
        The study estimates the material breakdown, circularity and costs
        associated with the dismantling, removal, and salvage or disposal of all
        equipment and site infrastructure. All costs in this study are presented
        in {scenario?.currency.name},&nbsp;
        {scenario?.currency.currencyInformation.symbol}.
      </Text>
      <Text style={styles.paragraph}>
        The Customer has provided site specific turbine and project inputs to
        ReWind, which is a fully independent, third party software. ReWind
        assumes that there are strong parallels between wind power project
        construction and decommissioning programs and consequently bases the
        estimates for circularity and decommissioning costs on its broad
        experience of wind power project construction programs and the
        associated costs of labour, plant, and materials.
      </Text>
      {printSections?.includes(ReportSectionCode.Circularity) && (
        <>
          <Text style={styles.paragraph}>
            DNV defines circularity percentage as the ratio of the recoverable
            material mass to the total material mass of the Project. The Project
            Circularity percentage is presented below in&nbsp;
            <Link
              src="#circularityTable"
              style={{ ...innerStyles.link, textDecoration: 'none' }}>
              Project Circularity Percentage Table.
            </Link>
          </Text>
          <Text style={styles.paragraph}>
            A further detailed circularity breakdown is provided in&nbsp;
            <Link
              src="#windfarmcircularity"
              style={{ ...innerStyles.link, textDecoration: 'none' }}>
              Section&nbsp;
              {printSections
                .sort()
                .findIndex(value => value === ReportSectionCode.Circularity) +
                1}
              .
            </Link>
          </Text>

          <Table
            linkId="circularityTable"
            name="Project Circularity Percentage"
            description="Project Circularity"
            rows={projectRows}
            headers={projectHeaders}
            breakPage={false}
          />
        </>
      )}
      {printSections?.includes(ReportSectionCode.DecommissioningCosts) && (
        <>
          <Text style={styles.paragraph}>
            The complete decommissioning cost is calculated as the sum of the
            cost of disassembly, removal, and disposal of the turbines
            {scopeOptions().length > 0 ? ' and balance of plant (BoP)' : ''}
            {scenario?.includeScrapValue
              ? ', as may be offset by gains from salvage or resale of materials and components. DNV has presented the salvage value as a separate item in all costs summaries.'
              : '.'}
          </Text>
          <Text style={styles.paragraph}>
            The total decommissioning costs for the Project are presented
            in&nbsp;
            <Link
              src="#totalCosts"
              style={{ ...innerStyles.link, textDecoration: 'none' }}>
              Total Decommissioning Costs Table.
            </Link>
            &nbsp; below.&nbsp;
            {scenario?.includeScrapValue && (
              <>
                The net decommissioning costs for the Project is presented
                in&nbsp;
                <Link
                  src="#netCosts"
                  style={{ ...innerStyles.link, textDecoration: 'none' }}>
                  Net Decommissioning Costs Table
                </Link>
                &nbsp; below, where scrap value is considered.
              </>
            )}
          </Text>
          <Table
            linkId="totalCosts"
            name="Total Decommissioning Costs"
            description="Total Decommissioning Costs"
            rows={totalRows}
            headers={decommissioningHeaders}
            breakPage={false}
          />
          {scenario?.includeScrapValue && (
            <>
              <View wrap={false}>
                <Table
                  name="Net Decommissioning Costs"
                  description="Net Decommissioning Costs"
                  rows={netRows}
                  headers={decommissioningHeaders}
                  breakPage={false}
                />
              </View>
            </>
          )}
          <Text style={styles.paragraph}>
            A more detailed cost breakdown is provided in&nbsp;
            <Link
              src="#decommissioningcosts"
              style={{ ...innerStyles.link, textDecoration: 'none' }}>
              Section&nbsp;
              {printSections
                .sort()
                .findIndex(
                  value => value === ReportSectionCode.DecommissioningCosts
                ) + 1}
              .
            </Link>
          </Text>
        </>
      )}
    </View>
  )
}
export default ReportExecutiveSummaryPage
