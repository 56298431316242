import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getCurrentUser, checkNotifications } from '@/store/slices/users'
import { helper } from '@/utils'
import { GroupType } from '@/interfaces'
import { ActionCreator } from '@/store'

const { isAuthenticated, isSuperAdministrator } = helper

const useApp = (routes: any, authCheckInterval = 1000) => {
  const guestRoutes = routes.guest
  const authenticatedRoutes = [...guestRoutes, ...routes.authenticated]
  const adminRoutes = [...authenticatedRoutes, ...routes.admin]

  const location = useLocation()
  const dispatch = useAppDispatch()
  const {
    currentUser,
    isLoadingCurrentUser,
    isAuthSessionExpired,
    notificationsCount
  } = useAppSelector(s => s.users)

  const [appRoutes, setAppRoutes] = useState<any[]>([])

  useEffect(() => {
    dispatch(getCurrentUser() as ActionCreator)
  }, [dispatch])

  useEffect(() => {
    let intervalId: any = undefined
    if (currentUser?.id) {
      dispatch(checkNotifications() as ActionCreator)
      if (!isAuthSessionExpired)
        intervalId = setInterval(() => {
          dispatch(checkNotifications() as ActionCreator)
        }, authCheckInterval)
    }

    setAppRoutes(
      isSuperAdministrator(currentUser)
        ? adminRoutes
        : isAuthenticated(currentUser)
        ? authenticatedRoutes
        : guestRoutes
    )

    return () => intervalId && clearInterval(intervalId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthSessionExpired, currentUser, dispatch])

  return {
    appRoutes,
    isHomePage: location?.pathname === '/',
    currentUser,
    isAuthenticated: isAuthenticated(currentUser),
    isLoadingCurrentUser,
    notificationsCount,
    companyId: currentUser?.groups?.find(
      group => group.type === GroupType.Company
    )?.id
  }
}

export default useApp
