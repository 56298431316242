import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getPlantWeightsForProject } from '@/store/slices/circularity'
import { useEffect } from 'react'
import useLocalStorage from './useLocalStorage'
import { UnknownAction } from '@reduxjs/toolkit'
import { ProjectType } from '@/interfaces'

export enum TabIndex {
  Turbine = 0,
  TotalWindFarm = 1,
  BalanceOfPlant = 2
}

const useProjectMaterialBreakdown = (
  projectId: string,
  turbineId?: string,
  projectType?: ProjectType
) => {
  const dispatch = useAppDispatch()
  const { isLoading: isLoadingPlantWeights, plantWeights } = useAppSelector(
    s => s.circularity
  )
  const { isUpdating } = useAppSelector(state => state.projects)

  const [selectedTab, setSelectedTab] = useLocalStorage<TabIndex>(
    `useProjectMaterialBreakdown-${projectId}`,
    TabIndex.Turbine
  )

  const getPlantWeights = (projectId: string) => {
    dispatch(getPlantWeightsForProject(projectId) as unknown as UnknownAction)
  }

  useEffect(() => {
    if (
      (selectedTab === TabIndex.TotalWindFarm ||
        selectedTab === TabIndex.BalanceOfPlant) &&
      !isUpdating &&
      turbineId &&
      projectType !== ProjectType.OffshoreFloating
    )
      getPlantWeights(projectId)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, isUpdating, turbineId])

  return {
    isLoadingPlantWeights,
    plantWeights,
    selectedTab,
    setSelectedTab
  }
}

export default useProjectMaterialBreakdown
