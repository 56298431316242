import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getJsonData } from '@/store/slices/general'
import { UnknownAction } from '@reduxjs/toolkit'

const useLicenses = () => {
  const dispatch = useAppDispatch()
  const { licenses } = useAppSelector(s => s.general)

  useEffect(() => {
    dispatch(getJsonData('licenses') as unknown as UnknownAction)
  }, [dispatch])

  return licenses
}
export default useLicenses
