import React, { useEffect } from 'react'
import {
  OBadge,
  OCol,
  ORow,
  OTab,
  OTabs
} from '@dnvgl-onefoundation/onedesign-react'
import { useChartData, useCircularityRateData, useLocalStorage } from '@/hooks'
import { config, helper, turbines } from '@/utils'
import { Input, TooltipHelpText, TurbineSvg } from '../helpers'
import {
  ComponentType,
  MaterialsBreakdownsViewModel,
  ProjectType,
  TurbinePart
} from '@/interfaces'
import MaterialBreakdownAlert from '../helpers/MaterialBreakdownAlert'
import MaterialBreakdownChart from './MaterialBreakdownChart'
import { SpinIndicator } from '../layout'
import useTexts from '../../hooks/useTexts'

const { materialBreakdown } = config
const { formatTonnes, formatPercentage, capitalize } = helper
const { getComponentType } = turbines

interface Props {
  data?: MaterialsBreakdownsViewModel | null
  projectType?: ProjectType
  animationDuration?: number
  turbineId: string
  showOwnHeader?: boolean
  isLoading?: boolean
  isEditable?: boolean
  setIsEditable?: (i?: boolean) => void
  onUpdate?: (
    turbineId: string,
    part: TurbinePart,
    propertyName: string,
    propertyValue: number
  ) => void
  onComponentSelected?: (i: ComponentType | null) => void
}

const MaterialBreakdown = ({
  data,
  projectType,
  animationDuration = 0.5,
  turbineId,
  showOwnHeader = false,
  isLoading = false,
  isEditable = false,
  setIsEditable,
  onUpdate,
  onComponentSelected
}: Props) => {
  const [selectedTab, setSelectedTab] = useLocalStorage<number>(
    `projectMaterialBreakdownTab-${turbineId}`,
    ComponentType.Blades as number
  )

  const { turbineCircularityRate, getTurbineCircularityRateData } =
    useCircularityRateData()

  const texts = useTexts()

  useEffect(() => {
    projectType !== ProjectType.OffshoreFloating &&
      getTurbineCircularityRateData(turbineId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const partName = materialBreakdown.parts?.[selectedTab as number]
    onComponentSelected?.(getComponentType(partName))
  }, [onComponentSelected, selectedTab])

  const partName = materialBreakdown.parts?.[selectedTab as number]
  const chartData = useChartData(
    data,
    partName,
    materialBreakdown.turbineFields
  )

  const getActualFields = (data: any) => {
    const enabledFields: string[] = []
    for (const key in data)
      if (data.hasOwnProperty(key) && data?.[key] != null)
        enabledFields.push(key)

    return materialBreakdown.turbineFields.filter(i =>
      enabledFields.includes((i as any)?.name)
    )
  }

  const onPartClick = (part: TurbinePart) =>
    materialBreakdown.parts.forEach((p, i) => {
      if (p === part) setSelectedTab(i)
    })

  const isDataProvided = data !== null

  if (isLoading)
    return (
      <div>
        <SpinIndicator />
      </div>
    )

  return (
    <>
      {showOwnHeader && (
        <ORow className="border-top pt-2">
          <OCol md="5" className="font-weight-bold">
            <i className={[config.icons.materialBreakdown, 'mx-2'].join(' ')} />
            Material breakdown
          </OCol>
        </ORow>
      )}

      {isDataProvided ? (
        <>
          <ORow>
            <OCol md="5">
              <div className="text-center pt-3 pb-2">
                <TurbineSvg
                  animationDuration={animationDuration}
                  parts={[materialBreakdown.parts?.[selectedTab as number]]}
                  onClick={onPartClick}
                  onDoubleClick={onPartClick}
                />
              </div>
            </OCol>
            <OCol md="7" className="mt-1">
              {!!turbineCircularityRate && (
                <div className="w-100 mb-4 mt-1 justify-content-end align-items-center d-flex">
                  <span className="mr-2 align-items-center d-flex">
                    <TooltipHelpText
                      className="mr-1"
                      message={texts?.report.circularityFormula.hint}
                      placement="bottom"
                    />
                    Circularity rate:
                  </span>
                  <OBadge variant="success">
                    {formatPercentage(turbineCircularityRate)}
                  </OBadge>
                </div>
              )}
              <MaterialBreakdownChart
                animationDuration={animationDuration}
                chartData={chartData}
                height={500}
              />
            </OCol>
          </ORow>
          <ORow>
            <OCol md="12">
              <OTabs
                className="mx-2"
                selectedTab={selectedTab}
                onTabSelect={id => {
                  setSelectedTab(id)
                  setIsEditable?.(false)
                }}>
                {materialBreakdown.parts.map(part => (
                  <OTab key={part} title={capitalize(`${part}`)}>
                    <table className="sattibutegrid w-100 material-breakdown-values">
                      <tbody>
                        {getActualFields((data as any)?.[part] || []).map(f => (
                          <tr className="details-row-hover" key={f.heading}>
                            <th className="details-label">{f.heading}</th>
                            <td className="details-value avenir-demi">
                              {isEditable && f.name !== 'total' ? (
                                <Input
                                  className="material-breakdown-input"
                                  defaultValue={
                                    (data as any)?.[partName]?.[f.name]
                                  }
                                  propertyName={f.name}
                                  onBlur={(n, v) =>
                                    onUpdate?.(
                                      turbineId,
                                      partName,
                                      n,
                                      parseFloat(v)
                                    )
                                  }
                                />
                              ) : (
                                formatTonnes(
                                  (data as any)?.[partName]?.[f.name]
                                )
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </OTab>
                ))}
              </OTabs>
            </OCol>
          </ORow>
        </>
      ) : (
        <div className="mt-3">
          <MaterialBreakdownAlert />
        </div>
      )}
    </>
  )
}

export default React.memo(MaterialBreakdown)
