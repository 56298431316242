import React from 'react'
import IconComponent from '@/components/helpers/IconComponent'
import { Button } from '@mui/joy'

interface Props {
  isLocked?: boolean
  isLoading?: boolean
  onClick?: () => void
}

const LockButton = ({ onClick, isLocked, isLoading }: Props) => (
  <Button
    variant="plain"
    size="md"
    disabled={isLoading}
    startDecorator={
      <IconComponent
        iconClass={`fas ${isLocked ? 'fa-lock-open' : 'fa-lock'}`}
      />
    }
    onClick={onClick}>
    {isLocked ? 'Unlock' : 'Lock'}
  </Button>
)

export default React.memo(LockButton)
