import { useTexts } from '@/hooks'
import { ProjectType } from '@/interfaces'
import React from 'react'
import SimpleList from './SimpleList'
import { helper } from '@/utils'

interface Props {
  projectType?: ProjectType
  includeScrap?: boolean
}

const { currentYear } = helper
const ReportCostText = ({ projectType, includeScrap }: Props) => {
  const texts = useTexts()
  return (
    <div className="mt-3">
      {projectType === ProjectType.Onshore &&
        texts?.report.cost.Onshore.map((i: any, key: number) =>
          typeof i === 'string' ? (
            <p key={i}>{i}</p>
          ) : (
            <>
              <SimpleList
                key={key}
                items={i as string[]}
                className={includeScrap ? 'mb-0' : undefined}
              />
            </>
          )
        )}
      {projectType === ProjectType.Onshore && includeScrap && (
        <>
          <SimpleList
            key="onshoreScrap"
            items={[texts?.report.cost.OnshoreScrapConsideration]}
          />
        </>
      )}
      {projectType === ProjectType.Onshore && (
        <p>{texts?.report.cost.OnshoreScope}</p>
      )}

      {projectType === ProjectType.OffshoreBottomFixed &&
        texts?.report.cost.OffshoreBottomFixed.map((i: any, key: number) =>
          typeof i === 'string' ? (
            <p key={i}>
              {i.replace(
                texts?.report.costAssumptions.CurrentYear,
                currentYear.toString()
              )}
            </p>
          ) : (
            <SimpleList key={key} items={i as string[]} />
          )
        )}
      {projectType === ProjectType.OffshoreFloating &&
        texts?.report.cost.OffshoreFloating.map((i: any, key: number) =>
          typeof i === 'string' ? (
            <p key={i}>
              {i.replace(
                texts?.report.costAssumptions.CurrentYear,
                currentYear.toString()
              )}
            </p>
          ) : (
            <SimpleList key={key} items={i as string[]} />
          )
        )}
    </div>
  )
}

export default React.memo(ReportCostText)
