import { ProjectItemViewModel, ReportSectionCode } from '@/interfaces'
import { Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import ConditionalRender from './ConditionalRenderer'

interface Props {
  project?: ProjectItemViewModel
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const ReportIntroductionPage: React.FC<Props> = ({
  project,
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.Introduction]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])
  const getIntroVariant = (
    isCircularityIncluded: boolean,
    isDecommissioningIncluded: boolean
  ) => {
    if (isCircularityIncluded && isDecommissioningIncluded) {
      return texts?.report.intro.map((i: any) => (
        <ConditionalRender key={i} data={i} />
      ))
    }
    if (isCircularityIncluded) {
      return texts?.report.introWithoutDecommissioning.map((i: any) => (
        <ConditionalRender key={i} data={i} />
      ))
    }
    if (isDecommissioningIncluded) {
      return texts?.report.introWithoutCircularity.map((i: any) => (
        <ConditionalRender key={i} data={i} />
      ))
    } else {
      return texts?.report.introWithoutCircularityAndDecommissiong.map(
        (i: any) => <ConditionalRender key={i} data={i} />
      )
    }
  }

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="introduction" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Introduction) + 1}
        . Introduction
      </Text>
      <Text style={styles.paragraph}>
        “{project?.companyName}” (the “Customer”) has used DNV's ReWind software
        to generate this decommissioning cost
        {printSections.includes(ReportSectionCode.Circularity)
          ? ' and circularity report'
          : ''}
        . The Customer has used ReWind model the material breakdown
        {printSections.includes(ReportSectionCode.Circularity)
          ? ', circularity '
          : ' '}
        and decommissioning costs the “{project?.name}” Wind Farm (the
        “Project”).
      </Text>
      <Text style={{ ...styles.subTitle, marginTop: '7px' }}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Introduction) + 1}
        .1 Objective
      </Text>
      {getIntroVariant(
        printSections.includes(ReportSectionCode.Circularity),
        printSections.includes(ReportSectionCode.DecommissioningCosts)
      )}
    </View>
  )
}
export default ReportIntroductionPage
