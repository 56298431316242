import { useEffect, useState } from 'react'
import { SettingsTabIndex } from '@/interfaces'
import { useNavigate } from 'react-router-dom'

const globalPath = 'default'
const countriesPath = 'countries'
const projectsPath = 'projects'

export default function useSettingsPage(
  basePath?: string,
  url?: string,
  getDefault?: Function,
  getCountries?: Function,
  getProjects?: Function
) {
  const navigate = useNavigate()
  const [selectedTab, setSelectedTab] = useState<SettingsTabIndex>(
    SettingsTabIndex.Default
  )

  const onTabSelect = (id: SettingsTabIndex) => {
    if (id === SettingsTabIndex.Default) navigate(`${url}/${globalPath}`)
    if (id === SettingsTabIndex.Countries) navigate(`${url}/${countriesPath}`)
    if (id === SettingsTabIndex.Projects) navigate(`${url}/${projectsPath}`)
  }

  useEffect(() => {
    if (
      basePath !== globalPath &&
      basePath !== countriesPath &&
      basePath !== projectsPath
    )
      return onTabSelect(SettingsTabIndex.Default)

    if (basePath === globalPath) {
      setSelectedTab(SettingsTabIndex.Default)
      getDefault?.()
    }

    if (basePath === countriesPath) {
      setSelectedTab(SettingsTabIndex.Countries)
      getCountries?.()
    }

    if (basePath === projectsPath) {
      setSelectedTab(SettingsTabIndex.Projects)
      getProjects?.()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [basePath])

  return { selectedTab, onTabSelect }
}
