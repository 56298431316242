import React from 'react'
import { UserIdentity } from '@/interfaces'
import { helper } from '@/utils'
const { toLocaleDate } = helper

interface Props {
  createdBy?: UserIdentity | null
  updatedBy?: UserIdentity | null
  createdAt: any | Date
  updatedAt: any | Date
}

const ModificationInfo = ({
  createdBy,
  updatedBy,
  createdAt,
  updatedAt
}: Props) => {
  if (!createdBy && !updatedBy) {
    return <></>
  }
  return (
    <div
      className="w-100"
      style={{ fontSize: '13px', paddingLeft: '20px', textAlign: 'right' }}>
      <div>
        <span>Created:&nbsp;</span>
        <span>{toLocaleDate(createdAt)}&nbsp;by&nbsp;</span>
        <span>{createdBy?.fullName}</span>
      </div>
      <div>
        <span>Updated:</span>
        <span>{toLocaleDate(updatedAt)}&nbsp;by&nbsp;</span>
        <span>{updatedBy?.fullName}</span>
      </div>
    </div>
  )
}

export default React.memo(ModificationInfo)
