import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { api, helper } from '@/utils'
import {
  getCostCalculationForScenario,
  actions
} from '@/store/slices/calculations'
import {
  OffshoreFixedCostCalculationViewModel,
  OffshoreFloatingCostCalculationViewModel,
  OnshoreCostCalculationViewModel,
  ProjectType
} from '@/interfaces'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'

const { genericErrorHandler, isValidGuid } = helper

const useScenarioCostCalculation = () => {
  const {
    isLoading,
    costCalculationForScenario,
    onshoreCalculation,
    offshoreFixedCalculation,
    offshoreFloatingCalculation
  } = useAppSelector(s => s.calculations)

  const dispatch = useAppDispatch()

  const get = (scenarioId: string, projectType: ProjectType) => {
    isValidGuid(scenarioId) &&
      dispatch(getCostCalculationForScenario(scenarioId, projectType))
  }

  const refreshState = (
    t: ProjectType,
    payload:
      | OnshoreCostCalculationViewModel
      | OffshoreFixedCostCalculationViewModel
      | OffshoreFloatingCostCalculationViewModel
  ) => {
    if (t === ProjectType.Onshore)
      dispatch(
        actions.setOnshoreCalculation(
          payload as OnshoreCostCalculationViewModel
        )
      )

    if (t === ProjectType.OffshoreBottomFixed)
      dispatch(
        actions.setOffshoreFixedCalculation(
          payload as OffshoreFixedCostCalculationViewModel
        )
      )

    if (t === ProjectType.OffshoreFloating)
      dispatch(
        actions.setOffshoreFloatingCalculation(
          payload as OffshoreFloatingCostCalculationViewModel
        )
      )
  }

  const onDeleteConfirmed = (
    scenarioId: string,
    scenarioName: string,
    projectType: ProjectType,
    onSuccess: Function
  ) => {
    api.costCalculations
      .delete(scenarioId, projectType)
      .then(() => {
        showSuccess(scenarioName, 'Successfully deleted calculation results')
        onSuccess?.()
        get(scenarioId, projectType)
      })
      .catch(genericErrorHandler)
  }

  return {
    isLoading,
    costCalculationForScenario,
    onshoreCalculation,
    offshoreFixedCalculation,
    offshoreFloatingCalculation,
    get,
    refreshState,
    onDeleteConfirmed
  }
}

export default useScenarioCostCalculation
