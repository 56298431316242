import React from 'react'

interface Props {
  className?: string
  title?: string
  icon?: string
}

const ContentSectionTitle = ({ className, title, icon }: Props) => (
  <span className={`font-weight-bold ${className ? className : ''}`}>
    {!!icon && <i className={`${icon} ml-1 mr-3`} />}
    {title}
  </span>
)

export default React.memo(ContentSectionTitle)
