import React, { useState, useEffect } from 'react'
import ProvidersProjectLines from './ProvidersProjectLines'
import { MapBoxWrapper } from '../maps'
import {
  CoordinatesViewModel,
  ProjectItemViewModel,
  ProviderItemViewModel
} from '@/interfaces'
import { useBounds } from '@/hooks'

interface ProjectMapProps {
  selectedProject?: ProjectItemViewModel | null
  metalProcessingProvider?: ProviderItemViewModel | null
  bladeProcessingProvider?: ProviderItemViewModel | null
  concreteProcessingProvider?: ProviderItemViewModel | null
}

const ProjectProvidersMap: React.FC<ProjectMapProps> = ({
  selectedProject,
  metalProcessingProvider,
  bladeProcessingProvider,
  concreteProcessingProvider
}) => {
  const [coordinates, setCoordinates] = useState<CoordinatesViewModel[]>([])

  useEffect(() => {
    const output = []
    if (selectedProject?.location) output.push(selectedProject.location)
    if (metalProcessingProvider?.location)
      output.push(metalProcessingProvider.location)
    if (bladeProcessingProvider?.location)
      output.push(bladeProcessingProvider.location)
    if (concreteProcessingProvider?.location)
      output.push(concreteProcessingProvider?.location)
    setCoordinates(output)
  }, [
    selectedProject,
    metalProcessingProvider,
    bladeProcessingProvider,
    concreteProcessingProvider
  ])

  const bounds = useBounds(coordinates)

  if (!selectedProject || !selectedProject?.location?.latitude) return null

  return (
    <MapBoxWrapper
      id="supplyChainMapSrc"
      latitude={selectedProject?.location?.latitude}
      longitude={selectedProject?.location?.longitude}
      zoom={5}
      height={500}
      bounds={bounds}>
      <ProvidersProjectLines
        selectedProject={selectedProject}
        metalProcessingProvider={metalProcessingProvider}
        bladeProcessingProvider={bladeProcessingProvider}
        concreteProcessingProvider={concreteProcessingProvider}
      />
    </MapBoxWrapper>
  )
}

export default React.memo(ProjectProvidersMap)
