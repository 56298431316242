import React from 'react'

import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'

import { AppUser } from '@/interfaces'
import { helper } from '@/utils'

interface Props {
  user?: AppUser | null
  className?: string
}

const UserRoleIndicator = ({ user, className }: Props) => {
  const { isSuperAdministrator, isInternal } = helper

  if (isSuperAdministrator(user as AppUser))
    return (
      <OTooltip content="Super Administrator">
        <div className={`nav-item ${className}`}>
          <i className="fad fa-user-astronaut fg-dark-blue" />
        </div>
      </OTooltip>
    )

  return isInternal(user as AppUser) ? (
    <OTooltip content="DNV Internal User">
      <div className={`nav-item ${className}`}>
        <i className="fad fa-user-tie fg-dark-blue" />
      </div>
    </OTooltip>
  ) : null
}

export default React.memo(UserRoleIndicator)
