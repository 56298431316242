type LocalStorageSetOptions<T> = {
  serializer?: (value: T) => string
}

type LocalStorageGetOptions<T> = {
  deserializer?: (value: string) => T
}

function set<T>(
  key: string,
  value: T | undefined,
  options: LocalStorageSetOptions<T> = {}
) {
  const { serializer = JSON.stringify } = options

  try {
    window.localStorage.setItem(key, serializer(value))
  } catch (error) {
    console.error(error)
  }
}

function get<T>(
  key: string,
  options: LocalStorageGetOptions<T> = {}
): T | undefined {
  const { deserializer = JSON.parse } = options

  try {
    const item = window.localStorage.getItem(key)

    if (item !== null) return deserializer(item)

    return undefined
  } catch {
    return undefined
  }
}

function remove<T>(key: string): T | undefined {
  try {
    window.localStorage.removeItem(key)
    return undefined
  } catch {
    return undefined
  }
}

function removeMultiple(keys: string[]) {
  keys.forEach(key => {
    if (typeof key === 'string') remove(key)
  })
}

const localStorage = {
  set,
  get,
  remove,
  removeMultiple
}

export default localStorage
