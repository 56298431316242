import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkDispatch
} from '@reduxjs/toolkit'
import { api, helper } from '@/utils'
import { AppUser } from '@/interfaces'
import { ActionCreator, ApplicationState } from '../index'

export interface UsersState {
  isLoadingCurrentUser: boolean
  currentUser: AppUser | null
  isInternalUser: boolean
  isAuthSessionExpired: boolean
  notificationsCount: number
}

const { isInternal } = helper

const initialState: UsersState = {
  isLoadingCurrentUser: true,
  currentUser: null,
  isInternalUser: false,
  isAuthSessionExpired: false,
  notificationsCount: 0
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsLoadingCurrentUser: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCurrentUser = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<AppUser | null>) => {
      state.currentUser = action.payload
      state.isInternalUser = isInternal(action.payload)
    },
    setAuthSessionExpired: state => {
      state.isAuthSessionExpired = true
      state.currentUser = null
    },
    setNotificationsCount: (state, action: PayloadAction<number>) => {
      state.notificationsCount = action.payload
    }
  }
})

export const getErrorHandler =
  (dispatch: ThunkDispatch<ApplicationState, null, AnyAction>) =>
  (error?: any) => {
    if (error?.response?.status === 401)
      dispatch(usersSlice.actions.setAuthSessionExpired())
    else helper.handleErrorMessage(error)
  }

export const { actions } = usersSlice

export const getCurrentUser: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoadingCurrentUser(true))
  return api.users
    .fetchCurrentUser()
    .then(user => {
      if (user?.id) dispatch(actions.setCurrentUser(user))
    })
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoadingCurrentUser(false)))
}

export const checkNotifications: ActionCreator = () => (dispatch, getState) => {
  if (!getState().users.isAuthSessionExpired)
    api.users
      .fetchNotificationsCount()
      .then(data => dispatch(actions.setNotificationsCount(!!data ? data : 0)))
      .catch(getErrorHandler(dispatch))
}
