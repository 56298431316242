import React from 'react'
import {
  OHeader,
  OHeaderDropdownSeparator,
  OHeaderDropdownLink,
  OHeaderUser,
  OHeaderNotifications,
  OButton,
  OLogo,
  HeaderRoute
} from '@dnvgl-onefoundation/onedesign-react'
import { AppUser, SubscriptionTier } from '@/interfaces'
import { UserRoleIndicator } from '@/components/helpers'
import { PrintHeader } from '@/components/print'
import { helper } from '@/utils'
import { Link } from 'react-router-dom'
import * as Constants from '@/components/helpers/Constants'

interface Props {
  routes: HeaderRoute[]
  appName: string
  logInLink: string
  logOutLink: string
  notificationsLink: string
  notificationsCount: number
  signUpLink: string
  isLoadingCurrentUser: boolean
  isHomePage: boolean
  currentUser: AppUser | null
}

const { toLocaleDate, isDnvUser } = helper

const SiteHeader = ({
  routes,
  appName,
  logInLink,
  logOutLink,
  signUpLink,
  notificationsLink,
  notificationsCount,
  isLoadingCurrentUser,
  isHomePage,
  currentUser: user
}: Props) => (
  <div className="w-100">
    <PrintHeader />
    <OHeader className="no-print" compact container={false} routes={routes}>
      {isHomePage && !user ? null : (
        <div className="o-header-right" id="top-of-the-page">
          <span className="o-header-product border-0 position-relative">
            {appName}
          </span>
          <ul
            className="o-header-actions"
            data-testid={Constants.userHeaderActions}>
            {!!user ? (
              <>
                <OHeaderNotifications
                  path={notificationsLink}
                  count={notificationsCount}
                  target="_blank"
                />
                {isDnvUser(user) && (
                  <UserRoleIndicator user={user} className="p-2" />
                )}
                <OHeaderUser
                  userFirstName={user?.firstName}
                  userLastName={user?.lastName}>
                  <div className="o-header-dropdown-menu-item">
                    <small
                      id="userEmail"
                      data-testid="user-dropdown-menu-email">
                      {user?.email}
                    </small>
                  </div>
                  {isDnvUser(user) ? (
                    <OLogo className="p-3" />
                  ) : (
                    <div className="o-header-dropdown-menu-item">
                      <span>
                        {user?.groups
                          ?.map(group => group.name)
                          ?.filter(i => i !== 'Default')
                          ?.join(', ')}
                      </span>
                    </div>
                  )}
                  {user?.subscription?.tier === SubscriptionTier.Trial &&
                    user?.subscription?.expirationDate && (
                      <div className="o-header-dropdown-menu-item">
                        <span>
                          Subscription expiration date:{' '}
                          <span className="text-danger">
                            {toLocaleDate(
                              `${user.subscription.expirationDate}`
                            )}
                          </span>
                        </span>
                      </div>
                    )}
                  <li className="o-header-dropdown-menu-item">
                    <Link to="/account">
                      <>
                        <i className="fal fa-user mr-2" />
                        <span>My Account</span>
                      </>
                    </Link>
                  </li>
                  <OHeaderDropdownSeparator />
                  <OHeaderDropdownLink
                    text={
                      <>
                        <i className="fal fa-sign-out-alt mr-2" />
                        <span data-testid={Constants.signOutBtn}>Sign out</span>
                      </>
                    }
                    href={logOutLink}
                  />
                </OHeaderUser>
              </>
            ) : isLoadingCurrentUser ? (
              <section className="o-header-user">
                <p>&nbsp;</p>
              </section>
            ) : (
              <>
                <a href={signUpLink}>
                  <OButton iconClass="fal fa-user-plus" variant="flat">
                    Sign up
                  </OButton>
                </a>
                <a
                  href={`${logInLink}?returnUrl=${encodeURIComponent(
                    window.location.pathname
                  )}`}>
                  <OButton iconClass="fal fa-sign-in-alt" variant="flat">
                    Sign in
                  </OButton>
                </a>
              </>
            )}
          </ul>
        </div>
      )}
    </OHeader>
  </div>
)

export default React.memo(SiteHeader)
