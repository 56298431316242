import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  getCountries as getCountriesAction,
  getDefaultConfiguration
} from '@/store/slices/configurations'
import { UnknownAction } from '@reduxjs/toolkit'

const useConfigurations = () => {
  const dispatch = useAppDispatch()
  const { countries, defaultConfiguration, isLoading } = useAppSelector(
    s => s.configurations
  )

  const getDefault = () =>
    dispatch(getDefaultConfiguration() as unknown as UnknownAction)
  const getCountries = () =>
    dispatch(getCountriesAction() as unknown as UnknownAction)

  return {
    isLoading,
    countries,
    defaultConfiguration,
    getDefault,
    getCountries
  }
}
export default useConfigurations
