import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { config, helper } from '@/utils'
import useItemId from './useItemId'
import { getConfiguration } from '@/store/slices/configurations'

const { isValidGuid } = helper

const useConfiguration = () => {
  const dispatch = useAppDispatch()
  const { itemConfiguation, isLoading } = useAppSelector(s => s.configurations)
  const countryId = useItemId(config.urls.settingsCountries)
  const projectId = useItemId(config.urls.settingsProjects)
  const scenarioId = useItemId(config.urls.settingsScenarios)

  useEffect(() => {
    if (isValidGuid(countryId)) dispatch(getConfiguration(countryId, 'country'))
  }, [countryId, dispatch])

  useEffect(() => {
    if (isValidGuid(projectId)) dispatch(getConfiguration(projectId, 'project'))
  }, [projectId, dispatch])

  useEffect(() => {
    if (isValidGuid(scenarioId))
      dispatch(getConfiguration(scenarioId, 'scenario'))
  }, [scenarioId, dispatch])

  return {
    isLoading,
    countryId,
    projectId,
    scenarioId,
    itemConfiguation
  }
}
export default useConfiguration
