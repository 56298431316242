import {
  OContainer,
  ORow,
  OCol,
  OFooter
} from '@dnvgl-onefoundation/onedesign-react'
import Annotation from 'chartjs-plugin-annotation'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from 'chart.js'
import { SiteHeader, Feedback } from '@/components/layout'
import { HomePage } from '@/components/pages'
import { config } from '@/utils'
import { ValueLabelPlugin } from '@/utils/chartjs'
import { useApp } from '@/hooks'
import AppRoutes from '@/Routes'
import { hotjar } from 'react-hotjar'
import { useEffect } from 'react'
import * as amplitude from '@amplitude/analytics-browser'
import * as process from 'process'
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser'

const { routes, appFullName, links, authCheckInterval, amplitudeApiKey } =
  config

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Annotation,
  ValueLabelPlugin
)

const App = () => {
  const {
    appRoutes,
    isHomePage,
    currentUser,
    isAuthenticated,
    isLoadingCurrentUser,
    notificationsCount
  } = useApp(routes, authCheckInterval)

  useEffect(() => {
    if (
      isAuthenticated &&
      process.env.NODE_ENV === 'production' &&
      currentUser
    ) {
      const sessionReplayTracking = sessionReplayPlugin()
      amplitude.add(sessionReplayTracking)
      hotjar.initialize({ id: 3522775, sv: 6 })
      amplitude.init(amplitudeApiKey, {
        autocapture: { elementInteractions: true },
        serverZone: 'EU',
        userId: currentUser?.id
      })
      const identifyEvent = new amplitude.Identify()
      identifyEvent.set(
        'company',
        currentUser?.groups
          ?.filter(group => group.name !== 'Default')
          .map(group => group.name)
          .join(', ') ?? ''
      )
      identifyEvent.set('firstName', currentUser?.firstName)
      identifyEvent.set('companyId', currentUser?.groups?.[0]?.companyId ?? '')

      amplitude.identify(identifyEvent)
    }
  }, [isAuthenticated, currentUser])

  useEffect(() => {
    if (isAuthenticated && hotjar.initialized()) {
      hotjar.identify(`${currentUser?.id}`, {
        userEmail: currentUser?.email,
        name: currentUser?.fullName
      })
    }
  }, [isAuthenticated, currentUser])

  return (
    <>
      <SiteHeader
        routes={appRoutes}
        appName={appFullName}
        logInLink={links.header.logIn}
        logOutLink={links.header.logOut}
        signUpLink={links.header.signUp}
        notificationsLink={links.header.notifications}
        notificationsCount={notificationsCount}
        isLoadingCurrentUser={isLoadingCurrentUser}
        isHomePage={isHomePage}
        currentUser={currentUser}
      />
      {isHomePage ? (
        <HomePage />
      ) : (
        <OContainer main>
          <ORow>
            <OCol col="12" className="mt-2">
              <AppRoutes currentUser={currentUser} />
            </OCol>
          </ORow>
        </OContainer>
      )}
      <OFooter
        small={!isHomePage}
        links={links.footer.big}
        smallLinks={links.footer.big.flatMap(x => x.links)}
        socialMediaLinks={isHomePage ? undefined : []}
        newTab
        className="no-print mt-4"
      />
      {isAuthenticated && <Feedback />}
    </>
  )
}

export { App }
