import React from 'react'
import { ProcessedMaterial, ReportSectionCode } from '@/interfaces'
import { Text, View } from '@react-pdf/renderer'
import Table from './Table'
import { helper, providers } from '@/utils'
import { ProviderInfo } from '@/store/slices/scenarioProviders'

interface Props {
  metalProcessingProvider?: ProviderInfo | null
  bladeProcessingProvider?: ProviderInfo | null
  concreteProcessingProvider?: ProviderInfo | null
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const getTitleLeft = (providerInfo: ProviderInfo | null) => {
  switch (providerInfo?.material) {
    case ProcessedMaterial.Metal:
      return 'Metal processing'
    case ProcessedMaterial.Blade:
      return 'Blade processing'
    case ProcessedMaterial.Concrete:
      return 'Concrete processing'
  }
}

const getTitleRight = (providerInfo: ProviderInfo | null) => {
  if (!providerInfo || providerInfo?.isLandfill) {
    return ''
  }

  if (providerInfo?.provider) {
    return providerInfo.provider.name
  }

  return 'Input from Customer'
}

const getTableRows = (info: ProviderInfo | null) => {
  if (info?.isLandfill) {
    return [
      {
        text: 'Landfill at nearest available facility',
        value: 'header'
      }
    ]
  }
  if (info?.provider) {
    return buildProviderData(info?.provider, true)
  }
  //
  if ((info?.distance ?? -1) >= 0) {
    return [
      {
        text: 'Location and contact details',
        value: 'header'
      },
      {
        text: 'Distance to the facility',
        value: `${info?.distance} km`
      }
    ]
  }
  return []
}

const ProviderInfoPdf = ({ info }: { info: ProviderInfo | null }) => {
  const rows = getTableRows(info)
  return (
    <Table
      name={getTitleLeft(info)}
      description={getTitleRight(info)}
      rows={convertOptionsToTableRows(rows as any)}
      breakPage={false}
    />
  )
}
const { convertOptionsToTableRows } = helper
const { buildProviderData } = providers

const ReportMaterialProcessingPage: React.FC<Props> = ({
  metalProcessingProvider,
  bladeProcessingProvider,
  concreteProcessingProvider,
  styles,
  texts,
  printSections
}) => {
  return (
    <View>
      <View />
      <Text id="materialprocessing" style={styles.subTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Circularity) + 1}
        .2. Material processing
      </Text>
      <Text style={styles.paragraph}>
        {texts?.report.materialProcessing.intro}
      </Text>

      {!!metalProcessingProvider && (
        <ProviderInfoPdf info={metalProcessingProvider} />
      )}
      {!!bladeProcessingProvider && (
        <ProviderInfoPdf info={bladeProcessingProvider} />
      )}
      {!!concreteProcessingProvider && (
        <ProviderInfoPdf info={concreteProcessingProvider} />
      )}
    </View>
  )
}

export default ReportMaterialProcessingPage
