import { useEffect } from 'react'
import { O404 } from '@dnvgl-onefoundation/onedesign-react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { Page, ProtectedContent } from '@/components/layout'
import AdminContainer from './components/admin/users/AdminContainer'
import {
  AboutPage,
  AdminPage,
  PrivacyPage,
  TouPage,
  ContactSupportPage,
  MyAccountPage,
  CookiesPolicyPage,
  ProvidersPage,
  ErrorsPage,
  ProviderPage,
  ProjectsPage,
  ProjectPage,
  ReportPage,
  LicensesPage,
  TurbinesPage,
  TurbinesImportPage,
  TurbinePage,
  ScenarioPage,
  ScenariosPage,
  ProvidersImportPage,
  SettingsPage,
  SettingsItemPage,
  HowItWorksPage,
  ScenarioSettingsPage
} from '@/components/pages'
import { config, helper } from '@/utils'
import { AppUser, UserRoleType } from '@/interfaces'

interface Props {
  currentUser: AppUser | null
}

const { urls } = config
const { setActiveNavItem } = helper
const superAdminRole = [UserRoleType.SuperAdministrator]

const regularPages = [
  { path: urls.about, component: <AboutPage /> },
  { path: 'errors', component: <ErrorsPage /> },
  { path: 'error', component: <ErrorsPage /> },
  {
    path: 'privacy',
    component: <PrivacyPage />
  },
  {
    path: 'tou',
    component: <TouPage />
  },
  {
    path: 'cookies',
    component: <CookiesPolicyPage />
  },
  {
    path: 'licenses',
    component: <LicensesPage />
  }
]
const protectedPages = [
  { path: urls.howItWorks, component: <HowItWorksPage /> },
  {
    path: urls.projects,
    component: <ProjectsPage />
  },
  {
    path: `${urls.projects}/:id`,
    component: <ProjectPage />
  },
  {
    path: urls.turbines,
    component: <TurbinesPage />
  },
  {
    path: `${urls.turbines}/:id`,
    component: <TurbinePage />
  },
  {
    path: 'support',
    component: <ContactSupportPage />
  },
  {
    path: 'account',
    component: <MyAccountPage />
  },
  {
    path: urls.providers,
    component: <ProvidersPage />
  },
  {
    path: `${urls.providers}/:id`,
    component: <ProviderPage />
  },
  {
    path: urls.scenarios,
    component: <ScenariosPage />
  }
]

const handleRouteChange = (newLocation: any) => {
  const items = newLocation.pathname.split('/').filter((i: string) => i)
  let path = items[0]
  setTimeout(() => setActiveNavItem(`/${path || ''}`), 200)
}

const AppRoutes = ({ currentUser }: Props) => {
  const location = useLocation()

  useEffect(() => {
    handleRouteChange(location)
  }, [location])

  return (
    <Routes>
      {regularPages.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
      {protectedPages.map(({ path, component }) => (
        <Route
          key={path}
          path={path}
          element={<ProtectedContent>{component}</ProtectedContent>}
        />
      ))}
      <Route
        path={`${urls.scenarios}/:id`}
        element={
          <ProtectedContent>
            <ScenarioPage currentUser={currentUser} />
          </ProtectedContent>
        }
      />
      <Route
        path={urls.admin}
        element={
          <ProtectedContent roles={superAdminRole}>
            <AdminPage />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.admin}/:section/*`}
        element={
          <ProtectedContent roles={superAdminRole}>
            <AdminContainer currentUser={currentUser!} />
          </ProtectedContent>
        }
      />
      <Route
        path={urls.adminTurbinesImport}
        element={
          <ProtectedContent roles={superAdminRole}>
            <TurbinesImportPage />
          </ProtectedContent>
        }
      />
      <Route
        path={urls.adminProvidersImport}
        element={
          <ProtectedContent roles={superAdminRole}>
            <ProvidersImportPage />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.settings}/*`}
        element={
          <ProtectedContent>
            <SettingsPage />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.settingsCountries}/:id`}
        element={
          <ProtectedContent>
            <SettingsItemPage type="country" />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.settingsProjects}/:id`}
        element={
          <ProtectedContent>
            <ScenarioSettingsPage type="project" />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.settingsScenarios}/:id`}
        element={
          <ProtectedContent>
            <ScenarioSettingsPage type="scenario" />
          </ProtectedContent>
        }
      />
      <Route
        path={`${urls.scenarios}/:id${urls.reports}`}
        element={
          <ProtectedContent>
            <ReportPage />
          </ProtectedContent>
        }
      />
      <Route
        path="*"
        element={
          <Page>
            <div style={{ height: '30rem' }}>
              <O404 />
            </div>
          </Page>
        }
      />
    </Routes>
  )
}

export default AppRoutes
