import { Option } from '@dnvgl-onefoundation/onedesign-react'
import { helper } from '@/utils'
import useTurbineOptions from './useTurbineOptions'
import {
  TurbineItemViewModel,
  MaterialsBreakdownsViewModel
} from '@/interfaces'

const { formatTonnes } = helper

const useReportTurbineDetails = (
  turbineItem: TurbineItemViewModel | null,
  turbineMaterialBreakdown: MaterialsBreakdownsViewModel | null
) => {
  const { windClass, bladeSparMaterial, generatorType } = useTurbineOptions()

  const turbineDetails: any = [
    {
      text: 'Nominal power',
      value: turbineItem?.ratedPower ? `${turbineItem?.ratedPower} MW` : ''
    },
    {
      text: 'Wind class (IEC)',
      value: windClass.find?.((i: Option) => i.value === turbineItem?.windClass)
        ?.text
    },
    {
      text: 'Rotor diameter',
      value: turbineItem?.rotorDiameter ? `${turbineItem.rotorDiameter} m` : ''
    },
    {
      text: 'Hub height',
      value: turbineItem?.hubHeight ? `${turbineItem.hubHeight} m` : ''
    },
    {
      text: 'Generator type',
      value: generatorType.find?.(
        (i: Option) => i.value === turbineItem?.generatorType
      )?.text
    },
    {
      text: 'Blade spar material',
      value: bladeSparMaterial.find?.(
        (i: Option) => i.value === turbineItem?.bladeSparMaterial
      )?.text
    },
    {
      text: 'Tower',
      value: 'header'
    },
    {
      text: 'Steel',
      value: formatTonnes(turbineMaterialBreakdown?.tower?.steel)
    },
    {
      text: 'Component mass',
      value: formatTonnes(turbineMaterialBreakdown?.tower?.total)
    },
    {
      text: 'Blades',
      value: 'header'
    },
    {
      text: 'Glass fibre',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.glassFibre)
    },
    {
      text: 'Epoxy resin',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.epoxyResin)
    },
    {
      text: 'PUR/PVC foam',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.purpvcFoam)
    },
    {
      text: 'Balsa wood',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.balsaWood)
    },
    {
      text: 'Aluminium',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.aluminium)
    },
    {
      text: 'Steel',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.steel)
    },
    {
      text: 'Epoxy gelcoat',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.epoxyGelcoat)
    },
    {
      text: 'Component mass',
      value: formatTonnes(turbineMaterialBreakdown?.blades?.total)
    },
    {
      text: 'Hub',
      value: 'header'
    },
    {
      text: 'Cast iron',
      value: formatTonnes(turbineMaterialBreakdown?.hub?.castIron)
    },
    {
      text: 'Component mass',
      value: formatTonnes(turbineMaterialBreakdown?.hub?.total)
    },
    {
      text: 'Nacelle',
      value: 'header'
    },
    {
      text: 'Cast iron',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.castIron)
    },
    {
      text: 'Steel',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.steel)
    },
    {
      text: 'Copper',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.copper)
    },
    {
      text: 'Rare earth magnets',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.rareEarths)
    },
    {
      text: 'Aluminium',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.aluminium)
    },
    {
      text: 'Silicon',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.silicon)
    },
    {
      text: 'Other',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.other)
    },
    {
      text: 'Component mass',
      value: formatTonnes(turbineMaterialBreakdown?.nacelle?.total)
    },
    {
      text: 'Total',
      value: 'header'
    },
    {
      text: 'Total turbine',
      value: formatTonnes(turbineMaterialBreakdown?.total?.total)
    }
  ]

  return { turbineDetails }
}

export default useReportTurbineDetails
