import React, { useCallback, useState } from 'react'
import {
  OButton,
  OModal,
  OAlert,
  OField,
  FieldType,
  showSuccess,
  ORow,
  OCol,
  showError
} from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../../layout'
import {
  AppUser,
  CreateUserGroup,
  GroupType,
  UserGroupItem
} from '@/interfaces'
import { config, helper, api } from '@/utils'
import { addGroupConfirm } from '../../modals'
import { useDispatch } from 'react-redux'
import { getGroups } from '@/store/slices/userGroups'
import { useNavigate } from 'react-router-dom'
import { useItemId, useUserGroups } from '@/hooks'

interface Props {
  currentUser: AppUser
}

const { admin } = config
const { deepClone, handleErrorMessage, isCustomer, isNamePresent } = helper

const AddUserGroup = ({ currentUser }: Props) => {
  const navigate = useNavigate()
  const type = isCustomer(currentUser) ? GroupType.Team : GroupType.Company
  const routeGroupId = useItemId(config.urls.adminUserGroups)
  const { companies, getCompany } = useUserGroups()
  const initialValues: CreateUserGroup = {
    name: '',
    type: type,
    companyId:
      type === GroupType.Team ? getCompany(routeGroupId)?.id : undefined
  }
  const [isVisible, setIsVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [payload, setPayload] = useState<CreateUserGroup>(initialValues)

  const dispatch = useDispatch()

  const reloadGroups = useCallback(() => {
    dispatch(getGroups())
  }, [dispatch])

  const showModal = () => {
    setPayload(deepClone(initialValues))
    setIsSubmitting(false)
    setIsVisible(true)
  }
  const hideModal = () => setIsVisible(false)
  const handleOK = () => {
    if (isSubmitting) return undefined
    hideModal()

    if (isNamePresent(payload.name, companies))
      return showError(
        payload.name,
        `The company "${payload.name}" already exists!`
      )

    addGroupConfirm(payload.name).then(confirmed => {
      if (confirmed) {
        setIsSubmitting(true)
        api.groups
          .post(payload)
          .then(newCompanyId => {
            showSuccess('Company created', payload.name)
            if (!!newCompanyId) {
              navigate(`/${config.urls.adminUserGroups}/${newCompanyId}`)
              window.location.reload()
            } else reloadGroups()
          })
          .catch(handleErrorMessage)
          .finally(() => {
            setIsSubmitting(false)
            hideModal()
          })
      }
    })
  }

  const setPayloadProperty = (propertyName: string, value: string) => {
    const clonePayload = deepClone(payload)
    clonePayload[propertyName] = value
    setPayload(clonePayload)
  }

  const bodySlot = (
    <ORow className="text-left">
      <OCol col="12">
        <OField
          value={{ name: payload.name }}
          onChange={e => setPayloadProperty('name', e.value as string)}
          field={{
            name: 'name',
            heading: 'Name',
            type: FieldType.Input,
            maxLength: admin.maxCompanyNameLength,
            help: `Max length: ${admin.maxCompanyNameLength} characters`
          }}
        />
        {!payload?.name?.length && (
          <OAlert
            variant="danger"
            description="Name is required"
            dismissable={false}
          />
        )}
        {type === GroupType.Team && companies?.length > 1 && (
          <>
            <OField
              value={{ companyId: payload.companyId }}
              onChange={e => setPayloadProperty('companyId', e.value as string)}
              field={{
                name: 'companyId',
                heading: 'Company',
                type: FieldType.Select,
                options: companies?.map((x: UserGroupItem) => ({
                  value: x.id,
                  text: x.name
                }))
              }}
            />
            {!payload?.companyId && (
              <OAlert
                variant="danger"
                description="Company is required"
                dismissable={false}
              />
            )}
          </>
        )}
      </OCol>
    </ORow>
  )

  return (
    <>
      <OButton iconClass="fas fa-building" onClick={showModal}>
        Create company
      </OButton>
      <OModal
        visible={isVisible}
        hideCloseButton
        okText="Create"
        okDisabled={!payload.name?.length}
        clickOutside={false}
        titleText="Create company"
        bodySlot={
          isSubmitting ? (
            <div className="text-center mt-5">
              <SpinIndicator />
            </div>
          ) : (
            bodySlot
          )
        }
        onOk={handleOK}
        onCancel={hideModal}
      />
    </>
  )
}

export default React.memo(AddUserGroup)
