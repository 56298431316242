import React from 'react'
import { config } from '@/utils'
import Input from './Input'
import { OnboardingRole, SaveOnboardingModel } from '@/interfaces'

interface Props {
  className?: string
  payload: SaveOnboardingModel
  setRole: (value: OnboardingRole) => void
  toggleFlag: (value: keyof SaveOnboardingModel) => void
  setAnotherGoal: (value: string) => void
}

const { onboarding } = config

const OnboardingForm = ({
  className,
  payload,
  setRole,
  toggleFlag,
  setAnotherGoal
}: Props) => {
  const handleToggle = (value: any) => {
    const key = value as keyof SaveOnboardingModel
    toggleFlag(key)
  }

  return (
    <div className={className} style={{ maxWidth: 500 }}>
      <h3>What best describes your role?</h3>
      <Input
        className="m-0"
        propertyName="tier"
        type="select"
        defaultValue={payload.role}
        options={onboarding.roleOptions}
        onChange={(_n, v) => setRole(parseInt(v) as unknown as OnboardingRole)}
      />
      <h3>What do you want to achieve with ReWind?</h3>
      {onboarding.goalOptions.map(({ text, value }) => (
        <div
          key={value}
          className="custom-control custom-checkbox pointer"
          onClick={() => handleToggle(value)}>
          <input
            autoComplete="off"
            className="custom-control-input"
            type="checkbox"
            checked={payload[value as keyof typeof payload] as boolean}
            onChange={() => handleToggle(value)}
          />
          <label className="custom-control-label">{text}</label>
        </div>
      ))}
      <Input
        value={payload.anotherGoal}
        onChange={(_n, v) => setAnotherGoal(v)}
        className="mt-2"
        isTextarea
        propertyName="anotherGoal"
        placeholder="Other. Please add a description."
      />
    </div>
  )
}

export default React.memo(OnboardingForm)
