import { Image, Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import ReportList from './ReportList'
import ConditionalRender from './ConditionalRenderer'
import { ReportSectionCode } from '@/interfaces'

interface Props {
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const ReportCircularityPage: React.FC<Props> = ({
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.Circularity]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="circularity" style={styles.subTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.Circularity) + 1}
        .1. Wind farm circularity
      </Text>
      {texts?.report.windFarmCircularity.map((i: any) => (
        <ConditionalRender key={i} data={i} />
      ))}
      <Image source="/images/circularity-formula.jpg" />
      <Text style={styles.paragraph}>Where:</Text>
      <ReportList items={texts?.report.circularityFormula.items} />
      <Text style={styles.paragraph}>
        {texts?.report.circularityFormula.bottom}
      </Text>
    </View>
  )
}

export default ReportCircularityPage
