import {
  Materials,
  SubstationComponentCircularityRateViewModel
} from '@/interfaces'
import React from 'react'
import { helper } from '@/utils'

type CircularityTableProps = {
  data?: SubstationComponentCircularityRateViewModel | null
}

const { formatTonnes, formatPercentage } = helper

const cellClass = ['p-2', 'border'].join(' ')

const CircularitySubstationDetailsTable: React.FC<CircularityTableProps> = ({
  data
}) => {
  return (
    <>
      <tr>
        <td className={cellClass}>{Materials[Materials.Steel]}</td>
        <td className={cellClass}>{formatTonnes(data?.steel)}</td>
        <td className={cellClass}>
          {formatPercentage((data?.steelRecoveryRate || 0) * 100)}
        </td>
        <td className={cellClass}>{formatTonnes(data?.steelRecycled)}</td>
      </tr>
      <tr>
        <td className={cellClass}>{Materials[Materials.Concrete]}</td>
        <td className={cellClass}>{formatTonnes(data?.concrete)}</td>
        <td className={cellClass}>
          {formatPercentage((data?.concreteRecoveryRate || 0) * 100)}
        </td>
        <td className={cellClass}>{formatTonnes(data?.concreteRecycled)}</td>
      </tr>
      <tr>
        <td className={cellClass}>{Materials[Materials.Copper]}</td>
        <td className={cellClass}>{formatTonnes(data?.copper)}</td>
        <td className={cellClass}>
          {formatPercentage((data?.copperRecoveryRate || 0) * 100)}
        </td>
        <td className={cellClass}>{formatTonnes(data?.copperRecycled)}</td>
      </tr>
      <tr>
        <td className={cellClass}>{Materials[Materials.Aluminium]}</td>
        <td className={cellClass}>{formatTonnes(data?.aluminium)}</td>
        <td className={cellClass}>
          {formatPercentage((data?.aluminiumRecoveryRate || 0) * 100)}
        </td>
        <td className={cellClass}>{formatTonnes(data?.aluminiumRecycled)}</td>
      </tr>
      <tr>
        <td className={cellClass}>{Materials[Materials.Other]}</td>
        <td className={cellClass}>{formatTonnes(data?.other)}</td>
        <td className={cellClass}>
          {formatPercentage((data?.otherRecoveryRate || 0) * 100)}
        </td>
        <td className={cellClass}>{formatTonnes(data?.otherRecycled)}</td>
      </tr>
    </>
  )
}

export default React.memo(CircularitySubstationDetailsTable)
