import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '@/utils'
import { getErrorHandler } from './users'
import { ActionCreator } from '../index'
import {
  CountryWithConfigurationsViewModel,
  CostCalculationConfigurationRegisterViewModel,
  ConfigurationType
} from '@/interfaces'

export interface ConfigurationsState {
  isLoading: boolean
  countries: CountryWithConfigurationsViewModel[]
  defaultConfiguration: CostCalculationConfigurationRegisterViewModel | null
  itemConfiguation: CostCalculationConfigurationRegisterViewModel | null
}

const initialState: ConfigurationsState = {
  isLoading: false,
  countries: [],
  defaultConfiguration: null,
  itemConfiguation: null
}

export const configurationsSlice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setCountries: (
      state,
      action: PayloadAction<CountryWithConfigurationsViewModel[]>
    ) => {
      state.countries = action.payload
    },
    setdefaultConfiguration: (
      state,
      action: PayloadAction<CostCalculationConfigurationRegisterViewModel | null>
    ) => {
      state.defaultConfiguration = action.payload
    },
    setItemConfiguration: (
      state,
      action: PayloadAction<CostCalculationConfigurationRegisterViewModel | null>
    ) => {
      state.itemConfiguation = action.payload
    }
  }
})

export const { actions } = configurationsSlice

const resetAll = (dispatch: Function) => {
  dispatch(actions.setdefaultConfiguration(null))
  dispatch(actions.setItemConfiguration(null))
}

export const getCountries: ActionCreator = () => dispatch =>
  api.configurations
    .readCountries()
    .then(i => dispatch(actions.setCountries(i || [])))
    .catch(getErrorHandler(dispatch))

export const getDefaultConfiguration: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoading(true))
  resetAll(dispatch)
  api.configurations
    .readGlobal()
    .then(i => dispatch(actions.setdefaultConfiguration(i)))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getConfiguration: any =
  (id: string, type: ConfigurationType) => (dispatch: any) => {
    dispatch(actions.setIsLoading(true))
    resetAll(dispatch)
    api.configurations
      .read(id, type)
      .then(i => dispatch(actions.setItemConfiguration(i)))
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }
