import { useEffect, useState } from 'react'
import { api } from '@/utils'
import {
  SaveOnboardingModel,
  OnboardingRole,
  OnboardingStep
} from '@/interfaces'

const defaultPayload: SaveOnboardingModel = {
  role: OnboardingRole.AssetManagement,
  understandMaterialBreakdown: true,
  understandDecommissioningCosts: true,
  estimateTurbineResidualValue: true,
  understandCircularity: true,
  discoverNewRecyclingMethodsAndFacilities: true,
  anotherGoal: ''
}

const useOnboardingForm = (
  step: OnboardingStep,
  isOnboardingSubmitted: boolean,
  onFormSubmitted?: () => void
) => {
  const [payload, setPayload] = useState<SaveOnboardingModel>(defaultPayload)

  const setRole = (role: OnboardingRole) => setPayload({ ...payload, role })

  const toggleFlag = (flag: keyof SaveOnboardingModel) =>
    setPayload({ ...payload, [flag]: !payload[flag] })

  const setAnotherGoal = (anotherGoal: string) =>
    setPayload({ ...payload, anotherGoal })

  const submit = () =>
    api.onboarding.submit(payload).then(() => onFormSubmitted?.())

  const checkIfSubmitted = () =>
    api.onboarding
      .status()
      .then(isSubmitted => isSubmitted && onFormSubmitted?.())

  useEffect(() => {
    if (step === 1) checkIfSubmitted()
    if (step === 2 && !isOnboardingSubmitted) submit()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  return {
    payload,
    setRole,
    toggleFlag,
    setAnotherGoal,
    submit
  }
}

export default useOnboardingForm
