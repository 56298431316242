import React from 'react'
import { SubstationWeightsViewModel } from '@/interfaces'

import { helper } from '@/utils'

const { formatPercentage, formatTonnes } = helper

interface Props {
  data?: SubstationWeightsViewModel | null
  substationType: string
}

const cellClass = 'border py-1 px-2'

const BoPSubstation: React.FC<Props> = ({ data, substationType }) => (
  <>
    <tr>
      <td rowSpan={6} className="p-3 border">
        {substationType} Substation
      </td>
      <td className={cellClass}>Steel</td>
      <td className={cellClass}>{formatTonnes(data?.steel)}</td>
      <td className={cellClass}>{formatPercentage(data?.steelPercentage)}</td>
    </tr>
    <tr>
      <td className={cellClass}>Concrete</td>
      <td className={cellClass}>{formatTonnes(data?.concrete)}</td>
      <td className={cellClass}>
        {formatPercentage(data?.concretePercentage)}
      </td>
    </tr>
    <tr>
      <td className={cellClass}>Copper</td>
      <td className={cellClass}>{formatTonnes(data?.copper)}</td>
      <td className={cellClass}>{formatPercentage(data?.copperPercentage)}</td>
    </tr>
    <tr>
      <td className={cellClass}>Aluminium</td>
      <td className={cellClass}>{formatTonnes(data?.aluminium)}</td>
      <td className={cellClass}>
        {formatPercentage(data?.aluminiumPercentage)}
      </td>
    </tr>
    <tr>
      <td className={cellClass}>Other</td>
      <td className={cellClass}>{formatTonnes(data?.other)}</td>
      <td className={cellClass}>{formatPercentage(data?.otherPercentage)}</td>
    </tr>
    <tr className="font-weight-bold bg-light">
      <td className={cellClass}>Total</td>
      <td className={cellClass}>{formatTonnes(data?.total)}</td>
      <td className={cellClass}>100%</td>
    </tr>
  </>
)

export default React.memo(BoPSubstation)
