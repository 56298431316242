import { useEffect } from 'react'
import { getCurrencies } from '@/store/slices/general'
import { useAppDispatch, useAppSelector } from '@/store/hooks'

const useCurrencies = () => {
  const dispatch = useAppDispatch()
  const state = useAppSelector(s => s)

  const { currencies } = state.general

  const get = () => dispatch(getCurrencies() as any)
  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { currencies }
}
export default useCurrencies
