import React from 'react'
import { View, Text, Svg, Circle, Path } from '@react-pdf/renderer'
import { TurbinePart } from '@/interfaces'

interface Props {
  parts?: TurbinePart[]
  blades?: string
  nacelle?: string
  hub?: string
  tower?: string
}

const ReportTurbineSvg = ({ blades, nacelle, hub, tower }: Props) => (
  <View style={{ margin: 30, marginLeft: 0 }}>
    <Svg width="540" height="282" viewBox="0 0 600 342">
      <Circle cx="117.564" cy="133" r="20" stroke="#666666" />
      <Path
        fill="#fff"
        d="M120.564 121V2.56155C120.564 1.26041 119.342 0.305694 118.079 0.621267L117.941 0.655928C117.116 0.862018 116.512 1.56651 116.434 2.41266L106.638 108.697C106.591 109.212 106.745 109.726 107.068 110.13L114.126 118.952C114.41 119.307 114.564 119.747 114.564 120.202V121C114.564 122.105 115.46 123 116.564 123H118.564C119.669 123 120.564 122.105 120.564 121Z"
        stroke="#666666"
      />
      <Path
        fill="#fff"
        d="M126.297 142.124L228.868 201.344C229.994 201.994 231.433 201.413 231.79 200.162L231.83 200.024C232.063 199.207 231.755 198.332 231.062 197.841L143.915 136.216C143.492 135.917 142.97 135.794 142.459 135.871L131.289 137.573C130.84 137.641 130.382 137.554 129.988 137.327L129.297 136.928C128.34 136.376 127.117 136.704 126.565 137.66L125.565 139.392C125.013 140.349 125.34 141.572 126.297 142.124Z"
        stroke="#666666"
      />
      <Path
        fill="#fff"
        d="M105.354 136L2.78282 195.219C1.656 195.87 1.44058 197.406 2.34502 198.342L2.44436 198.444C3.03502 199.055 3.9472 199.226 4.71898 198.871L101.662 154.212C102.132 153.995 102.499 153.605 102.688 153.123L106.799 142.6C106.964 142.177 107.269 141.823 107.662 141.596L108.354 141.196C109.31 140.644 109.638 139.421 109.086 138.464L108.086 136.732C107.533 135.776 106.31 135.448 105.354 136Z"
        stroke="#666666"
      />
      <Circle cx="117.564" cy="133" r="10" stroke="#666666" />
      <Path d="M111.564 153H123.564V158H111.564V153Z" stroke="#666666" />
      <Path
        d="M111.564 158H123.564L124.564 278H110.564L111.564 158Z"
        stroke="#666666"
      />
      <Path
        d="M110.564 278H124.564L125.532 337.968C125.55 339.085 124.649 340 123.532 340H111.597C110.48 340 109.579 339.085 109.597 337.968L110.564 278Z"
        stroke="#666666"
      />
      <>
        {/* Blades */}
        {blades !== undefined && (
          <>
            <Path
              transform="translate(-78 -40)"
              d="M196.333 54C196.333 55.4728 197.527 56.6667 199 56.6667C200.473 56.6667 201.667 55.4728 201.667 54C201.667 52.5272 200.473 51.3333 199 51.3333C197.527 51.3333 196.333 52.5272 196.333 54ZM535 54.5C535.276 54.5 535.5 54.2761 535.5 54C535.5 53.7239 535.276 53.5 535 53.5V54.5ZM199 54.5H535V53.5H199V54.5Z"
              fill="#0F204B"
            />
            <Text x="465" y="14" fill="#0F204B">
              Blades
            </Text>
            <Text x="465" y="33" fill="#0F204B" style={{ fontSize: 14 }}>
              {blades}
            </Text>
          </>
        )}
        {/* Nacelle */}
        {nacelle !== undefined && (
          <>
            <Path
              transform="translate(-78 -72)"
              d="M207.333 191C207.333 189.527 208.527 188.333 210 188.333C211.473 188.333 212.667 189.527 212.667 191C212.667 192.473 211.473 193.667 210 193.667C208.527 193.667 207.333 192.473 207.333 191ZM535 128.5C535.276 128.5 535.5 128.724 535.5 129C535.5 129.276 535.276 129.5 535 129.5V128.5ZM210 190.5H353.453V191.5H210V190.5ZM368.953 175V145H369.953V175H368.953ZM385.453 128.5H535V129.5H385.453V128.5ZM368.953 145C368.953 135.887 376.34 128.5 385.453 128.5V129.5C376.893 129.5 369.953 136.44 369.953 145H368.953ZM353.453 190.5C362.014 190.5 368.953 183.56 368.953 175H369.953C369.953 184.113 362.566 191.5 353.453 191.5V190.5Z"
              fill="#0F204B"
            />
            <Text x="465" y="58" fill="#0F204B">
              Nacelle
            </Text>
            <Text x="465" y="77" fill="#0F204B" style={{ fontSize: 14 }}>
              {nacelle}
            </Text>
          </>
        )}
        {/* Hub */}
        {hub !== undefined && (
          <>
            <Path
              transform="translate(-80 -88)"
              d="M207.333 224C207.333 225.473 208.527 226.667 210 226.667C211.473 226.667 212.667 225.473 212.667 224C212.667 222.527 211.473 221.333 210 221.333C208.527 221.333 207.333 222.527 207.333 224ZM535 224.5C535.276 224.5 535.5 224.276 535.5 224C535.5 223.724 535.276 223.5 535 223.5V224.5ZM210 224.5H535V223.5H210V224.5Z"
              fill="#0F204B"
            />
            <Text x="465" y="136" fill="#0F204B">
              Hub
            </Text>
            <Text x="465" y="155" fill="#0F204B" style={{ fontSize: 14 }}>
              {hub}
            </Text>
          </>
        )}
        {/* Tower */}
        {tower !== undefined && (
          <>
            <Path
              transform="translate(-80 -110)"
              d="M202.333 344C202.333 345.473 203.527 346.667 205 346.667C206.473 346.667 207.667 345.473 207.667 344C207.667 342.527 206.473 341.333 205 341.333C203.527 341.333 202.333 342.527 202.333 344ZM535 344.5C535.276 344.5 535.5 344.276 535.5 344C535.5 343.724 535.276 343.5 535 343.5L535 344.5ZM205 344.5L535 344.5L535 343.5L205 343.5L205 344.5Z"
              fill="#0F204B"
            />
            <Text x="465" y="234" fill="#0F204B">
              Tower
            </Text>
            <Text x="465" y="253" fill="#0F204B" style={{ fontSize: 14 }}>
              {tower}
            </Text>
          </>
        )}
      </>
    </Svg>
  </View>
)

export default React.memo(ReportTurbineSvg)
