import React from 'react'
import { FieldType, OTable } from '@dnvgl-onefoundation/onedesign-react'
import { Page, SpinIndicator } from '@/components/layout'
import { ContentSection } from '@/components/layout'
import { ContactSupportSnippet } from '@/components/helpers'
import { useLicenses } from '@/hooks'

const fields = [
  {
    name: 'name',
    heading: 'Name',
    sortable: true,
    type: FieldType.Input
  },
  {
    name: 'version',
    heading: 'Version'
  },
  {
    name: 'license',
    heading: 'License',
    sortable: true,
    type: FieldType.Input
  }
]

const LicensesPage = () => {
  const licenses = useLicenses()
  return (
    <Page title="Licenses">
      {!!licenses ? (
        <>
          <ContentSection>
            <OTable
              small
              fields={fields}
              allItems={licenses.allItems}
              showFilter={false}
              striped
              showOptionsButton={false}
              showActionIcons={false}
              showColumnsButton={false}
            />
            <ul className="mt-5">
              {licenses?.links?.map?.((link: any) => (
                <li key={link?.name}>
                  {link?.name}:&nbsp;
                  <a href={link?.url}>{link?.url}</a>
                </li>
              ))}
            </ul>
          </ContentSection>
          <ContactSupportSnippet />
        </>
      ) : (
        <SpinIndicator />
      )}
    </Page>
  )
}

export default React.memo(LicensesPage)
