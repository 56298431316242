import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionCreator } from '@/store'

import { api, helper } from '@/utils'
import { getErrorHandler } from './users'
import { CircularityRateViewModel, PlantWeightsViewModel } from '@/interfaces'

const { isValidGuid } = helper

export interface CircularitysState {
  isLoading: boolean
  scenarioCircularityRate: CircularityRateViewModel | null
  turbineCircularityRate: number | null
  turbineCircularityRateForScenario: number | null
  plantWeights: PlantWeightsViewModel | null
  plantWeightProjectId: string | null
}

const initialState: CircularitysState = {
  isLoading: false,
  scenarioCircularityRate: null,
  turbineCircularityRate: null,
  turbineCircularityRateForScenario: null,
  plantWeights: null,
  plantWeightProjectId: null
}

export const circularitySlice = createSlice({
  name: 'circularity',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setScenarioCircularityRate: (
      state,
      action: PayloadAction<CircularityRateViewModel | null>
    ) => {
      state.scenarioCircularityRate = action.payload
    },
    setTurbineCircularityRate: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.turbineCircularityRate = action.payload
    },
    setTurbineCircularityRateForScenario: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.turbineCircularityRateForScenario = action.payload
    },
    setPlantWeightsForProject: (
      state,
      action: PayloadAction<PlantWeightsViewModel | null>
    ) => {
      state.plantWeights = action.payload
    },
    setPlantWeightProjectId: (state, action: PayloadAction<string | null>) => {
      state.plantWeightProjectId = action.payload
    }
  }
})

export const { actions } = circularitySlice

export const resetScenarioCircularityRate: ActionCreator = () => dispatch => {
  dispatch(actions.setScenarioCircularityRate(null))
}

export const getScenarioCircularityRate: ActionCreator =
  (scenarioId: string) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading || !isValidGuid(scenarioId)) return undefined

    dispatch(resetScenarioCircularityRate())
    dispatch(actions.setIsLoading(true))

    return api.circularity
      .readScenarioCircularityRate(scenarioId)
      .then(data => {
        if (!!data) dispatch(actions.setScenarioCircularityRate(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

export const resetTurbineCircularityRate: ActionCreator = () => dispatch =>
  dispatch(actions.setTurbineCircularityRate(null))

export const getTurbineCircularityRate: ActionCreator =
  (turbineId: string) => dispatch => {
    if (!isValidGuid(turbineId)) return undefined

    return api.circularity
      .readTurbineRateForDetails(turbineId)
      .then(data => {
        if (typeof data === 'number')
          dispatch(actions.setTurbineCircularityRate(data))
      })
      .catch(getErrorHandler(dispatch))
  }

export const getTurbineCircularityRateForScenario: ActionCreator =
  (scenarioId: string) => dispatch => {
    if (!isValidGuid(scenarioId)) return undefined

    dispatch(actions.setIsLoading(true))
    return api.circularity
      .readTurbineRateForScenario(scenarioId)
      .then(data => {
        if (typeof data === 'number')
          dispatch(actions.setTurbineCircularityRateForScenario(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

export const getPlantWeightsForProject: ActionCreator =
  (projectId: string) => dispatch => {
    if (!isValidGuid(projectId)) return undefined

    dispatch(actions.setIsLoading(true))
    dispatch(actions.setPlantWeightsForProject(null))
    dispatch(actions.setPlantWeightProjectId(projectId))

    return api.circularity
      .readPlantWeightsForProject(projectId)
      .then(data => dispatch(actions.setPlantWeightsForProject(data)))
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }
