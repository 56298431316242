import { View } from '@react-pdf/renderer'
import React from 'react'
import Table from './Table'
import { config, helper } from '@/utils'
import {
  CircularityRateViewModel,
  Materials,
  ProjectItemViewModel,
  ProjectType
} from '@/interfaces'

interface Props {
  project?: ProjectItemViewModel
  scenarioCircularityRate?: CircularityRateViewModel | null
  styles?: any
  texts?: any
}

const { missingValueCharacter, showNewSubstations } = config
const {
  formatTonnes,
  formatPercentage,
  safeFormatPercentage,
  getAnchorMaterialName
} = helper

const buildRows = (
  project?: ProjectItemViewModel,
  data?: CircularityRateViewModel | null
) => {
  const rows: any[] = [
    [
      'Material',
      'Wind Farm material mass (t)',
      'Material recovery rate',
      'Recovered material mass (t)'
    ]
  ]

  // Wind Turbine
  rows.push(['Wind Turbine'])

  // Steel
  rows.push([
    'Steel',
    formatTonnes(data?.windfarmMaterialBreakdown?.steel),
    data?.windfarmMaterialBreakdown?.steelRecoveryRate
      ? safeFormatPercentage(data?.windfarmMaterialBreakdown?.steelRecoveryRate)
      : missingValueCharacter,
    formatTonnes(data?.windfarmMaterialBreakdown?.steelRecoveredMassT)
  ])

  // Cast Iron
  rows.push([
    'Cast Iron',
    formatTonnes(data?.windfarmMaterialBreakdown?.castIron),
    data?.windfarmMaterialBreakdown?.castIronRecoveryRate
      ? safeFormatPercentage(
          data?.windfarmMaterialBreakdown?.castIronRecoveryRate
        )
      : missingValueCharacter,
    formatTonnes(data?.windfarmMaterialBreakdown?.castIronRecoveredMassT)
  ])

  // Copper
  rows.push([
    'Copper',
    formatTonnes(data?.windfarmMaterialBreakdown?.copper),
    safeFormatPercentage(data?.windfarmMaterialBreakdown?.copperRecoveryRate),
    formatTonnes(data?.windfarmMaterialBreakdown?.copperRecoveredMassT)
  ])

  // Aluminium
  rows.push([
    'Aluminium',
    formatTonnes(data?.windfarmMaterialBreakdown?.aluminium),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.aluminiumRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.aluminiumRecoveredMassT)
  ])

  // Rare Earths
  rows.push([
    'Rare Earths',
    formatTonnes(data?.windfarmMaterialBreakdown?.rareEarths),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.rareEarthsRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.rareEarthsRecoveredMassT)
  ])

  // Glass Fibre
  rows.push([
    'Glass Fibre',
    formatTonnes(data?.windfarmMaterialBreakdown?.glassFibre),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.glassFibreRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.glassFibreRecoveredMassT)
  ])

  // Epoxy Resin
  rows.push([
    'Epoxy Resin',
    formatTonnes(data?.windfarmMaterialBreakdown?.epoxyResin),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.epoxyResinRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.epoxyResinRecoveredMassT)
  ])
  // PUR/PVC Foam
  rows.push([
    'PUR/PVC foam',
    formatTonnes(data?.windfarmMaterialBreakdown?.purpvcFoam),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.purpvcFoamRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.purpvcFoamRecoveredMassT)
  ])

  // Balsa Wood
  rows.push([
    'Balsa wood',
    formatTonnes(data?.windfarmMaterialBreakdown?.balsaWood),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.balsaWoodRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.balsaWoodRecoveredMassT)
  ])

  // Epoxy Gelcoat
  rows.push([
    'Epoxy gelcoat',
    formatTonnes(data?.windfarmMaterialBreakdown?.epoxyGelcoat),
    safeFormatPercentage(
      data?.windfarmMaterialBreakdown?.epoxyGelcoatRecoveryRate
    ),
    formatTonnes(data?.windfarmMaterialBreakdown?.epoxyGelcoatRecoveredMassT)
  ])

  // Silicon
  rows.push([
    'Silicon',
    formatTonnes(data?.windfarmMaterialBreakdown?.silicon),
    safeFormatPercentage(data?.windfarmMaterialBreakdown?.siliconRecoveryRate),
    formatTonnes(data?.windfarmMaterialBreakdown?.siliconRecoveredMassT)
  ])
  // Other
  rows.push([
    'Other',
    formatTonnes(data?.windfarmMaterialBreakdown?.other),
    safeFormatPercentage(data?.windfarmMaterialBreakdown?.otherRecoveryRate),
    formatTonnes(data?.windfarmMaterialBreakdown?.otherRecoveredMassT)
  ])

  // Total Turbine Recyclability
  rows.push([
    'Total Turbine Recyclability',
    formatPercentage(data?.windfarmMaterialBreakdown?.totalRecoveryRate)
  ])

  // Check if it's an Offshore Floating project
  if (project?.projectType === ProjectType.OffshoreFloating) {
    // Floater
    rows.push(['Floater'])

    // Steel
    rows.push([
      'Steel',
      formatTonnes(data?.floatersTableData?.windFarmMetalMassT),
      safeFormatPercentage(data?.floatersTableData?.metalRecoveryRate),
      formatTonnes(data?.floatersTableData?.recoveredMetalMassT)
    ])

    // Concrete
    rows.push([
      'Concrete',
      formatTonnes(data?.floatersTableData?.windFarmConcreteMassT),
      safeFormatPercentage(data?.floatersTableData?.concreteRecoveryRate),
      formatTonnes(data?.floatersTableData?.recoveredConcreteMassT)
    ])

    // Other
    rows.push([
      'Other',
      formatTonnes(data?.floatersTableData?.windFarmOtherMassT),
      safeFormatPercentage(data?.floatersTableData?.otherRecoveryRate),
      formatTonnes(data?.floatersTableData?.recoveredOtherMassT)
    ])

    // Total Floater Recyclability
    rows.push([
      'Total Floater Recyclability',
      formatPercentage(data?.floatersTableData?.totalRecoveryRate)
    ])

    // Mooring Lines
    rows.push(['Mooring Lines'])

    // Steel (Mooring Lines)
    rows.push([
      'Steel',
      formatTonnes(data?.mooringLinesTableData?.windFarmMetalMassT),
      safeFormatPercentage(data?.mooringLinesTableData?.metalRecoveryRate),
      formatTonnes(data?.mooringLinesTableData?.recoveredMetalMassT)
    ])

    // Other (Mooring Lines)
    rows.push([
      'Other',
      formatTonnes(data?.mooringLinesTableData?.windFarmOtherMassT),
      safeFormatPercentage(data?.mooringLinesTableData?.otherRecoveryRate),
      formatTonnes(data?.mooringLinesTableData?.recoveredOtherMassT)
    ])

    // Total Mooring Lines Recyclability
    rows.push([
      'Total Mooring Lines Recyclability',
      safeFormatPercentage(data?.mooringLinesTableData?.totalRecoveryRate)
    ])

    // Continue adding rows for Anchors...
    rows.push(['Anchors'])

    // Material (Anchors) - Replace 'Material' with actual material if available
    rows.push([
      getAnchorMaterialName(project?.anchor?.material),
      formatTonnes(data?.anchorsTableData?.windFarmMetalMassT),
      safeFormatPercentage(data?.anchorsTableData?.metalRecoveryRate),
      formatTonnes(data?.anchorsTableData?.recoveredMetalMassT)
    ])

    // Total Anchors Recyclability
    rows.push([
      'Total Anchors Recyclability',
      safeFormatPercentage(data?.anchorsTableData?.totalRecoveryRate)
    ])
  }

  // Check if it's an Offshore Bottom Fixed or Onshore project
  if (
    project?.projectType === ProjectType.OffshoreBottomFixed ||
    project?.projectType === ProjectType.Onshore
  ) {
    // Foundations
    rows.push(['Foundations'])

    // Steel
    rows.push([
      'Steel',
      formatTonnes(data?.foundationsTableData?.windFarmMetalMassT),
      safeFormatPercentage(data?.foundationsTableData?.metalRecoveryRate),
      formatTonnes(data?.foundationsTableData?.recoveredMetalMassT)
    ])

    // Concrete
    rows.push([
      'Concrete',
      formatTonnes(data?.foundationsTableData?.windFarmConcreteMassT),
      safeFormatPercentage(data?.foundationsTableData?.concreteRecoveryRate),
      formatTonnes(data?.foundationsTableData?.recoveredConcreteMassT)
    ])

    // Other
    rows.push([
      'Other',
      formatTonnes(data?.foundationsTableData?.windFarmOtherMassT),
      safeFormatPercentage(data?.foundationsTableData?.otherRecoveryRate),
      formatTonnes(data?.foundationsTableData?.recoveredOtherMassT)
    ])

    // Total Foundation Recyclability
    rows.push([
      'Total Foundation Recyclability',
      formatPercentage(data?.foundationsTableData?.totalRecoveryRate)
    ])
  }

  // Array Cables
  rows.push(['Array Cables'])

  // Array Cables Metal
  rows.push([
    data?.cablesTableData.arrayCablesMetalName,
    formatTonnes(data?.cablesTableData?.arrayCablesMetalWeightT),
    safeFormatPercentage(data?.cablesTableData?.arrayCablesMetalRecoveryRate),
    formatTonnes(data?.cablesTableData?.arrayCablesRecoveredMetalWeightT)
  ])

  // Array Cables Other
  rows.push([
    'Other',
    formatTonnes(data?.cablesTableData?.arrayCablesOtherWeightT),
    safeFormatPercentage(data?.cablesTableData?.arrayCablesOtherRecoveryRate),
    formatTonnes(data?.cablesTableData?.arrayCablesRecoveredOtherWeightT)
  ])

  // Total Array Cables Recyclability
  rows.push([
    'Total Array Cables Recyclability',
    formatPercentage(data?.cablesTableData?.arrayCablesTotalRecoveryRate)
  ])

  // Export Cables
  rows.push(['Export Cables'])

  // Export Cables Metal
  rows.push([
    data?.cablesTableData.exportCablesMetalName,
    formatTonnes(data?.cablesTableData?.exportCablesMetalWeightT),
    safeFormatPercentage(data?.cablesTableData?.exportCablesMetalRecoveryRate),
    formatTonnes(data?.cablesTableData?.exportCablesRecoveredMetalWeightT)
  ])

  // Export Cables Other
  rows.push([
    'Other',
    formatTonnes(data?.cablesTableData?.exportCablesOtherWeightT),
    safeFormatPercentage(data?.cablesTableData?.exportCablesOtherRecoveryRate),
    formatTonnes(data?.cablesTableData?.exportCablesRecoveredOtherWeightT)
  ])

  // Total Export Cables Recyclability
  rows.push([
    'Total Export Cables Recyclability',
    formatPercentage(data?.cablesTableData?.exportCablesTotalRecoveryRate)
  ])

  if (project?.hasOnshoreSubstation && showNewSubstations) {
    // Onshore Substation
    rows.push(['Onshore Substation'])

    // Onshore Substation Steel
    rows.push([
      Materials[Materials.Steel],
      formatTonnes(data?.substations.onshore?.steel),
      safeFormatPercentage(data?.substations.onshore?.steelRecoveryRate),
      formatTonnes(data?.substations.onshore?.steelRecycled)
    ])

    // Onshore Substation Concrete
    rows.push([
      Materials[Materials.Concrete],
      formatTonnes(data?.substations.onshore?.concrete),
      safeFormatPercentage(data?.substations.onshore?.concreteRecoveryRate),
      formatTonnes(data?.substations.onshore?.concreteRecycled)
    ])

    // Onshore Substation Copper
    rows.push([
      Materials[Materials.Copper],
      formatTonnes(data?.substations.onshore?.copper),
      safeFormatPercentage(data?.substations.onshore?.copperRecoveryRate),
      formatTonnes(data?.substations.onshore?.copperRecycled)
    ])

    // Onshore Substation Aluminium
    rows.push([
      Materials[Materials.Aluminium],
      formatTonnes(data?.substations.onshore?.aluminium),
      safeFormatPercentage(data?.substations.onshore?.aluminiumRecoveryRate),
      formatTonnes(data?.substations.onshore?.aluminiumRecycled)
    ])

    // Onshore Substation Other
    rows.push([
      Materials[Materials.Other],
      formatTonnes(data?.substations.onshore?.other),
      safeFormatPercentage(data?.substations.onshore?.otherRecoveryRate),
      formatTonnes(data?.substations.onshore?.otherRecycled)
    ])

    // Onshore Substation Total
    rows.push([
      'Total Onshore Substation Recyclability',
      formatPercentage(data?.substations?.onshore?.totalPercentage)
    ])
  }

  if (project?.hasOffshoreSubstation && showNewSubstations) {
    // Offshore Substation
    rows.push(['Offshore Substation'])

    // Offshore Substation Steel
    rows.push([
      Materials[Materials.Steel],
      formatTonnes(data?.substations.offshore?.steel),
      safeFormatPercentage(data?.substations.offshore?.steelRecoveryRate),
      formatTonnes(data?.substations.offshore?.steelRecycled)
    ])

    // Offshore Substation Concrete
    rows.push([
      Materials[Materials.Concrete],
      formatTonnes(data?.substations.offshore?.concrete),
      safeFormatPercentage(data?.substations.offshore?.concreteRecoveryRate),
      formatTonnes(data?.substations.offshore?.concreteRecycled)
    ])

    // Offshore Substation Copper
    rows.push([
      Materials[Materials.Copper],
      formatTonnes(data?.substations.offshore?.copper),
      safeFormatPercentage(data?.substations.offshore?.copperRecoveryRate),
      formatTonnes(data?.substations.offshore?.copperRecycled)
    ])

    // Offshore Substation Aluminium
    rows.push([
      Materials[Materials.Aluminium],
      formatTonnes(data?.substations.offshore?.aluminium),
      safeFormatPercentage(data?.substations.offshore?.aluminiumRecoveryRate),
      formatTonnes(data?.substations.offshore?.aluminiumRecycled)
    ])

    // Offshore Substation Other
    rows.push([
      Materials[Materials.Other],
      formatTonnes(data?.substations.offshore?.other),
      safeFormatPercentage(data?.substations.offshore?.otherRecoveryRate),
      formatTonnes(data?.substations.offshore?.otherRecycled)
    ])

    // Offshore Substation Total
    rows.push([
      'Total Offshore Substation Recyclability',
      formatPercentage(data?.substations?.offshore?.totalPercentage)
    ])
  }

  // Project Circularity Calculation
  rows.push(['Project Circularity Calculation'])

  // Total Material Mass
  rows.push([
    'Total Material Mass',
    formatTonnes(data?.circularityWeights?.windfarmTotalMaterialWeightT)
  ])

  // Total Recovered Material
  rows.push([
    'Total Recovered Material',
    formatTonnes(data?.circularityWeights?.windfarmRecoveredMaterialWeight)
  ])

  // Project Circularity
  rows.push([
    'Project Circularity',
    formatPercentage(data?.circularityWeights?.windfarmCircularityRate)
  ])

  return rows
}

const ReportCircularityTablePage: React.FC<Props> = ({
  project,
  scenarioCircularityRate
}) => (
  <View>
    <Table
      name="Circularity"
      rows={buildRows(project, scenarioCircularityRate)}
    />
  </View>
)
export default ReportCircularityTablePage
