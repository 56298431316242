import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  getScenarioCircularityRate,
  getTurbineCircularityRate,
  getTurbineCircularityRateForScenario
} from '@/store/slices/circularity'
import { UnknownAction } from '@reduxjs/toolkit'

const useCircularityRateData = () => {
  const dispatch = useAppDispatch()
  const {
    scenarioCircularityRate,
    turbineCircularityRate,
    turbineCircularityRateForScenario
  } = useAppSelector(s => s.circularity)

  const getScenarioCircularityRateData = (scenarioId?: string) =>
    dispatch(getScenarioCircularityRate(scenarioId) as unknown as UnknownAction)

  const getTurbineCircularityRateData = (turbineId?: string) =>
    dispatch(getTurbineCircularityRate(turbineId) as unknown as UnknownAction)

  const getTurbineCircularityRateDataForScenario = (turbineId?: string) =>
    dispatch(
      getTurbineCircularityRateForScenario(
        turbineId
      ) as unknown as UnknownAction
    )

  return {
    getScenarioCircularityRateData,
    getTurbineCircularityRateData,
    getTurbineCircularityRateDataForScenario,
    scenarioCircularityRate,
    turbineCircularityRate,
    turbineCircularityRateForScenario
  }
}

export default useCircularityRateData
