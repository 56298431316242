import React from 'react'
import BackButton from './BackButton'
import DraftIndicator from './DraftIndicator'

interface Props {
  disabled?: boolean
  className?: string
  isDraft?: boolean
  isLocked?: boolean
  title?: string
  backLink?: string
  backName?: string
}

const BreadcrumbsTitle = ({
  disabled,
  className,
  isDraft,
  isLocked,
  title,
  backLink,
  backName
}: Props) => (
  <div className={['w-100', 'mt-1', className].join(' ')}>
    <div>
      {!!backLink && (
        <BackButton disabled={disabled} linkTo={backLink} text={backName} />
      )}
    </div>
    <div className="d-flex align-items-center">
      <h2 className="font-weight-bold m-0 ml-3 word-break-break-all">
        {title}
      </h2>
      <DraftIndicator text="Saved as draft" isDraft={isDraft} />
      <DraftIndicator
        text={isLocked ? 'Locked' : 'Unlocked'}
        isDraft={isLocked}
      />
    </div>
  </div>
)
export default React.memo(BreadcrumbsTitle)
