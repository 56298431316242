import React from 'react'
import { Page } from '../layout'
import { OnboardingTutorial } from '../helpers'

const HowItWorksPage = () => (
  <Page title="How it works">
    <OnboardingTutorial isDisplayForced />
  </Page>
)

export default React.memo(HowItWorksPage)
