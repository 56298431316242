import React from 'react'

interface Props {
  onClick?: () => void
}

const CloseButton = ({ onClick }: Props) => (
  <button className="btn btn-none" onClick={onClick}>
    <i className="fal fa-times fg-neutral-80" />
  </button>
)

export default React.memo(CloseButton)
