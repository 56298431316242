import React from 'react'

type Props = {
  className?: string
  items?: string[]
}

const SimpleList: React.FC<Props> = ({ className, items }) =>
  items?.length ? (
    <ul className={className}>
      {items?.map?.((i: any, key: number) =>
        typeof i === 'string' ? (
          <li key={key}>{i}</li>
        ) : (
          <ul key={key}>
            {i?.map?.((j: any) => (
              <li key={j}>{j}</li>
            ))}
          </ul>
        )
      )}
    </ul>
  ) : null

export default React.memo(SimpleList)
