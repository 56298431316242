import React from 'react'
import { OButton, OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import { Link } from 'react-router-dom'
import { Button } from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'

interface Props {
  text?: string
  tooltip?: string
  linkTo: string
  disabled?: boolean
}

const BackButton = ({ text, tooltip, linkTo, disabled }: Props) => (
  <div className="d-flex-inline align-items-center">
    <Link to={linkTo}>
      {text ? (
        <Button
          disabled={disabled}
          variant="plain"
          startDecorator={
            <IconComponent iconClass="fal fa-angle-left" size={20} />
          }>
          {text}
        </Button>
      ) : (
        <OTooltip content={tooltip}>
          <OButton disabled={disabled} variant="flat">
            <i className="fal fa-chevron-left" />
          </OButton>
        </OTooltip>
      )}
    </Link>
  </div>
)

export default React.memo(BackButton)
