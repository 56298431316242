import React from 'react'
import Page from '../../layout/Page'
import ContentSection from '../../layout/ContentSection'
import { AppName } from '../../helpers'

const TouPage = () => (
  <Page title="Terms of use" isFullWidthTitle>
    <ContentSection className="pt-4">
      <p>
        <AppName /> service is subject to the{' '}
        <a
          href="https://id.veracity.com/terms-of-use"
          target="_blank"
          rel="noreferrer">
          Veracity platform terms of use
        </a>
        .
      </p>
    </ContentSection>
  </Page>
)

export default React.memo(TouPage)
