import React from 'react'
import { FieldType, OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import { useProviders } from '@/hooks'
import { ContentSection, Page } from '@/components/layout'
import {
  AddItem,
  FacilityTypeIndicator,
  ItemsCollection,
  ProviderPin,
  ProvidersFilters,
  StatusIndicator,
  TooltipHelpText
} from '@/components/helpers'
import { MapBoxWrapper } from '@/components/maps'
import { ProviderStatus, ProviderType } from '@/interfaces'
import { config, tooltips, helper } from '@/utils'
import { Link } from 'react-router-dom'

const { urls, defaultSelectOption } = config
const { buildItemLink } = helper

const id = 'ProvidersPage'

const ProvidersPage = () => {
  const {
    isLoading,
    filteredItems,
    isEditable,
    countryOptions,
    defaultCountry,
    providerTypeNames,
    selectedTypes,
    selectedMatrials,
    selectedServices,
    setDefaultCountry,
    addProvider,
    onSearchChange,
    onFilterToggle,
    onClearSelection
  } = useProviders()

  return (
    <Page
      title="Service providers"
      actions={
        isEditable && (
          <AddItem
            type="provider"
            disabled={isLoading}
            fields={
              [
                { heading: 'Name *', id: 'name', isRequired: true },
                {
                  heading: 'Country',
                  id: 'countryId',
                  type: FieldType.Select,
                  defaultValue: defaultCountry,
                  options: defaultCountry
                    ? countryOptions
                    : [defaultSelectOption, ...countryOptions],
                  onChange: (value: string) => setDefaultCountry(value)
                },
                {
                  heading: 'Website',
                  id: 'websiteAddress',
                  defaultValue: config.providers.websiteAddress
                },
                {
                  heading: 'Facility type',
                  type: 'label'
                },
                {
                  heading: (
                    <>
                      Second Life&nbsp;
                      <FacilityTypeIndicator type={ProviderType.SecondLife} />
                      &nbsp;
                      <TooltipHelpText
                        message={tooltips.providers['Second Life']}
                      />
                    </>
                  ),
                  id: providerTypeNames[ProviderType.SecondLife],
                  type: FieldType.Checkbox,
                  defaultValue: true
                },
                {
                  heading: (
                    <>
                      Material Processing&nbsp;
                      <FacilityTypeIndicator
                        type={ProviderType.MaterialProcessing}
                      />
                      &nbsp;
                      <TooltipHelpText
                        message={tooltips.providers['Material Processing']}
                      />
                    </>
                  ),
                  id: providerTypeNames[ProviderType.MaterialProcessing],
                  type: FieldType.Checkbox,
                  defaultValue: true
                },
                {
                  heading: (
                    <>
                      Decommissioning&nbsp;
                      <FacilityTypeIndicator
                        type={ProviderType.Decommissioning}
                      />
                      &nbsp;
                      <TooltipHelpText
                        message={tooltips.providers['Decommissioning']}
                      />
                    </>
                  ),
                  id: providerTypeNames[ProviderType.Decommissioning],
                  type: FieldType.Checkbox,
                  defaultValue: true
                },
                {
                  heading: (
                    <>
                      Port&nbsp;
                      <FacilityTypeIndicator type={ProviderType.Port} />
                      &nbsp;
                      <TooltipHelpText message={tooltips.providers['Port']} />
                    </>
                  ),
                  id: providerTypeNames[ProviderType.Port],
                  type: FieldType.Checkbox,
                  defaultValue: true
                }
              ] as any
            }
            onOK={addProvider}
          />
        )
      }>
      <ORow>
        <OCol md="12">
          <ContentSection contentClassName="p-0">
            <MapBoxWrapper height={560}>
              {filteredItems?.map((i: any) => (
                <ProviderPin
                  key={`${i.id}-${i.name}`}
                  latitude={i?.location?.latitude}
                  longitude={i?.location?.longitude}
                  iconClass={i?.iconClass}
                  isDraft={i?.isDraft}
                  color={i?.color}>
                  <div className="p-2">
                    <div className="mb-2">
                      {i?.isDraft && 'Draft: '}
                      <Link to={[urls.providers, i?.id].join('/')}>
                        {i?.name}
                      </Link>
                    </div>
                    <div className="text-center">{i?.providerTypesIcons}</div>
                  </div>
                </ProviderPin>
              ))}
            </MapBoxWrapper>
          </ContentSection>
        </OCol>
        <OCol md="12">
          <ItemsCollection
            id={id}
            className="card-dnvgl-overflow"
            isLoading={isLoading}
            items={filteredItems}
            itemName="provider"
            fields={[
              {
                name: 'name',
                heading: 'Company',
                sortable: true,
                formatter: (name: string) =>
                  buildItemLink(
                    [
                      urls.providers,
                      filteredItems?.find?.((item: any) => item.name === name)
                        ?.id
                    ].join('/'),
                    name
                  )
              },
              {
                name: 'countryName',
                heading: 'Location',
                sortable: true
              },
              {
                name: 'status',
                heading: 'Status',
                sortable: true,
                formatter: (s: ProviderStatus) => (
                  <StatusIndicator status={s} isTableView />
                )
              },

              {
                name: 'providerTypesIconsTableView',
                heading: 'Facility type'
              },
              {
                name: 'materialsList',
                heading: 'Materials'
              },
              {
                name: 'servicesList',
                heading: 'Services'
              }
            ]}
            tabs={[
              {
                title: 'Saved providers',
                filter: (i: any) => !i.isDraft
              },
              {
                title: 'Drafts',
                filter: (i: any) => i.isDraft
              }
            ]}
            searchAllowed={false}>
            <ProvidersFilters
              selectedTypes={selectedTypes}
              selectedMatrials={selectedMatrials}
              selectedServices={selectedServices}
              onSearchChange={onSearchChange}
              onFilterToggle={onFilterToggle}
              onClearSelection={onClearSelection}
            />
          </ItemsCollection>
        </OCol>
      </ORow>
    </Page>
  )
}

export default React.memo(ProvidersPage)
