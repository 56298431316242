import helper from './helper'
import { CurrencyInformationViewModel } from '@/interfaces'
import { TooltipHelpText } from '@/components/helpers'
import { tooltips } from '@/utils/index'

export const dayRateLabels = {
  vesselAndStaff: 'Vessel & staff',
  fuel: 'Fuel',
  crew: 'Crew',
  equipment: 'Equipment',
  deBurial: 'De burial',
  bargeTugSetup: 'Barge tug setup'
}

export const getDayRateInputLabel = (key: string) =>
  (dayRateLabels as any)?.[key]

export const getDefaultValue = (section: string, key: string, payload: any) =>
  section === 'removalOfOffshoreSubstation'
    ? payload?.removalOfOffshoreSubstation?.updateOffshoreFixedCostViewModel?.[
        key
      ]
    : payload?.[section]?.updateOffshoreCostViewModel?.[key]

export const onEngineeringSectionChange = (
  data: any,
  section: string,
  subSection: string | null,
  name: string,
  value: any,
  callback?: Function
) => {
  const clone: any = JSON.parse(JSON.stringify(data))

  if (clone !== null) {
    if (subSection) clone[section][subSection][name] = value || 0
    else clone[section][name] = value || 0

    callback?.(clone)
  }
}

export const wrapperSectionNames = [
  'updateOffshoreCostViewModel',
  'updateOffshoreFixedCostViewModel'
]

export const valueFormater = (
  value: number,
  currency: CurrencyInformationViewModel
) => `${helper.formatCurrencyMask(value, currency)}/day`

export const buildDayRates = (section: any) => {
  const items = []
  for (const key of Object.keys(dayRateLabels)) {
    if (section?.dayRate?.[key] !== undefined)
      items.push({
        text: getDayRateInputLabel(key),
        value: [
          section.dayRate?.[key]?.absolute || 0,
          section.dayRate?.[key]?.perTurbine || 0,
          section.dayRate?.[key]?.perMW || 0
        ],
        isNested: true,
        valueFormater
      })
  }
  if (section?.dayRate?.total !== undefined)
    items.push({
      text: 'Total',
      textNode: (
        <span>
          <span className="text-bold">Total</span>
          <TooltipHelpText
            message={tooltips.TotalsRounding}
            placement="right"
            className="ml-1"
          />
        </span>
      ),
      value: [
        section.dayRate?.total?.absolute || 0,
        section.dayRate?.total?.perTurbine || 0,
        section.dayRate?.total?.perMW || 0
      ],
      isNested: true,
      valueFormater
    })

  if (items?.length) {
    ;(items as any)[0].rowSpan = items?.length
    ;(items as any)[0].rowSpanTitle = 'Day rates'
  }
  return items
}
