import {
  CircularityRateViewModel,
  ProjectItemViewModel,
  ProjectType
} from '@/interfaces'
import React from 'react'
import { config, helper } from '@/utils'
import UnderDevelopment from './UnderDevelopment'
import CircularitySubstationDetailsTable from '@/components/helpers/CircularitySubstationDetailsTable'

type CircularityTableProps = {
  data?: CircularityRateViewModel | null
  project?: ProjectItemViewModel
}

const { projects, showNewSubstations } = config
const { formatTonnes, formatPercentage } = helper

const cellClass = ['p-2', 'border'].join(' ')
const headerClass = [cellClass, 'px-2', 'bg-light'].join(' ')

const Title = ({ title }: { title: string }) => (
  <tr>
    <th colSpan={4} className={headerClass}>
      {title}
    </th>
  </tr>
)

export const Row = ({
  materialName,
  windFarmMaterialMassT,
  materialRecoveryRate,
  recoveredMaterialMassT
}: {
  materialName?: string
  windFarmMaterialMassT?: number
  materialRecoveryRate?: number
  recoveredMaterialMassT?: number
}) => (
  <tr>
    <td className={cellClass}>{materialName}</td>
    <td className={cellClass}>{formatTonnes(windFarmMaterialMassT)}</td>
    <td className={cellClass}>{formatPercentage(materialRecoveryRate)}</td>
    <td className={cellClass}>{formatTonnes(recoveredMaterialMassT)}</td>
  </tr>
)

const Total = ({ title, value }: { title: string; value?: string }) => (
  <tr className="font-weight-bold">
    <td className={cellClass} colSpan={2}>
      {title}
    </td>
    <td className={cellClass} colSpan={2}>
      {value}
    </td>
  </tr>
)

const CircularityTable: React.FC<CircularityTableProps> = ({
  data,
  project: p
}) => {
  return data ? (
    <table className="w-100">
      <thead className="font-weight-bold bg-light">
        <tr>
          <th className={cellClass}>Material</th>
          <th className={cellClass}>Wind Farm material mass (t)</th>
          <th className={cellClass}>Material recovery rate</th>
          <th className={cellClass}>Recovered material mass (t)</th>
        </tr>
      </thead>
      <tbody>
        <Title title="Wind Turbine" />
        <tr>
          <td className={cellClass}>Steel</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.steel)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.steelRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.steelRecoveredMassT)}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Cast iron</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.castIron)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.castIronRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.castIronRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Copper</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.copper)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.copperRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.copperRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Aluminium</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.aluminium)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.aluminiumRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.aluminiumRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Rare earths</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.rareEarths)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.rareEarthsRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.rareEarthsRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Glass fibre</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.glassFibre)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.glassFibreRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.glassFibreRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Epoxy resin</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.epoxyResin)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.epoxyResinRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.epoxyResinRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>PUR/PVC foam</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.purpvcFoam)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.purpvcFoamRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.purpvcFoamRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Balsa wood</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.balsaWood)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.balsaWoodRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.balsaWoodRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Epoxy gelcoat</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.epoxyGelcoat)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.epoxyGelcoatRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.epoxyGelcoatRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Silicon</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.silicon)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.siliconRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.windfarmMaterialBreakdown?.siliconRecoveredMassT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Other</td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.other)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.windfarmMaterialBreakdown?.otherRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(data?.windfarmMaterialBreakdown?.otherRecoveredMassT)}
          </td>
        </tr>
        <Total
          title="Total Turbine Recyclability"
          value={formatPercentage(
            data?.windfarmMaterialBreakdown?.totalRecoveryRate
          )}
        />

        {p?.projectType === ProjectType.OffshoreFloating && (
          <>
            <Title title="Floater" />
            <tr>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.windFarmMetalMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.floatersTableData?.metalRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.recoveredMetalMassT)}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Concrete</td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.windFarmConcreteMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.floatersTableData?.concreteRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.recoveredConcreteMassT)}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.windFarmOtherMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.floatersTableData?.otherRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.floatersTableData?.recoveredOtherMassT)}
              </td>
            </tr>
            <Total
              title="Total Floater Recyclability"
              value={formatPercentage(
                data?.floatersTableData?.totalRecoveryRate
              )}
            />
            <Title title="Mooring Lines" />
            <tr>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(data?.mooringLinesTableData?.windFarmMetalMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.mooringLinesTableData?.metalRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.mooringLinesTableData?.recoveredMetalMassT)}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(data?.mooringLinesTableData?.windFarmOtherMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.mooringLinesTableData?.otherRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.mooringLinesTableData?.recoveredOtherMassT)}
              </td>
            </tr>
            <Total
              title="Total Mooring Lines Recyclability"
              value={formatPercentage(
                data?.mooringLinesTableData?.totalRecoveryRate
              )}
            />
            <Title title="Anchors" />
            <tr>
              <td className={cellClass}>
                {
                  projects.selectOptions.anchor.find(
                    i => i.value === p?.anchor?.material
                  )?.text
                }
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.anchorsTableData?.windFarmMetalMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.anchorsTableData?.metalRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.anchorsTableData?.recoveredMetalMassT)}
              </td>
            </tr>
            <Total
              title="Total Anchors Recyclability"
              value={formatPercentage(
                data?.anchorsTableData?.totalRecoveryRate
              )}
            />
          </>
        )}

        {(p?.projectType === ProjectType.OffshoreBottomFixed ||
          p?.projectType === ProjectType.Onshore) && (
          <>
            <Title title="Foundations" />
            <tr>
              <td className={cellClass}>Steel</td>
              <td className={cellClass}>
                {formatTonnes(data?.foundationsTableData?.windFarmMetalMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.foundationsTableData?.metalRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.foundationsTableData?.recoveredMetalMassT)}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Concrete</td>
              <td className={cellClass}>
                {formatTonnes(
                  data?.foundationsTableData?.windFarmConcreteMassT
                )}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.foundationsTableData?.concreteRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(
                  data?.foundationsTableData?.recoveredConcreteMassT
                )}
              </td>
            </tr>
            <tr>
              <td className={cellClass}>Other</td>
              <td className={cellClass}>
                {formatTonnes(data?.foundationsTableData?.windFarmOtherMassT)}
              </td>
              <td className={cellClass}>
                {formatPercentage(
                  data?.foundationsTableData?.otherRecoveryRate * 100
                )}
              </td>
              <td className={cellClass}>
                {formatTonnes(data?.foundationsTableData?.recoveredOtherMassT)}
              </td>
            </tr>
            <Total
              title="Total Foundation Recyclability"
              value={formatPercentage(
                data?.foundationsTableData?.totalRecoveryRate
              )}
            />
          </>
        )}

        <Title title="Array Cables" />
        <tr>
          <td className={cellClass}>
            {data?.cablesTableData.arrayCablesMetalName}
          </td>
          <td className={cellClass}>
            {formatTonnes(data?.cablesTableData?.arrayCablesMetalWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.cablesTableData?.arrayCablesMetalRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.cablesTableData?.arrayCablesRecoveredMetalWeightT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Other</td>
          <td className={cellClass}>
            {formatTonnes(data?.cablesTableData?.arrayCablesOtherWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.cablesTableData?.arrayCablesOtherRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.cablesTableData?.arrayCablesRecoveredOtherWeightT
            )}
          </td>
        </tr>
        <Total
          title="Total Array Cables Recyclability"
          value={formatPercentage(
            data?.cablesTableData?.arrayCablesTotalRecoveryRate
          )}
        />
        <Title title="Export Cables" />
        <tr>
          <td className={cellClass}>
            {data?.cablesTableData.exportCablesMetalName}
          </td>
          <td className={cellClass}>
            {formatTonnes(data?.cablesTableData?.exportCablesMetalWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.cablesTableData?.exportCablesMetalRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.cablesTableData?.exportCablesRecoveredMetalWeightT
            )}
          </td>
        </tr>
        <tr>
          <td className={cellClass}>Other</td>
          <td className={cellClass}>
            {formatTonnes(data?.cablesTableData?.exportCablesOtherWeightT)}
          </td>
          <td className={cellClass}>
            {formatPercentage(
              data?.cablesTableData?.exportCablesOtherRecoveryRate * 100
            )}
          </td>
          <td className={cellClass}>
            {formatTonnes(
              data?.cablesTableData?.exportCablesRecoveredOtherWeightT
            )}
          </td>
        </tr>
        <Total
          title="Total Export Cables Recyclability"
          value={formatPercentage(
            data?.cablesTableData?.exportCablesTotalRecoveryRate
          )}
        />
        {p?.hasOnshoreSubstation && showNewSubstations && (
          <>
            <Title title="Onshore Substation" />
            <CircularitySubstationDetailsTable
              data={data.substations.onshore}
            />
            <Total
              title="Total Onshore Substations Recyclability"
              value={formatPercentage(
                data?.substations.onshore?.totalPercentage || 0
              )}
            />
          </>
        )}

        {p?.hasOffshoreSubstation && showNewSubstations && (
          <>
            <Title title="Offshore Substation" />
            <CircularitySubstationDetailsTable
              data={data.substations.offshore}
            />
            <Total
              title="Total Offshore Substations Recyclability"
              value={formatPercentage(
                data?.substations.offshore?.totalPercentage || 0
              )}
            />
          </>
        )}

        <Title title="Project Circularity Calculation" />
        <Total
          title="Total Material Mass"
          value={formatTonnes(
            data?.circularityWeights?.windfarmTotalMaterialWeightT
          )}
        />
        <Total
          title="Total Recovered Material"
          value={formatTonnes(
            data?.circularityWeights?.windfarmRecoveredMaterialWeight
          )}
        />
        <Total
          title="Project Circularity"
          value={formatPercentage(
            data?.circularityWeights?.windfarmCircularityRate
          )}
        />
      </tbody>
    </table>
  ) : (
    <UnderDevelopment />
  )
}

export default React.memo(CircularityTable)
