import { useEffect } from 'react'

import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getJsonData } from '@/store/slices/general'
import { UnknownAction } from '@reduxjs/toolkit'

const useTexts = () => {
  const dispatch = useAppDispatch()
  const { texts } = useAppSelector(s => s.general)
  useEffect(() => {
    dispatch(getJsonData('texts') as unknown as UnknownAction)
  }, [dispatch])

  return texts
}
export default useTexts
