import React, { ReactNode, useEffect, useState } from 'react'
import { Option, showError } from '@dnvgl-onefoundation/onedesign-react'
import { config } from '@/utils'
import InputValue from './InputValue'

interface Props {
  propertyName: string
  className?: string
  label?: ReactNode
  type?: 'text' | 'number' | 'date' | 'select'
  defaultValue?: string | number
  value?: string | number
  options?: Option[]
  disabled?: boolean
  placeholder?: string
  helpText?: string
  min?: number | string
  max?: number | string
  showOnlyValue?: boolean
  isValueMissing?: boolean
  isTextarea?: boolean
  isLabelBold?: boolean
  noBorder?: boolean
  prefix?: string
  onChange?: (propertyName: string, propertyValue: string) => void
  onBlur?: (propertyName: string, propertyValue: string) => void
}

const { missingValueCharacter } = config

const Input = ({
  propertyName,
  className,
  label,
  type = 'text',
  defaultValue,
  value,
  options = [],
  disabled = false,
  placeholder,
  helpText,
  min,
  max,
  showOnlyValue,
  isValueMissing,
  isTextarea,
  isLabelBold,
  noBorder,
  prefix,
  onChange: onChangeProp,
  onBlur: onBlurProp
}: Props) => {
  const [innerValue, setInnerValue] = useState(
    value || defaultValue || (type === 'number' ? 0 : '')
  )
  const checkValue = (value: any) => {
    const errors = []
    if (min !== undefined && value < min) errors.push(`min value: ${min}`)
    if (max !== undefined && value > max) errors.push(`max value: ${max}`)
    return errors?.length
      ? showError(
          `Oops! It appears there might be an issue with the data you entered.`,
          `Please review the field highlighted in red and make necessary corrections (${errors.join(
            ', '
          )})`
        )
      : true
  }

  useEffect(() => {
    value && setInnerValue(value)
  }, [value])

  const eventHandlers = {
    onChange: (e: any) => {
      setInnerValue(e.target.value)
      onChangeProp?.(propertyName, e.target.value)
    },
    onBlur: (e: any) =>
      checkValue(e.target.value) && onBlurProp?.(propertyName, e.target.value)
  }

  if (showOnlyValue)
    return (
      <InputValue
        label={label}
        value={
          type === 'select'
            ? options?.find?.(i => i.value === value || defaultValue)?.text ||
              missingValueCharacter
            : innerValue || defaultValue || missingValueCharacter
        }
      />
    )

  return (
    <div
      className={[
        'form-group',
        'flex-column',
        className,
        disabled ? 'is-disabled' : undefined,
        prefix ? 'd-flex' : undefined
      ].join(' ')}>
      {!!label && (
        <div
          className={[
            isValueMissing ? 'text-danger' : undefined,
            isLabelBold ? 'font-weight-bold' : undefined
          ].join(' ')}>
          {label}
        </div>
      )}
      <div className="d-flex">
        {!!prefix && <span className="mt-1 mr-1">{prefix}</span>}
        {type === 'select' ? (
          <select className="custom-select" value={value} {...eventHandlers}>
            {options?.map(o => (
              <option
                key={`${o.value}-${o.text}`}
                value={`${o.value}`}
                disabled={o.disabled}
                className={o.disabled ? 'is-disabled' : ''}>
                {o.text}
              </option>
            ))}
          </select>
        ) : isTextarea ? (
          <textarea
            style={{ border: noBorder ? 'none' : 'auto' }}
            id={propertyName}
            className="form-control"
            value={innerValue}
            placeholder={placeholder}
            {...eventHandlers}></textarea>
        ) : (
          <input
            style={{ border: noBorder ? 'none' : 'auto' }}
            id={propertyName}
            className={[
              'form-control',
              innerValue !== undefined &&
                ((min !== undefined && (innerValue as number) < min) ||
                  (max !== undefined && (innerValue as number) > max)) &&
                'text-danger border-danger'
            ].join(' ')}
            type={type}
            value={innerValue}
            placeholder={placeholder}
            min={min}
            max={max}
            {...eventHandlers}
          />
        )}
      </div>

      {!!helpText && (
        <div>
          <small>{helpText}</small>
        </div>
      )}
    </div>
  )
}

export default React.memo(Input)
