import React from 'react'
import { AppUser, UserGroupItem } from '@/interfaces'

interface Props {
  currentUser: AppUser
  currentUserGroup: UserGroupItem
}

const AdminTeamsList = ({ currentUserGroup }: Props) =>
  currentUserGroup ? (
    <div className="row w-100" style={{ minWidth: '500px' }}>
      <div className="col-12 mt-2 mb-5">
        <div className="mt-5 text-center">
          {currentUserGroup?.name}&nbsp;does not include any teams yet.
        </div>
      </div>
    </div>
  ) : null

export default React.memo(AdminTeamsList)
