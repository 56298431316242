import React from 'react'
import { View, Text, Link } from '@react-pdf/renderer'
import { TocNavItem } from '@/interfaces'

interface Props {
  items?: TocNavItem[]
  styles?: any
}

const innerStyles = {
  top: {
    marginTop: 40
  },
  link: {
    color: '#0f204b',
    fontSize: 14,
    marginBottom: 6,
    width: '95%'
  }
}

const ReportTableOfContents: React.FC<Props> = ({ items, styles }: Props) => (
  <View style={innerStyles.top}>
    <Text style={styles.subTitle}>Table of contents</Text>
    {items?.map(({ title, id, page }, index) => (
      <Link
        key={id}
        src={`#${id}`}
        style={{ ...innerStyles.link, textDecoration: 'none' }}>
        <View
          style={{
            width: 'calc(100%-20px)',
            justifyContent: 'space-between',
            alignContent: 'space-between',
            flexWrap: 'nowrap',
            flexDirection: 'row',
            borderBottom: ' 1px dotted black',
            marginLeft: '10px',
            marginRight: '10px'
          }}>
          <Text>
            {index + 1}. {title}
          </Text>
          <Text>{page}</Text>
        </View>
      </Link>
    ))}
  </View>
)

export default ReportTableOfContents
