import React from 'react'
import ReportTable from './ReportTable'
import { helper } from '@/utils'
import { PrintPageBreak } from '../print'
import {
  OnshoreParameterViewModel,
  ProjectType,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel
} from '@/interfaces'
import ReportSection from './ReportSection'
import { useTexts } from '@/hooks'
import SimpleList from './SimpleList'

const { camelCaseToHumanFriendly, formatCurrencyMask, currentYear } = helper

interface Props {
  scenarioCostCalcationConfiguration:
    | CostCalculationOnshoreConfigurationRegisterViewModel
    | CostCalculationOffshoreFixedConfigurationRegisterViewModel
    | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
    | null
  projectType: ProjectType
  includeScrap?: boolean
}

const buildItems = (parameters?: OnshoreParameterViewModel[]) => {
  const items: any = []
  // eslint-disable-next-line array-callback-return
  Object.keys(!!parameters ? parameters : []).map((i: any) => {
    const header = { text: camelCaseToHumanFriendly(i), value: 'header' }
    const visibleItems: any[] = []
    ;(parameters as any)?.[i]?.forEach?.((p: any) => {
      if (p.isVisibleForUser)
        visibleItems.push({
          text: p?.displayName,
          value: formatCurrencyMask(p.doubleValue, p.currencyInformation),
          description: p?.description
        })
    })

    if (visibleItems.length > 0) {
      items.push(header)
      visibleItems?.forEach?.((i: any) => items.push(i))
    }
  })

  return items
}

const ReportCostAssumptions = ({
  scenarioCostCalcationConfiguration,
  projectType,
  includeScrap
}: Props) => {
  const texts = useTexts()
  // Supporting only Onshore for now
  return scenarioCostCalcationConfiguration ? (
    <>
      {projectType === ProjectType.Onshore &&
        texts?.report.costAssumptions.Onshore.map((i: any, key: number) =>
          typeof i === 'string' ? (
            <p key={i}>
              {!includeScrap
                ? i
                    .replace(
                      texts?.report.costAssumptions
                        .OnshoreScrapMetalPricesConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions.OnshoreScrapConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions
                        .OnshoreScrapMetalScopeConditional,
                      ''
                    )
                    .replace(
                      texts?.report.costAssumptions.CurrentYear,
                      currentYear.toString()
                    )
                    .replace(
                      texts?.report.costAssumptions.SelectedCurrency,
                      `${scenarioCostCalcationConfiguration.currency.name}, ${scenarioCostCalcationConfiguration.currency.currencyInformation.symbol}`
                    )
                : i
                    .replace(
                      texts?.report.costAssumptions.CurrentYear,
                      currentYear.toString()
                    )
                    .replace(
                      texts?.report.costAssumptions.SelectedCurrency,
                      `${scenarioCostCalcationConfiguration.currency.name}, ${scenarioCostCalcationConfiguration.currency.currencyInformation.symbol}`
                    )}
            </p>
          ) : (
            <SimpleList key={key} items={i as string[]} />
          )
        )}
      <ReportSection titleLeft="Cost assumptions">
        <ReportTable
          items={
            buildItems(
              scenarioCostCalcationConfiguration
                ?.costCalculationConfigurationSet?.parameters as any[]
            ) as any[]
          }
          includesDescriptons
          className="border-right"
        />
      </ReportSection>
      <PrintPageBreak />
    </>
  ) : null
}
export default React.memo(ReportCostAssumptions)
