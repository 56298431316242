import { useCallback, useMemo } from 'react'
import { GroupType } from '@/interfaces'
import { useAppSelector } from '@/store/hooks'

const useUserGroups = () => {
  const groups = useAppSelector(s => s.userGroups.userGroups)
  const companies = useMemo(
    () => groups?.filter(x => x.type === GroupType.Company),
    [groups]
  )
  const groupsDictionary = useMemo(
    () => new Map(groups.map(g => [g.id, g])),
    [groups]
  )
  const getGroup = useCallback(
    (groupId: string | undefined) =>
      groupId !== undefined ? groupsDictionary.get(groupId) : undefined,
    [groupsDictionary]
  )
  const getCompany = useCallback(
    (groupId: string | undefined) => {
      const currentGroup = getGroup(groupId)
      if (currentGroup?.type === GroupType.Company) return currentGroup
      else if (currentGroup?.companyId) return getGroup(currentGroup.companyId)
      return undefined
    },
    [getGroup]
  )

  return { groups, companies, getGroup, getCompany }
}

export default useUserGroups
