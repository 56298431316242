import {
  OButton,
  OModal,
  OAlert,
  OField,
  FieldType,
  ORow,
  OCol
} from '@dnvgl-onefoundation/onedesign-react'
import { helper } from '@/utils'
import { useUser } from '@/hooks'
import { SpinIndicator } from '@/components/layout'
import { VeracityLogo } from '@/components/helpers'
import { AppUser, GroupType } from '@/interfaces'
import React from 'react'

interface Props {
  currentUser: AppUser
}

const { isValidEmail } = helper

const AddUser = ({ currentUser }: Props) => {
  const {
    email,
    groupId,
    userGroups,
    isSearchCompleted,
    veracityUser,
    isVisible,
    isSubmitting,
    isLoading,
    isCustomer,
    handleOK,
    hideModal,
    showModal,
    getVeracityUser,
    setEmail,
    setGroupId,
    setIsSearchCompleted,
    getOKButtonText
  } = useUser(currentUser)

  const bodySlot = (
    <>
      <ORow>
        <OCol>
          <div style={{ minHeight: '1rem' }}>
            {isLoading && <SpinIndicator />}
          </div>
        </OCol>
      </ORow>
      <ORow className={`row text-left ${isLoading && 'is-disabled'}`}>
        <OCol col={isCustomer ? '12' : '8'} align="center">
          <OField
            value={{ email }}
            onChange={e => {
              setEmail(e.value as string)
              setIsSearchCompleted(false)
            }}
            field={{
              name: 'email',
              heading: 'User E-Mail',
              type: FieldType.Input
            }}
          />
        </OCol>
        {!isCustomer && (
          <OCol col="4" align="center">
            <OButton
              className="mt-2 w-100"
              iconClass="fal fa-search"
              disabled={!isValidEmail(email)}
              onClick={getVeracityUser}
              variant={isValidEmail(email) ? 'primary' : 'subtle'}>
              Search
            </OButton>
          </OCol>
        )}
        <OCol col="12">
          {!isValidEmail(email) && (
            <OAlert
              variant="danger"
              description="Please provide a valid E-mail address."
              dismissable={false}
            />
          )}
        </OCol>
        {isCustomer && (
          <>
            <OCol col="12">
              <OField
                value={{ groupId }}
                onChange={e => setGroupId(e.value as string)}
                field={{
                  name: 'groupId',
                  heading: 'Team',
                  type: FieldType.Select,
                  options: userGroups.map(x => ({
                    value: x.id,
                    text: `${x.type === GroupType.Company ? '' : '● '}${x.name}`
                  }))
                }}
              />
              {!groupId && (
                <OAlert
                  variant="danger"
                  description="Please provide group to add to."
                  dismissable={false}
                />
              )}
            </OCol>
          </>
        )}
        {isSearchCompleted && (
          <OCol>
            <p className="mt-4">
              {veracityUser ? (
                <span>
                  Found user on Veracity:&nbsp;
                  <strong>
                    {veracityUser?.firstName}&nbsp;
                    {veracityUser?.lastName}
                  </strong>
                </span>
              ) : (
                <span>
                  User with the {email} address&nbsp;
                  <strong>has not been found on Veracity</strong>. You can
                  invite the user to join, or provide another E-Mail address.
                </span>
              )}
            </p>
          </OCol>
        )}
      </ORow>
    </>
  )

  return (
    <>
      <OButton iconClass="fal fa-user" onClick={showModal}>
        Invite user
      </OButton>
      <OModal
        visible={isVisible}
        hideCloseButton
        okText={getOKButtonText()}
        clickOutside={false}
        titleText={
          <div style={{ minWidth: '560px' }}>
            <span>Invite user</span>
            <div className="float-right">
              <VeracityLogo />
            </div>
          </div>
        }
        bodySlot={
          isSubmitting ? (
            <div className="text-center mt-5">
              <SpinIndicator />
            </div>
          ) : (
            bodySlot
          )
        }
        onOk={handleOK}
        okDisabled={
          (isCustomer && !groupId) ||
          (!isCustomer && !isSearchCompleted) ||
          !email ||
          !isValidEmail ||
          isLoading
        }
        onCancel={hideModal}
      />
    </>
  )
}

export default React.memo(AddUser)
