import { ComponentType, TurbinePart } from '@/interfaces'

const getComponentType = (part: TurbinePart): ComponentType | null => {
  if (part === 'blades') return ComponentType.Blades
  if (part === 'hub') return ComponentType.Hub
  if (part === 'nacelle') return ComponentType.Nacelle
  if (part === 'tower') return ComponentType.Tower

  return null
}

const turbines = { getComponentType }

export default turbines
