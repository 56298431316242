import React, { useEffect } from 'react'
import {
  ORow,
  OCol,
  OList,
  OButton
} from '@dnvgl-onefoundation/onedesign-react'

import { helper } from '@/utils'

import Input from './Input'
import { useConfigurationUpdate, useIsSuperAdministrator } from '@/hooks'
import { SpinIndicator } from '@/components/layout'

import {
  ConfigurationType,
  OffshoreBottomFixedCostCalculationConfigurationSetViewModel,
  OffshoreFloatingCostCalculationConfigurationSetViewModel,
  OnshoreCostCalculationConfigurationSetViewModel,
  ParameterViewModel,
  ProjectType
} from '@/interfaces'

interface Props {
  data?:
    | OnshoreCostCalculationConfigurationSetViewModel
    | OffshoreBottomFixedCostCalculationConfigurationSetViewModel
    | OffshoreFloatingCostCalculationConfigurationSetViewModel
  projectType: ProjectType
  id: string | 'default'
  type: ConfigurationType | null
  isLocked?: boolean
}

const { camelCaseToHumanFriendly, formatCurrencyMask } = helper

const ConfigurationParameters = ({
  data,
  projectType,
  id,
  type,
  isLocked
}: Props) => {
  const isSuperAdministrator = useIsSuperAdministrator()
  const {
    isSaveEnabled,
    isUpdating,
    selected,
    setSelected,
    onChange,
    onSaveChanges,
    errors
  } = useConfigurationUpdate()

  useEffect(() => {
    if (data?.parameters && !(data as any)?.parameters?.[selected])
      setSelected(Object.keys(data?.parameters)?.[0])
  }, [data, selected, setSelected])

  const isEditable = (canUserUpdate: boolean) => {
    // Super admin could edit all
    if (isSuperAdministrator) return true
    // Global and country configuration cannot be edited by regular users
    if (type === null || type === 'country') return false
    // Regular user could edit only if canUserUpdate is allowed by the backend
    if (canUserUpdate) return true
  }

  return (
    <ORow>
      {isSaveEnabled && !isLocked && (
        <OCol md="12" className="text-right">
          <div style={{ minHeight: 30 }}>
            {isUpdating ? (
              <SpinIndicator />
            ) : (
              <OButton
                size="small"
                className="mr-4"
                onClick={() => onSaveChanges(projectType, id, type)}
                disabled={errors}>
                Save changes
              </OButton>
            )}
          </div>
        </OCol>
      )}
      <OCol md="3" className={isUpdating ? 'is-dimmed' : undefined}>
        <OList
          className="pt-2 pl-2 mb-2"
          items={
            Object.keys(!!data?.parameters ? data.parameters : []).map(
              (i: any) => {
                return {
                  id: i,
                  content: <span>{camelCaseToHumanFriendly(i)}</span>
                }
              }
            ) as any
          }
          small
          onSelected={({ id }: any) => setSelected(id)}
          selected={selected ? [selected] : []}
        />
      </OCol>
      <OCol md="9" className={isUpdating ? 'is-dimmed' : undefined}>
        {data?.parameters?.[selected as any]?.map((i: ParameterViewModel) => (
          <ORow key={i?.name} className="border-bottom mr-3 pb-2">
            <OCol md="8">
              <h4>{i?.displayName}</h4>
              <p>{i?.description}</p>
            </OCol>
            <OCol md="4" className="mt-2 pt-1">
              {isEditable(i.canUserUpdate) ? (
                <Input
                  disabled={isLocked}
                  propertyName={i.name}
                  defaultValue={i.doubleValue}
                  helpText={`${i.isCost ? i.currencyInformation.symbol : ''}${i.currencyInformation.unit}`}
                  type="number"
                  min={i.min}
                  max={i.max}
                  onChange={(n, v) => {
                    const floatValue = parseFloat(v)
                    onChange?.(n, floatValue, projectType, i.min, i.max)
                  }}
                />
              ) : (
                <h4>
                  {formatCurrencyMask(i.doubleValue, i.currencyInformation)}
                </h4>
              )}
            </OCol>
          </ORow>
        ))}
      </OCol>

      {data?.parameters && Object.keys(data.parameters).length === 0 && (
        <h4 className="text-center w-100">
          Configuration not released yet. This feature is still in development.
        </h4>
      )}
    </ORow>
  )
}
export default React.memo(ConfigurationParameters)
