import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { config, helper } from '@/utils'
import { AppUser, UserGroupItem } from '@/interfaces'
import { ActionCreator, ApplicationState } from '@/store'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  getGroups,
  checkExternalUsers,
  actions
} from '@/store/slices/userGroups'
import useItemId from './useItemId'

const useAdminUsers = (currentUser: AppUser) => {
  const [currentGroup, setCurrentGroup] = useState<UserGroupItem | null>(null)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentGroupId = useItemId(config.urls.adminUserGroups)
  const isInternal = helper.isInternal(currentUser)
  const { userGroups } = useAppSelector((s: ApplicationState) => s.userGroups)

  const groups = isInternal
    ? [config.groups.unassignedGroup].concat(userGroups)
    : userGroups

  const refresh = useCallback(() => {
    dispatch(actions.resetUserGroups())
    dispatch(getGroups() as ActionCreator)
  }, [dispatch])

  const onSelected = (id: string) =>
    navigate(`/${config.urls.adminUserGroups}/${id}`)

  useEffect(() => {
    refresh()
    const companyIds = helper.getUserCompanyIds(currentUser)
    if (companyIds)
      companyIds.forEach((Id: any) =>
        dispatch(checkExternalUsers(Id) as ActionCreator)
      )
  }, [refresh, currentUser, dispatch])

  useEffect(() => {
    const group = groups.find(i => i.id === currentGroupId)
    setCurrentGroup(group || null)
  }, [currentGroupId, groups])

  return {
    currentGroup,
    groups,
    onSelected
  }
}

export default useAdminUsers
