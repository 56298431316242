import React from 'react'
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer'
import { ScenarioItemViewModel } from '@/interfaces'
import DisplayValue from './DisplayValue'

import { helper } from '@/utils'

interface Props {
  scenario?: ScenarioItemViewModel
  texts: any | null
}

const styles = StyleSheet.create({
  main: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 210,
    position: 'absolute'
  },
  background: {
    backgroundColor: '#99d9f0',
    minHeight: 160,
    zIndex: 200,
    opacity: 0.7,
    width: '100%',
    height: 210,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    position: 'absolute'
  },
  left: {
    padding: 5,
    fontSize: 15,
    width: '50%',
    zIndex: 200
  },
  right: {
    padding: 5,
    width: '50%',
    fontSize: 14,
    zIndex: 200
  },
  image: {
    position: 'absolute',
    width: '100%',
    zIndex: 1,
    left: 0,
    right: 0,
    top: 0
  }
})

const ReportMeta: React.FC<Props> = ({ scenario, texts }: Props) => (
  <View>
    <Image source="/images/rewind-home.png" style={styles.image} />
    <View style={styles.main}>
      <View style={styles.background}></View>
      <View style={styles.left}>
        <Text>{texts.report.title}</Text>
      </View>
      <View style={styles.right}>
        <DisplayValue name="Project" value={scenario?.project?.name} />
        <DisplayValue name="Customer" value={scenario?.companyName} />
        <DisplayValue name="Report generated" value={helper.currentDate} />
      </View>
    </View>
  </View>
)

export default ReportMeta
