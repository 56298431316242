import { config, costs } from '@/utils'
import { OnshoreCostCalculationViewModel } from '@/interfaces'

const { calculations } = config
const { getValue } = costs

const useCalculationCostChartData = (
  costCalculationForScenario: OnshoreCostCalculationViewModel | null
) => {
  const buildBarData = () => ({
    labels: ['Costs', 'Scrap value', 'Total'],
    datasets: [
      {
        label: 'Turbine disassembly cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.disassemblyCost
      },
      {
        label: 'Turbine removal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.removalCost
      },
      {
        label: 'Material disposal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.materialDisposalCost
      },
      {
        label: 'Turbine blade disposal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.bladeDisposalCost
      },
      {
        label: 'Turbine foundations removal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.turbineFoundationsDisposalCost
      },
      {
        label: 'Cable removal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.cableRemovalCost
      },
      {
        label: 'Removal of other on-site constructions',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor:
          calculations.chartColors.removalOfOtherOnSiteConstructions
      },

      {
        label: 'Access roads removal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.accessRoadsRemovalCost
      },
      {
        label: 'Crane pads removal cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.cranePadsRemovalCost
      },
      {
        label: 'Reinstate cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.reinstateCost
      },
      {
        label: 'Project overheads cost',
        data: [getValue(costCalculationForScenario, 0), 0, 0],
        backgroundColor: calculations.chartColors.projectOverheadCost
      },
      {
        label: 'Scrap value',
        data: [0, getValue(costCalculationForScenario, 0), 0],
        backgroundColor: calculations.chartColors.scrap
      },
      {
        label: 'Total',
        data: [0, 0, getValue(costCalculationForScenario, 0)],
        backgroundColor: calculations.chartColors.total
      }
    ]
  })
  return {
    costChartData: buildBarData()
  }
}
export default useCalculationCostChartData
