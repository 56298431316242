import React from 'react'
import { Page, ContentSection } from '../layout'

const ErrorsPage = () => (
  <Page title="An error occured">
    <ContentSection contentClassName="p-4">
      <p>We're sorry, but it appears that something went wrong.</p>
      <p>Please try the following:</p>
      <ul>
        <li>Refresh the page</li>
        <li>Clear your browser cache and cookies</li>
        <li>Try accessing the page from a different device or browser</li>
      </ul>
      <p>If the problem persists, please contact us for further assistance.</p>
    </ContentSection>
  </Page>
)

export default React.memo(ErrorsPage)
