import { Option } from '@dnvgl-onefoundation/onedesign-react'

import { getProjectTypeName } from './helper'
import { ProjectType } from '@/interfaces'

const projectTypeOptions = [
  ProjectType.Onshore,
  ProjectType.OffshoreBottomFixed,
  ProjectType.OffshoreFloating
].map(value => ({
  text: getProjectTypeName(value),
  value
})) as Option[]

const projects = { projectTypeOptions }

export default projects
