import { useState } from 'react'

const useLngLat = () => {
  const [lng, setLng] = useState<number | undefined>()
  const [lat, setLat] = useState<number | undefined>()

  return {
    setLng,
    setLat,
    lngLat: { lng, lat }
  }
}
export default useLngLat
