import { showConfirm } from '@dnvgl-onefoundation/onedesign-react'

export const deleteConfirm = (name: string) =>
  showConfirm('Delete', `Please confirm, if you want to delete "${name}".`)

export const resetDataConfirm = (name: string) =>
  showConfirm(
    'Reset',
    `Please confirm, if you want to reset all changes for ${name}.`
  )

export const addGroupConfirm = (
  name: string,
  displayType: string = 'company'
) =>
  showConfirm(
    `Add ${displayType}`,
    `Please confirm, if you want to add ${displayType} "${name}". The ${displayType} cannot be removed.`
  )
