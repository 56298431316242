import React, { useEffect, useState } from 'react'
import {
  CircularityRateViewModel,
  ProjectItemViewModel,
  ProjectType,
  ReportSectionCode
} from '@/interfaces'
import { Text, View } from '@react-pdf/renderer'
import Table from './Table'

import { helper } from '@/utils'

interface Props {
  project?: ProjectItemViewModel
  scenarioCircularityRate?: CircularityRateViewModel | null
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const { formatPercentage, formatTonnes, getAnchorMaterialName } = helper

const buildRows = (
  project?: ProjectItemViewModel,
  scenarioCircularityRate?: CircularityRateViewModel | null
) => {
  if (scenarioCircularityRate === null || scenarioCircularityRate === undefined)
    return []
  const {
    cablesTableData,
    cablesWeights,
    onshoreOffshoreWeights,
    floatingWeights,
    floatersTableData,
    substations
  } = scenarioCircularityRate
  const rows: any[] = []

  const projectType = project?.projectType

  // Floating
  if (
    floatingWeights &&
    floatersTableData &&
    projectType === ProjectType.OffshoreFloating
  ) {
    rows.push(['Floater'])
    rows.push([
      'Steel',
      formatTonnes(floatingWeights?.floaterSteelWeightT),
      formatPercentage(floatingWeights?.floaterSteelPercentage)
    ])
    rows.push([
      'Concrete',
      formatTonnes(floatingWeights?.floaterConcreteWeightT),
      formatPercentage(floatingWeights?.floaterConcretePercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(floatingWeights?.floaterOtherWeightT),
      formatPercentage(floatingWeights?.floaterOtherPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(floatingWeights?.floaterTotalWeightT),
      '100%'
    ])
    rows.push(['Mooring lines'])
    rows.push([
      'Steel',
      formatTonnes(floatingWeights?.mooringLinesSteelWeightT),
      formatPercentage(floatingWeights?.mooringLinesSteelPercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(floatingWeights?.mooringLinesOtherWeightT),
      formatPercentage(floatingWeights?.mooringLinesOtherPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(floatingWeights?.mooringLinesTotalWeightT),
      '100%'
    ])
    rows.push(['Anchors'])
    rows.push([
      getAnchorMaterialName(project?.anchor?.material),
      formatTonnes(floatingWeights?.anchorsMetalWeightT),
      formatPercentage(floatingWeights?.anchorsMetalPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(floatingWeights?.anchorsTotalWeightT),
      '100%'
    ])
  }

  // Onshore and OffshoreBottomFixed
  if (
    onshoreOffshoreWeights &&
    (projectType === ProjectType.Onshore ||
      projectType === ProjectType.OffshoreBottomFixed)
  ) {
    rows.push(['Foundations'])
    rows.push([
      'Steel',
      formatTonnes(onshoreOffshoreWeights?.foundationsSteelWeightT),
      formatPercentage(onshoreOffshoreWeights?.foundationsSteelPercentage)
    ])
    rows.push([
      'Concrete',
      formatTonnes(onshoreOffshoreWeights?.foundationsConcreteWeightT),
      formatPercentage(onshoreOffshoreWeights?.foundationsConcretePercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(onshoreOffshoreWeights?.foundationsOtherWeightT),
      formatPercentage(onshoreOffshoreWeights?.foundationsOtherPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(onshoreOffshoreWeights?.foundationsTotalWeightT),
      '100%'
    ])
  }

  // Array cables
  if (cablesTableData && cablesWeights) {
    rows.push(['Array cables'])
    rows.push([
      cablesTableData.arrayCablesMetalName,
      formatTonnes(cablesTableData?.arrayCablesMetalWeightT),
      formatPercentage(cablesWeights?.arrayCablesMetalPercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(cablesWeights?.arrayCablesOtherWeightT),
      formatPercentage(cablesWeights?.arrayCablesOtherPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(cablesWeights?.arrayCablesTotalWeightT),
      '100%'
    ])
  }

  // Export cables
  if (cablesTableData && cablesWeights) {
    rows.push(['Export cables'])
    rows.push([
      cablesTableData.exportCablesMetalName,
      formatTonnes(cablesTableData?.exportCablesMetalWeightT),
      formatPercentage(cablesWeights?.exportCablesMetalPercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(cablesWeights?.exportCablesOtherWeightT),
      formatPercentage(cablesWeights?.exportCablesOtherPercentage)
    ])
    rows.push([
      'Total',
      formatTonnes(cablesWeights?.exportCablesTotalWeightT),
      '100%'
    ])
  }
  if (substations.onshore) {
    rows.push(['Onshore Substation'])
    rows.push([
      'Steel',
      formatTonnes(substations.onshore?.steel),
      formatPercentage(substations.onshore?.steelPercentage)
    ])
    rows.push([
      'Concrete',
      formatTonnes(substations.onshore?.concrete),
      formatPercentage(substations.onshore.concretePercentage)
    ])
    rows.push([
      'Copper',
      formatTonnes(substations.onshore.copper),
      formatPercentage(substations.onshore.copperPercentage)
    ])
    rows.push([
      'Aluminium',
      formatTonnes(substations.onshore.aluminium),
      formatPercentage(substations.onshore.aluminiumPercentage)
    ])
    rows.push([
      'Other',
      formatTonnes(substations.onshore.other),
      formatPercentage(substations.onshore.otherPercentage)
    ])
    rows.push(['Total', formatTonnes(substations.onshore.total), '100%'])
  }

  return rows
}

const ReportBalanceOfPlantPage: React.FC<Props> = ({
  project,
  scenarioCircularityRate,
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.BalanceOfPlant]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="balanceofplant" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(value => value === ReportSectionCode.BalanceOfPlant) + 1}
        . Balance of plant
      </Text>
      <Text style={styles.paragraph}>
        {project?.projectType === ProjectType.Onshore &&
          texts?.report?.balanceOfPlant.Onshore}
        {project?.projectType === ProjectType.OffshoreBottomFixed &&
          texts?.report?.balanceOfPlant.OffshoreBottomFixed}
        {project?.projectType === ProjectType.OffshoreFloating &&
          texts?.report?.balanceOfPlant.OffshoreFloating}
      </Text>
      <Table
        name="Balance of plant"
        rows={
          scenarioCircularityRate
            ? buildRows(project, scenarioCircularityRate)
            : []
        }
      />
      <Text style={styles.paragraphSmall}>
        {texts?.report.balanceOfPlant.bottom}
      </Text>
    </View>
  )
}

export default ReportBalanceOfPlantPage
