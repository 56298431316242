import { OAlert } from '@dnvgl-onefoundation/onedesign-react'
import React from 'react'
import { config, helper } from '@/utils'

const FloatingAlertMessage = () => (
  <OAlert
    className="m-3"
    dismissable={false}
    message="Exciting news!"
    description={
      <div className="my-2">
        We're currently developing a new feature to calculate decommissioning
        costs for floating projects. While this feature is not yet live, we
        encourage you to start entering your project inputs now. This way,
        you'll be ready to hit the ground running as soon as our enhanced cost
        model is deployed to ReWind. By entering your data early, you'll:
        <ol className="mt-2">
          <li>Save time when the feature goes live</li>
          <li>Help us fine-tune the model to better serve your needs</li>
          <li>Be among the first to benefit from this powerful new tool</li>
        </ol>
        If you're interested in learning more, or would like support on floating
        projects offline, feel free to email us at{' '}
        <a href={helper.getFeedbackLink('Floating feature request')}>
          {config.support.to}
        </a>
        . Your input is valuable to us as we continue to improve ReWind and
        deliver cutting-edge solutions for your projects.
      </div>
    }
  />
)

export default React.memo(FloatingAlertMessage)
