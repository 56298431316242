import React from 'react'
import { TooltipHelpText } from '@/components/helpers/index'

interface Props {
  label?: string
  tooltip?: string
  value?: boolean
  yesLabel?: string
  noLabel?: string
  className?: string
  isLabelBold?: boolean
  onToggle?: (value?: boolean) => void
  disabled?: boolean
}

const RadioToggle = ({
  label,
  tooltip,
  value,
  yesLabel = 'Yes',
  noLabel = 'No',
  className,
  isLabelBold,
  onToggle,
  disabled = false
}: Props) => (
  <div className={className}>
    {!!label && (
      <div className={[isLabelBold ? 'font-weight-bold' : undefined].join(' ')}>
        {label}
        {!!tooltip && (
          <TooltipHelpText className="ml-2" message={tooltip} placement="top" />
        )}
      </div>
    )}
    <div
      className={`custom-control custom-radio d-inline-flex mt-2 ${!disabled ? 'pointer' : ''}`}
      onClick={() => {
        if (!value && !disabled) onToggle?.(true)
      }}>
      <input
        autoComplete="off"
        className="custom-control-input"
        type="radio"
        checked={!!value}
        readOnly
        disabled={disabled}
      />
      <label className="custom-control-label">{yesLabel}</label>
    </div>
    <div
      className={`custom-control custom-radio d-inline-flex pointer ml-4 ${!disabled ? 'pointer' : ''}`}
      onClick={() => {
        if (!!value && !disabled) onToggle?.(false)
      }}>
      <input
        autoComplete="off"
        className="custom-control-input"
        type="radio"
        checked={!value}
        readOnly
        disabled={disabled}
      />
      <label className="custom-control-label">{noLabel}</label>
    </div>
  </div>
)

export default React.memo(RadioToggle)
