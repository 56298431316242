import React from 'react'
import { ContentSection } from '@/components/layout'
import { ScenarioSimpleItemViewModel } from '@/interfaces'
import { config } from '@/utils'
import { AddItem } from '../helpers'
import { Link } from 'react-router-dom'
import { OBadge } from '@dnvgl-onefoundation/onedesign-react'

interface Props {
  alreadyAvailableNames?: string[]
  scenarios?: ScenarioSimpleItemViewModel[]
  scenarioName?: string
  addScenario?: (payload: any) => any
}

const { icons, urls } = config

const ProjectPageScenariosSection = ({
  alreadyAvailableNames = [],
  scenarios = [],
  scenarioName = '',
  addScenario
}: Props) => (
  <ContentSection
    title="Scenarios"
    icon={icons.scenario}
    className="overflow-hidden"
    underlineTitle
    actions={
      <AddItem
        alreadyAvailableNames={alreadyAvailableNames}
        type="scenario"
        buttonVariant="outlined"
        buttonSize="sm"
        label="Create new scenario for this project"
        fields={[
          {
            heading: 'Name *',
            id: 'name',
            defaultValue: scenarioName,
            isRequired: true
          }
        ]}
        inputGridColumns="12"
        onOK={addScenario}
      />
    }>
    {scenarios?.length ? (
      scenarios.map(scenario => (
        <Link key={scenario.id} to={`${urls.scenarios}/${scenario.id}`}>
          <OBadge className="mr-1 mb-1" outline>
            {scenario?.name}
          </OBadge>
        </Link>
      ))
    ) : (
      <p className="text-center">
        You have not created any scenarios for this project yet.
      </p>
    )}
  </ContentSection>
)

export default React.memo(ProjectPageScenariosSection)
