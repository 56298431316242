import { Option } from '@dnvgl-onefoundation/onedesign-react'
import { helper } from '@/utils'
import Table from './Table'

const { convertOptionsToTableRows } = helper

interface Props {
  decommissioningScope?: any[]
}

const ReportDecommissioningScope: React.FC<Props> = ({
  decommissioningScope
}) => (
  <Table
    name="Decommissioning scope"
    rows={convertOptionsToTableRows(decommissioningScope as Option[])}
  />
)

export default ReportDecommissioningScope
