import React, { ReactNode } from 'react'

interface Props {
  title?: string
  children?: ReactNode
}

const ContentSectionFlat = ({ title, children }: Props) => (
  <div className="border mb-3">
    <div className="p-3 font-weight-bold bg-light border-bottom">{title}</div>
    <div className="p-3">{children}</div>
  </div>
)

export default React.memo(ContentSectionFlat)
