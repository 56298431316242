import { OAlert, OButton } from '@dnvgl-onefoundation/onedesign-react'
import React, { useMemo, useState } from 'react'
import { Input } from '@/components/helpers'
import { ProviderInfoViewModel, RecycleMethod } from '@/interfaces'

interface Props {
  title: string
  providerInfo: ProviderInfoViewModel | null | undefined
  iconClass?: string
  isDisabled?: boolean
  isLocked?: boolean
  isUpdating?: boolean
  selectButtonVariant?: 'secondary' | 'subtle'
  onSelectClick?: () => void
  onResetClick?: () => void
  onChange?: (opt: { type: RecycleMethod; distance?: number }) => void
}

const SelectProviderControl = ({
  title,
  providerInfo,
  iconClass,
  isDisabled,
  isUpdating,
  selectButtonVariant,
  onSelectClick,
  onResetClick,
  onChange,
  isLocked
}: Props) => {
  const getRecycleMethod = (info: ProviderInfoViewModel | null | undefined) => {
    if (info?.isLandfill) {
      return RecycleMethod.Landfill
    }

    if ((info?.distance ?? -1) > -1) {
      return RecycleMethod.Distance
    }

    return RecycleMethod.Provider
  }
  const [recycleMethod, setRecycleMethod] = useState<RecycleMethod>(
    getRecycleMethod(providerInfo)
  )

  // below just sets the recycle method when provider info gets change
  useMemo(() => {
    const rm = getRecycleMethod(providerInfo)
    setRecycleMethod(rm)
  }, [providerInfo])

  const handleTypeChange = (type: RecycleMethod, distance?: number) => {
    if (isDisabled || isLocked) {
      return
    }

    setRecycleMethod(type)
    onChange?.({ type, distance })
  }

  return (
    <div className="pt-3 p-2 border-bottom">
      <div className="mb-2">
        <span>
          <i className={`${iconClass} fg-dark-blue mr-2`} />
          {title}
        </span>
      </div>
      <div className={`justify-content-between d-flex my-2 pointer`}>
        <div
          className={`custom-control custom-radio d-flex mt-2 pointer`}
          onClick={() => handleTypeChange(RecycleMethod.Provider)}>
          <input
            disabled={isLocked || isUpdating}
            name={title}
            className="custom-control-input"
            type="radio"
            value={RecycleMethod.Provider}
            checked={recycleMethod === RecycleMethod.Provider}
            readOnly
          />
          <label className="custom-control-label">Provider</label>
        </div>
        {!isDisabled && (
          <div
            className={`custom-control custom-radio d-flex mt-2 pointer`}
            onClick={() => handleTypeChange(RecycleMethod.Distance)}>
            <input
              disabled={isLocked || isUpdating}
              name={title}
              className="custom-control-input"
              type="radio"
              value={RecycleMethod.Distance}
              checked={recycleMethod === RecycleMethod.Distance}
              readOnly
            />
            <label className="custom-control-label">Distance</label>
          </div>
        )}
        {!isDisabled && (
          <div
            className={`custom-control custom-radio d-flex mt-2 pointer`}
            onClick={() => handleTypeChange(RecycleMethod.Landfill)}>
            <input
              disabled={isLocked || isUpdating}
              name={title}
              className="custom-control-input"
              type="radio"
              value={RecycleMethod.Landfill}
              checked={recycleMethod === RecycleMethod.Landfill}
              readOnly
            />
            <label className="custom-control-label">Landfill</label>
          </div>
        )}
      </div>
      {recycleMethod === RecycleMethod.Provider ? (
        providerInfo?.provider?.name ? (
          <OAlert
            className="mt-2"
            dismissable={!isDisabled && !isLocked}
            variant="info"
            message={providerInfo?.provider?.name}
            showIcon={false}
            onDismiss={() => onResetClick?.()}
          />
        ) : (
          <OButton
            variant={selectButtonVariant}
            className="w-100 mt-2"
            disabled={isLocked}
            onClick={() => {
              onSelectClick?.()
            }}>
            Click to select
          </OButton>
        )
      ) : null}
      {recycleMethod === RecycleMethod.Distance && (
        <>
          <label>Transportation distance (km)</label>
          <Input
            type="number"
            min={0}
            max={40000}
            disabled={isLocked || isUpdating}
            propertyName="distance"
            onBlur={(_, propertyValue) =>
              handleTypeChange(RecycleMethod.Distance, Number(propertyValue))
            }
            value={providerInfo?.distance}
          />
          <>The distance is used instead of the provider in the assessment.</>
        </>
      )}
      {recycleMethod === RecycleMethod.Landfill && (
        <>This part and material will go to the landfill.</>
      )}
    </div>
  )
}

export default React.memo(SelectProviderControl)
