import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionCreator } from '@/store'

import { api, helper } from '@/utils'
import { getErrorHandler } from './users'
import {
  CostCalculationOffshoreFixedConfigurationRegisterViewModel,
  CostCalculationOffshoreFloatingConfigurationRegisterViewModel,
  CostCalculationOnshoreConfigurationRegisterViewModel,
  CreateScenarioViewModel,
  CurrencyInformationViewModel,
  PaginatedListOfScenarioItemViewModel,
  ScenarioItemViewModel,
  UpdatePropertyViewModel,
  updateScenarioModel,
  updateScenarioProperty
} from '@/interfaces'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'

export interface ScenariosState {
  isLoading: boolean
  isUpdating: boolean
  isNotFound: boolean
  isMetalProcessingProviderRequired: boolean
  isBladeProcessingProviderIdRequired: boolean
  isConcreteProcessingProviderRequired: boolean
  scenarios: PaginatedListOfScenarioItemViewModel | null
  scenario: ScenarioItemViewModel | null
  scenarioCurrency: CurrencyInformationViewModel | null
  scenarioCostCalcationConfiguration:
    | CostCalculationOnshoreConfigurationRegisterViewModel
    | CostCalculationOffshoreFixedConfigurationRegisterViewModel
    | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
    | null
}

const initialState: ScenariosState = {
  isLoading: false,
  isUpdating: false,
  isNotFound: false,
  isMetalProcessingProviderRequired: true,
  isBladeProcessingProviderIdRequired: true,
  isConcreteProcessingProviderRequired: true,
  scenarios: null,
  scenarioCurrency: null,
  scenario: null,
  scenarioCostCalcationConfiguration: null
}

const { isValidGuid } = helper

export const scenariosSlice = createSlice({
  name: 'scenarios',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload
    },
    setIsMetalProcessingProviderRequired: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isMetalProcessingProviderRequired = action.payload
    },
    setIsBladeProviderRequired: (state, action: PayloadAction<boolean>) => {
      state.isBladeProcessingProviderIdRequired = action.payload
    },
    setIsConcreteBladeProviderRequired: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConcreteProcessingProviderRequired = action.payload
    },
    setIsNotFound: (state, action: PayloadAction<boolean>) => {
      state.isNotFound = action.payload
    },
    setScenarios: (
      state,
      action: PayloadAction<PaginatedListOfScenarioItemViewModel | null>
    ) => {
      if (Array.isArray(action?.payload?.items)) {
        action?.payload?.items.forEach((x: any) => {
          x.projectName = x.project?.name
          x.projectType = x.project?.projectType
        })
      }
      state.scenarios = action.payload
    },
    setScenario: (
      state,
      action: PayloadAction<ScenarioItemViewModel | null>
    ) => {
      state.scenario = action.payload
    },
    setCurrency: (
      state,
      action: PayloadAction<CurrencyInformationViewModel | null>
    ) => {
      state.scenarioCurrency = action.payload
    },
    setScenarioCostCalculationConfiguration: (
      state,
      action: PayloadAction<
        | CostCalculationOnshoreConfigurationRegisterViewModel
        | CostCalculationOffshoreFixedConfigurationRegisterViewModel
        | CostCalculationOffshoreFloatingConfigurationRegisterViewModel
        | null
      >
    ) => {
      state.scenarioCostCalcationConfiguration = action.payload
    }
  }
})

export const { actions } = scenariosSlice

export const resetScenario: ActionCreator = () => dispatch => {
  dispatch(actions.setScenarios(null))
  dispatch(actions.setScenario(null))
}

export const getScenarios: ActionCreator = () => (dispatch, getState) => {
  const state = getState()
  if (state.scenarios.isLoading || state.scenarios.isUpdating) return

  dispatch(resetScenario())
  dispatch(actions.setIsLoading(true))

  return api.scenarios
    .readAll()
    .then(data => {
      dispatch(actions.setScenarios(data))
    })
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getScenario: ActionCreator =
  (id: string) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading || state.scenarios.isUpdating) return

    dispatch(resetScenario())
    dispatch(actions.setIsLoading(true))
    dispatch(actions.setIsNotFound(false))

    return api.scenarios
      .read(id)
      .then(
        data => isValidGuid(data?.id) && dispatch(actions.setScenario(data))
      )
      .catch(e => {
        dispatch(actions.setIsNotFound(true))
        getErrorHandler(dispatch)(e)
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

export const getScenarioConfiguration: ActionCreator =
  (id: string) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading || state.scenarios.isUpdating) return

    dispatch(actions.setIsLoading(true))

    return (
      api.configurations
        .readScenarioCostConfiguration(id)
        .then(data => {
          if (data)
            dispatch(actions.setScenarioCostCalculationConfiguration(data))
        })
        // .catch(e => getErrorHandler(dispatch)(e))
        .finally(() => dispatch(actions.setIsLoading(false)))
    )
  }

export const createScenario: ActionCreator =
  (payload: CreateScenarioViewModel) => dispatch => {
    dispatch(actions.setIsLoading(true))
    return api.scenarios
      .create(payload)
      .then(id => (isValidGuid(id) ? id : undefined))
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))
  }

export const updateProperty: ActionCreator =
  (updateProperty: updateScenarioProperty) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading || state.scenarios.isUpdating) return

    dispatch(actions.setIsUpdating(true))
    const payload: UpdatePropertyViewModel = {
      propertyName: updateProperty.propertyName,
      propertyValue: updateProperty.propertyValue
    }
    return api.scenarios
      .updateProperty(updateProperty.id, payload)
      .then(data => {
        showSuccess('Scenario updated.')
        dispatch(actions.setScenario(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }

export const updateScenario: ActionCreator =
  (payload: updateScenarioModel) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading || state.scenarios.isUpdating) return

    dispatch(actions.setIsUpdating(true))
    return api.scenarios
      .updateMultipleProperties(payload.scenarioId, payload)
      .then(data => {
        showSuccess('Scenario updated.')
        dispatch(actions.setScenario(data))
      })
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsUpdating(false)))
  }

export const getScenarioCurrency: ActionCreator =
  (id: string) => (dispatch, getState) => {
    const state = getState()
    if (state.scenarios.isLoading) return

    return api.currencies
      .getInfo(id)
      .then(data => dispatch(actions.setCurrency(data)))
      .catch(e => {
        getErrorHandler(dispatch)(e)
      })
      .finally(() => dispatch(actions.setIsLoading(false)))
  }
