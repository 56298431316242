import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, helper } from '@/utils'
import {
  CreateProviderViewModel,
  PaginatedListOfProviderItemViewModel,
  ProviderItemViewModel,
  UpdatePropertyViewModel
} from '@/interfaces'
import { getErrorHandler } from './users'
import { ActionCreator } from '../index'
import { showSuccess } from '@dnvgl-onefoundation/onedesign-react'

export interface ProvidersState {
  isLoading: boolean
  providers: PaginatedListOfProviderItemViewModel | null
  provider: ProviderItemViewModel | null
}

const initialState: ProvidersState = {
  isLoading: false,
  providers: null,
  provider: null
}

const { isValidGuid } = helper

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setProviders: (
      state,
      action: PayloadAction<PaginatedListOfProviderItemViewModel | null>
    ) => {
      state.providers = action.payload
    },
    setProvider: (
      state,
      action: PayloadAction<ProviderItemViewModel | null>
    ) => {
      state.provider = action.payload
    }
  }
})

export const { actions } = providersSlice

export const getProviders: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setProviders(null))
  dispatch(actions.setProvider(null))
  return api.providers
    .readAll()
    .then((data: PaginatedListOfProviderItemViewModel) =>
      dispatch(actions.setProviders(data))
    )
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const getProvider: ActionCreator = id => dispatch => {
  dispatch(actions.setIsLoading(true))
  dispatch(actions.setProvider(null))
  dispatch(actions.setProviders(null))

  return api.providers
    .read(id)
    .then((data: ProviderItemViewModel) => dispatch(actions.setProvider(data)))
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoading(false)))
}

export const createProvider =
  (payload: CreateProviderViewModel) => (dispatch: any) =>
    api.providers
      .create(payload)
      .then((id: string) => (isValidGuid(id) ? id : undefined))
      .catch(getErrorHandler(dispatch))
      .finally(() => dispatch(actions.setIsLoading(false)))

export const updateProperty =
  (propertyName: string, propertyValue: string | number, id: string) =>
  (dispatch: any) => {
    const payload: UpdatePropertyViewModel = { propertyName, propertyValue }
    return api.providers
      .update(id, payload)
      .then((data: any) => {
        showSuccess('Provider updated.')
        if (data?.id) dispatch(actions.setProvider(data))
      })
      .catch(getErrorHandler(dispatch))
  }
