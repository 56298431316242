import React, { CSSProperties } from 'react'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'

import { helper } from '@/utils'

const fixedContainerStyles = {
  position: 'fixed',
  top: '40%',
  right: '-28px',
  transform: 'rotate(-90deg)'
}

const Feedback = () => (
  <div style={fixedContainerStyles as CSSProperties}>
    <a href={helper.getFeedbackLink()}>
      <OButton className="no-print" variant="primary" size="small">
        Feedback
      </OButton>
    </a>
  </div>
)

export default React.memo(Feedback)
