import React from 'react'
import { useTurbineOptions, useTurbines } from '@/hooks'
import { config, helper } from '@/utils'
import { AddItem, ItemsCollection } from '@/components/helpers'
import { Page } from '@/components/layout'
import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'
import { ApplicationType } from '@/interfaces'

const { urls } = config
const { isNotDraft, buildItemLink, toLocaleDate } = helper

const TurbinesPage = () => {
  const { isLoading, items, alreadyAvailableNames, add, saveDraft } =
    useTurbines()
  const { newTurbineFields } = useTurbineOptions()
  return (
    <Page
      title="Turbines"
      isLoading={isLoading}
      actions={
        <OTooltip content="Add new turbine to the library" placement="left">
          <AddItem
            allowSavingDraft
            disabled={isLoading}
            type="turbine"
            fields={newTurbineFields}
            inputGridColumns="6"
            alreadyAvailableNames={alreadyAvailableNames}
            onOK={add}
            onCancel={saveDraft}
          />
        </OTooltip>
      }>
      <ItemsCollection
        id="TurbinesPage"
        isLoading={isLoading}
        items={items}
        itemName="turbine"
        fields={[
          {
            name: 'name',
            heading: 'Name',
            sortable: true,
            formatter: (name: string) =>
              buildItemLink(
                [
                  urls.turbines,
                  items?.find?.((item: any) => item.name === name)?.id
                ].join('/'),
                name
              )
          },
          {
            name: 'modelNumber',
            heading: 'Model',
            sortable: true
          },
          {
            name: 'manufacturer',
            heading: 'Manufacturer',
            sortable: true
          },
          {
            name: 'application',
            heading: 'Application',
            sortable: true,
            formatter: (app: ApplicationType) =>
              app === ApplicationType.Offshore ? 'Offshore' : 'Onshore'
          },
          {
            name: 'createdAt',
            heading: 'Date added',
            sortable: true,
            formatter: (date: string) => toLocaleDate(date)
          },
          {
            name: 'projectLinks',
            heading: 'Projects'
          },
          {
            name: 'companyName',
            heading: 'Company',
            sortable: true
          }
        ]}
        tabs={[
          { title: 'Saved turbines', filter: isNotDraft },
          { title: 'Drafts', filter: (i: any) => i?.isDraft }
        ]}
        filterKeys={['name', 'modelNumber', 'manufacturer', 'companyName']}
      />
    </Page>
  )
}

export default React.memo(TurbinesPage)
