import providers from './providers'
import helper from './helper'

const { providerTypeNames, providerMaterialsEnumNames, providerServiceNames } =
  providers

const getEnumFlagValues = (value?: number | null): number[] => {
  if (typeof value !== 'number') return []
  const values: number[] = []
  while (value) {
    const bit = value & (~value + 1)
    values.push(bit)
    value ^= bit
  }
  return values
}

const getTotal = (names: any) =>
  helper.getArraySum(Object.keys(names).map(i => parseInt(i) || 0))

const total = {
  types: getTotal(providerTypeNames),
  materials: getTotal(providerMaterialsEnumNames),
  services: getTotal(providerServiceNames)
}

const enumFlags = {
  providerTypeNames,
  providerMaterialsEnumNames,
  providerServiceNames,
  total,
  getEnumFlagValues,
  getTotal
}

export default enumFlags
