import { Text, View } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import {
  ProjectItemViewModel,
  ProjectType,
  ReportSectionCode
} from '@/interfaces'
import ConditionalRender from './ConditionalRenderer'
import ReportList from '@/components/pdf/ReportList'
import { helper } from '@/utils'

interface Props {
  project?: ProjectItemViewModel
  includeScrap?: boolean
  styles?: any
  texts?: any
  setPages?: any
  printSections: number[]
}

const { currentYear } = helper

const ReportDecommissioningCostsPage: React.FC<Props> = ({
  project,
  includeScrap,
  styles,
  texts,
  setPages,
  printSections
}) => {
  const [page, setPage] = useState<number | undefined>()
  useEffect(() => {
    page && setPages({ [ReportSectionCode.DecommissioningCosts]: page })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return (
    <View>
      <View render={({ pageNumber }) => setPage(pageNumber)} />
      <Text id="decommissioningcosts" style={styles.pageTitle}>
        {printSections
          .sort()
          .findIndex(
            value => value === ReportSectionCode.DecommissioningCosts
          ) + 1}
        . Decommissioning costs
      </Text>
      {project?.projectType === ProjectType.Onshore && (
        <>
          <>
            <Text style={styles.paragraph}>
              {texts?.report.decommissioningCostsIntro.Onshore}
            </Text>
            {texts?.report.cost.Onshore.map((i: any, key: number) => (
              <ConditionalRender key={key} data={i} />
            ))}
          </>

          {project?.projectType === ProjectType.Onshore && includeScrap && (
            <>
              <ConditionalRender
                key="onshoreScrap"
                data={[texts?.report.cost.OnshoreScrapConsideration]}
              />
            </>
          )}
          {project?.projectType === ProjectType.Onshore && (
            <Text style={styles.paragraph}>
              {texts?.report.cost.OnshoreScope}
            </Text>
          )}
        </>
      )}
      {project?.projectType === ProjectType.OffshoreBottomFixed &&
        texts?.report.cost.OffshoreBottomFixed.map((i: any) =>
          i === undefined ? null : typeof i === 'string' ? (
            <Text style={styles.paragraph}>
              {i.replace(
                texts?.report.costAssumptions.CurrentYear,
                currentYear.toString()
              )}
            </Text>
          ) : (
            <ReportList items={i as string[]} />
          )
        )}
      {project?.projectType === ProjectType.OffshoreFloating &&
        texts?.report.cost.OffshoreFloating.map((i: any) =>
          i === undefined ? null : typeof i === 'string' ? (
            <Text style={styles.paragraph}>
              {i.replace(
                texts?.report.costAssumptions.CurrentYear,
                currentYear.toString()
              )}
            </Text>
          ) : (
            <ReportList items={i as string[]} />
          )
        )}
    </View>
  )
}

export default ReportDecommissioningCostsPage
