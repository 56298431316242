import React from 'react'
import { Page } from '@/components/layout'
import { NavigationCards } from '@/components/helpers'
import { config } from '@/utils'
import { ORow } from '@dnvgl-onefoundation/onedesign-react'

const { icons, urls } = config

const navigationItems = [
  {
    path: `${urls.admin}/UserGroups`,
    icon: icons.users,
    title: 'Manage users'
  },
  {
    path: urls.adminTurbinesImport,
    icon: icons.import,
    title: 'Import turbines'
  },
  {
    path: urls.adminProvidersImport,
    icon: icons.import,
    title: 'Import providers'
  }
]

const AdminPage = () => (
  <Page title="Administration">
    <ORow>
      <NavigationCards items={navigationItems} cardColumns="4" />
    </ORow>
  </Page>
)

export default React.memo(AdminPage)
