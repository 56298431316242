import {
  OnshoreFoundationSpecificationViewModel,
  ProjectItemViewModel,
  ProjectTurbineViewModel
} from '@/interfaces'
import React from 'react'
import { helper } from '@/utils'
import { ContentSectionValue } from '../layout'
import { SuperscriptUnit } from '../helpers'

interface Props {
  p?: ProjectItemViewModel | null
  projectTurbines?: ProjectTurbineViewModel[] | null
  metMastToBeRemoved?: boolean
}

const { getCheckedText } = helper

const SummaryStepDetailsOnshore = ({
  p,
  metMastToBeRemoved,
  projectTurbines
}: Props) => {
  const onshoreFoundations = projectTurbines?.[0]
    ?.foundationSpecification as OnshoreFoundationSpecificationViewModel
  return (
    <>
      <ContentSectionValue
        className="my-2"
        left="Access road length"
        right={p?.accessRoadLengthKm ? `${p?.accessRoadLengthKm} km` : null}
      />
      <ContentSectionValue
        className="my-2"
        left="Array cable length"
        right={p?.arrayCableLengthKm ? `${p?.arrayCableLengthKm} km` : null}
      />
      <ContentSectionValue
        className="my-2"
        left="Export cable length"
        right={p?.exportCableLengthKm ? `${p?.exportCableLengthKm} km` : null}
      />
      <ContentSectionValue
        className="my-2"
        left="Foundation volume"
        right={
          <span>
            {onshoreFoundations?.foundationVolume?.toFixed?.(2)}
            {<SuperscriptUnit unit="m" power={3} className="ml-1" />}
          </span>
        }
      />
      <ContentSectionValue
        className="my-2"
        left="Foundation total weight"
        right={
          onshoreFoundations?.totalWeight
            ? `${onshoreFoundations?.totalWeight} tonnes`
            : null
        }
      />
      <ContentSectionValue
        className="my-2"
        left="HV substation"
        right={getCheckedText(!!p?.hasOnshoreSubstation)}
      />
      <ContentSectionValue
        className="my-2"
        left="Met mast number"
        right={p?.metMastNumber}
      />
      {metMastToBeRemoved !== undefined && (
        <ContentSectionValue
          className="my-2"
          left="Met masts"
          right={getCheckedText(metMastToBeRemoved)}
        />
      )}
    </>
  )
}

export default React.memo(SummaryStepDetailsOnshore)
