import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  showConfirm,
  showError,
  showPrompt,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { api, helper, config, enumFlags, providers, locations } from '@/utils'
import { ActionCreator } from '@/store'
import { useCountries, useLngLat, useLocalStorage } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { getProvider, updateProperty } from '@/store/slices/providers'
import { getCountries } from '@/store/slices/general'
import { TabName } from '@/interfaces'
import { getErrorHandler } from '@/store/slices/users'

const {
  isSuperAdministrator,
  getArraySum,
  waitaSecond,
  getPercentage,
  formatCoordinates
} = helper
const { getEnumFlagValues } = enumFlags
const { icons, urls, messages } = config
const { iconCodes, pinColors } = providers
const { getCoordinatesByAddress } = locations

const useProvider = () => {
  const { id } = useParams()
  const { lngLat, setLng, setLat } = useLngLat()
  const navigate = useNavigate()
  const state = useAppSelector(s => s)
  const { isLoading, provider } = state.providers
  const { countryOptions } = state.general
  const { currentUser } = state.users
  const dispatch = useAppDispatch()
  const { countries } = useCountries()
  const get = () => {
    dispatch(getProvider(id) as ActionCreator)
    dispatch(getCountries() as any)
  }
  const [, setProvidersActiveTab] = useLocalStorage<number>('ProvidersPage')
  const [address, setAddress] = useState<string | null>(null)

  const updateLocation = (country: string) => {
    const countryData = countries?.find?.(i => i.id === country)

    if (countryData?.location)
      waitaSecond(() => onUpdate('location', countryData.location))
  }

  const onLocationUpdate = (e: any) => {
    const longitude = e?.lngLat?.lng ? e?.lngLat?.lng.toFixed?.(4) : 0
    const latitude = e?.lngLat?.lat ? e?.lngLat?.lat.toFixed?.(4) : 0
    setLng(longitude)
    setLat(latitude)
    onUpdate('location', { longitude, latitude })
  }

  const onRemove = () =>
    showConfirm(
      'Remove',
      `Please confirm removing the provider. ${messages.infoWillBeLost}`
    ).then((confirmed: boolean) => {
      if (confirmed)
        api.providers
          .delete(`${id}`)
          .then(() => {
            showSuccess('Provider removed.', provider?.name)
            navigate(urls.providers)
          })
          .catch(getErrorHandler(dispatch))
    })

  const onStateToggle = () => onUpdate?.('isDraft', !provider?.isDraft)

  useEffect(() => {
    if (provider?.location) {
      setLng(provider?.location?.longitude)
      setLat(provider?.location?.latitude)
    }

    // Update location
    if (provider?.countryId && provider?.location === null)
      updateLocation(provider.countryId)

    if (provider?.address) setAddress(provider?.address)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider])

  useEffect(() => {
    if (id) get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const onUpdate = (
    propertyName: string,
    propertyValue: string | number | boolean | any
  ) => dispatch(updateProperty(propertyName, propertyValue, `${id}`))

  const onCountryChange = (countryId: string) => {
    onUpdate('countryId', countryId)
    updateLocation(countryId)
  }

  const onEnumUpdate = (
    propertyName: string,
    previousValues: number[],
    value: number
  ) => {
    if (previousValues?.includes(value))
      previousValues = previousValues.filter(i => i !== value)
    else previousValues = [...previousValues, value]
    onUpdate(propertyName, getArraySum(previousValues))
  }

  const onRename = () =>
    showPrompt('Rename provider', provider?.name).then(value => {
      if (value) onUpdate?.('name', value)
    })

  const providerTypes = getEnumFlagValues(provider?.providerTypes) || []
  const materials = getEnumFlagValues(provider?.materials) || []
  const services = getEnumFlagValues(provider?.services) || []

  const multipleProviderTypes = providerTypes?.length > 1

  const onSave = () => waitaSecond(() => onUpdate?.('isDraft', false))

  const onSaveToDrafts = () => {
    onUpdate?.('isDraft', true)
    setProvidersActiveTab(TabName.Drafts)
    waitaSecond(() => navigate(urls.providers))
  }

  const onSaveAndClose = () => {
    onUpdate?.('isDraft', false)
    setProvidersActiveTab(TabName.Saved)
    waitaSecond(() => navigate(urls.providers))
  }

  const onSetCoordinatesByAddress = () =>
    address &&
    getCoordinatesByAddress(address)
      .then((data: any) => {
        const location = {
          longitude: data?.center?.[0],
          latitude: data?.center?.[1]
        }
        showConfirm(
          data?.place_name,
          `Coordinates: ${formatCoordinates(
            location
          )}. Relevance: ${getPercentage(data?.relevance)}%. `
        ).then(confirmed => {
          if (confirmed) onUpdate('location', location)
        })
      })
      .catch(error => showError(error))

  return {
    isLoading,
    countryOptions,
    provider,
    providerTypes,
    materials,
    services,
    lngLat,
    iconClass: multipleProviderTypes
      ? icons.multiple
      : (iconCodes as any)?.[providerTypes?.[0]],
    pinColor: multipleProviderTypes
      ? undefined
      : (pinColors as any)?.[providerTypes?.[0]],
    isEditable: isSuperAdministrator(currentUser),
    setAddress,
    onSetCoordinatesByAddress,
    onLocationUpdate,
    onRename,
    onUpdate,
    onCountryChange,
    onEnumUpdate,
    onRemove,
    onStateToggle,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  }
}
export default useProvider
