import React from 'react'
import { OToggleSwitch } from '@dnvgl-onefoundation/onedesign-react'
import TooltipHelpText from './TooltipHelpText'

interface Props {
  isEditable: boolean
  isChecked: boolean
  name: string
  tooltip: string
  onEnumUpdate?: Function
}

const ProviderToggle: React.FC<Props> = ({
  isEditable,
  isChecked,
  name,
  tooltip,
  onEnumUpdate
}) =>
  isEditable ? (
    <OToggleSwitch
      className="pointer mb-3 z-index-auto"
      textLocation="hidden"
      checked={isChecked}
      disabled={!isEditable}
      onChange={() => onEnumUpdate?.()}>
      {name}
      &nbsp;
      <TooltipHelpText message={tooltip} />
    </OToggleSwitch>
  ) : (
    <div className={isChecked ? 'font-weight-bold' : 'd-none'}>
      {name}&nbsp;
      <TooltipHelpText message={tooltip} />
    </div>
  )

export default React.memo(ProviderToggle)
