import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

import { helper } from '@/utils'
import { ProjectItemViewModel } from '@/interfaces'
import CopyrightSymbolSvg from './CopyrightSymbolSvg'

interface Props {
  project?: ProjectItemViewModel
}

const { currentYear } = helper

const styles = StyleSheet.create({
  label: {
    fontSize: 12,
    fontFamily: 'DNV Display Medium'
  },
  footer: {
    position: 'absolute',
    bottom: 10,
    left: 0,
    right: 0,
    flexDirection: 'row',
    paddingLeft: 0
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderTop: '1 solid #CCC',
    paddingTop: 4,
    marginRight: '2cm',
    marginLeft: '2cm',
    width: '100%'
  },
  left: {
    display: 'flex',
    width: '80%',
    fontSize: 8
  },
  right: {
    display: 'flex',
    textAlign: 'right',
    width: '20%'
  },
  pageNumber: {
    paddingTop: 8,
    fontSize: 12
  },
  leftText: {
    flexDirection: 'row'
  },
  textContainer: {
    display: 'flex',
    marginRight: 3
  }
})

// Create Footer Component
const ReportFooter: React.FC<Props> = ({ project }) => (
  <View fixed style={styles.footer}>
    <View style={styles.container}>
      <View style={styles.left}>
        <Text style={styles.label}>DNV ReWind Report</Text>
        <Text style={styles.leftText}>Customer: {project?.companyName}</Text>
        <View style={styles.leftText}>
          <View style={styles.textContainer}>
            <Text>Copyright</Text>
          </View>
          <View style={styles.textContainer}>
            <CopyrightSymbolSvg />
          </View>
          <View style={styles.textContainer}>
            <Text>DNV AS {currentYear}</Text>
          </View>
        </View>
      </View>
      <View style={styles.right}>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `Page ${pageNumber} / ${totalPages}`
          }
        />
      </View>
    </View>
  </View>
)

export default ReportFooter
