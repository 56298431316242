const fireDomEvent = (el: any, etype: string) => {
  if (el?.fireEvent) el.fireEvent('on' + etype)
  else {
    var evObj = document.createEvent('Events')
    evObj?.initEvent(etype, true, false)
    el?.dispatchEvent(evObj)
  }
}

const simulateDomClick = (element?: string) =>
  element && fireDomEvent(document.querySelector(element), 'click')

const addClass = (selector: string, className: string) => {
  const elements = document.querySelectorAll(selector)
  elements.forEach(element => element.classList.add(className))
}
const removeClass = (selector: string, className: string) => {
  const elements = document.querySelectorAll(selector)
  elements.forEach(element => element.classList.remove(className))
}

const doc = {
  fireDomEvent,
  simulateDomClick,
  addClass,
  removeClass
}

export default doc
