import { RefObject, useEffect } from 'react'

const useClickOutside = (
  ref: RefObject<HTMLElement>,
  callback: () => void
): void => {
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node

    // Check if the target is the ref container or a child of the ref container
    if (
      ref.current &&
      !ref.current.contains(target) &&
      !ref.current.isEqualNode(target)
    )
      callback()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => document.removeEventListener('mousedown', handleClickOutside)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, callback])
}

export default useClickOutside
