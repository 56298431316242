import React from 'react'
import {
  Card,
  Divider,
  FormControl,
  FormLabel,
  Option,
  Select,
  Typography
} from '@mui/joy'
import { CoordinatesValue, TooltipHelpText } from '../helpers'
import { MapBoxPin, MapBoxWrapper } from '@/components/maps'
import { useCountries } from '@/hooks'
import { helper, projects } from '@/utils'
import { ProjectItemViewModel, LatLng, ProjectType } from '@/interfaces'
import { Grid2 } from '@mui/material'
import ReInput from '@/components/helpers/ReInput'
import { SpinIndicator } from '@/components/layout'
import IconComponent from '@/components/helpers/IconComponent'
import * as Constants from '@/components/helpers/Constants'

interface Props {
  isLocked?: boolean
  isEditable?: boolean
  isUpdating?: boolean
  project?: ProjectItemViewModel | null
  lngLat: LatLng
  onCountryChange: (e: any) => void
  onUpdate: (propertyName: string, propertyValue: any) => void
  onLocationUpdate: (e: any) => void
}

const { addPluralS, toHtmlDate, numberToText } = helper
const { projectTypeOptions } = projects

const ProjectPageSiteSection = ({
  isLocked,
  isEditable,
  isUpdating,
  project,
  lngLat,
  onCountryChange,
  onUpdate,
  onLocationUpdate
}: Props) => {
  const { countryOptions } = useCountries()

  const location = {
    latitude: lngLat?.lat,
    longitude: lngLat?.lng
  }

  const isProjectWithScenarios = !!project?.scenarios?.length

  return (
    <Card sx={{ backgroundColor: 'white', marginTop: '16px' }}>
      {isUpdating && <SpinIndicator />}
      <Typography level="h4" sx={{ display: 'flex', alignItems: 'center' }}>
        <IconComponent iconClass="fal fas fa-map-pin mr-2" />
        Site
      </Typography>
      <Divider />
      <Grid2 container gap={2} spacing={2} mb={1}>
        <ReInput
          propertyName="name"
          gridSize={6}
          label="Project name"
          defaultValue={project?.name}
          disabled={isUpdating || isLocked}
          onBlur={(n, v) => onUpdate(n, v)}
          data-testid={Constants.projectNameInput}
        />
        <ReInput
          propertyName="type"
          gridSize={6}
          label="Type"
          readOnly
          defaultValue={
            projectTypeOptions?.find(i => i.value === project?.projectType)
              ?.text
          }
          disabled={isUpdating || isLocked}
          variant="plain"
          data-testid={Constants.projectType}
        />
        <Grid2 size={6}>
          {isProjectWithScenarios ? (
            <ReInput
              propertyName="country"
              gridSize={12}
              label={
                <>
                  Country&nbsp;
                  <TooltipHelpText
                    message={`This project already contains ${numberToText(
                      project?.scenarios?.length
                    )} scenario${addPluralS(
                      project?.scenarios?.length
                    )} specific to its current country setting. To ensure data consistency and integrity, the country setting cannot be modified once scenarios are added. If your work requires scenarios in a different country, please create a new project for that country.`}
                  />
                </>
              }
              readOnly
              defaultValue={
                countryOptions.find(i => i.value === project.countryId)?.text
              }
              disabled={isUpdating || isLocked}
              variant="plain"
            />
          ) : (
            <FormControl>
              <FormLabel>Country</FormLabel>
              <Select
                defaultValue={project?.countryId}
                disabled={isUpdating || isLocked}
                onChange={(_, v) => onCountryChange(v)}>
                {countryOptions.map(country => (
                  <Option key={country.text} value={country?.value}>
                    {country.text}
                  </Option>
                ))}
              </Select>
            </FormControl>
          )}
        </Grid2>
        <CoordinatesValue
          lngLat={lngLat}
          isEditable={isEditable}
          isLocked={isLocked}
          onLocationUpdate={onLocationUpdate}
        />
        <ReInput
          propertyName="dateOfCommissioning"
          gridSize={6}
          label="Date of commissioning"
          type="date"
          disabled={isUpdating || isLocked}
          defaultValue={toHtmlDate(`${project?.dateOfCommissioning}`)}
          onBlur={(n, v) => onUpdate(n, v)}
          slotProps={{
            input: {
              min: '1980-01-01',
              max: `${new Date().getFullYear() + 100}-12-31`
            }
          }}
        />
        <ReInput
          propertyName="assumedEndOfLifeDate"
          label="Assumed end of life date"
          gridSize={6}
          type="date"
          disabled={isUpdating || isLocked}
          defaultValue={toHtmlDate(`${project?.assumedEndOfLifeDate}`)}
          onBlur={(n, v) => onUpdate(n, v)}
          slotProps={{
            input: {
              min: project?.dateOfCommissioning
                ? toHtmlDate(`${project?.dateOfCommissioning}`)
                : '1980-01-01',
              max: `${new Date().getFullYear() + 100}-12-31`
            }
          }}
          min={
            project?.dateOfCommissioning
              ? toHtmlDate(`${project?.dateOfCommissioning}`)
              : '1980-01-01'
          }
          max={`${new Date().getFullYear() + 100}-12-31`}
        />
        <ReInput
          propertyName="address"
          label={
            project?.projectType === ProjectType.Onshore
              ? 'Address'
              : 'Planned installation/decommissioning port'
          }
          disabled={isUpdating || isLocked}
          defaultValue={project?.address ?? ''}
          onBlur={(n, v) => onUpdate(n, v)}
        />
      </Grid2>
      {!!project && location?.latitude && location?.longitude && (
        <MapBoxWrapper height={230} zoom={4} {...location}>
          {!!lngLat?.lat && !!lngLat.lng && (
            <MapBoxPin
              draggable={!isUpdating}
              onDragEnd={onLocationUpdate}
              {...location}
            />
          )}
        </MapBoxWrapper>
      )}
    </Card>
  )
}

export default React.memo(ProjectPageSiteSection)
