import { ReactElement } from 'react'
import { OAlert } from '@dnvgl-onefoundation/onedesign-react'
import { SpinIndicator } from '../layout'

interface Props {
  alwaysDisplayContent?: boolean
  children?: ReactElement
  isRequesting?: boolean
  isRequested?: null | string
}

const RequestToTeamsCalculationWrapper = ({
  alwaysDisplayContent,
  children,
  isRequesting,
  isRequested
}: Props) => {
  if (isRequesting)
    return (
      <div className="my-5">
        <SpinIndicator />
      </div>
    )

  if (isRequested)
    return (
      <>
        <OAlert
          dismissable={false}
          variant="success"
          message="Thank you!"
          description={
            <>
              <p className="mt-2">We have received your request.</p>
              <p>{isRequested}</p>
            </>
          }
        />
        {alwaysDisplayContent && children}
      </>
    )

  return children || null
}

export default RequestToTeamsCalculationWrapper
