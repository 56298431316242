import {
  ApplicationType,
  BladeSparMaterial,
  GeneratorType,
  Manufacturer,
  WindClass
} from '@/interfaces'
import { config } from '@/utils'
import { FieldInputType, FieldType } from '@dnvgl-onefoundation/onedesign-react'

const manufacturers = [
  { text: 'Acciona', value: Manufacturer.Acciona },
  { text: 'Alstom Wind', value: Manufacturer.AlstomWind },
  {
    text: 'China Guodian Corporation',
    value: Manufacturer.ChinaGuodianCorporation
  },
  { text: 'Clipper', value: Manufacturer.Clipper },
  { text: 'CRRC', value: Manufacturer.CRRC },
  { text: 'CSIC - HZ Wind Power', value: Manufacturer.CSICHZWindPower },
  { text: 'DeWind', value: Manufacturer.DeWind },
  { text: 'DSTN', value: Manufacturer.DSTN },
  { text: 'Doosan', value: Manufacturer.Doosan },
  { text: 'Enercon', value: Manufacturer.Enercon },
  { text: 'Enron Wind', value: Manufacturer.EnronWind },
  { text: 'Envision Energy', value: Manufacturer.EnvisionEnergy },
  { text: 'Frisia', value: Manufacturer.Frisia },
  {
    text: 'Fuji Heavy Industries',
    value: Manufacturer.FujiHeavyIndustries
  },
  { text: 'Gamesa', value: Manufacturer.Gamesa },
  { text: 'GE Renewable Energy', value: Manufacturer.GERenewableEnergy },
  { text: 'Goldwind', value: Manufacturer.Goldwind },
  { text: 'Hanjin', value: Manufacturer.Hanjin },
  { text: 'Hitachi', value: Manufacturer.Hitachi },
  { text: 'Hyosung', value: Manufacturer.Hyosung },
  {
    text: 'Hyundai Heavy Industries',
    value: Manufacturer.HyundaiHeavyIndustries
  },
  { text: 'Inox Wind', value: Manufacturer.InoxWind },
  { text: 'Japan Steel Works', value: Manufacturer.JapanSteelWorks },
  { text: 'Končar', value: Manufacturer.Koncar },
  { text: 'Lagerwey Wind', value: Manufacturer.Lagerwey },
  { text: 'Machine Sazi Arak', value: Manufacturer.MachineSaziArak },
  { text: 'Mapna', value: Manufacturer.Mapna },
  {
    text: 'Mitsubishi Heavy Industries',
    value: Manufacturer.MitsubishiHeavyIndustries
  },
  { text: 'NEG Micon', value: Manufacturer.NEGMicon },
  { text: 'Nordex', value: Manufacturer.Nordex },
  { text: 'Nordic Windpower', value: Manufacturer.NordicWindpower },
  { text: 'NovaWind', value: Manufacturer.NovaWind },
  {
    text: 'Northern Power Systems',
    value: Manufacturer.NorthernPowerSystems
  },
  { text: 'PacWind', value: Manufacturer.PacWind },
  { text: 'Prokon', value: Manufacturer.Prokon },
  { text: 'Raum Energy Inc.', value: Manufacturer.RaumEnergyInc },
  { text: 'RRB Energy Limited', value: Manufacturer.RRBEnergyLimited },
  { text: 'Scanwind', value: Manufacturer.Scanwind },
  { text: 'SANY', value: Manufacturer.SANY },
  { text: 'Senvion', value: Manufacturer.Senvion },
  {
    text: 'Shanghai Electric (SEwind)',
    value: Manufacturer.ShanghaiElectric
  },
  {
    text: 'Siemens Gamesa Renewable Energy',
    value: Manufacturer.SiemensGamesaRenewableEnergy
  },
  { text: 'Sinovel', value: Manufacturer.Sinovel },
  {
    text: 'STX Windpower (South Korea / The Netherlands)',
    value: Manufacturer.STXWindpower
  },
  { text: 'Suzlon', value: Manufacturer.Suzlon },
  { text: 'TECO', value: Manufacturer.TECO },
  { text: 'UNISON', value: Manufacturer.UNISON },
  { text: 'Vergnet', value: Manufacturer.Vergnet },
  { text: 'Vestas', value: Manufacturer.Vestas },
  { text: 'WEG', value: Manufacturer.WEG },
  { text: 'Windflow', value: Manufacturer.Windflow },
  { text: 'WinWinD', value: Manufacturer.WinWinD },
  { text: 'World Wind', value: Manufacturer.WorldWind },
  { text: 'Xant', value: Manufacturer.Xant },
  { text: 'Other', value: Manufacturer.Other }
]

const windClass = [
  { text: 'IA', value: WindClass.IA },
  { text: 'IB', value: WindClass.IB },
  { text: 'IC', value: WindClass.IC },
  { text: 'IIA', value: WindClass.IIA },
  { text: 'IIB', value: WindClass.IIB },
  { text: 'IIC', value: WindClass.IIC },
  { text: 'IIIA', value: WindClass.IIIA },
  { text: 'IIIB', value: WindClass.IIIB },
  { text: 'IIIC', value: WindClass.IIIC },
  { text: 'IV', value: WindClass.IV }
]

const bladeSparMaterial = [
  {
    text: 'Glass Fibre',
    value: BladeSparMaterial.GlassFibre
  },
  {
    text: 'Carbon Fibre',
    value: BladeSparMaterial.CarbonFibre
  },
  {
    text: 'Balsa Wood',
    value: BladeSparMaterial.BalsaWood
  }
]

const generatorType = [
  config.defaultSelectOption,
  {
    text: 'Direct Drive Permanent Magnet Generator',
    value: GeneratorType.DirectDrivePermanentMagnetGenerator
  },
  {
    text: 'Geared Double Fed Induction Generator',
    value: GeneratorType.GearedDoubleFedInductionGenerator
  },
  {
    text: 'Geared Permanent Magnet Generator',
    value: GeneratorType.GearedPermanentMagnetGenerator
  },
  {
    text: 'Synchronous Generator',
    value: GeneratorType.SynchronousGenerator
  }
]

const turbineApplication = [
  {
    text: 'Offshore',
    value: ApplicationType.Offshore
  },
  {
    text: 'Onshore',
    value: ApplicationType.Onshore
  }
]

const defaulValues = {
  manufacturer: manufacturers[0].value,
  windClass: windClass[0].value,
  bladeSparMaterial: bladeSparMaterial[0].value,
  generatorType: generatorType[0].value,
  ratedPower: 3.5,
  rotorDiameter: 2,
  hubHeight: 20
}

const newTurbineFields = [
  {
    heading: 'Name *',
    id: 'name',
    tooltipHelpText: config.messages.turbineNameHelpText,
    isRequired: true
  },
  {
    heading: 'Generator type *',
    id: 'generatorType',
    defaultValue: defaulValues.generatorType,
    type: FieldType.Select,
    options: generatorType,
    isRequired: true
  },
  { heading: 'Model *', id: 'modelNumber', isRequired: true },
  {
    heading: 'Manufacturer',
    id: 'manufacturer',
    defaultValue: defaulValues.manufacturer,
    type: FieldType.Select,
    options: manufacturers
  },
  {
    heading: 'Turbine rated power (MW)',
    id: 'ratedPower',
    type: FieldType.Input,
    inputType: FieldInputType.Number,
    defaultValue: defaulValues.ratedPower,
    min: 0
  },
  {
    heading: 'Rotor diameter (m)',
    id: 'rotorDiameter',
    type: FieldType.Input,
    inputType: FieldInputType.Number,
    defaultValue: defaulValues.rotorDiameter,
    min: 0
  },
  {
    heading: 'Hub height (m)',
    id: 'hubHeight',
    type: FieldType.Input,
    inputType: FieldInputType.Number,
    defaultValue: defaulValues.hubHeight,
    min: 0
  },
  {
    heading: 'Wind class',
    id: 'windClass',
    defaultValue: defaulValues.windClass,
    type: FieldType.Select,
    options: windClass
  },
  {
    heading: 'Blade spar material',
    tooltipHelpText: config.messages.bladeSparMaterial,
    id: 'bladeSparMaterial',
    defaultValue: defaulValues.bladeSparMaterial,
    type: FieldType.Select,
    options: bladeSparMaterial
  }
] as any

const getManufacturer = (text?: string) =>
  manufacturers?.find(i => i.text === text)?.value

const useTurbineOptions = () => ({
  manufacturers,
  windClass,
  bladeSparMaterial,
  generatorType,
  turbineApplication,
  defaulValues,
  newTurbineFields,
  getManufacturer
})

export default useTurbineOptions
