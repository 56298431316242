import React, { useEffect, ReactNode, useRef } from 'react'
import Map, { MapRef } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { config } from '@/utils'

const { mapbox } = config
const { europeViewState, publicAccessToken, mapStyle } = mapbox

interface Props {
  id?: string
  width?: number | string
  height?: number | string
  longitude?: number
  latitude?: number
  zoom?: number
  bounds?: any
  children?: ReactNode
}

const MapBoxWrapper = ({
  id,
  width = '100%',
  height = 400,
  longitude = europeViewState.longitude,
  latitude = europeViewState.latitude,
  zoom = europeViewState.zoom,
  bounds,
  children
}: Props) => {
  const mapRef = useRef<MapRef>()
  useEffect(() => {
    mapRef?.current?.fitBounds?.(bounds, { padding: 60, duration: 1000 })
  }, [bounds])
  return (
    <Map
      id={id}
      ref={mapRef as any}
      initialViewState={{
        longitude,
        latitude,
        zoom
      }}
      style={{ width, height }}
      mapboxAccessToken={publicAccessToken}
      mapStyle={mapStyle}
      renderWorldCopies={false}
      preserveDrawingBuffer>
      {children}
    </Map>
  )
}

export default React.memo(MapBoxWrapper)
