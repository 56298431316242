import React, { useEffect } from 'react'
import { useAppSelector } from '@/store/hooks'
import {
  OButton,
  ORow,
  OCol,
  OContainer
} from '@dnvgl-onefoundation/onedesign-react'
import { config, helper } from '@/utils'
import { NavigationCards, OnboardingTutorial } from '@/components/helpers'
import * as Constants from '@/components/helpers/Constants'

const { appFullName, links, icons, urls, staticAssetsUrlBlob } = config
const { isAuthenticated, setActiveNavItem, getGreeting } = helper

const navigationItems = [
  {
    path: urls.turbines,
    icon: icons.windTurbine,
    title: 'Turbines'
  },
  { path: urls.projects, icon: icons.projectsGrid, title: 'Projects' },
  {
    path: urls.scenarios,
    icon: icons.scenario,
    title: 'Scenarios'
  },
  { path: urls.providers, icon: icons.providersMap, title: 'Providers' },
  {
    path: urls.settingsDefault,
    icon: icons.calculator,
    title: 'Cost calculator settings'
  },
  {
    path: urls.howItWorks,
    icon: icons.infoCircle,
    title: 'How it works'
  }
]

const HomePage = () => {
  const { isLoadingCurrentUser, currentUser } = useAppSelector(s => s.users)

  const welcomeMessage = isAuthenticated(currentUser)
    ? `${getGreeting()} ${currentUser?.firstName}`
    : `Welcome to ${appFullName}`

  useEffect(() => {
    setActiveNavItem('/')
  }, [])

  return (
    <main
      className="container-fluid p-0 position-relative"
      style={{ flex: '1' }}>
      <div
        className="home-hero fg-primary-dark"
        style={{
          backgroundImage: `url('${staticAssetsUrlBlob}/images/rewind-home.png')`
        }}>
        {!isLoadingCurrentUser && !isAuthenticated(currentUser) && (
          <div
            className="position-absolute w-100 text-center"
            style={{ top: 70 }}>
            <div className="text-center">
              <h1 className="dnv-display-regular d-inline-block mt-5">
                {welcomeMessage}
              </h1>
              <div className="mt-4">
                <a href={links.header.signUp}>
                  <OButton iconClass="fal fa-user-plus" variant="primary">
                    <span data-testid={Constants.signUpBtn}> Sign up </span>
                  </OButton>
                </a>
                <span>&nbsp;&nbsp;&nbsp;</span>
                <a
                  href={`${links.header.logIn}?returnUrl=${encodeURIComponent(
                    window.location.pathname
                  )}`}>
                  <OButton iconClass="fal fa-sign-in-alt" variant="primary">
                    <span data-testid={Constants.signInBtn}> Sign in </span>
                  </OButton>
                </a>
              </div>
            </div>
          </div>
        )}
        {!isLoadingCurrentUser && isAuthenticated(currentUser) && (
          <>
            <OContainer className="position-relative">
              <ORow className="position-absolute w-100 home-hero-row">
                <OCol md="12" className="text-center mb-5">
                  <h1 className="dnv-display-regular d-inline-block">
                    {welcomeMessage}
                  </h1>
                </OCol>
                <OCol md="12">
                  <ORow className="mt-5">
                    <NavigationCards items={navigationItems} cardColumns="4" />
                  </ORow>
                </OCol>
              </ORow>
            </OContainer>
            <OnboardingTutorial />
          </>
        )}
      </div>
    </main>
  )
}

export default React.memo(HomePage)
