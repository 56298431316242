import React from 'react'

export const scrollToRef = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  e: React.MouseEvent<HTMLElement>
) => {
  e.preventDefault()
  if (ref.current) {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }
}
export const ScrollToElement = <T extends HTMLElement>({
  elementRef,
  children
}: {
  elementRef: React.RefObject<T>
  children: React.ReactNode
}) => {
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    scrollToRef(elementRef, e)
  }
  return (
    <div onClick={handleClick} className="link">
      {children}
    </div>
  )
}
