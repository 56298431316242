import { ProviderStatus } from '@/interfaces'
import { OTooltip } from '@dnvgl-onefoundation/onedesign-react'

import { status as statusUtils } from '@/utils'

import React from 'react'

interface Props {
  isTableView?: boolean
  status?: ProviderStatus
  className?: string
}

const { getStatusClass, getStatusLabel } = statusUtils

const StatusIndicator = ({ isTableView, status, className }: Props) =>
  isTableView ? (
    <span className={className}>
      <small
        className={[
          'd-inline',
          'fas',
          'fa-circle',
          getStatusClass(status)
        ].join(' ')}
      />
      <small className="ml-1 white-space-nowrap d-inline">
        {getStatusLabel(status)}
      </small>
    </span>
  ) : (
    <OTooltip content={getStatusLabel(status)}>
      <i
        className={['fas', 'fa-circle', getStatusClass(status), className].join(
          ' '
        )}
      />
    </OTooltip>
  )

export default React.memo(StatusIndicator)
