import { updateProperty, updateScenario } from '@/store/slices/scenarios'
import { useDispatch } from 'react-redux'
import { UnknownAction } from '@reduxjs/toolkit'
import { updateScenarioModel, updateScenarioProperty } from '@/interfaces'

export default function useScenarioUpdate() {
  const dispatch = useDispatch()
  const onPropertyUpdate = (
    propertyName: string,
    propertyValue: string | number | boolean | any,
    scenarioId: string
  ) => {
    const updateModel: updateScenarioProperty = {
      propertyName: propertyName,
      propertyValue: propertyValue,
      id: scenarioId
    }
    dispatch(updateProperty(updateModel) as unknown as UnknownAction)
  }
  const onScenarioUpdate = (payload: any, scenarioId: string) => {
    const updateModel: updateScenarioModel = {
      ...payload,
      scenarioId: scenarioId
    }
    dispatch(updateScenario(updateModel) as unknown as UnknownAction)
  }

  return { onPropertyUpdate, onScenarioUpdate }
}
