import { useChartData } from '@/hooks'
import { MaterialsBreakdownsViewModel } from '@/interfaces'
import { OCol, ORow } from '@dnvgl-onefoundation/onedesign-react'
import React, { FC } from 'react'
import { MaterialBreakdownAlert, MaterialBreakdownChart } from '../projects'
import { config } from '@/utils'
import MaterialsTable from './MaterialsTable'

const { materialBreakdown } = config

interface Props {
  data?: MaterialsBreakdownsViewModel | null
  height?: number
}

const MaterialComposition: FC<Props> = ({ data, height = 600 }) => {
  const turbineMaterials = materialBreakdown.turbineFields.filter(i => i.name !== 'concrete')
  const chartData = useChartData(data, 'total', turbineMaterials)
  return (
    <ORow>
      {!!data ? (
        <>
          <OCol md="6">
            <MaterialsTable
              data={data?.total}
              baseFields={turbineMaterials}
              total={data?.total?.total}
            />
          </OCol>
          <OCol md="6" className="my-4">
            <div id="matrialCompositionSrc">
              <MaterialBreakdownChart
                animationDuration={1}
                chartData={chartData}
                height={height}
              />
            </div>
          </OCol>
        </>
      ) : (
        <OCol md="12">
          <MaterialBreakdownAlert />
        </OCol>
      )}
    </ORow>
  )
}

export default React.memo(MaterialComposition)
