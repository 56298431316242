const tooltips = {
  providers: {
    'Second Life':
      'Repurposing of full components or turbines for reuse, including selling used parts and relocating turbines to new sites.',
    'Material Processing':
      'Collection and processing of waste materials from decommissioned wind farms, including recycling, recovery and landfill.',
    Decommissioning:
      'Disassembly and reinstatement of wind farms, including transporting materials to recycling and repurposing facilities.',
    Port: 'Port',
    hqAddress: `In some cases companies have multiple locations, this is the address of the company's headquarters.`,
    processDescription:
      'The main functions of the company/facility, and how they perform these functions and services.',
    capacity: 'Quantity of material the facility can handle per day.',
    operationStatus:
      'ReWind sits at the forefront of wind farm circularity, therefore our database of facilities includes both operational existing facilities and facilities that will be built in the future. Select "operating" if the facility is currently in operation. Select "planned" if the facility will open in the future.',
    priceBladeProcessing:
      'Gate fee charged by blade processing facilities per tonne of material received.',
    commercialPartners:
      'Current partners of the company specifically related to wind farm decommissioning.',
    certification:
      'Any ISO or other certification held by the company, specifically related to their decommissioning or material processing functions.',
    facilityType:
      'The overall function provided by the company, being one or more of second life (repurposing of full components or turbines for reuse, including selling used parts and relocating turbines to new sites), material processing (collection and processing of waste materials from decommissioned wind farms, including recycling, recovery and landfill) and decommissioning (disassembly and reinstatement of wind farms, including transporting materials to recycling and repurposing facilities).',
    materials:
      'All materials or components handled by the company. In the case of turbines and components, this should be selected only where the full component is taken and reprocessed or repurposed in a second life application.',
    Turbine: 'Full wind turbine refurbished, resold or repurposed.',
    Blades: 'Full blades refurbished, resold or repurposed.',
    Concrete:
      'Applicable for facilities that process concrete through recycling, energy recovery or landfill.',
    Metals:
      'Applicable for facilities that process metals through recycling, energy recovery or landfill.',
    'Rare Earths':
      'Applicable for facilities that process rare earths through recycling, energy recovery or landfill.',
    Oils: 'Applicable for facilities that process oils through recycling, energy recovery or landfill.',
    services:
      'The specific processes used by the facility in the handling of materials or components.',
    Recycling:
      'Recycling involves the process of recovering materials and components from decommissioned wind turbines and repurposing them for future use, reducing waste and minimizing the environmental impact of wind energy.',
    Repurposing:
      'Repurposing involves finding alternative uses for decommissioned wind turbines or their components, such as using them for different renewable energy projects or non-energy-related applications, extending their lifespan and reducing waste.',
    'Re-Sale':
      'Resale involves selling decommissioned turbines or components to others for reuse, reducing the need for new production.',
    Landfill:
      'Landfill refers to the practice of disposing of decommissioned wind turbines or their components in landfills, which can have negative environmental impacts and contribute to waste and pollution. It is typically considered the least desirable option for end-of-life management of wind turbines.',
    Recovery:
      'The conversion of non-recyclable waste materials into usable heat, electricity or fuel, i.e. cement coprocessing of blades.',
    'Turnkey Decommissioning':
      'Applicable for companies that offer complete dismantling and decommissioning services for wind projects, including the processing, resale, or repurposing of turbines and components.',
    Transport:
      'The transportation of decommissioned turbines or their components to a new facility for recycling, repurposing, or disposal.'
  },
  project: {
    FoundationWeights:
      'To use weights instead of volume in weights related calculations, make sure at least one of them is greater than 0.'
  },
  TotalsRounding:
    'Reported cost allocations may differ to total as a result of zero-significant figure presentation',
  ContingencyAllocation:
    'Allocation to cover unforeseen costs or delays to decommissioning.',
  Scope: {
    ScrapValue: 'The scrap metal values can be modified in settings.',
    ContingencyAllocation:
      'Default contingency allocation is 5% of total costs excluding project overheads and scrap value. This covers unforeseen costs or delays and can be modified in settings.'
  }
}

export default tooltips
