import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { showConfirm, showSuccess } from '@dnvgl-onefoundation/onedesign-react'
import { api, helper } from '@/utils'
import {
  getConfiguration,
  getDefaultConfiguration
} from '@/store/slices/configurations'
import {
  ConfigurationType,
  ParameterUpdateModel,
  ProjectType
} from '@/interfaces'

const {
  getProjectTypeName,
  genericErrorHandler,
  handleErrorMessage,
  isValidGuid
} = helper

const cleanChanges = (items: ParameterUpdateModel[], name: string) =>
  items.filter(i => i.name !== name)

const useConfigurationUpdate = () => {
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const [changes, setChanges] = useState<ParameterUpdateModel[]>([])
  const [selected, setSelected] = useState('')
  const dispatch = useDispatch()
  const [errors, setErrors] = useState<boolean>(false)

  const onChange = (
    name: string,
    value: number,
    type: ProjectType,
    min: number,
    max: number
  ) => {
    setErrors(value > max || value < min)
    setChanges([
      ...cleanChanges(changes, name),
      { name, value: Math.abs(value), type }
    ])
  }

  const onSaveChanges = (
    projectType: ProjectType,
    id: string | null,
    type: ConfigurationType | null
  ) => changes?.length && updateConfiguration?.(projectType, id, type, changes)

  const refreshConfiguration = (
    id: string | null,
    type: ConfigurationType | null
  ) =>
    dispatch(
      id !== null && isValidGuid(id)
        ? getConfiguration(id, type)
        : getDefaultConfiguration()
    )

  const updateConfiguration = (
    projectType: ProjectType,
    id: string | null,
    type: ConfigurationType | null,
    payload: ParameterUpdateModel[]
  ) => {
    setIsUpdating(true)
    api.configurations
      .updateConfigurationParameters(id, type, payload)
      .then(() => {
        showSuccess(
          getProjectTypeName(projectType),
          'Configuration successfully updated!'
        )
        refreshConfiguration(id, type)
        setChanges([])
      })
      .catch(handleErrorMessage)
      .finally(() => setIsUpdating(false))
  }

  const revertConfiguration = (
    id: string,
    name: string,
    type: ConfigurationType
  ) => {
    if (isValidGuid(id)) {
      showConfirm(
        `Revert ${name} to defaults`,
        (
          <div style={{ wordBreak: 'normal' }}>
            <p>Are you sure you want to revert the {type} settings?</p>
            <p>
              This action will overwrite this and dependent configurations with
              the standard global settings.
            </p>
          </div>
        ) as any
      ).then((isConfirmed: boolean) => {
        if (!isConfirmed) return undefined
        setIsUpdating(true)
        api.configurations
          .reset(id, type)
          .then(() => {
            showSuccess(name, 'Configuration successfully reset!')
            refreshConfiguration(id, type)
          })
          .catch(genericErrorHandler)
          .finally(() => setIsUpdating(false))
      })
    }
  }

  return {
    isSaveEnabled: changes?.length > 0,
    isUpdating,
    selected,
    setSelected,
    revertConfiguration,
    onChange,
    onSaveChanges,
    errors
  }
}
export default useConfigurationUpdate
