import React from 'react'

import { helper, config } from '@/utils'
import { OAlert } from '@dnvgl-onefoundation/onedesign-react'

const MaterialBreakdownAlert: React.FC = () => (
  <OAlert
    dismissable={false}
    message="Material breakdown is under development, and will be available soon."
    description={
      <div className="my-2">
        Please get in touch with us at{' '}
        <a href={helper.getFeedbackLink('Material breakdown request')}>
          {config.support.to}
        </a>{' '}
        to request the material breakdown for this turbine.
      </div>
    }
  />
)

export default React.memo(MaterialBreakdownAlert)
