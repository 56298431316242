import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api } from '@/utils'
import { AppUser, UserGroupItem, UserRole, UserIdentity } from '@/interfaces'
import { Action, ActionCreator, AsyncAction } from '../index'
import { getErrorHandler } from './users'

export interface UserGroupState {
  isLoadingUserGroups: boolean
  userGroups: UserGroupItem[]
  isLoadingUsers: boolean
  users: AppUser[]
  isLoadingRoles: boolean
  roles: UserRole[]
  internalUsers: UserIdentity[]
  externalUsers: { [key: string]: AppUser[] }
}

const initialState: UserGroupState = {
  isLoadingUserGroups: false,
  userGroups: [],
  isLoadingUsers: false,
  users: [],
  isLoadingRoles: false,
  roles: [],
  internalUsers: [],
  externalUsers: {}
}

export const userGroupsSlice = createSlice({
  name: 'userGroups',
  initialState,
  reducers: {
    setIsLoadingUserGroups: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUserGroups = action.payload
    },
    setUserGroups: (state, action: PayloadAction<UserGroupItem[]>) => {
      state.userGroups = action.payload.sort(g => (g.isInternal ? -1 : 1))
    },
    resetUserGroups: state => {
      state.userGroups = []
    },
    setIsLoadingUsers: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUsers = action.payload
    },
    setUsers: (state, action: PayloadAction<AppUser[]>) => {
      state.users = action.payload
    },
    setIsLoadingRoles: (state, action: PayloadAction<boolean>) => {
      state.isLoadingRoles = action.payload
    },
    setRoles: (state, action: PayloadAction<UserRole[]>) => {
      state.roles = action.payload
    },
    setInternalUsers: (state, action: PayloadAction<UserIdentity[]>) => {
      state.internalUsers = action.payload
    },
    setExternalUsers: (
      state,
      action: PayloadAction<{ groupId: string; users: AppUser[] }>
    ) => {
      const { groupId, users } = action.payload
      const clone = state.externalUsers
      clone[groupId] = users
      state.externalUsers = clone
    },
    setTeam: (state, action: PayloadAction<UserGroupItem>) => {
      const index = state.userGroups.findIndex(x => x.id === action.payload.id)
      if (index >= 0) {
        state.userGroups[index] = action.payload
      }
    }
  }
})

export const { actions } = userGroupsSlice

export const getGroups: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoadingUserGroups(true))
  return api.groups
    .getAll()
    .then(data => {
      if (data.length) dispatch(actions.setUserGroups(data))
    })
    .catch(error => {
      dispatch(actions.setUserGroups([]))
      getErrorHandler(dispatch)(error)
    })
    .finally(() => dispatch(actions.setIsLoadingUserGroups(false)))
}

export const getInternalUsers: ActionCreator = () => dispatch => {
  dispatch(actions.setInternalUsers([]))
  return api.userAdministration
    .fetchInternalUsers()
    .then(data => {
      if (data.length) dispatch(actions.setInternalUsers(data))
    })
    .catch(getErrorHandler(dispatch))
}

export const getExternalUsers: (groupId: string) => AsyncAction =
  groupId => dispatch =>
    api.groups
      .getGroupUsers(groupId)
      .then(users => {
        if (users.length) dispatch(actions.setExternalUsers({ groupId, users }))
      })
      .catch(getErrorHandler(dispatch))

export const checkGroups: ActionCreator = () => (dispatch, getState) => {
  if (!getState().userGroups.userGroups.length) dispatch(getGroups())
}

export const checkInternalUsers: ActionCreator = () => (dispatch, getState) => {
  if (!getState().userGroups.internalUsers.length) dispatch(getInternalUsers())
}

export const checkExternalUsers: (groupId: string) => Action =
  groupId => (dispatch, getState) => {
    if (!getState().userGroups.externalUsers[groupId])
      dispatch(getExternalUsers(groupId))
  }

export const getRoles: ActionCreator = () => dispatch => {
  dispatch(actions.setIsLoadingRoles(true))
  dispatch(actions.setRoles([]))
  return api.userAdministration
    .fetchRoles()
    .then(data => {
      if (data.length) dispatch(actions.setRoles(data))
    })
    .catch(getErrorHandler(dispatch))
    .finally(() => dispatch(actions.setIsLoadingRoles(false)))
}

export const getUsers: (
  id: string | 'Unassigned' | undefined,
  isReload: boolean
) => AsyncAction =
  (id, isReload = false) =>
  dispatch => {
    dispatch(actions.setIsLoadingUsers(true))
    if (!isReload) dispatch(actions.setUsers([]))
    return api.users
      .fetchByGroup(id === 'Unassigned' ? undefined : id)
      .then(
        data => (isReload || data.length) && dispatch(actions.setUsers(data))
      )
      .catch(error => {
        getErrorHandler(dispatch)(error)
        isReload && dispatch(actions.setUsers([]))
      })
      .finally(() => dispatch(actions.setIsLoadingUsers(false)))
  }
