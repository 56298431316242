import { ProviderStatus } from '@/interfaces'

const getStatusClass = (status?: ProviderStatus) =>
  status === ProviderStatus.Operating ? 'text-success' : 'text-warning'

const getStatusLabel = (status?: ProviderStatus) =>
  status === ProviderStatus.Operating ? 'Operating' : 'Planned'

const status = {
  getStatusClass,
  getStatusLabel
}

export default status
