import React from 'react'
import { config } from '@/utils'
import PrintLogo from './PrintLogo'

const { appFullName } = config

const PrintHeader = () => (
  <div className="print-only border-bottom p-0 m-0 pb-2 d-flex justify-content-between">
    <div>
      <PrintLogo />
    </div>
    <div className="d-flex">
      <div>
        <h1 className="dnv-display-medium">{appFullName}</h1>
      </div>
      <div className="ml-3">
        <img width={72} src="/images/siteUrl.svg" alt="QR" />
      </div>
    </div>
  </div>
)

export default React.memo(PrintHeader)
