import { Text } from '@react-pdf/renderer'
import ReportList from './ReportList'

interface Props {
  data?: string | string[]
}

const styles = {
  paragraph: {
    fontSize: 11,
    marginBottom: 10
  }
}

const ConditionalRender: React.FC<Props> = ({ data }) =>
  data === undefined ? null : typeof data === 'string' ? (
    <Text style={styles.paragraph}>{data}</Text>
  ) : (
    <ReportList items={data as string[]} />
  )

export default ConditionalRender
