import React from 'react'
import { ProjectItemViewModel, SubstationWeightsViewModel } from '@/interfaces'
import { Typography } from '@mui/joy'
import { Grid2 } from '@mui/material'
import ReInput from '@/components/helpers/ReInput'
import { helper } from '@/utils'

interface Props {
  project?: ProjectItemViewModel | null
  disabled?: boolean
  weights?: SubstationWeightsViewModel | null
  onUpdateSubstations: (
    propertyName: string,
    propertyValue: any,
    type: string,
    offshoreType?: string
  ) => void
  offshoreType?: string
}
const { formatTonnes } = helper

const ProjectPageSubstationWeights = ({
  weights,
  onUpdateSubstations,
  offshoreType,
  disabled
}: Props) => {
  return (
    <Grid2 sx={{ marginTop: 2 }}>
      <Typography level="title-md" width="100%">
        {!!offshoreType && `Offshore substation ${offshoreType}`}
      </Typography>
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="steel"
        label="Substation steel weight"
        type="number"
        defaultValue={weights?.steel}
        helperText="tonnes"
        slotProps={{ input: { min: 0, max: 1000000 } }}
        min={0}
        max={1000000}
        disabled={disabled}
        onBlur={(n, v) =>
          onUpdateSubstations(
            n,
            v,
            !offshoreType ? 'onshore' : 'offshore',
            offshoreType
          )
        }
      />
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="concrete"
        label="Substation concrete weight"
        type="number"
        defaultValue={weights?.concrete}
        helperText="tonnes"
        slotProps={{ input: { min: 0, max: 1000000 } }}
        min={0}
        max={1000000}
        disabled={disabled}
        onBlur={(n, v) =>
          onUpdateSubstations(
            n,
            v,
            !offshoreType ? 'onshore' : 'offshore',
            offshoreType
          )
        }
      />
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="copper"
        label="Substation copper weight"
        type="number"
        defaultValue={weights?.copper}
        helperText="tonnes"
        slotProps={{ input: { min: 0, max: 1000000 } }}
        min={0}
        max={1000000}
        disabled={disabled}
        onBlur={(n, v) =>
          onUpdateSubstations(
            n,
            v,
            !offshoreType ? 'onshore' : 'offshore',
            offshoreType
          )
        }
      />
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="aluminium"
        label="Substation aluminium weight"
        type="number"
        defaultValue={weights?.aluminium}
        helperText="tonnes"
        slotProps={{ input: { min: 0, max: 1000000 } }}
        min={0}
        max={1000000}
        disabled={disabled}
        onBlur={(n, v) =>
          onUpdateSubstations(
            n,
            v,
            !offshoreType ? 'onshore' : 'offshore',
            offshoreType
          )
        }
      />
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="other"
        label="Substation other weight"
        type="number"
        defaultValue={weights?.other}
        helperText="tonnes"
        slotProps={{ input: { min: 0, max: 1000000 } }}
        min={0}
        max={1000000}
        disabled={disabled}
        onBlur={(n, v) =>
          onUpdateSubstations(
            n,
            v,
            !offshoreType ? 'onshore' : 'offshore',
            offshoreType
          )
        }
      />
      <ReInput
        wrapperStyles={{ marginTop: 2 }}
        propertyName="total"
        label="Substation total weight"
        readOnly
        variant="plain"
        value={formatTonnes(weights?.total)}
      />
    </Grid2>
  )
}

export default React.memo(ProjectPageSubstationWeights)
