import {
  CostValueOption,
  OffshoreFixedCostCalculationViewModel,
  OffshoreFloatingCostCalculationViewModel,
  OnshoreCostCalculationViewModel,
  UpdateOffshoreFixedCostCalculationViewModel,
  UpdateOffshoreFloatingCostCalculationViewModel,
  UpdateOnshoreCostCalculationViewModel
} from '@/interfaces'

const getValue = (
  results: any | null,
  type: any,
  option: CostValueOption = 'value'
) => {
  if (results === null) return undefined
  const item = results?.costs?.find?.((i: any) => i.type === type)

  if (!item) return undefined

  return item[option]
}

const transformOnshore = (
  calculation?: OnshoreCostCalculationViewModel | null
): UpdateOnshoreCostCalculationViewModel => {
  const output: UpdateOnshoreCostCalculationViewModel = {
    disassemblyCost: 0,
    removalCost: 0,
    materialDisposalCost: 0,
    bladeDisposalCost: 0,
    turbineFoundationsDisposalCost: 0,
    cableRemovalCost: 0,
    removalOfOtherOnSiteConstructions: 0,
    accessRoadsRemovalCost: 0,
    cranePadsRemovalCost: 0,
    reinstateCost: 0,
    projectOverheadCost: 0,
    metalScrapValue: 0
  }

  for (const key in output) {
    if (output.hasOwnProperty(key)) {
      ;(output as any)[key] = (calculation as any)?.[key]?.absolute || 0
    }
  }

  return output
}

const transformOffshoreFixed = (
  calculation?: OffshoreFixedCostCalculationViewModel | null
): UpdateOffshoreFixedCostCalculationViewModel => ({
  removalOfArrayCables: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfArrayCables.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfArrayCables?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfArrayCables?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfArrayCables?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfArrayCables?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfArrayCables?.dayRate?.deBurial?.absolute || 0
  },
  removalOfOffshoreSubstation: {
    updateOffshoreFixedCostViewModel: {
      mobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfOffshoreSubstation?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.removalOfOffshoreSubstation?.dayRate?.fuel?.absolute || 0,
    bargeTugSetup:
      calculation?.removalOfOffshoreSubstation?.dayRate?.bargeTugSetup
        ?.absolute || 0
  },
  removalOfExportCable: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfExportCable.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfExportCable?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfExportCable?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfExportCable?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfExportCable?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfExportCable?.dayRate?.deBurial?.absolute || 0
  },
  dismantlingAndTransportationOfOffshoreWindTurbines: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.mobilisation.absolute || 0,
      demobilisation:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.demobilisation.absolute || 0,
      operatingDuration:
        calculation?.dismantlingAndTransportationOfOffshoreWindTurbines
          .offshoreCostViewModel.operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.vesselAndStaff?.absolute || 0,
    fuel:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.fuel?.absolute || 0,
    crew:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.crew?.absolute || 0,
    equipment:
      calculation?.dismantlingAndTransportationOfOffshoreWindTurbines?.dayRate
        ?.equipment?.absolute || 0
  },
  preparationForRemovalOfFoundations: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.preparationForRemovalOfFoundations.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.fuel
        ?.absolute || 0,
    crew:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.crew
        ?.absolute || 0,
    equipment:
      calculation?.preparationForRemovalOfFoundations?.dayRate?.equipment
        ?.absolute || 0
  },
  removalOfFoundations: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfFoundations.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfFoundations.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfFoundations.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfFoundations?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfFoundations?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfFoundations?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfFoundations?.dayRate?.equipment?.absolute || 0
  },
  preDecommissioningSurveys:
    calculation?.preDecommissioningSurveys?.absolute || 0,
  postDecommissioningSurveys:
    calculation?.postDecommissioningSurveys?.absolute || 0,
  projectManagement: calculation?.projectManagement?.absolute || 0,
  harbourCosts: calculation?.harbourCosts?.absolute || 0,
  backOfficeResources: calculation?.backOfficeResources?.absolute || 0,
  personnelTransferAndSafetyVessels:
    calculation?.personnelTransferAndSafetyVessels?.absolute || 0,
  toolsAndJigs: calculation?.toolsAndJigs?.absolute || 0,
  metalScrapValue: calculation?.metalScrapValue?.absolute || 0
})

const transformOffshoreFloating = (
  calculation?: OffshoreFloatingCostCalculationViewModel | null
): UpdateOffshoreFloatingCostCalculationViewModel => ({
  removalOfArrayCables: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfArrayCables.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfArrayCables.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfArrayCables?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfArrayCables?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfArrayCables?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfArrayCables?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfArrayCables?.dayRate?.deBurial?.absolute || 0
  },
  removalOfOffshoreSubstation: {
    updateOffshoreFixedCostViewModel: {
      mobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removalOfOffshoreSubstation.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfOffshoreSubstation?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.removalOfOffshoreSubstation?.dayRate?.fuel?.absolute || 0,
    bargeTugSetup:
      calculation?.removalOfOffshoreSubstation?.dayRate?.bargeTugSetup
        ?.absolute || 0
  },
  removalOfExportCable: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.mobilisation
          .absolute || 0,
      demobilisation:
        calculation?.removalOfExportCable.offshoreCostViewModel.demobilisation
          .absolute || 0,
      operatingDuration:
        calculation?.removalOfExportCable.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removalOfExportCable?.dayRate?.vesselAndStaff?.absolute || 0,
    fuel: calculation?.removalOfExportCable?.dayRate?.fuel?.absolute || 0,
    crew: calculation?.removalOfExportCable?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.removalOfExportCable?.dayRate?.equipment?.absolute || 0,
    deBurial:
      calculation?.removalOfExportCable?.dayRate?.deBurial?.absolute || 0
  },
  removingTurbineUnitTransportationToPortDissasembly: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.mobilisation.absolute || 0,
      demobilisation:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.demobilisation.absolute || 0,
      operatingDuration:
        calculation?.removingTurbineUnitTransportationToPortDissasembly
          .offshoreCostViewModel.operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.vesselAndStaff?.absolute || 0,
    fuel:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.fuel?.absolute || 0,
    crew:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.crew?.absolute || 0,
    equipment:
      calculation?.removingTurbineUnitTransportationToPortDissasembly?.dayRate
        ?.equipment?.absolute || 0
  },
  mooringSystemAndAnchorRemoval: {
    updateOffshoreCostViewModel: {
      mobilisation:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .mobilisation.absolute || 0,
      demobilisation:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .demobilisation.absolute || 0,
      operatingDuration:
        calculation?.mooringSystemAndAnchorRemoval.offshoreCostViewModel
          .operatingDuration || 0
    },
    vesselAndStaff:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.vesselAndStaff
        ?.absolute || 0,
    fuel:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.fuel?.absolute || 0,
    crew:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.crew?.absolute || 0,
    equipment:
      calculation?.mooringSystemAndAnchorRemoval?.dayRate?.equipment
        ?.absolute || 0
  },
  seaFasteningForOSS: calculation?.seaFasteningForOSS?.absolute || 0,
  preDecommissioningSurveys:
    calculation?.preDecommissioningSurveys?.absolute || 0,
  postDecommissioningSurveys:
    calculation?.postDecommissioningSurveys?.absolute || 0,
  projectManagement: calculation?.projectManagement?.absolute || 0,
  harbourCosts: calculation?.harbourCosts?.absolute || 0,
  backOfficeResources: calculation?.backOfficeResources?.absolute || 0,
  personnelTransferAndSafetyVessels:
    calculation?.personnelTransferAndSafetyVessels?.absolute || 0,
  metalScrapValue: calculation?.metalScrapValue?.absolute || 0
})

const costs = {
  getValue,
  transformOnshore,
  transformOffshoreFixed,
  transformOffshoreFloating
}

export default costs
