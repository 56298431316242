import { useCallback, useEffect, useState } from 'react'
import {
  AppUser,
  AppUserRole,
  GroupType,
  SubscriptionTier,
  UpdateAction,
  UserGroupItem,
  UserRoleType,
  UserViewModel
} from '@/interfaces'
import { api, config, helper } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { ApplicationState } from '@/store'
import { getUsers } from '@/store/slices/userGroups'
import { Input, UserRoleIndicator } from '@/components/helpers'
import {
  OActionBtn,
  OButton,
  OToggleSwitch,
  OTooltip,
  showConfirm,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'

const {
  deepClone,
  handleErrorMessage,
  hasRole,
  isSuperAdministrator: checkIsSuperAdministrator,
  toLocaleDate
} = helper

const useAdminUsersList = (
  currentUser: AppUser,
  currentUserGroup: UserGroupItem
) => {
  const isSuperAdministrator = checkIsSuperAdministrator(currentUser)
  const isInternalGroup = currentUserGroup && currentUserGroup?.isInternal
  const isUnassignedGroup = currentUserGroup === config.groups.unassignedGroup

  const [allItems, setAllItems] = useState<UserViewModel[]>([])
  const users = useSelector((s: ApplicationState) => s.userGroups.users)
  const isLoadingUsers = useSelector(
    (state: ApplicationState) => state.userGroups.isLoadingUsers
  )
  const userGroups = useSelector(
    (s: ApplicationState) => s.userGroups.userGroups
  )

  const dispatch = useDispatch()

  const loadUsers = useCallback(
    (isReload?: boolean) => {
      if (currentUserGroup?.id)
        dispatch(getUsers(currentUserGroup.id, isReload ?? true))
    },
    [currentUserGroup, dispatch]
  )

  useEffect(() => {
    loadUsers(false)
  }, [loadUsers])

  useEffect(() => {
    users?.length ? setAllItems(extendUsers(users)) : setAllItems([])
  }, [users]) // eslint-disable-line react-hooks/exhaustive-deps

  const setUserRole = (u: AppUser, role: UserRoleType) => {
    api.userAdministration
      .patchRole({
        userId: u.id,
        role,
        updateAction: hasRole(u, role) ? UpdateAction.Remove : UpdateAction.Set
      })
      .then(() => {
        loadUsers()
        showSuccess('Updated', `Updated role for ${u.firstName} ${u.lastName}.`)
      })
      .catch(handleErrorMessage)
  }

  const setSubscriptionTier = (u: AppUser, t: SubscriptionTier) => {
    api.groups
      .setSubscriptionTier(currentUserGroup.id, u.id, t)
      .then(() => {
        loadUsers()
        showSuccess('Updated', `Updated tier for ${u.firstName} ${u.lastName}.`)
      })
      .catch(handleErrorMessage)
  }

  const addActionsForRoles = (u: AppUser) => (
    <OToggleSwitch
      small
      textLocation="hidden"
      disabled={!isSuperAdministrator}
      checked={hasRole(u, UserRoleType.SuperAdministrator)}
      onChange={() => setUserRole(u, UserRoleType.SuperAdministrator)}
      className="pointer">
      <UserRoleIndicator user={u} />
    </OToggleSwitch>
  )

  const subscriptionIndicator = (u: AppUser) => (
    <Input
      className="m-0"
      propertyName="tier"
      value={u?.subscription?.tier}
      showOnlyValue={!isSuperAdministrator}
      type="select"
      options={config.subscriptionTierOptions}
      onChange={(_n, v) => setSubscriptionTier(u, parseInt(v))}
    />
  )

  const removeUserFromCurrentGroup = (user: AppUser) =>
    showConfirm(
      `${currentUserGroup?.name}`,
      `Remove ${user.firstName} ${user.lastName} from ${currentUserGroup?.name}?`
    ).then(confirmed => {
      if (confirmed)
        api.groups
          .deleteUser(currentUserGroup.id, user.id)
          .then(() => loadUsers())
          .catch(helper.handleErrorMessage)
    })

  const addUserToGroup = (user: AppUser, group: UserGroupItem) => {
    api.groups
      .addUser(group.id, user.id)
      .then(() => {
        showSuccess('Updated', `User assigned to "${group?.name}".`)
        currentUserGroup.id === 'Unassigned' && loadUsers()
      })
      .catch(handleErrorMessage)
  }

  const addActionsForGroups = (u: AppUser) => {
    const targetGroups = isUnassignedGroup
      ? userGroups.filter(g => g?.type === GroupType.Company && !g?.isInternal)
      : currentUserGroup?.type === GroupType.Company
        ? userGroups.filter(
            g =>
              g?.type === GroupType.Team &&
              g?.companyId === currentUserGroup.id &&
              !u?.groups?.some(x => x.id === g.id)
          )
        : []

    return (
      <>
        {!isInternalGroup &&
          currentUserGroup?.type === GroupType.Company &&
          !!targetGroups?.length && (
            <OTooltip content="Add to Group" placement="top">
              <OActionBtn
                variant="flat"
                iconClass="fad fa-users"
                actions={targetGroups.map(o => ({
                  name: <span>{o?.name}</span>,
                  onClick: () => addUserToGroup(u, o)
                }))}
              />
            </OTooltip>
          )}
        {!isUnassignedGroup && (
          <OTooltip
            content={`Remove from ${currentUserGroup?.name}`}
            placement="top">
            <OButton
              variant="flat"
              iconClass="fad fa-users-slash text-danger"
              disabled={!isSuperAdministrator && currentUserGroup?.isInternal}
              onClick={() => removeUserFromCurrentGroup(u)}
            />
          </OTooltip>
        )}
      </>
    )
  }

  const extendUsers = (users: AppUser[] = []) =>
    users.map((user: AppUser) => {
      const updatedUser = deepClone(user) as UserViewModel
      if (isInternalGroup) updatedUser.admin = addActionsForRoles(updatedUser)
      updatedUser.subscriptionIndicator = subscriptionIndicator(updatedUser)
      updatedUser.subscriptionExpiration =
        updatedUser?.subscription?.tier !== SubscriptionTier.Trial
          ? config.missingValueCharacter
          : toLocaleDate(`${updatedUser?.subscription?.expirationDate}`)
      updatedUser.actions = addActionsForGroups(updatedUser)
      updatedUser.rolesDisplayString = updatedUser?.roles?.length
        ? updatedUser?.roles.map((r: AppUserRole) => r?.name)?.join(', ')
        : 'No roles'
      return updatedUser
    })

  return { isInternalGroup, isUnassignedGroup, isLoadingUsers, allItems }
}

export default useAdminUsersList
