import React from 'react'
import { OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input, RadioToggle } from '../helpers'
import { config } from '@/utils'
import { ProjectItemViewModel, SubstationViewModel } from '@/interfaces'
import ProjectPageSubstationWeights from '@/components/projects/ProjectPageSubstationWeights'

interface Props {
  project?: ProjectItemViewModel | null
  disabled?: boolean
  projectSubstations: SubstationViewModel | null
  onUpdate: (propertyName: string, propertyValue: any) => void
  onUpdateSubstations: (
    propertyName: string,
    propertyValue: any,
    type: string
  ) => void
}

const { projects } = config

const ProjectPageOnshoreSubstation = ({
  project,
  onUpdate,
  onUpdateSubstations,
  projectSubstations,
  disabled
}: Props) => {
  return (
    <>
      <OCol md="6" className="mt-2">
        <RadioToggle
          label="Onshore substation"
          disabled={disabled}
          value={project?.hasOnshoreSubstation}
          onToggle={() => {
            onUpdate('hasOnshoreSubstation', !project?.hasOnshoreSubstation)
          }}
        />
      </OCol>
      {project?.hasOnshoreSubstation ? (
        <>
          <OCol md="6" className="mt-2">
            <Input
              propertyName="count"
              label={`Number of onshore substations`}
              type="select"
              defaultValue={1}
              disabled={disabled}
              value={projectSubstations?.onshore?.count}
              options={projects.selectOptions.numberOfSubstations}
              onChange={(n, v) => onUpdateSubstations(n, v, 'onshore')}
            />
          </OCol>
          <ProjectPageSubstationWeights
            project={project}
            disabled={disabled}
            onUpdateSubstations={onUpdateSubstations}
            weights={projectSubstations?.onshore}
          />
        </>
      ) : (
        <OCol md="6" />
      )}
    </>
  )
}

export default React.memo(ProjectPageOnshoreSubstation)
