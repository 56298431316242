import {
  FieldInputType,
  FieldType,
  Option
} from '@dnvgl-onefoundation/onedesign-react'
import { AppUser, MaterialsBreakdownViewModel } from './BackendGenerated'
import { ReactNode } from 'react'

export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE'

export enum ProjectDocumentsSection {
  Documents = 'Files',
  Deliverables = 'Deliverables'
}

export type value = string | number | boolean | undefined

export interface FormField {
  name: string
}

export interface StringField extends FormField {
  type: 'string'
}

export interface TextareaField extends FormField {
  type: 'textarea'
}

export interface NumberField extends FormField {
  type: 'number'
}

export interface OptionsField extends FormField {
  options: string[]
  type: 'select' | 'checkbox'
}

export interface SelectField extends OptionsField {
  type: 'select'
}

export interface CheckBoxField extends OptionsField {
  type: 'checkbox'
}

export type FieldTemplate =
  | StringField
  | TextareaField
  | NumberField
  | SelectField
  | CheckBoxField

export type AnyFunction = (...args: any[]) => any

export type ViewToggleButtonType = 'map' | 'grid' | 'list'

export type TurbinePart = 'blades' | 'hub' | 'nacelle' | 'tower' | 'total'

export interface MaterialBreakdownItem {
  Steel?: number | null
  CastIron?: number | null
  Polyester?: number | null
  GFRP?: number | null
  EpoxyResin?: number | null
  PVCFoam?: number | null
  PURAdhesie?: number | null
  Aluminium?: number | null
  EpoxyGelcoat?: number | null
  Other?: number | null
  Total?: number
}

export interface LatLng {
  lng: number | undefined
  lat: number | undefined
}

export enum TabName {
  Saved = 0,
  Drafts = 1
}

export enum ScenarioProviderType {
  MetalProcessingProvider = 0,
  BladeProcessingProvider = 1,
  ConcreteProcessingProvider = 2
}

export type CostValueOption = 'value' | 'valuePerMW' | 'valuePerTurbine'

export type SelectedProviderType = 'metal' | 'blade' | 'other'

export interface CustomField {
  heading: string
  id: string
  type?: FieldType | 'label'
  inputType?: FieldInputType
  options?: Option[]
  defaultValue?: any
  tooltipHelpText?: string
  isRequired?: boolean
  onChange?: (value?: string) => void
}

export interface TableItemOption extends Option {
  description?: string
  isHighlighted?: boolean
  isBold?: boolean
  isSmall?: boolean
  rowSpan?: number
  rowSpanTitle?: ReactNode
  isNested?: boolean
  valueFormater?: Function
  textNode?: ReactNode
}

export interface UserViewModel extends AppUser {
  rolesDisplayString?: string
  admin?: ReactNode
  actions?: ReactNode
  subscriptionIndicator?: ReactNode
  subscriptionExpiration?: string
}

export enum ReportSectionCode {
  ExecutiveSummary = 0,
  Introduction = 1,
  MaterialComposition = 2,
  BalanceOfPlant = 3,
  Circularity = 4,
  SupplyChainMap = 5,
  DecommissioningCosts = 6
}
export type OnboardingStep = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export enum SettingsTabIndex {
  Default = 0,
  Countries = 1,
  Projects = 2
}
export interface TocNavItem {
  title: string
  id: string
  page?: number
}

export interface UpdateMaterialsBreakdownWithType {
  data: MaterialsBreakdownViewModel
  turbineId: string
  type: MaterialComponentType
}

export type MaterialComponentType =
  | 'blades'
  | 'hub'
  | 'nacelle'
  | 'tower'
  | 'total'

export type updateScenarioProperty = {
  propertyName: string
  propertyValue: string | number
  id: string
}

export type updateScenarioModel = {
  payload: any
  scenarioId: string
}

export enum Materials {
  'Steel',
  'Concrete',
  'Copper',
  'Aluminium',
  'Other'
}

export enum RecycleMethod {
  'Provider',
  'Distance',
  'Landfill'
}

export type ConfigurationType = 'country' | 'project' | 'scenario'

export * from './BackendGenerated'
