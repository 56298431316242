import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { config, helper } from '@/utils'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { createScenario, getScenarios } from '@/store/slices/scenarios'
import { CreateScenarioViewModel } from '@/interfaces'
import { UnknownAction } from '@reduxjs/toolkit'

const { urls } = config
const { genericErrorHandler, isValidGuid } = helper

const useScenarios = () => {
  const state = useAppSelector(s => s)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { isLoading, scenarios: paginatedScenarios } = state.scenarios

  const get = () => dispatch(getScenarios() as any)
  useEffect(() => {
    get()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const create = (payload: CreateScenarioViewModel) =>
    dispatch(createScenario(payload) as unknown as UnknownAction as any)
      ?.then(
        (id: string) => isValidGuid(id) && navigate(`${urls.scenarios}/${id}`)
      )
      .catch(genericErrorHandler)

  const addScenario = (payload: any) => payload?.name?.length && create(payload)

  return {
    isLoading,
    items: paginatedScenarios?.items,
    addScenario
  }
}

export default useScenarios
