import React from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input, InputValue } from '@/components/helpers'
import {
  OffshoreFixedCostCalculationViewModel,
  UpdateOffshoreFixedCostCalculationViewModel
} from '@/interfaces'
import { helper } from '@/utils'
import {
  offshoreFixedEngineeringCostSections,
  offshoreFixedOverheadValues,
  scrapValues
} from './consts'
import {
  getDefaultValue,
  onEngineeringSectionChange,
  getDayRateInputLabel,
  wrapperSectionNames
} from '@/utils/calculation'
import { useScenario } from '@/hooks'

interface Props {
  payload?: UpdateOffshoreFixedCostCalculationViewModel | null
  calculation?: OffshoreFixedCostCalculationViewModel | null
  onUpdate?: (data: UpdateOffshoreFixedCostCalculationViewModel) => void
  includeScrapValue?: boolean
}

const { formatCurrencyMask } = helper

const AdminUpdateCalculationResultsOffshoreFixed = ({
  payload,
  calculation,
  onUpdate,
  includeScrapValue
}: Props) => {
  const { scenario } = useScenario()

  return payload && calculation ? (
    <ORow>
      <OCol md="12">
        <table className="w-100 border">
          <tbody>
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={5}>
                Engineering costs
              </th>
              <th className="bg-dark fg-white p-2">
                {formatCurrencyMask(
                  calculation?.totalEngineeringCosts?.absolute,
                  scenario?.currency.currencyInformation!
                )}
              </th>
            </tr>
            <tr className="bg-light border">
              <th className="p-1 border">Description</th>
              <th className="p-1 border">
                Mobilisation ({scenario?.currency.currencyInformation.symbol})
              </th>
              <th className="p-1 border">
                Demobilisation ({scenario?.currency.currencyInformation.symbol})
              </th>
              <th className="p-1 border">Operating duration&nbsp;(days)</th>
              <th className="p-1 border">
                Day rate ({scenario?.currency.currencyInformation.symbol}/day)
              </th>
              <th className="p-1 border">
                Total cost ({scenario?.currency.currencyInformation.symbol})
              </th>
            </tr>
            {offshoreFixedEngineeringCostSections?.map?.(i => (
              <tr key={i.title}>
                <td className="p-0 pl-1 border">{i.title}</td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName="mobilisation"
                    defaultValue={Math.round(
                      getDefaultValue(i.section, 'mobilisation', payload)
                    )}
                    type="number"
                    min={0}
                    onChange={(n, v) =>
                      onEngineeringSectionChange?.(
                        payload,
                        i.section,
                        i.section === 'removalOfOffshoreSubstation'
                          ? 'updateOffshoreFixedCostViewModel'
                          : 'updateOffshoreCostViewModel',
                        n,
                        v,
                        onUpdate
                      )
                    }
                    onBlur={() => null}
                  />
                </td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName="demobilisation"
                    defaultValue={Math.round(
                      getDefaultValue(i.section, 'demobilisation', payload)
                    )}
                    type="number"
                    min={0}
                    onChange={(n, v) =>
                      onEngineeringSectionChange?.(
                        payload,
                        i.section,
                        i.section === 'removalOfOffshoreSubstation'
                          ? 'updateOffshoreFixedCostViewModel'
                          : 'updateOffshoreCostViewModel',
                        n,
                        v,
                        onUpdate
                      )
                    }
                    onBlur={() => null}
                  />
                </td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName="operatingDuration"
                    defaultValue={getDefaultValue(
                      i.section,
                      'operatingDuration',
                      payload
                    )}
                    type="number"
                    min={0}
                    onChange={(n, v) =>
                      onEngineeringSectionChange?.(
                        payload,
                        i.section,
                        i.section === 'removalOfOffshoreSubstation'
                          ? 'updateOffshoreFixedCostViewModel'
                          : 'updateOffshoreCostViewModel',
                        n,
                        v,
                        onUpdate
                      )
                    }
                    onBlur={() => null}
                  />
                </td>
                <td className="p-0 border">
                  {Object.keys((payload as any)?.[i.section])
                    ?.filter(i => !wrapperSectionNames?.includes(i))
                    ?.map?.(key => (
                      <Input
                        key={key}
                        label={getDayRateInputLabel(key)}
                        className="mb-0"
                        propertyName={key}
                        defaultValue={Math.round(
                          (payload as any)?.[i.section]?.[key]
                        )}
                        type="number"
                        min={0}
                        onChange={(n, v) =>
                          onEngineeringSectionChange?.(
                            payload,
                            i.section,
                            null,
                            n,
                            v,
                            onUpdate
                          )
                        }
                        onBlur={() => null}
                      />
                    ))}
                </td>
                <td className="p-0 border">
                  <InputValue
                    className="d-flex m-0 p-1 font-weight-bold"
                    value={formatCurrencyMask(
                      (calculation as any)?.[i.section]?.total?.absolute,
                      scenario?.currency.currencyInformation!
                    )}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={5}>
                Overhead costs
              </th>
              <th className="bg-dark fg-white p-2">
                {formatCurrencyMask(
                  calculation?.totalOverheadCosts?.absolute,
                  scenario?.currency.currencyInformation!
                )}
              </th>
            </tr>
            <tr className="bg-light border">
              <th colSpan={5} className="pl-1 border">
                Description
              </th>
              <th className="p-1 border">Cost</th>
            </tr>
            {offshoreFixedOverheadValues?.map?.(i => (
              <tr key={i.key}>
                <td colSpan={5} className="p-1 border">
                  {i.title}
                </td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName={i.key}
                    defaultValue={Math.round((payload as any)?.[i.key])}
                    type="number"
                    min={0}
                    onChange={(n, v) => onUpdate?.({ ...payload, [n]: v || 0 })}
                    onBlur={() => null}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={6}>
                Scrap value
              </th>
            </tr>
            <tr className="bg-light border">
              <th colSpan={5} className="pl-1 border">
                Description
              </th>
              <th className="p-1 border">
                {scenario?.currency.currencyInformation.symbol}/Wind farm
              </th>
            </tr>
            {scrapValues?.map?.(i => (
              <tr key={i.propertyName}>
                <td colSpan={5} className="p-0 pl-1 border">
                  {i.label}
                </td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName={i.propertyName}
                    defaultValue={
                      Math.round((payload as any)?.[i.propertyName]) || 0
                    }
                    type="number"
                    min={0}
                    onChange={(n, v) => onUpdate?.({ ...payload, [n]: v || 0 })}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={5}>
                {includeScrapValue ? 'Net Total' : 'Total'}
              </th>
              <th className="bg-dark fg-white p-2">
                {formatCurrencyMask(
                  calculation?.totalCosts?.absolute,
                  scenario?.currency.currencyInformation!
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </OCol>
    </ORow>
  ) : null
}
export default React.memo(AdminUpdateCalculationResultsOffshoreFixed)
