import { Page } from '@/components/layout'
import { useSelector } from 'react-redux'
import { ApplicationState } from '@/store'
import { BreadcrumbsTitle } from '@/components/helpers'
import AddUser from './AddUser'
import AddUserGroup from './AddUserGroup'
import AdminUsers from './AdminUsers'
import { config } from '@/utils'

import { AppUser } from '@/interfaces'

const { urls } = config

interface Props {
  currentUser: AppUser
}

const AdminContainer = ({ currentUser }: Props) => {
  const { isLoadingUserGroups, isLoadingUsers } = useSelector(
    (state: ApplicationState) => state.userGroups
  )
  return (
    <Page
      isLoading={isLoadingUserGroups || isLoadingUsers}
      disabled={isLoadingUserGroups || isLoadingUsers}
      title={
        <BreadcrumbsTitle
          title="Users"
          backLink={urls.admin}
          backName="Administration"
        />
      }
      isH1Title={false}
      actions={
        <div>
          <AddUser currentUser={currentUser} />
          &nbsp;
          <AddUserGroup currentUser={currentUser} />
        </div>
      }
      children={
        <div className="pl-2">
          <AdminUsers currentUser={currentUser} />
        </div>
      }
    />
  )
}

export default AdminContainer
