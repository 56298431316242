import { LegendOptions } from 'chart.js'
import React, { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'
import { config } from '@/utils'

interface MaterialBreakdownChartProps {
  animationDuration: number
  chartData: {
    labels: string[]
    values: number[]
  }
  height?: number
  width?: number
  legendPosition?: 'bottom' | 'left' | 'center' | 'right' | 'top' | 'chartArea'
}

const { charts, materialBreakdown } = config

const buildBackgroundColor = (labels: string[]) => {
  const output: string[] = []
  labels.forEach(label => {
    const field = materialBreakdown.turbineFields.find(i => i.heading === label)
    if (field) output.push(field.color)
  })

  return output
}

const MaterialBreakdownChart: FC<MaterialBreakdownChartProps> = ({
  animationDuration,
  chartData,
  height,
  width,
  legendPosition = 'bottom'
}) => {
  const total = chartData.values.reduce((acc, value) => acc + value, 0)
  return (
    <Doughnut
      className="material-breakdown-chart m-auto"
      height={height}
      width={width}
      options={{
        animation: { duration: animationDuration * 1000 },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context) {
                const label = context.label!
                const value = context.parsed
                const percentage = (value / total) * 100
                return `${label}: ${percentage.toFixed(2)}%`
              }
            }
          },
          legend: {
            ...(charts.legend as LegendOptions<'doughnut'>), // existing legend options
            position: legendPosition,
            labels: {
              ...charts.legend?.labels, // preserve existing label options
              font: {
                size: 12 // Set your desired font size here
              }
            }
          }
        }
      }}
      data={{
        labels: chartData.labels,
        datasets: [
          {
            data: chartData.values,
            backgroundColor: buildBackgroundColor(chartData.labels),
            borderWidth: 2
          }
        ]
      }}
    />
  )
}

export default React.memo(MaterialBreakdownChart)
