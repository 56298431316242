import React from 'react'
import { OCol, ODetails, ORow } from '@dnvgl-onefoundation/onedesign-react'
import { Page, ContentSection, SpinIndicator } from '@/components/layout'
import { useAccount } from '@/hooks'

const MyAccountPage = () => {
  const { isLoadingCurrentUser, fields, value } = useAccount()
  return (
    <Page title="My Account">
      <ORow>
        <OCol md="12">
          <ContentSection>
            {isLoadingCurrentUser ? (
              <SpinIndicator />
            ) : (
              <ODetails fields={fields} value={value as any} boldLabels />
            )}
          </ContentSection>
        </OCol>
      </ORow>
    </Page>
  )
}

export default React.memo(MyAccountPage)
