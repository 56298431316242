import { AnyAction, configureStore, ThunkAction } from '@reduxjs/toolkit'
import { generalSlice, GeneralState } from './slices/general'
import { usersSlice, UsersState } from './slices/users'
import { userGroupsSlice, UserGroupState } from './slices/userGroups'
import { projectsSlice, ProjectsState } from './slices/projects'
import { providersSlice, ProvidersState } from './slices/providers'
import { turbinesSlice, TurbinesState } from './slices/turbines'
import { scenariosSlice, ScenariosState } from './slices/scenarios'
import { calculationsctsSlice, CalculationsState } from './slices/calculations'
import {
  scenarioProvidersSlice,
  ScenarioProvidersState
} from './slices/scenarioProviders'
import { circularitySlice, CircularitysState } from './slices/circularity'
import {
  configurationsSlice,
  ConfigurationsState
} from './slices/configurations'

export interface ApplicationState {
  calculations: CalculationsState
  circularity: CircularitysState
  configurations: ConfigurationsState
  general: GeneralState
  projects: ProjectsState
  providers: ProvidersState
  scenarioProviders: ScenarioProvidersState
  scenarios: ScenariosState
  turbines: TurbinesState
  userGroups: UserGroupState
  users: UsersState
}

const reducer = {
  calculations: calculationsctsSlice.reducer,
  circularity: circularitySlice.reducer,
  configurations: configurationsSlice.reducer,
  general: generalSlice.reducer,
  projects: projectsSlice.reducer,
  providers: providersSlice.reducer,
  scenarioProviders: scenarioProvidersSlice.reducer,
  scenarios: scenariosSlice.reducer,
  turbines: turbinesSlice.reducer,
  userGroups: userGroupsSlice.reducer,
  users: usersSlice.reducer
}

const store = configureStore({
  reducer
})

export type ApplicationDispatch = typeof store.dispatch

export interface Action
  extends ThunkAction<any, ApplicationState, null, AnyAction> {}

export interface ActionCreator {
  (params?: any): Action
}

export interface AsyncAction
  extends ThunkAction<void | Promise<any>, ApplicationState, null, AnyAction> {}

export default store
