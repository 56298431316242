import React, { ReactNode, useEffect, useState } from 'react'
import { FormControl, FormHelperText, FormLabel, Input } from '@mui/joy'
import { Grid2 } from '@mui/material'
import { showError } from '@dnvgl-onefoundation/onedesign-react'
import { InputSlotsAndSlotProps } from '@mui/joy/Input/InputProps'

interface Props extends InputSlotsAndSlotProps {
  propertyName: string
  helperText?: string
  wrapperStyles?: any
  label?: string | ReactNode | null
  gridSize?: number
  value?: string | number
  defaultValue?: string | number
  type?: 'text' | 'number' | 'date' | 'select'
  min?: number | string
  max?: number | string
  onChange?: (propertyName: string, propertyValue: any) => void
  onBlur?: (propertyName: string, propertyValue: any) => void
  disabled?: boolean
  readOnly?: boolean
  variant?: 'plain' | 'outlined' | 'soft' | 'solid'
  sx?: any
}

const ReInput = (props: Props) => {
  const {
    propertyName,
    label,
    helperText,
    gridSize = 12,
    value,
    defaultValue,
    type,
    min,
    max,
    wrapperStyles,
    onChange: onChangeProp,
    onBlur: onBlurProp,
    ...rest
  } = props
  const [innerValue, setInnerValue] = useState(
    value || defaultValue || (type === 'number' ? 0 : '')
  )
  const [errors, setErrors] = useState<string[]>([])

  useEffect(() => {
    defaultValue && defaultValue !== value && setInnerValue(defaultValue)
  }, [defaultValue, value])

  const checkValue = (val: any) => {
    if (min !== undefined && val < min) {
      setErrors(prevErrors => [...prevErrors, `min value: ${min}`])
      return false
    }
    if (max !== undefined && val > max) {
      setErrors(prevErrors => [...prevErrors, `max value: ${max}`])
      return false
    }
    setErrors([])
    return true
  }

  useEffect(() => {
    errors?.length &&
      showError(
        `Oops! It appears there might be an issue with the data you entered.`,
        `Please review the field highlighted in red and make necessary corrections (${errors.join(
          ', '
        )})`
      )
  }, [errors])

  const eventHandlers = {
    onBlur: (e: any) => {
      checkValue(e.target.value) && onBlurProp?.(propertyName, e.target.value)
    },
    onChange: (e: any) => {
      setInnerValue(e.target.value)
      onChangeProp?.(propertyName, e.target.value)
    }
  }

  return (
    <Grid2 size={gridSize} sx={wrapperStyles}>
      <FormControl error={errors.length > 0}>
        {!!label && <FormLabel>{label}</FormLabel>}
        <Input
          sx={{
            backgroundColor: 'white'
          }}
          value={innerValue}
          type={type}
          {...eventHandlers}
          {...rest}
        />
        {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    </Grid2>
  )
}

export default React.memo(ReInput)
