import React, { useState } from 'react'
import { Button, ButtonGroup, IconButton } from '@mui/joy'
import IconComponent from '@/components/helpers/IconComponent'

interface Props {
  onSave?: () => void
  onSaveToDrafts?: () => void
  onSaveAndClose?: () => void
}

const SaveButton = ({ onSave, onSaveToDrafts, onSaveAndClose }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  const hideMenu = () => setIsMenuOpen(false)

  const handleSave = () => {
    hideMenu()
    onSave?.()
  }

  const handleSaveToDrafts = () => {
    hideMenu()
    onSaveToDrafts?.()
  }

  const handleSaveAndClose = () => {
    hideMenu()
    onSaveAndClose?.()
  }

  return (
    <div className="d-inline position-relative">
      <ButtonGroup>
        <Button variant="solid" color="primary" onClick={handleSave}>
          Save
        </Button>
        <IconButton
          variant="solid"
          color="primary"
          onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <IconComponent
            iconClass={`fal fa-chevron-${isMenuOpen ? 'up' : 'down'}`}
          />
        </IconButton>
      </ButtonGroup>
      {isMenuOpen && (
        <div
          className="position-absolute"
          style={{ right: 0, bottom: -75, width: 170, zIndex: 10000 }}>
          <Button
            variant="outlined"
            onClick={handleSaveAndClose}
            sx={{ backgroundColor: 'white', width: '100%' }}>
            Save and close
          </Button>
          <Button
            variant="outlined"
            onClick={handleSaveToDrafts}
            sx={{ backgroundColor: 'white', width: '100%' }}>
            Save to drafts
          </Button>
        </div>
      )}
    </div>
  )
}

export default React.memo(SaveButton)
