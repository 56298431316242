import React from 'react'
import { Box, FormControl, FormLabel, Radio, RadioGroup } from '@mui/joy'
import { TooltipHelpText } from '@/components/helpers/index'

interface Props {
  selectedValue?: boolean
  disabled?: boolean
  label?: string
  tooltip?: string
  yesLabel?: string
  noLabel?: string
  handleChange: () => void
}

const ReRadioToggle = ({
  selectedValue,
  handleChange,
  disabled,
  label,
  tooltip,
  yesLabel = 'Yes',
  noLabel = 'No'
}: Props) => (
  <Box sx={{ display: 'flex', gap: 2 }}>
    <FormControl>
      {!!label && (
        <FormLabel>
          {label}
          {!!tooltip && (
            <TooltipHelpText
              className="ml-2"
              message={tooltip}
              placement="top"
            />
          )}
        </FormLabel>
      )}
      <RadioGroup
        name="radio-buttons-group-focus"
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-end',
          marginTop: 0
        }}>
        <Radio
          label={yesLabel}
          checked={selectedValue}
          onChange={handleChange}
          value={0}
          name="radio-buttons"
          disabled={disabled}
        />
        <Radio
          sx={{ marginLeft: 4 }}
          label={noLabel}
          checked={!selectedValue}
          onChange={handleChange}
          value={1}
          name="radio-buttons"
          disabled={disabled}
        />
      </RadioGroup>
    </FormControl>
  </Box>
)

export default React.memo(ReRadioToggle)
