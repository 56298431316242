import React from 'react'

interface Props {
  iconClass: string
  color?: string
  size?: number
}

const IconComponent = ({ iconClass, color, size = 16 }: Props) => {
  return (
    <i
      className={`${iconClass}`}
      style={{
        pointerEvents: 'none',
        color: color || 'inherit',
        fontSize: `${size}px`
      }}
    />
  )
}

export default React.memo(IconComponent)
