import { useEffect, useState } from 'react'

import { helper } from '@/utils'
import useHtmlToImage from './useHtmlToImage'

const { callAsync } = helper
const captureDelay = 5000
const unlockDelay = 2000

const useReportCaptureImages = () => {
  const { captureImage } = useHtmlToImage()
  const [isDelayed, setIsDelayed] = useState<boolean>(true)
  const [projectSiteSrc, setProjectSiteSrc] = useState<string>()
  const [matrialCompositionSrc, setMatrialCompositionSrc] = useState<string>()
  const [supplyChainMapSrc, setSupplyChainMapSrc] = useState<string>()
  const [calcBreakdownSrc, setCalcBreakdownSrc] = useState<string>()

  useEffect(() => {
    callAsync(() => {
      captureImage('projectSiteSrc').then(i => setProjectSiteSrc(i))
      captureImage('matrialCompositionSrc').then(i =>
        setMatrialCompositionSrc(i)
      )
      captureImage('supplyChainMapSrc').then(i => setSupplyChainMapSrc(i))
      captureImage('calcBreakdownSrc').then(i => setCalcBreakdownSrc(i))
      callAsync(() => setIsDelayed(false), unlockDelay)
    }, captureDelay)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    isDelayed,
    projectSiteSrc,
    matrialCompositionSrc,
    supplyChainMapSrc,
    calcBreakdownSrc
  }
}
export default useReportCaptureImages
