import React from 'react'
import Page from '../../layout/Page'
import ContentSection from '../../layout/ContentSection'
import { AppName } from '../../helpers'

const PrivacyPage = () => (
  <Page title="Privacy statement" isFullWidthTitle>
    <ContentSection className="pt-4">
      <p>
        <AppName /> service is provided on the Veracity data platform.
      </p>
      <p>
        The{' '}
        <a
          href="https://services.veracity.com/PrivacyStatement"
          target="_blank"
          rel="noreferrer">
          Veracity platform privacy statement
        </a>{' '}
        defines personal data and the steps we take to protect your personal
        data.
      </p>
    </ContentSection>
  </Page>
)

export default React.memo(PrivacyPage)
