import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { api, helper } from '@/utils'
import {
  ProcessedMaterial,
  ProviderInfoViewModel,
  ProviderItemViewModel
} from '@/interfaces'
import { getErrorHandler } from './users'
import { ActionCreator } from '../index'

export interface ProviderInfo extends ProviderInfoViewModel {
  provider?: ProviderItemViewModel
}

export interface ScenarioProvidersState {
  metalProcessingProvider: ProviderInfo | null
  bladeProcessingProvider: ProviderInfo | null
  concreteProcessingProvider: ProviderInfo | null
}

const initialState: ScenarioProvidersState = {
  metalProcessingProvider: null,
  bladeProcessingProvider: null,
  concreteProcessingProvider: null
}

const { isValidGuid } = helper

export const scenarioProvidersSlice = createSlice({
  name: 'scenarioProviders',
  initialState,
  reducers: {
    setProcessingProvider: (
      state,
      action: PayloadAction<ProviderInfo | null>
    ) => {
      switch (action.payload?.material) {
        case ProcessedMaterial.Metal:
          state.metalProcessingProvider = action.payload
          break
        case ProcessedMaterial.Blade:
          state.bladeProcessingProvider = action.payload
          break
        case ProcessedMaterial.Concrete:
          state.concreteProcessingProvider = action.payload
          break
      }
    },
    setMetalProcessingProvider: (
      state,
      action: PayloadAction<ProviderInfo | null>
    ) => {
      state.metalProcessingProvider = action.payload
    },
    setBladeProcessingProvider: (
      state,
      action: PayloadAction<ProviderInfo | null>
    ) => {
      state.bladeProcessingProvider = action.payload
    },
    setconcreteProcessingProvider: (
      state,
      action: PayloadAction<ProviderInfo | null>
    ) => {
      state.concreteProcessingProvider = action.payload
    }
  }
})

export const { actions } = scenarioProvidersSlice

export const resetProvider: ActionCreator = type => dispatch => {
  if (type === ProcessedMaterial.Metal)
    dispatch(actions.setMetalProcessingProvider(null))
  if (type === ProcessedMaterial.Blade)
    dispatch(actions.setBladeProcessingProvider(null))
  if (type === ProcessedMaterial.Concrete)
    dispatch(actions.setconcreteProcessingProvider(null))
}

export const getProvider: ActionCreator =
  (info: ProviderInfoViewModel) => dispatch => {
    const id: string = info.provider?.id || ''
    const material = info.material
    dispatch(resetProvider(material))

    if (!isValidGuid(id)) {
      dispatch(
        actions.setProcessingProvider({
          ...info,
          provider: undefined
        })
      )
      return
    }

    return api.providers
      .read(id)
      .then((provider: ProviderItemViewModel) => {
        dispatch(actions.setProcessingProvider({ ...info, provider }))
      })
      .catch(getErrorHandler(dispatch))
  }
