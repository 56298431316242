import React from 'react'
import { OModal } from '@dnvgl-onefoundation/onedesign-react'
import { useOnboardingTutorial } from '@/hooks'
import { config } from '@/utils'
import OnboardingStepCard from './OnboardingStepCard'

interface Props {
  isDisplayForced?: boolean
}

const { appName } = config

const OnboardingTutorial = ({ isDisplayForced }: Props) => {
  const {
    step,
    image,
    title,
    texts,
    isVisible,
    currentUserFirstName,
    isOnboardingSubmitted,
    hideModal,
    setIsOnboardingSubmitted,
    onNext,
    onPrev,
    onRemindLater,
    onSkipTour,
    onCompleted
  } = useOnboardingTutorial(isDisplayForced)

  return (
    <OModal
      className="modal-onboarding"
      visible={isVisible}
      hideCloseButton
      okText="OK"
      clickOutside={false}
      bodySlot={
        <OnboardingStepCard
          isOnboardingSubmitted={isOnboardingSubmitted}
          firstGreeting={`Welcome to ${appName}, ${currentUserFirstName}!`}
          step={step}
          image={image}
          title={title}
          texts={texts}
          onFormSubmitted={() => setIsOnboardingSubmitted(true)}
          onNext={onNext}
          onPrev={onPrev}
          onRemindLater={onRemindLater}
          onSkipTour={onSkipTour}
          onCompleted={onCompleted}
        />
      }
      size="lg"
      hideFooter
      onOk={hideModal}
      onCancel={hideModal}
    />
  )
}

export default React.memo(OnboardingTutorial)
