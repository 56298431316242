import React from 'react'
import { Option } from '@dnvgl-onefoundation/onedesign-react'
import { Image, Text, View } from '@react-pdf/renderer'
import Table from './Table'
import { helper } from '@/utils'
import {
  CurrencyInformationViewModel,
  ScenarioItemViewModel
} from '@/interfaces'

interface Props {
  costScenarioBreakdownTableItems?: any[]
  calcBreakdownSrc?: string
  styles?: any
  texts?: any
  currencyInformation?: CurrencyInformationViewModel
  scenario?: ScenarioItemViewModel
}

const { convertOptionsToTableRows, formatCurrencyMask } = helper

const ReportCalculationResultsPage: React.FC<Props> = ({
  costScenarioBreakdownTableItems,
  calcBreakdownSrc,
  styles,
  texts,
  currencyInformation,
  scenario
}) => {
  const headers: any[] = [
    '',
    `${currencyInformation?.symbol}/Wind Farm`,
    `${currencyInformation?.symbol}/Turbine`,
    `${currencyInformation?.symbol}/MW`
  ]
  return (
    <View>
      <Text style={styles.subTitle}>Results</Text>
      {texts?.report.costCalculationResults.map((i: string) => (
        <Text style={styles.paragraph} key={i}>
          {i}
        </Text>
      ))}
      <View style={styles.container}>
        <Table
          breakPage
          name="Cost scenario breakdown"
          rows={convertOptionsToTableRows(
            costScenarioBreakdownTableItems as Option[]
          )}
          valueFormater={formatCurrencyMask}
          currencyInformation={currencyInformation}
          headers={headers}
        />
      </View>
      <View style={styles.section} break>
        <View style={styles.graphHeader}>
          <Text
            style={{
              paddingBottom: 2
            }}>
            Cost scenario breakdown graph
          </Text>
          {!!scenario && <Text>{scenario.name}</Text>}
        </View>
        {!!calcBreakdownSrc && <Image source={calcBreakdownSrc} />}
      </View>
    </View>
  )
}
export default ReportCalculationResultsPage
