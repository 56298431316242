import { useEffect } from 'react'
import {
  showConfirm,
  showError,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import { api, helper, config } from '@/utils'
import { updateProperty } from '@/store/slices/turbines'
import {
  useIsSuperAdministrator,
  useLocalStorage,
  useMaterialBreakdown,
  useTurbineItem
} from '@/hooks'
import { TabName } from '@/interfaces'

const { genericErrorHandler, waitaSecond, isInternalCompany } = helper
const { urls, messages } = config

const useTurbine = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isSuperAdministrator = useIsSuperAdministrator()
  const { isLoading, isUpdating } = useAppSelector(s => s.turbines)
  const { turbineItem, get: getTurbineModel } = useTurbineItem()
  const { turbineMaterialBreakdown, get: getMaterialBreakdown } =
    useMaterialBreakdown()
  const [, setTurbinesActiveTab] = useLocalStorage<number>('TurbinesPage')

  useEffect(() => {
    if (id) getTurbineModel(`${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (turbineItem?.id === id) getMaterialBreakdown(`${id}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [turbineItem])

  const onUpdate = (
    propertyName: string,
    propertyValue: string | number | any
  ) => dispatch(updateProperty(propertyName, propertyValue, `${id}`))

  const onRemove = () =>
    showConfirm(
      'Discard draft',
      `Please confirm removing the "${turbineItem?.name}" turbine. ${messages.infoWillBeLost}`
    ).then((confirmed: boolean) => {
      if (confirmed)
        api.turbines
          .delete(`${id}`)
          .then(() => {
            showSuccess('Trubine removed.', turbineItem?.name)
            waitaSecond(() => navigate(urls.turbines))
          })
          .catch(genericErrorHandler)
    })

  const onSave = () => waitaSecond(() => onUpdate?.('isDraft', false))

  const onSaveToDrafts = () => {
    const usedInProjects = turbineItem?.projects?.map((i: any) => i?.name)
    if (usedInProjects?.length)
      showError(
        `Unable to save the turbine to drafts because it is currently in use for the following projects: ${usedInProjects?.join(
          ', '
        )}.`
      )
    else {
      onUpdate?.('isDraft', true)
      setTurbinesActiveTab(TabName.Drafts)
      waitaSecond(() => navigate(urls.turbines))
    }
  }

  const onSaveAndClose = () => {
    onUpdate?.('isDraft', false)
    setTurbinesActiveTab(TabName.Saved)
    waitaSecond(() => navigate(urls.turbines))
  }

  return {
    turbineId: id,
    isLoading,
    isUpdating,
    isRemoveAllowed:
      (!isInternalCompany(turbineItem?.companyName) || isSuperAdministrator) &&
      !turbineItem?.isLocked,
    turbineItem,
    turbineMaterialBreakdown,
    isInternalTurbine: isInternalCompany(turbineItem?.companyName),
    onUpdate,
    onRemove,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  }
}
export default useTurbine
