import React from 'react'
import {
  OTabs,
  OTab,
  ORow,
  OCol,
  OButton
} from '@dnvgl-onefoundation/onedesign-react'
import { helper } from '@/utils'
import ConfigurationParameters from './ConfigurationParameters'
import { useConfigurationUpdate } from '@/hooks'
import { SpinIndicator } from '../layout'

import {
  ConfigurationType,
  CostCalculationConfigurationRegisterViewModel,
  ProjectType
} from '@/interfaces'

interface Props {
  data?: CostCalculationConfigurationRegisterViewModel | null
  id: string | 'default'
  type: ConfigurationType | null
  name: string | 'Default'
}

const { isEmptyObject, toLocaleDateTime } = helper

const Configuration: React.FC<Props> = ({ data, id, type, name }) => {
  const { isUpdating, revertConfiguration } = useConfigurationUpdate()
  return (
    <div>
      <ORow>
        <OCol md="9">
          <h2>{data?.name}</h2>
        </OCol>
        <OCol md="3" className="text-right mt-2">
          {!!data?.lastEdit && (
            <div className="pr-3">
              Last edit: {toLocaleDateTime(data?.lastEdit?.toString())}
            </div>
          )}
          {data?.reversible && !data?.isDefault && (
            <div>
              <OButton
                disabled={isUpdating}
                size="small"
                className="mr-4"
                onClick={() =>
                  type !== null && revertConfiguration(id, name, type)
                }>
                Revert to default configuration
              </OButton>
            </div>
          )}
        </OCol>
      </ORow>
      {isUpdating ? (
        <SpinIndicator />
      ) : (
        <OTabs>
          <OTab
            title="Onshore"
            disabled={isEmptyObject(
              data?.onshoreCostCalculationConfigurationSet?.parameters
            )}>
            <ConfigurationParameters
              data={data?.onshoreCostCalculationConfigurationSet}
              projectType={ProjectType.Onshore}
              id={id}
              type={type}
            />
          </OTab>
          <OTab
            title="Offshore bottom fixed"
            disabled={isEmptyObject(
              data?.offshoreBottomFixedCostCalculationConfigurationSet
                ?.parameters
            )}>
            <ConfigurationParameters
              data={data?.offshoreBottomFixedCostCalculationConfigurationSet}
              projectType={ProjectType.OffshoreBottomFixed}
              id={id}
              type={type}
            />
          </OTab>
          <OTab
            title="Offshore floating"
            disabled={isEmptyObject(
              data?.offshoreFloatingCostCalculationConfigurationSet?.parameters
            )}>
            <ConfigurationParameters
              data={data?.offshoreFloatingCostCalculationConfigurationSet}
              projectType={ProjectType.OffshoreFloating}
              id={id}
              type={type}
            />
          </OTab>
        </OTabs>
      )}
    </div>
  )
}
export default React.memo(Configuration)
