import React from 'react'

const VeracityLogo = () => (
  <svg
    width="109"
    height="39"
    viewBox="0 0 109 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.82015 33.2132V34.4715H1.73706V22.4301H2.82015V28.3074H2.85201C3.17056 27.819 3.58469 27.4526 4.09438 27.2084C4.60407 26.9536 5.12968 26.8261 5.67123 26.8261C6.25525 26.8261 6.78087 26.927 7.24808 27.1288C7.72591 27.3305 8.12942 27.6066 8.45859 27.957C8.79839 28.3074 9.05854 28.7215 9.23906 29.1994C9.43019 29.6772 9.52576 30.1922 9.52576 30.7444C9.52576 31.2965 9.43019 31.8115 9.23906 32.2894C9.05854 32.7672 8.79839 33.1813 8.45859 33.5317C8.12942 33.8821 7.72591 34.1582 7.24808 34.36C6.78087 34.5617 6.25525 34.6626 5.67123 34.6626C5.12968 34.6626 4.60407 34.5405 4.09438 34.2963C3.58469 34.052 3.17056 33.691 2.85201 33.2132H2.82015ZM8.37895 30.7444C8.37895 30.3302 8.31524 29.9427 8.18782 29.5816C8.0604 29.21 7.87457 28.8914 7.63035 28.626C7.39674 28.3499 7.10473 28.1322 6.75432 27.9729C6.40391 27.8137 6.00571 27.734 5.55973 27.734C5.14561 27.734 4.76334 27.8137 4.41293 27.9729C4.06252 28.1216 3.75989 28.334 3.50505 28.61C3.2502 28.8755 3.04845 29.1941 2.89979 29.5657C2.76175 29.9267 2.69273 30.3196 2.69273 30.7444C2.69273 31.1691 2.76175 31.5673 2.89979 31.939C3.04845 32.3 3.2502 32.6132 3.50505 32.8787C3.75989 33.1442 4.06252 33.3565 4.41293 33.5158C4.76334 33.6645 5.14561 33.7388 5.55973 33.7388C6.00571 33.7388 6.40391 33.6645 6.75432 33.5158C7.10473 33.3565 7.39674 33.1442 7.63035 32.8787C7.87457 32.6026 8.0604 32.2841 8.18782 31.923C8.31524 31.5514 8.37895 31.1585 8.37895 30.7444Z"
      fill="#002A3E"
    />
    <path
      d="M14.4111 33.3087H14.443L16.7525 27.0173H17.9312L14.22 36.3987C13.997 36.9721 13.7103 37.4287 13.3599 37.7685C13.0201 38.1189 12.5263 38.2941 11.8786 38.2941C11.5494 38.2941 11.2256 38.2623 10.907 38.1986L11.0185 37.2111C11.2733 37.296 11.5388 37.3385 11.8149 37.3385C12.1865 37.3385 12.4785 37.2217 12.6909 36.9881C12.9033 36.7651 13.0891 36.4412 13.2484 36.0165L13.8536 34.4555L10.6681 27.0173H11.8786L14.4111 33.3087Z"
      fill="#002A3E"
    />
    <path
      d="M23.6696 23.1946H27.4127C28.1135 23.1946 28.8143 23.3114 29.5152 23.545C30.2266 23.7786 30.8637 24.1343 31.4265 24.6122C31.9999 25.0794 32.4618 25.6687 32.8122 26.3801C33.1626 27.081 33.3378 27.8986 33.3378 28.833C33.3378 29.7781 33.1626 30.601 32.8122 31.3018C32.4618 32.0027 31.9999 32.592 31.4265 33.0698C30.8637 33.537 30.2266 33.8875 29.5152 34.1211C28.8143 34.3547 28.1135 34.4715 27.4127 34.4715H23.6696V23.1946ZM24.8164 33.4521H27.0941C27.9436 33.4521 28.6816 33.3194 29.3081 33.0539C29.9452 32.7884 30.4708 32.4433 30.8849 32.0186C31.2991 31.5832 31.607 31.0895 31.8088 30.5373C32.0211 29.9851 32.1273 29.4171 32.1273 28.833C32.1273 28.249 32.0211 27.6809 31.8088 27.1288C31.607 26.5766 31.2991 26.0881 30.8849 25.6634C30.4708 25.228 29.9452 24.8776 29.3081 24.6122C28.6816 24.3467 27.9436 24.214 27.0941 24.214H24.8164V33.4521Z"
      fill="#002A3E"
    />
    <path
      d="M43.7201 32.7991H43.752V23.1946H44.8988V34.4715H43.4653L36.903 24.7714H36.8712V34.4715H35.7244V23.1946H37.1579L43.7201 32.7991Z"
      fill="#002A3E"
    />
    <path
      d="M51.3443 32.9902H51.3762L55.0396 23.1946H56.266L51.9496 34.4715H50.7391L46.4386 23.1946H47.6809L51.3443 32.9902Z"
      fill="#002A3E"
    />
    <path
      d="M7.02847 17.2413H9.01307L15.7473 0.517117H13.1384L9.05767 11.1537C8.72318 12.0457 8.3218 13.2275 8.23261 14.1195H8.00962C7.92042 13.2498 7.51904 12.0457 7.16226 11.1314L3.10385 0.517117H0.294189L7.02847 17.2413Z"
      fill="#002A3E"
    />
    <path
      d="M17.449 17.2413H27.818V14.9891H19.9242V9.88267H26.4801V7.63048H19.9242V3.90655H17.449V17.2413ZM17.449 2.76931H27.595V0.517117H17.449V2.76931Z"
      fill="#002A3E"
    />
    <path
      d="M30.3841 17.2413H32.8593V10.5293H36.5833L39.772 17.2413H42.604L40.1957 12.2687C39.5267 10.8861 39.2368 10.284 38.5679 9.86037V9.63738C40.0396 9.39209 41.7343 8.36634 41.7343 6.27024V4.77621C41.7343 1.92195 39.772 0.517117 36.6055 0.517117H30.3841V2.79161H36.6279C38.3449 2.79161 39.2591 3.43828 39.2591 4.91001V6.13645C39.2591 7.60818 38.3449 8.25485 36.6279 8.25485H32.8593V3.92885H30.3841V17.2413Z"
      fill="#002A3E"
    />
    <path
      d="M49.3745 7.36289C49.8873 6.02495 50.4448 4.64242 50.534 3.63897H50.757C50.9131 4.66472 51.4929 6.09185 52.028 7.51898L53.2768 10.7969H48.0588L49.3745 7.36289ZM43.0193 17.2413H45.606L47.1892 13.0937H54.1464L55.7074 17.2413H58.4724L51.7382 0.517117H49.7536L43.0193 17.2413Z"
      fill="#002A3E"
    />
    <path
      d="M66.4729 17.4643H67.008C71.1556 17.4643 73.7423 15.3236 73.7423 11.399H71.2448C71.2448 13.8965 69.7954 15.0783 66.9857 15.0783H66.4952C63.3956 15.0783 61.7901 13.6289 61.7901 10.5962V7.1622C61.7901 4.12955 63.3956 2.68011 66.4952 2.68011H66.9857C69.7954 2.68011 71.2448 3.86196 71.2448 6.35944H73.7423C73.7423 2.43483 71.1556 0.294128 67.008 0.294128H66.4729C62.0577 0.294128 59.2926 2.68011 59.2926 7.0284V10.73C59.2926 15.0783 62.0577 17.4643 66.4729 17.4643Z"
      fill="#002A3E"
    />
    <path d="M76.637 17.2413H79.1122V0.517117H76.637V17.2413Z" fill="#002A3E" />
    <path
      d="M86.1306 17.2413H88.6058V3.92885H86.1306V17.2413ZM81.0018 2.79161H93.7345V0.517117H81.0018V2.79161Z"
      fill="#002A3E"
    />
    <path
      d="M100.192 17.2413H102.667V11.0645L108.732 0.517117H106.123L102.712 6.47093C102.221 7.34059 101.82 8.23255 101.664 8.99071H101.441C101.262 8.25484 100.883 7.36289 100.37 6.44863L96.9809 0.517117H94.1713L100.192 10.9753V17.2413Z"
      fill="#002A3E"
    />
  </svg>
)

export default React.memo(VeracityLogo)
