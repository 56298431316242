import { ProviderInfoViewModel, ScenarioItemViewModel } from '@/interfaces'

const hasProviderInfoConfigured = (
  metalProcessingProvider?: ProviderInfoViewModel
) =>
  !!metalProcessingProvider &&
  (!!metalProcessingProvider.provider?.id ||
    (metalProcessingProvider.distance || 0) >= 0 ||
    !!metalProcessingProvider.isLandfill)

const useScenarioStepsAvailability = (
  scenario: ScenarioItemViewModel | null
) => {
  const isProvidersStepDisabled = !scenario?.project?.id
  const isDefineScopeStepDisabled =
    isProvidersStepDisabled ||
    !(
      hasProviderInfoConfigured(scenario?.providers?.metal) &&
      hasProviderInfoConfigured(scenario?.providers?.blade) &&
      hasProviderInfoConfigured(scenario?.providers?.concrete)
    )

  return {
    isProvidersStepDisabled,
    isDefineScopeStepDisabled,
    isSummaryStepDisabled: isDefineScopeStepDisabled
  }
}

export default useScenarioStepsAvailability
