import React from 'react'
import ReportSection from './ReportSection'
import { ORow } from '@dnvgl-onefoundation/onedesign-react'
import ReportTable from './ReportTable'
import CostCalculationChart from './CostCalculationChart'
import { helper } from '@/utils'
import { CurrencyInformationViewModel, ProjectType } from '@/interfaces'
import { PrintPageBreak } from '../print'

interface Props {
  projectName?: string
  projectType: ProjectType
  numberOfTurbines?: number
  turbineRatedPower?: number
  turbineName?: string
  costScenarioBreakdownTableItems?: any[]
  costScenarioBreakdownChartData?: any[]
  scenarioCurrency?: CurrencyInformationViewModel
}

const { formatCurrencyMask } = helper

const ReportCostScenarioBreakdown = ({
  projectName,
  projectType,
  costScenarioBreakdownTableItems,
  costScenarioBreakdownChartData,
  scenarioCurrency
}: Props) => {
  const onshoreHeaders = [
    '',
    `${scenarioCurrency?.symbol}/Wind farm`,
    `${scenarioCurrency?.symbol}/Turbine`,
    `${scenarioCurrency?.symbol}/MW`
  ]

  const offshoreHeaders = [
    '',
    '',
    `${scenarioCurrency?.symbol}/Wind farm`,
    `${scenarioCurrency?.symbol}/Turbine`,
    `${scenarioCurrency?.symbol}/MW`
  ]

  return (
    <>
      <PrintPageBreak />
      <ReportSection
        className="mt-1"
        titleLeft={`Cost scenario breakdown`}
        titleRight={projectName}>
        <ORow className="justify-content-center">
          <ReportTable
            headers={
              projectType === ProjectType.Onshore
                ? onshoreHeaders
                : offshoreHeaders
            }
            items={costScenarioBreakdownTableItems}
            valueFormater={formatCurrencyMask}
            className="border-right cost-scenario-table"
            scenarioCurrency={scenarioCurrency}
          />
        </ORow>
        <ORow className="justify-content-center">
          <div id="calcBreakdownSrc" className="my-4">
            <CostCalculationChart
              data={costScenarioBreakdownChartData}
              currencySymbol={scenarioCurrency?.symbol}
            />
          </div>
        </ORow>
      </ReportSection>
    </>
  )
}

export default React.memo(ReportCostScenarioBreakdown)
