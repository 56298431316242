import React, { useEffect, useState } from 'react'
import { ORow, OCol } from '@dnvgl-onefoundation/onedesign-react'
import { Input } from '@/components/helpers'
import { UpdateOnshoreCostCalculationViewModel } from '@/interfaces'
import { helper } from '@/utils'
import {
  onshoreEngineeringCosts,
  onshoreOverheadCosts,
  scrapValues
} from './consts'
import { useScenario } from '@/hooks'

interface Props {
  payload: UpdateOnshoreCostCalculationViewModel
  onUpdate?: (payload: UpdateOnshoreCostCalculationViewModel) => void
  includeScrapValue?: boolean
}
interface HeadlineProps {
  currencySymbol?: string
}
const { getArraySum, formatCurrencyMask } = helper

const DescriptionHeadline = ({ currencySymbol }: HeadlineProps) => (
  <tr className="bg-light border">
    <th className="p-1 border">Description</th>
    <th className="p-1 border">{currencySymbol}/Wind farm</th>
  </tr>
)

const AdminUpdateCalculationResultsOnshore = ({
  payload,
  onUpdate,
  includeScrapValue
}: Props) => {
  const [total, setTotal] = useState(0)
  const { scenario } = useScenario()

  useEffect(() => {
    setTotal(
      getArraySum([
        payload?.disassemblyCost || 0,
        payload?.removalCost || 0,
        payload?.materialDisposalCost || 0,
        payload?.bladeDisposalCost || 0,
        payload?.turbineFoundationsDisposalCost || 0,
        payload?.cableRemovalCost || 0,
        payload?.removalOfOtherOnSiteConstructions || 0,
        payload?.accessRoadsRemovalCost || 0,
        payload?.cranePadsRemovalCost || 0,
        payload?.reinstateCost || 0,
        payload?.projectOverheadCost || 0
      ]) - (payload?.metalScrapValue || 0)
    )
  }, [payload])

  return payload ? (
    <ORow>
      <OCol md="12">
        <table className="w-100 border">
          <tbody>
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={2}>
                Engineering costs
              </th>
            </tr>
            <DescriptionHeadline
              currencySymbol={scenario?.currency.currencyInformation.symbol}
            />
            {onshoreEngineeringCosts.map(i => (
              <tr key={i.propertyName}>
                <td className="p-0 pl-1 border">{i.label}</td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName={i.propertyName}
                    defaultValue={
                      Math.round((payload as any)?.[i.propertyName]) || 0
                    }
                    type="number"
                    onChange={(n, v) => onUpdate?.({ ...payload, [n]: v || 0 })}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={2}>
                Overhead costs
              </th>
            </tr>
            <DescriptionHeadline
              currencySymbol={scenario?.currency.currencyInformation.symbol}
            />
            {onshoreOverheadCosts.map(i => (
              <tr key={i.propertyName}>
                <td className="p-0 pl-1 border">{i.label}</td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName={i.propertyName}
                    defaultValue={
                      Math.round((payload as any)?.[i.propertyName]) || 0
                    }
                    type="number"
                    min={0}
                    onChange={(n, v) => onUpdate?.({ ...payload, [n]: v || 0 })}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2" colSpan={2}>
                Scrap value
              </th>
            </tr>
            <DescriptionHeadline
              currencySymbol={scenario?.currency.currencyInformation.symbol}
            />
            {scrapValues.map(i => (
              <tr key={i.propertyName}>
                <td className="p-0 pl-1 border">{i.label}</td>
                <td className="p-0 border">
                  <Input
                    noBorder
                    className="d-flex m-0 p-1"
                    propertyName={i.propertyName}
                    defaultValue={
                      Math.round((payload as any)?.[i.propertyName]) || 0
                    }
                    type="number"
                    onChange={(n, v) => onUpdate?.({ ...payload, [n]: v || 0 })}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <th className="bg-dark fg-white p-2">
                {includeScrapValue ? 'Net Total' : 'Total'}
              </th>
              <th className="bg-dark fg-white p-2">
                {formatCurrencyMask(
                  total,
                  scenario?.currency.currencyInformation!
                )}
              </th>
            </tr>
          </tbody>
        </table>
      </OCol>
    </ORow>
  ) : null
}

export default React.memo(AdminUpdateCalculationResultsOnshore)
