import { ProjectItemViewModel } from '@/interfaces'
import React from 'react'
import { helper } from '@/utils'
import { ContentSectionValue } from '../layout'

interface Props {
  p?: ProjectItemViewModel | null
}

const { getCheckedText } = helper

const SummaryStepDetailsOffshoreFloating = ({ p }: Props) => (
  <>
    <ContentSectionValue
      className="my-2"
      left="Floater total weight"
      right={p?.floaterTotalWeightT ? `${p?.floaterTotalWeightT} tonnes` : null}
    />
    <ContentSectionValue
      className="my-2"
      left="Mooring line length"
      right={
        p?.primaryMooringLine?.lengthM
          ? `${p?.primaryMooringLine?.lengthM} m`
          : null
      }
    />
    <ContentSectionValue
      className="my-2"
      left="Array cable length"
      right={p?.arrayCableLengthKm ? `${p?.arrayCableLengthKm} km` : null}
    />
    <ContentSectionValue
      className="my-2"
      left="Export cable length"
      right={p?.exportCableLengthKm ? `${p?.exportCableLengthKm} km` : null}
    />
    <ContentSectionValue
      className="my-2"
      left="Offshore substation"
      right={getCheckedText(!!p?.hasOffshoreSubstation)}
    />
  </>
)

export default React.memo(SummaryStepDetailsOffshoreFloating)
