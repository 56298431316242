import React, { useState, useEffect } from 'react'
import {
  OBadge,
  OButton,
  OCol,
  OModal,
  ORow,
  showConfirm,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { ProjectType, ScenarioItemViewModel } from '@/interfaces'
import { useScenarioCostCalculation } from '@/hooks'
import { SpinIndicator } from '@/components/layout'
import { api, helper, costs } from '@/utils'

import {
  AdminUpdateCalculationResultsOffshoreFixed,
  AdminUpdateCalculationResultsOffshoreFloating,
  AdminUpdateCalculationResultsOnshore
} from './'
import IconComponent from '@/components/helpers/IconComponent'
import { Button } from '@mui/joy'

const { transformOnshore, transformOffshoreFixed, transformOffshoreFloating } =
  costs
const { getProjectTypeName, toLocaleDateTime } = helper
interface Props {
  projectType?: ProjectType
  scenario?: ScenarioItemViewModel | null
}

const AdminUpdateCalculationResults = ({ projectType, scenario }: Props) => {
  const {
    isLoading,
    onshoreCalculation,
    offshoreFixedCalculation,
    offshoreFloatingCalculation,
    get: getCalculationData,
    refreshState,
    onDeleteConfirmed
  } = useScenarioCostCalculation()

  const [isUpdating, setIsUpdating] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [updatedAt, setUpdatedAt] = useState<Date | undefined>(undefined)
  const [payload, setPayload] = useState<any>({})
  const showModal = () => {
    setPayload({})
    setUpdatedAt(undefined)
    if (scenario?.id && projectType !== undefined) {
      getCalculationData(scenario?.id, projectType)
      setTimeout(() => setIsVisible(true), 1000)
    }
  }

  useEffect(() => {
    setUpdatedAt(onshoreCalculation?.updatedAt)
    setPayload(transformOnshore(onshoreCalculation))
  }, [onshoreCalculation])

  useEffect(() => {
    setUpdatedAt(offshoreFixedCalculation?.updatedAt)
    setPayload(transformOffshoreFixed(offshoreFixedCalculation))
  }, [offshoreFixedCalculation])

  useEffect(() => {
    setUpdatedAt(offshoreFloatingCalculation?.updatedAt)
    setPayload(transformOffshoreFloating(offshoreFloatingCalculation))
  }, [offshoreFloatingCalculation])

  const hideModal = () => setIsVisible(false)
  const handleOK = () => {
    if (scenario?.id && projectType !== undefined) {
      setIsUpdating(true)
      api.costCalculations
        .update(scenario?.id, projectType, payload)
        .then((payload: any) => {
          showSuccess(scenario.name, 'Successfully updated calculation results')
          refreshState(projectType, payload)
          hideModal()
        })
        .catch(helper.handleErrorMessage)
        .finally(() => setIsUpdating(false))
    }
  }

  const onDeleteClick = () =>
    showConfirm(
      'Delete calculation',
      'Are you sure you want to delete this calculation?'
    ).then(confirmed => {
      if (confirmed && scenario && projectType !== undefined)
        onDeleteConfirmed(scenario?.id, scenario?.name, projectType, hideModal)
    })

  const bodySlot =
    isLoading || isUpdating ? (
      <SpinIndicator />
    ) : (
      <div>
        {!!updatedAt && (
          <div className="text-right">
            Updated: {toLocaleDateTime(`${updatedAt}`)}
          </div>
        )}
        {projectType === ProjectType.Onshore && (
          <AdminUpdateCalculationResultsOnshore
            includeScrapValue={scenario?.includeScrapValue}
            payload={payload}
            onUpdate={payload => setPayload(payload)}
          />
        )}
        {projectType === ProjectType.OffshoreBottomFixed && (
          <AdminUpdateCalculationResultsOffshoreFixed
            includeScrapValue={scenario?.includeScrapValue}
            payload={payload}
            calculation={offshoreFixedCalculation}
            onUpdate={payload => setPayload(payload)}
          />
        )}
        {projectType === ProjectType.OffshoreFloating && (
          <AdminUpdateCalculationResultsOffshoreFloating
            includeScrapValue={scenario?.includeScrapValue}
            payload={payload}
            calculation={offshoreFloatingCalculation}
            onUpdate={payload => setPayload(payload)}
          />
        )}
      </div>
    )

  return scenario?.id && projectType !== undefined ? (
    <>
      <Button
        disabled={projectType === undefined}
        variant="plain"
        onClick={() => showModal()}
        startDecorator={
          <IconComponent iconClass="fas fa-tasks-alt" size={20} />
        }>
        Update results
      </Button>
      {projectType !== undefined && (
        <OModal
          className="w-100"
          titleText={
            <ORow className="w-100">
              <OCol md="10">
                <span className="mr-1">Update results: {scenario?.name},</span>
                <span className="mr-1">{getProjectTypeName(projectType)}</span>
                <OBadge outline variant="secondary">
                  Per wind farm
                </OBadge>
              </OCol>
              <OCol className="text-right" md="2">
                <OButton onClick={onDeleteClick}>Delete</OButton>
              </OCol>
            </ORow>
          }
          size="xl"
          visible={isVisible}
          hideCloseButton
          clickOutside={false}
          bodySlot={bodySlot}
          okText="Update"
          onOk={handleOK}
          onCancel={hideModal}
          okDisabled={isLoading || isUpdating || !payload}
          cancelDisabled={isLoading || isUpdating}
        />
      )}
    </>
  ) : null
}

export default React.memo(AdminUpdateCalculationResults)
