export const onshoreEngineeringCosts = [
  {
    propertyName: 'disassemblyCost',
    label: 'Turbine disassembly cost'
  },
  {
    propertyName: 'removalCost',
    label: 'Turbine removal cost'
  },
  {
    propertyName: 'materialDisposalCost',
    label: 'Material disposal cost'
  },
  {
    propertyName: 'bladeDisposalCost',
    label: 'Turbine blade disposal cost'
  },
  {
    propertyName: 'turbineFoundationsDisposalCost',
    label: 'Turbine foundations removal cost'
  },
  {
    propertyName: 'cableRemovalCost',
    label: 'Cable removal cost'
  },
  {
    propertyName: 'removalOfOtherOnSiteConstructions',
    label: 'Removal of other on-site constructions'
  },
  {
    propertyName: 'accessRoadsRemovalCost',
    label: 'Access roads removal cost'
  },
  {
    propertyName: 'cranePadsRemovalCost',
    label: 'Crane pads removal cost'
  },
  {
    propertyName: 'reinstateCost',
    label: 'Reinstate cost'
  }
]

export const onshoreOverheadCosts = [
  {
    propertyName: 'projectOverheadCost',
    label: 'Project overhead cost'
  }
]

export const scrapValues = [
  {
    propertyName: 'metalScrapValue',
    label: 'Metal scrap value'
  }
]

export const offshoreFloatingEngineeringCostSections = [
  {
    title: 'Removing turbine unit, transportation to port, disassembly',
    section: 'removingTurbineUnitTransportationToPortDissasembly'
  },
  {
    title: 'Removal of array cables',
    section: 'removalOfArrayCables'
  },
  {
    title: 'Mooring system and anchor removal',
    section: 'mooringSystemAndAnchorRemoval'
  },
  {
    title: 'Removal of export cables',
    section: 'removalOfExportCable'
  },
  {
    title: 'Removal of offshore substation',
    section: 'removalOfOffshoreSubstation'
  }
]

export const offshoreFixedEngineeringCostSections = [
  {
    title: 'Dismantling and transportation of offshore wind turbines',
    section: 'dismantlingAndTransportationOfOffshoreWindTurbines'
  },
  {
    title: 'Removal of array cables',
    section: 'removalOfArrayCables'
  },
  {
    title: 'Preparation for removal of foundations',
    section: 'preparationForRemovalOfFoundations'
  },
  {
    title: 'Removal of foundations',
    section: 'removalOfFoundations'
  },
  {
    title: 'Removal of export cables',
    section: 'removalOfExportCable'
  },
  {
    title: 'Removal of offshore substation',
    section: 'removalOfOffshoreSubstation'
  }
]

export const offshoreFloatingOverheadValues = [
  {
    title: 'Sea fastening (for OSS)',
    key: 'seaFasteningForOSS'
  },
  {
    title: 'Pre-decommissioning surveys',
    key: 'preDecommissioningSurveys'
  },
  {
    title: 'Post-decommissioning surveys',
    key: 'postDecommissioningSurveys'
  },
  {
    title: 'Project management',
    key: 'projectManagement'
  },
  {
    title: 'Harbour costs',
    key: 'harbourCosts'
  },
  {
    title: 'Back-office resources',
    key: 'backOfficeResources'
  },
  {
    title: 'Personnel transfer and safety vessels',
    key: 'personnelTransferAndSafetyVessels'
  }
]

export const offshoreFixedOverheadValues = [
  {
    title: 'Pre-decommissioning surveys',
    key: 'preDecommissioningSurveys'
  },
  {
    title: 'Post-decommissioning surveys',
    key: 'postDecommissioningSurveys'
  },
  {
    title: 'Project management',
    key: 'projectManagement'
  },
  {
    title: 'Harbour costs',
    key: 'harbourCosts'
  },
  {
    title: 'Back-office resources',
    key: 'backOfficeResources'
  },
  {
    title: 'Personnel transfer and safety vessels',
    key: 'personnelTransferAndSafetyVessels'
  },
  {
    title: 'Tools and jigs',
    key: 'toolsAndJigs'
  }
]
