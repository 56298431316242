import helper from './helper'

export const ValueLabelPlugin = {
  id: 'ValueLabelPlugin',
  afterDatasetsDraw: (chart: {
    data?: any
    getDatasetMeta?: any
    ctx?: any
  }) => {
    // Check if the plugin is enabled for a specific chart
    if (!(chart as any)?.options?.plugins?.valueLabelPlugin?.enabled) {
      return undefined
    }
    const { ctx } = chart
    ctx.font = '10px Arial'
    ctx.textAlign = 'left'
    ctx.textBaseline = 'middle'

    chart.data.datasets.forEach(
      (dataset: { data: { [x: string]: any } }, datasetIndex: any) => {
        const meta = chart.getDatasetMeta(datasetIndex)
        meta.data.forEach(
          (bar: { x: any; y: number }, index: string | number) => {
            const value =
              dataset.data[index][0] !== undefined &&
              dataset.data[index][1] !== undefined
                ? dataset.data[index][1] - dataset.data[index][0]
                : dataset.data[index]
            const num = Math.abs(parseFloat(value as string))
            if (num === 0) return undefined
            const dataValue =
              typeof num === 'number'
                ? helper.formatFinancialShortcut(num)
                : undefined
            ctx.fillText(dataValue, bar.x + 2, bar.y)
          }
        )
      }
    )
  }
}
