import { useState, useEffect } from 'react'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'

interface Coordinate {
  longitude: number
  latitude: number
}

function useBounds(coordinates: Coordinate[]): LngLatBounds | null {
  const [bounds, setBounds] = useState<LngLatBounds | null>(null)

  useEffect(() => {
    if (coordinates && coordinates.length > 0) {
      const newBounds = coordinates.reduce(
        (bounds, coord) =>
          bounds.extend(new mapboxgl.LngLat(coord.longitude, coord.latitude)),
        new mapboxgl.LngLatBounds(
          new mapboxgl.LngLat(
            coordinates[0].longitude,
            coordinates[0].latitude
          ),
          new mapboxgl.LngLat(coordinates[0].longitude, coordinates[0].latitude)
        )
      )
      setBounds(newBounds)
    }
  }, [coordinates])

  return bounds
}

export default useBounds
