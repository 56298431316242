import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  showConfirm,
  showError,
  showSuccess
} from '@dnvgl-onefoundation/onedesign-react'
import { ActionCreator } from '@/store'
import { useLngLat, useLocalStorage } from '@/hooks'
import { useAppDispatch, useAppSelector } from '@/store/hooks'
import {
  getProject,
  updateProperty,
  actions,
  getProjectSubstations,
  updateSubstation,
  getProjectTurbines,
  updateTurbine,
  assignTurbine
} from '@/store/slices/projects'
import { api, config, helper } from '@/utils'
import {
  getTurbines,
  getUserTurbines,
  getTurbineMaterialBreakdown
} from '@/store/slices/turbines'
import useCountries from './useCountries'
import { createScenario } from '@/store/slices/scenarios'
import {
  ApplicationType,
  ProjectType,
  TabName,
  TurbineInfoPatch
} from '@/interfaces'
import { getProjectTypeName } from '@/utils/helper'
import { UnknownAction } from '@reduxjs/toolkit'

const { genericErrorHandler, isValidGuid, waitaSecond, sort } = helper
const { urls, inputLabels, projects } = config

const useProject = (projectId?: string | null) => {
  const state = useAppSelector(s => s)
  const { id: paramId } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { countries } = useCountries()
  const id = projectId !== undefined ? projectId : paramId
  const {
    project,
    isLoading,
    isNotFound: isProjectNotFound,
    isUpdating,
    projectSubstations,
    projectTurbines
  } = state.projects
  const {
    userTurbines,
    isLoading: isLoadingMaterialBreakdown,
    turbineMaterialBreakdown
  } = state.turbines
  const { lngLat, setLng, setLat } = useLngLat()
  const [materialBreakdownAll, setMaterialBreakdownAll] =
    useLocalStorage<boolean>('useProject-materialBreakdownAll', false)

  const [, setProjectsActiveTab] = useLocalStorage<number>('ProjectsPage')

  const onUpdate = (
    propertyName: string,
    propertyValue: string | number | boolean | any
  ) => dispatch(updateProperty(propertyName, propertyValue, `${id}`))

  const onUpdateSubstation = (
    propertyName: string,
    propertyValue: string | number | boolean | any,
    type: string,
    offshoreType?: string
  ) =>
    dispatch(
      updateSubstation(propertyName, propertyValue, `${id}`, type, offshoreType)
    )

  const onChangeTurbine = (
    projectId: string,
    oldTurbineId: string,
    newTurbineId: string,
    turbineInfo: TurbineInfoPatch
  ) =>
    dispatch(updateTurbine(projectId, oldTurbineId, newTurbineId, turbineInfo))

  const onAssignTurbine = (
    projectId: string,
    turbineId: string,
    turbineInfo: TurbineInfoPatch
  ) => dispatch(assignTurbine(projectId, turbineId, turbineInfo))

  function getAll(projectId: string) {
    getBasic(projectId)
    getAllTurbines()
  }

  function getBasic(projectId: string) {
    dispatch(getProject(`${projectId}`) as ActionCreator)
    dispatch(getProjectSubstations(`${projectId}`) as ActionCreator)
  }

  function getAllTurbines() {
    dispatch(getTurbines() as unknown as UnknownAction)
  }

  function getAllUserTurbines() {
    dispatch(getUserTurbines() as unknown as UnknownAction)
  }
  function getAllProjectTurbines(projectId: string) {
    dispatch(getProjectTurbines(`${projectId}`) as unknown as UnknownAction)
  }

  function handleTurbineTypeMissmatch() {
    const isOnshoreProject = project?.projectType === ProjectType.Onshore
    const isOnshoreTurbine =
      projectTurbines?.[0]?.application === ApplicationType.Onshore
    const isTurbineTypeMissmatch = isOnshoreProject !== isOnshoreTurbine

    if (project?.projectType) {
      if (isTurbineTypeMissmatch)
        showError(
          `This turbine is not suitable for "${getProjectTypeName(
            project?.projectType
          )}" projects.`,
          'Please select a different turbine.'
        )
    }
  }

  useEffect(() => {
    if (!!id) {
      if (isValidGuid(id)) getAll(`${id}`)
      else {
        showError('The project ID is invalid!', 'Please check the URL')
        dispatch(actions.setIsNotFound(true))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (project?.countryId && project?.location === null)
      updateLocation(project.countryId)

    if (project?.location) {
      setLng(project?.location?.longitude)
      setLat(project?.location?.latitude)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project])

  useEffect(() => {
    if (projectTurbines?.[0]?.turbineId !== turbineMaterialBreakdown?.turbineId)
      dispatch(
        getTurbineMaterialBreakdown(
          projectTurbines?.[0]?.turbineId
        ) as unknown as UnknownAction
      )

    if (projectTurbines?.[0]?.turbineId) handleTurbineTypeMissmatch()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, projectTurbines])

  const onLocationUpdate = (e: any) => {
    const longitude = e?.lngLat?.lng ? e?.lngLat?.lng.toFixed?.(4) : 0
    const latitude = e?.lngLat?.lat ? e?.lngLat?.lat.toFixed?.(4) : 0
    setLng(longitude)
    setLat(latitude)
    onUpdate('location', { longitude, latitude })
  }

  const updateLocation = (country: string) => {
    const countryData = countries?.find?.(i => i.id === country)
    if (countryData?.location)
      setTimeout(() => onUpdate('location', countryData.location), 1000)
  }

  const onCountryChange = (countryId: string) => {
    onUpdate('countryId', countryId)
    updateLocation(countryId)
  }

  const addScenario = (payload: any) =>
    dispatch(
      createScenario({
        projectId: id,
        ...payload
      }) as unknown as UnknownAction as any
    )
      ?.then(
        (id: string) => isValidGuid(id) && navigate(`${urls.scenarios}/${id}`)
      )
      .catch(genericErrorHandler)

  const save = () => waitaSecond(() => onUpdate?.('isDraft', false))

  const onSave = () => {
    if (project?.projectType === ProjectType.Onshore) {
      const missingFields: any[] = []
      projects.requiredFields.onshore.forEach(key => {
        if ((project as any)?.[key] === null) missingFields.push(key)
      })
      if (missingFields.length)
        showConfirm(
          'Save',
          (
            <div>
              <p>
                Please note that some important fields are missing in your
                current project:
              </p>
              <ul>
                {missingFields.map(i => (
                  <li key={i}>{(inputLabels as any)?.[i]}</li>
                ))}
              </ul>
              <p>
                You may proceed to save your project without these fields, but
                we recommend filling them in for better results.
              </p>
            </div>
          ) as unknown as string
        ).then(confirmed => {
          if (confirmed) save()
        })
      else save()
    } else save()
  }

  const onSaveToDrafts = () => {
    onUpdate?.('isDraft', true)
    setProjectsActiveTab(TabName.Drafts)
    waitaSecond(() => navigate(urls.projects))
  }

  const onSaveAndClose = () => {
    onUpdate?.('isDraft', false)
    setProjectsActiveTab(TabName.Saved)
    waitaSecond(() => navigate(urls.projects))
  }

  const onRemove = () =>
    showConfirm(
      project?.isDraft ? 'Discard draft' : 'Remove project',
      (
        <div>
          <p>
            Are you sure you want to remove this{' '}
            {project?.isDraft ? 'draft' : 'project'}?
          </p>
          <p className="text-danger">
            All scenarios associated with this{' '}
            {project?.isDraft ? 'draft' : 'project'} will also be removed!
          </p>
        </div>
      ) as any
    ).then((confirmed: boolean) => {
      if (confirmed)
        api.projects
          .delete(`${id}`)
          .then(() => {
            showSuccess(
              `${project?.isDraft ? 'Draft' : 'Project'} successfully ${
                project?.isDraft ? 'discarded' : 'removed'
              }.`,
              project?.name
            )
            waitaSecond(() => navigate(urls.projects))
          })
          .catch(genericErrorHandler)
    })

  const turbineOptions =
    userTurbines
      ?.filter(i => !i.isDraft)
      ?.filter(i =>
        project?.projectType === ProjectType.Onshore
          ? i.application === ApplicationType.Onshore
          : i.application !== ApplicationType.Onshore
      )
      ?.map(i => ({
        text: i?.name ? i.name : `${i.modelNumber}`,
        value: i.id
      })) || []

  return {
    isLoading,
    isProjectNotFound,
    isLoadingMaterialBreakdown,
    isUpdating,
    project,
    lngLat,
    turbineOptions: sort(turbineOptions, 'text'),
    turbineMaterialBreakdown,
    materialBreakdownAll,
    projectSubstations,
    projectTurbines,
    setMaterialBreakdownAll,
    addScenario,
    onCountryChange,
    getAllUserTurbines,
    getAllProjectTurbines,
    getBasic,
    onUpdate,
    onUpdateSubstation,
    onChangeTurbine,
    onAssignTurbine,
    onLocationUpdate,
    onRemove,
    onSave,
    onSaveToDrafts,
    onSaveAndClose
  }
}
export default useProject
