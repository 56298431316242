import { ReportSectionCode } from '@/interfaces'
import useLocalStorage from './useLocalStorage'
import { useEffect, useState } from 'react'

const allSections = [
  ReportSectionCode.ExecutiveSummary,
  ReportSectionCode.Introduction,
  ReportSectionCode.MaterialComposition,
  ReportSectionCode.BalanceOfPlant,
  ReportSectionCode.Circularity,
  ReportSectionCode.SupplyChainMap,
  ReportSectionCode.DecommissioningCosts
]

const sectionNames = {
  [ReportSectionCode.ExecutiveSummary]: 'Executive Summary',
  [ReportSectionCode.Introduction]: 'Introduction',
  [ReportSectionCode.MaterialComposition]: 'Material composition',
  [ReportSectionCode.BalanceOfPlant]: 'Balance of plant',
  [ReportSectionCode.Circularity]: 'Circularity',
  [ReportSectionCode.SupplyChainMap]: 'Supply chain map',
  [ReportSectionCode.DecommissioningCosts]: 'Decommissioning costs'
}

const useReportPrintSections = (
  scenarioId?: string,
  onChange?: (sections?: ReportSectionCode[]) => void
) => {
  const [printSections, setPrintSections] = useLocalStorage<
    ReportSectionCode[]
  >(`useReportPrintSections-${scenarioId}`, allSections)
  const [isPdfReady, setIsPdfReady] = useState<boolean>(false)

  useEffect(() => {
    setIsPdfReady(false)
    const delay = () =>
      setTimeout(() => {
        setIsPdfReady(true)
      }, 3000)
    clearTimeout(delay())

    delay()
  }, [printSections])

  const allSelected = printSections?.length === allSections?.length
  const clearAll = () => {
    setPrintSections([])
    onChange?.([])
  }
  const toggle = (input: string) => {
    const section = parseInt(input)

    const sections = printSections?.length ? printSections : []
    const value = sections?.includes(section)
      ? sections.filter(i => i !== section)
      : [...sections, section]

    setPrintSections(value)
    onChange?.(value)
  }

  const selectAll = () => {
    setPrintSections(allSections)
    onChange?.(allSections)
  }

  return {
    allSelected,
    sectionNames,
    printSections,
    setPrintSections,
    clearAll,
    toggle,
    selectAll,
    isPdfReady
  }
}
export default useReportPrintSections
