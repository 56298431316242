import React from 'react'

type Props = {
  colorCode?: string
  className?: string
  size?: number
}

const Indicator: React.FC<Props> = ({ colorCode, className, size = 12 }) => {
  const style = {
    backgroundColor: colorCode || '#f8f9fa',
    borderRadius: '50%',
    height: `${size}px`,
    width: `${size}px`,
    display: 'inline-block'
  }

  return <div className={className} style={style} />
}

export default React.memo(Indicator)
