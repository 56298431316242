import React from 'react'
import { OTable } from '@dnvgl-onefoundation/onedesign-react'
import { AppUser, UserGroupItem } from '@/interfaces'

import useAdminUsersList from './hooks/useAdminUsersList'

interface Props {
  currentUser: AppUser
  currentUserGroup: UserGroupItem
}

const basicFields = [
  { name: 'firstName', heading: 'First Name', sortable: true },
  {
    name: 'lastName',
    heading: 'Last Name',
    sortable: true
  },
  { name: 'email', heading: 'Email', sortable: true }
]

const subscriptionFields = [
  { name: 'subscriptionIndicator', heading: 'Subscription', sortable: false },
  { name: 'subscriptionExpiration', heading: 'Expires', sortable: false }
]

const actionsField = { name: 'actions', heading: 'Actions', sortable: false }

const internalFields = [{ name: 'admin', heading: 'Admin', sortable: false }]

const AdminUsersList = ({ currentUser, currentUserGroup }: Props) => {
  const { isInternalGroup, isUnassignedGroup, isLoadingUsers, allItems } =
    useAdminUsersList(currentUser, currentUserGroup)

  if (!currentUserGroup) return null

  let fields = basicFields
  if (!isUnassignedGroup && !isInternalGroup)
    fields = [...fields, ...subscriptionFields]
  if (isInternalGroup) fields = [...fields, ...internalFields]
  fields = [...fields, actionsField]

  return (
    <div className="row">
      <div className="col-12 mt-2 mb-5">
        {allItems?.length ? (
          <OTable
            fields={fields}
            allItems={allItems}
            rowsPerPage={10}
            hideGoToEndButtons={false}
            showActionIcons={false}
            showFilter={false}
            showFilterButton={false}
            showOptionsButton={false}
            showColumnsButton={false}
            selectMode={false}
            responsive={false}
            small={false}
            bordered={false}
            striped={false}
            className="optimized-table"
          />
        ) : (
          !isLoadingUsers && (
            <div className="mt-5 text-center">
              {currentUserGroup?.name}&nbsp;does not include any users yet.
            </div>
          )
        )}
      </div>
    </div>
  )
}

export default React.memo(AdminUsersList)
