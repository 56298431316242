import config from './config'
import helper from './helper'
import enumFlags from './enumFlags'
import {
  ProviderItemViewModel,
  ProviderMaterial,
  ProviderService,
  ProviderStatus,
  ProviderType
} from '@/interfaces'

const { missingValueCharacter } = config
const { formatCoordinates, formatLink, formatMailto, toYear } = helper

const providerTypeNames = {
  [ProviderType.Decommissioning]: 'Decommissioning',
  [ProviderType.MaterialProcessing]: 'Material Processing',
  [ProviderType.SecondLife]: 'Second Life',
  [ProviderType.Port]: 'Port'
}

const providerMaterialsEnumNames = {
  [ProviderMaterial.Turbine]: 'Turbine',
  [ProviderMaterial.Blades]: 'Blades',
  [ProviderMaterial.Concrete]: 'Concrete',
  [ProviderMaterial.Metals]: 'Metals',
  [ProviderMaterial.RareEarths]: 'Rare Earths',
  [ProviderMaterial.Oils]: 'Oils'
}

const providerServiceNames = {
  [ProviderService.Recycling]: 'Recycling',
  [ProviderService.Repurposing]: 'Repurposing',
  [ProviderService.ReSale]: 'Re-Sale',
  [ProviderService.Landfill]: 'Landfill',
  [ProviderService.Recovery]: 'Recovery',
  [ProviderService.TurnkeyDecommissioning]: 'Turnkey Decommissioning',
  [ProviderService.Transport]: 'Transport',
  [ProviderService.CraneServices]: 'Crane Services'
}

const iconCodes = {
  [ProviderType.SecondLife]: 'fal fa-repeat-alt',
  [ProviderType.MaterialProcessing]: 'fal fa-layer-group',
  [ProviderType.Decommissioning]: 'fal fa-truck-plow',
  [ProviderType.Port]: 'fal fa-anchor'
}

const pinColors = {
  [ProviderType.SecondLife]: 'BAE6B0',
  [ProviderType.MaterialProcessing]: '99D9F0',
  [ProviderType.Decommissioning]: '15C2BB',
  [ProviderType.Port]: '0096C7'
}

const facilityTypeDisplayNames = {
  [ProviderType.SecondLife]: providerTypeNames[ProviderType.SecondLife],
  [ProviderType.MaterialProcessing]:
    providerTypeNames[ProviderType.MaterialProcessing],
  [ProviderType.Decommissioning]:
    providerTypeNames[ProviderType.Decommissioning],
  [ProviderType.Port]: providerTypeNames[ProviderType.Port]
}

const buildOptions = (obj: any) =>
  Object.keys(obj).map(key => ({
    text: (obj as any)[key],
    value: parseInt(key)
  }))

const providerTypeNamesOptions = buildOptions(providerTypeNames)
const providerMaterialsEnumNamesOptions = buildOptions(
  providerMaterialsEnumNames
)
const providerServiceNamesOptions = buildOptions(providerServiceNames)

const buildProviderData = (
  provider?: ProviderItemViewModel | null,
  applyPlainLinks = false
) => [
  {
    text: 'Location and contact details',
    value: 'header'
  },
  {
    text: 'Company',
    value: provider?.name || missingValueCharacter
  },
  {
    text: 'Location',
    value: provider?.countryName || missingValueCharacter
  },
  {
    text: 'Facility address',
    value: provider?.address || missingValueCharacter
  },
  {
    text: 'Coordinates',
    value: formatCoordinates(provider?.location)
  },
  {
    text: 'HQ address',
    value: provider?.hqAddress || missingValueCharacter
  },
  {
    text: 'HQ country',
    value: provider?.hqCountryName || missingValueCharacter
  },
  {
    text: 'Phone number',
    value: provider?.phoneNumber || missingValueCharacter
  },
  {
    text: 'Website',
    value: applyPlainLinks
      ? provider?.websiteAddress
      : formatLink(provider?.websiteAddress)
  },
  {
    text: 'Email address',
    value: provider?.websiteAddress
      ? provider?.email
      : formatMailto(provider?.email)
  },
  {
    text: 'Details',
    value: 'header'
  },
  {
    text: 'Operation status',
    value:
      provider?.status === ProviderStatus.Operating ? 'Operating' : 'Planned'
  },
  {
    text: 'Operational since',
    value: provider?.operationalSinceYear
      ? toYear(provider?.operationalSinceYear as unknown as string)
      : missingValueCharacter
  },
  {
    text: 'Process description',
    value: provider?.processDescription || missingValueCharacter
  },
  {
    text: 'Certifications',
    value: provider?.certification || missingValueCharacter
  },
  {
    text: 'Facility type',
    value:
      enumFlags
        .getEnumFlagValues(provider?.providerTypes)
        .map((i: any) => (providerTypeNames as any)[i])
        .join(', ') || missingValueCharacter
  },
  {
    text: 'Materials processes',
    value:
      enumFlags
        .getEnumFlagValues(provider?.materials)
        .map((i: any) => (providerMaterialsEnumNames as any)[i])
        .join(', ') || missingValueCharacter
  },
  {
    text: 'Services',
    value:
      enumFlags
        .getEnumFlagValues(provider?.services)
        .map((i: any) => (providerServiceNames as any)[i])
        .join(', ') ||
      missingValueCharacter ||
      missingValueCharacter
  },
  {
    text: 'Commercial partners',
    value: provider?.commercialPartners || missingValueCharacter
  }
]

const providers = {
  providerTypeNames,
  providerServiceNames,
  providerMaterialsEnumNames,
  iconCodes,
  pinColors,
  facilityTypeDisplayNames,
  providerTypeNamesOptions,
  providerMaterialsEnumNamesOptions,
  providerServiceNamesOptions,
  buildProviderData
}

export default providers
