import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import { config, helper } from '@/utils'
import Table from './Table'
import { TotalWindFarmWeightsViewModel} from '@/interfaces'

interface Props {
  styles?: any
  texts?: any
  windfarmMaterialBreakdown?: TotalWindFarmWeightsViewModel
}

const { materialBreakdown, missingValueCharacter } = config

const { calculatePercentage, formatPercentage, formatTonnes } = helper

const buildRows = (windfarmMaterialBreakdown?: TotalWindFarmWeightsViewModel) => {
  const rows: any[] = []
  materialBreakdown.turbineFields.forEach(field => {
    const row = []
    const value = (windfarmMaterialBreakdown as any)?.[field.name]

    if (field?.name !== undefined && value !== undefined) {
      const total = windfarmMaterialBreakdown?.total
      row.push(field.heading)
      row.push(formatTonnes(value))
      if (total) {
        const percentage = formatPercentage(
          calculatePercentage(value, total) || 0
        )
        row.push(`${percentage || missingValueCharacter}`)
      }
    }

    rows.push(row)
  })

  return rows
}

const ReportWindFarmTotalMB: React.FC<Props> = ({
  styles,
  texts,
  windfarmMaterialBreakdown
}) => (
  <View style={{ paddingTop: 2 }}>
    <Table
      breakPage
      name="Wind farm total material breakdown"
      rows={buildRows(windfarmMaterialBreakdown)}
    />
    <Text style={styles.paragraphSmall}>{texts?.report?.totalWindFarm}</Text>
  </View>
)

export default ReportWindFarmTotalMB
