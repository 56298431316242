import { MaterialsBreakdownsViewModel, TurbinePart } from '@/interfaces'
import { useEffect, useState } from 'react'

const excludedKeys = [
  'total',
  'componentType',
  'balsaWood',
  'updatedAt',
  'createdAt',
  'createdBy',
  'updatedBy'
]
const useChartData = (
  data?: MaterialsBreakdownsViewModel | null,
  partName?: TurbinePart,
  baseFields?: any[]
) => {
  const [chartData, setChartData] = useState<any>({ labels: [], values: [] })

  useEffect(() => {
    const labels = []
    const values = []
    const partData = partName ? (data as any)?.[partName] : ''
    const total = partData?.total

    if (total) {
      for (const key in partData) {
        if (
          partData.hasOwnProperty(key) &&
          !excludedKeys.includes(key) &&
          !!partData?.[key]
        ) {
          labels.push(baseFields?.find?.(f => f.name === key)?.heading || '')
          values.push(partData?.[key])
        }
      }
    }

    setChartData({ labels, values })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, partName])

  return chartData
}

export default useChartData
