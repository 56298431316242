import React, { ReactNode } from 'react'

import { config } from '@/utils'

interface Props {
  className?: string
  label?: ReactNode
  value?: string | number
  isError?: boolean
  isHorizontal?: boolean
}

const { missingValueCharacter } = config

const InputValue = ({
  className,
  label,
  value,
  isError,
  isHorizontal
}: Props) => (
  <div
    className={[
      isHorizontal ? 'd-flex' : 'form-group',
      isError && 'text-danger',
      className
    ].join(' ')}>
    {isHorizontal ? (
      <>
        <div className="d-flex w-50 py-2">{label}</div>
        <div className="d-flex w-50 py-2 font-weight-bold">
          {value !== undefined ? value : missingValueCharacter}
        </div>
      </>
    ) : (
      <>
        {!!label && <label>{label}</label>}
        <div
          className={[
            label ? 'mt-2' : undefined,
            isError ? 'text-danger' : undefined
          ].join(' ')}>
          {value !== undefined ? value : missingValueCharacter}
        </div>
      </>
    )}
  </div>
)

export default React.memo(InputValue)
