import { config, helper } from '@/utils'
import { OButton } from '@dnvgl-onefoundation/onedesign-react'
import { Page, ContentSection, SpinIndicator } from '.'
import { UserRoleType } from '@/interfaces'
import { useProtectedContent } from '@/hooks'

interface Props {
  children: JSX.Element
  roles?: UserRoleType[]
}

const ProtectedContent = ({ roles, children }: Props) => {
  const {
    currentUser,
    isLoadingCurrentUser,
    isAuthSessionExpired,
    removeLocalStorageRecords
  } = useProtectedContent()

  if (isLoadingCurrentUser) return <SpinIndicator />

  if (isAuthSessionExpired || !helper.isAuthenticated(currentUser)) {
    removeLocalStorageRecords()
    return (
      <Page title="Authentication required">
        <ContentSection
          icon="fas fa-user-shield text-danger"
          title="You must be logged in to access this page">
          <div className="mt-4">
            <a
              href={`${
                config.links.header.logIn
              }?returnUrl=${encodeURIComponent(window.location.pathname)}`}>
              <OButton iconClass="fal fa-sign-in-alt">Sign in</OButton>
            </a>
          </div>
        </ContentSection>
      </Page>
    )
  }

  return roles !== undefined &&
    currentUser?.roles?.find(x => roles!.includes(x.id)) === undefined ? (
    <Page title="Access Denied">
      <ContentSection
        title={
          <span className="text-danger">
            You do not have permission to access this page.
          </span>
        }
        icon="fal fa-alien-monster text-danger"
      />
    </Page>
  ) : (
    children
  )
}

export default ProtectedContent
