import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import ReportList from './ReportList'

interface Props {
  styles?: any
  texts?: any
}

const ReportDisclaimerPage: React.FC<Props> = ({ styles, texts }) => (
  <View>
    <Text style={styles.pageTitle}>Important notice and disclaimer</Text>
    <View style={styles.paragraph}>
      <ReportList items={texts?.report?.disclaimer} fontSize={10} />
    </View>
  </View>
)

export default ReportDisclaimerPage
