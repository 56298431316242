import { useEffect } from 'react'
import useLocalStorage from './useLocalStorage'
import { useDispatch } from 'react-redux'

import { actions } from '@/store/slices/scenarios'
import { ScenarioItemViewModel } from '@/interfaces'

const useSelectProvidersStorage = (scenario: ScenarioItemViewModel | null) => {
  const dispatch = useDispatch()

  const [
    isMetalProcessingProviderRequired,
    setIsMetalProcessingProviderRequired
  ] = useLocalStorage(`isMetalProcessingProviderRequired-${scenario?.id}`, true)

  const [
    isBladeProcessingProviderIdRequired,
    setIsBladeProcessingProviderIdRequired
  ] = useLocalStorage(
    `isBladeProcessingProviderIdRequired-${scenario?.id}`,
    true
  )

  const [
    isConcreteProcessingProviderRequired,
    setIsConcreteProcessingProviderRequired
  ] = useLocalStorage(
    `isConcreteProcessingProviderRequired-${scenario?.id}`,
    true
  )

  useEffect(() => {
    dispatch(
      actions.setIsMetalProcessingProviderRequired(
        !!isMetalProcessingProviderRequired
      )
    )
    dispatch(
      actions.setIsBladeProviderRequired(!!isBladeProcessingProviderIdRequired)
    )
    dispatch(
      actions.setIsConcreteBladeProviderRequired(
        !!isConcreteProcessingProviderRequired
      )
    )
  }, [
    isMetalProcessingProviderRequired,
    isBladeProcessingProviderIdRequired,
    isConcreteProcessingProviderRequired,
    dispatch
  ])

  const toggleRequiredProvider = (type: 'metal' | 'blade' | 'concrete') => {
    if (type === 'metal')
      return setIsMetalProcessingProviderRequired(
        !isMetalProcessingProviderRequired
      )
    if (type === 'blade')
      return setIsBladeProcessingProviderIdRequired(
        !isBladeProcessingProviderIdRequired
      )
    if (type === 'concrete')
      return setIsConcreteProcessingProviderRequired(
        !isConcreteProcessingProviderRequired
      )
  }

  useEffect(() => {
    if (scenario?.providers?.metal?.provider?.id)
      setIsMetalProcessingProviderRequired(true)
    if (scenario?.providers?.blade?.provider?.id)
      setIsBladeProcessingProviderIdRequired(true)
    if (scenario?.providers?.concrete?.provider?.id)
      setIsConcreteProcessingProviderRequired(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scenario])

  return {
    toggleRequiredProvider,
    isMetalProcessingProviderRequired,
    isBladeProcessingProviderIdRequired,
    isConcreteProcessingProviderRequired
  }
}
export default useSelectProvidersStorage
